import React, { Fragment } from 'react'
import { useParams } from 'react-router-dom'
import { useResultsById } from '@/api/results'
import { MapSearchPanel } from '@/features/DantiMap'
import type { ResultWithId } from '@/utils/types/raw-result-types'

export function MapSearch() {
  const { itemId } = useParams()
  const resultsById = useResultsById()
  const [item, setItem] = React.useState<ResultWithId | undefined>()

  React.useEffect(() => {
    setItem(itemId ? resultsById[itemId] : undefined)
  }, [itemId, resultsById])

  return (
    <Fragment>
      <MapSearchPanel item={item} />
    </Fragment>
  )
}

export default MapSearch
