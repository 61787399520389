import React from 'react'
import { ActionIcon, Menu } from '@mantine/core'
import { handleCollectionLocationDownload } from '@/api/collections'
import { useCollectionItems } from '@/api/collections/collection-items'
import type { Collection } from '@/api/collections/types'
import { ConditionalTooltip } from '@/components/core/ConditionalTooltip'
import { Icon } from '@/components/lib/Icon'
import {
  openDeleteCollectionModal,
  openShareModal,
} from '@/features/AppLayout/Modals'
import { useFeatureFlag } from '@/hooks/use-feature-flag'
import { useAvatarDetails } from '@/stores/user-store'
import { FEATURE_FLAG_VALUES } from '@/utils/constants'

import styles from '@/features/Collections/Collection.module.css'

export type CollectionMenuOrientation = 'vertical' | 'horizontal'

export interface CollectionMenuProps {
  collection: Collection
  orientation: CollectionMenuOrientation
}

export const CollectionMenu = ({
  collection,
  orientation,
}: CollectionMenuProps) => {
  const { email } = useAvatarDetails()
  const { filter } = useCollectionItems()
  const isCommercial = useFeatureFlag(FEATURE_FLAG_VALUES.commercial)

  const isShared = React.useMemo(() => {
    const role = collection.users.find((user) => user.email === email)?.role
    if (!role) {
      return true
    }
    return role !== 'creator' && role !== 'owner'
  }, [collection, email])

  return (
    <Menu shadow="md" withinPortal trigger="hover">
      <Menu.Target>
        <ActionIcon
          variant="white"
          onClick={(event) => event.stopPropagation()}
          data-cy="collection-menu"
          bg="transparent"
        >
          <Icon
            name={orientation === 'vertical' ? 'more_vert' : 'more_horiz'}
            style={{ color: '#000000' }}
          />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown>
        {/* TODO update again when collection wide analysis is online
        <Menu.Item
          leftSection={<Icon name="analytics" />}
          onClick={() => openAnalyzeCollectionModal(collection.id)}
          data-cy="collection-analyze"
        >
          Image Detections
        </Menu.Item> */}
        <ConditionalTooltip
          label={
            isShared
              ? "You don't have access to share this saved search"
              : undefined
          }
        >
          {/* Note, not using disable flag because it prevents the tooltip from being shown */}
          <Menu.Item
            classNames={{
              item: isShared ? styles['disabledMenuItem'] : undefined,
            }}
            leftSection={<Icon name="send" />}
            onClick={(event) => {
              event.stopPropagation()
              if (isShared) {
                return
              }
              openShareModal(collection)
            }}
            data-cy="collection-download"
          >
            Share
          </Menu.Item>
        </ConditionalTooltip>

        {!isCommercial && (
          <Menu.Item
            leftSection={<Icon name="download" />}
            onClick={(event) => {
              event.stopPropagation()
              void handleCollectionLocationDownload({
                collectionId: collection.id,
                collectionName: collection.name,
                totalCount: collection.counts.total,
                filter,
              })
            }}
            data-cy="collection-download"
          >
            Download Location Data
          </Menu.Item>
        )}

        <ConditionalTooltip
          label={
            isShared
              ? `You can't delete saved searches that were shared with you`
              : undefined
          }
        >
          <Menu.Item
            leftSection={<Icon name="delete" />}
            color="red"
            classNames={{
              item: isShared ? styles['disabledMenuItem'] : undefined,
            }}
            onClick={(event) => {
              event.stopPropagation()
              if (isShared) {
                return
              }
              openDeleteCollectionModal(collection.id)
            }}
            data-cy="collection-delete"
          >
            Delete
          </Menu.Item>
        </ConditionalTooltip>
      </Menu.Dropdown>
    </Menu>
  )
}
