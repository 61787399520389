import { atom, useAtom } from 'jotai'

const currentPageAtom = atom(0)
const nextTokenAtom = atom('')
const previousTokenAtom = atom('')
const searchTermAtom = atom('')
const pageSizeAtom = atom(0)

export const useUserData = (): {
  currentPage: number
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>
  nextToken: string
  setNextToken: React.Dispatch<React.SetStateAction<string>>
  previousToken: string
  setPreviousToken: React.Dispatch<React.SetStateAction<string>>
  searchTerm: string
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>
  pageSize: number
} => {
  const [currentPage, setCurrentPage] = useAtom(currentPageAtom)
  const [nextToken, setNextToken] = useAtom(nextTokenAtom)
  const [previousToken, setPreviousToken] = useAtom(previousTokenAtom)
  const [searchTerm, setSearchTerm] = useAtom(searchTermAtom)
  const [pageSize] = useAtom(pageSizeAtom)
  return {
    currentPage,
    setCurrentPage,
    nextToken,
    setNextToken,
    previousToken,
    setPreviousToken,
    searchTerm,
    setSearchTerm,
    pageSize,
  }
}
