import { getThumbnailDataUrl } from '@/api/thumbnail/get-thumbnail-data-url'
import type { ImageryResultPart } from '@/api/thumbnail/types'
import { useQuery } from '@tanstack/react-query'

export function useThumbnailProxy(
  item: ImageryResultPart,
  fetchCondition = false,
) {
  return useQuery({
    queryKey: ['thumbnails', item.thumbnail],
    queryFn: async () => {
      return getThumbnailDataUrl(item)
    },
    retry: false,
    enabled: fetchCondition,
  })
}
