import { SEARCH_CATEGORIES, useResults } from '@/api/results'
import type { ResultCategory } from '@/api/results/types'
import { useSearchResults } from './use-search-query'

export const useResultTotals = () => {
  const data = useSearchResults()
  const categorized = useResults()
  const totals = {} as Record<string, { value: number; relation?: string }>
  ;[
    SEARCH_CATEGORIES.ATTOM,
    SEARCH_CATEGORIES.HAZARD_PROPERTY,
    SEARCH_CATEGORIES.HAZARD_RISK,
    SEARCH_CATEGORIES.VEXCEL,
    SEARCH_CATEGORIES.REGRID,
  ].forEach((category) => {
    totals[category] = { value: categorized[category].length }
  })
  data.forEach((group: ResultCategory) => {
    switch (group.category) {
      case SEARCH_CATEGORIES.IMAGE:
        totals[SEARCH_CATEGORIES.IMAGE] = group.total
        break
      case SEARCH_CATEGORIES.FIRE:
        totals[SEARCH_CATEGORIES.FIRE] = group.total
        break
      case SEARCH_CATEGORIES.SHIP:
        totals[SEARCH_CATEGORIES.SHIP] = group.total
        break
      case SEARCH_CATEGORIES.PUBLICATION:
        totals[SEARCH_CATEGORIES.PUBLICATION] = group.total
        break
      case SEARCH_CATEGORIES.SOCIAL_MEDIA:
        totals[SEARCH_CATEGORIES.SOCIAL_MEDIA] = group.total
        break
    }
  })
  return totals
}
