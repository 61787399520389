import { useMemo } from 'react'
import { SEARCH_CATEGORIES } from '@/api/results/constants'
import type { CategorizedResults } from '@/api/results/types'
import { useResults } from './use-results'

export const useCategorizedResults = (): CategorizedResults => {
  const results = useResults()
  return useMemo(() => {
    const shipResults = results[SEARCH_CATEGORIES.SHIP] || []
    const fireResults = results[SEARCH_CATEGORIES.FIRE] || []
    const attomResults = results[SEARCH_CATEGORIES.ATTOM] || []
    const hazardPropertyResults =
      results[SEARCH_CATEGORIES.HAZARD_PROPERTY] || []
    const hazardRiskResults = results[SEARCH_CATEGORIES.HAZARD_RISK] || []
    const regridResults = results[SEARCH_CATEGORIES.REGRID] || []
    const vexcelResults = results[SEARCH_CATEGORIES.VEXCEL] || []
    return {
      insightResults: results[SEARCH_CATEGORIES.INSIGHT] || [],
      publicationResults: results[SEARCH_CATEGORIES.PUBLICATION] || [],
      imageResults: results[SEARCH_CATEGORIES.IMAGE] || [],
      socialMediaResults: results[SEARCH_CATEGORIES.SOCIAL_MEDIA] || [],
      dataResults: [...shipResults, ...fireResults],
      propertyResults: [
        ...attomResults,
        ...hazardPropertyResults,
        ...hazardRiskResults,
        ...regridResults,
        ...vexcelResults,
      ],
    }
  }, [results]) as CategorizedResults
}
