import React from 'react'
import { useMantineTheme } from '@mantine/core'
import type { SymbolName } from '@/utils/types/material-icons'
import { classes } from '@/utils/ui-helpers'

export interface SymbolProps extends React.HTMLAttributes<HTMLSpanElement> {
  name: SymbolName
  size?: number | string
  filled?: boolean
  weight?: number
  grade?: number
  opticalSize?: number
}

export const Icon = React.forwardRef<HTMLSpanElement, SymbolProps>(
  (props: SymbolProps, ref) => {
    const {
      name,
      size = '1.3333rem' as SymbolProps['size'],
      filled = false,
      weight = 300,
      grade = 0,
      opticalSize,
      style,
      className,
      ...rest
    } = props
    const theme = useMantineTheme()
    let realSize = size

    if (
      typeof size === 'string' &&
      Object.keys(theme.fontSizes).includes(size)
    ) {
      realSize = `calc(4/3 * ${theme.fontSizes[size]})`
    }

    return (
      <span
        ref={ref}
        style={{
          fontVariationSettings: `"FILL" ${
            filled ? 1 : 0
          }, "wght" ${weight}, "GRAD" ${grade}, "opsz" ${opticalSize ?? realSize}`,
          fontSize: size ? realSize : 'inherit',
          width: realSize,
          height: realSize,
          overflow: 'clip',
          ...style,
        }}
        className={classes('material-symbols-outlined', className)}
        {...rest}
      >
        {name}
      </span>
    )
  },
)

Icon.displayName = 'Icon'
