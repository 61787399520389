import { ActionIcon, Box, Button, Chip, Group, Text } from '@mantine/core'
import { Icon } from '@/components/lib/Icon'
import { DEFAULT_REASONS, TEST_IDS } from '@/features/InsightPanel/constants'
import { useInsightFeedback } from '@/features/InsightPanel/hooks'
import { messages } from '@/features/InsightPanel/messages'
import type React from 'react'
import { InsightCustomReason } from './InsightCustomReason'

import styles from './insight-feedback-panel.module.css'

export function InsightFeedbackForm() {
  const { closePanel, handleSelectReason, handleSubmit, isSubmitting } =
    useInsightFeedback()

  function handleSubmission(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    void handleSubmit()
  }

  return (
    <Box className={styles.form}>
      <Group justify="space-between" mb="sm">
        <Group gap="xs">
          <Icon
            data-testid={TEST_IDS.insightFeedbackIcon}
            name="thumb_down"
            size="sm"
            style={{ color: 'var(--mantine-color-gray-5)' }}
          />
          <Text size="sm" c="gray.5">
            {messages.TITLE} {messages.INSTRUCTIONS}
          </Text>
        </Group>
        <ActionIcon
          data-testid={TEST_IDS.insightFeedbackClose}
          variant="transparent"
          size="sm"
          onClick={closePanel}
        >
          <Icon
            name="close_small"
            style={{ color: 'var(--mantine-color-gray-6)' }}
          />
        </ActionIcon>
      </Group>
      <form onSubmit={handleSubmission}>
        <Group my="xs" gap="xs">
          <Chip.Group multiple onChange={handleSelectReason}>
            {Object.entries(DEFAULT_REASONS).map(([value, title]) => (
              <Chip variant="outline" key={value} value={value}>
                {title}
              </Chip>
            ))}
          </Chip.Group>
        </Group>
        <Group justify="space-between" mt="sm">
          <InsightCustomReason />
          <Button loading={isSubmitting} size="xs" type="submit">
            {messages.SUBMIT}
          </Button>
        </Group>
      </form>
    </Box>
  )
}
