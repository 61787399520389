import { Navigate } from 'react-router-dom'
import { REDIRECT_COOKIE_NAME } from '@/utils/constants.ts'

export function HandleRedirect(props: { to: string }) {
  const url = window.location.pathname + window.location.search

  window.sessionStorage.setItem(REDIRECT_COOKIE_NAME, url)

  return <Navigate to={props.to} replace />
}
