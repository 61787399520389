import { useUserStore } from './store'

export const useSetProfile = () => useUserStore((state) => state.setProfile)

type AvatarDetails = {
  avatarBackgroundColor: string
  userInitials: string
  email: string
}
export const useAvatarDetails = () => {
  const { avatarBackgroundColor, firstName, lastName, email } = useUserStore()
  return {
    avatarBackgroundColor,
    userInitials: `${firstName[0]}${lastName[0]}`,
    email,
  } as AvatarDetails
}
export const useUserEmail = () => useUserStore((state) => state.email)
export const useUserId = () => useUserStore((state) => state.id)
export const useUserUsername = () => useUserStore((state) => state.username)
