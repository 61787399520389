import { ActionIcon, Box, Group, Text } from '@mantine/core'
import { Icon } from '@/components/lib/Icon.tsx'

import classes from './Pager.module.css'

export interface PagerProps {
  totalCount: number
  currentPage: number
  pageSize: number
  nextPage: () => void
  previousPage: () => void
}

export function Pager({
  currentPage,
  pageSize,
  totalCount,
  nextPage,
  previousPage,
}: PagerProps) {
  const lowerRange = pageSize * currentPage + 1
  const higherRange = Math.min(totalCount, pageSize * (currentPage + 1))

  const maxPage = Math.ceil(totalCount / pageSize) - 1

  if (totalCount === 0) {
    return <Box w={105} />
  }

  return (
    <Group gap={12}>
      <Text size="xs">
        {lowerRange}-{higherRange} of {totalCount}
      </Text>
      <Group gap={16}>
        <ActionIcon
          size="xs"
          variant="subtle"
          color="black"
          onClick={previousPage}
          disabled={currentPage === 0}
          className={classes['button']}
        >
          <Icon name="keyboard_arrow_left" />
        </ActionIcon>
        <ActionIcon
          size="xs"
          variant="subtle"
          color="black"
          disabled={currentPage === maxPage}
          onClick={nextPage}
          className={classes['button']}
        >
          <Icon name="keyboard_arrow_right" />
        </ActionIcon>
      </Group>
    </Group>
  )
}
