import * as React from 'react'
import { notifications } from '@mantine/notifications'
export { type HistoryItem } from '@/api/history/types'
import { useDeleteHistoryMutation } from '@/api/history/hooks'
import type { DeleteHistoryParams } from '@/api/history/types'

export const useHistoryMutations = () => {
  const [deleteParams, setDeleteParams] = React.useState<DeleteHistoryParams>()
  const deleteHistoryMutation = useDeleteHistoryMutation()
  function deleteHistory(params: DeleteHistoryParams) {
    deleteHistoryMutation.mutate(params)
    setDeleteParams(params)
  }

  React.useEffect(() => {
    const { status } = deleteHistoryMutation
    const isDeleteAll = deleteParams?.all ?? false
    const deleteItemLength = (deleteParams?.ids ?? []).length ?? 0

    if (status === 'success' && (isDeleteAll || deleteItemLength > 0)) {
      let message = ''

      if (isDeleteAll) {
        message = 'All History removed'
      } else if (deleteItemLength === 1) {
        message = 'Item removed'
      } else {
        message = 'Items removed'
      }

      notifications.clean()
      notifications.show({
        title: 'Success',
        color: 'green',
        message,
        autoClose: 5000,
        withCloseButton: true,
      })
    } else if (status === 'error') {
      notifications.clean()
      notifications.show({
        title: 'Error',
        color: 'red',
        message: 'Error removing history',
        autoClose: 5000,
        withCloseButton: true,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteHistoryMutation.status])

  return {
    deleteHistory,
  }
}
