import { getSearchRequest } from '@/api/danti-requests'
import { startCase } from 'lodash'
import type {
  DataMetadataCategoryProvenanceResponse,
  DataMetadataImageProvenanceResponse,
  DataMetadataResponse,
  ImageProvenanceMetadata,
} from './types'

function formatCategoryProvenance(
  categoryProvenance: DataMetadataCategoryProvenanceResponse[],
  categoryNames: Record<string, string>,
) {
  const sources = new Set<string>()
  const providers = new Set<string>()
  const categories = {} as Record<string, string>

  categoryProvenance.forEach((source) => {
    sources.add(source.source)
    providers.add(source.provider)

    if (!categories[source.category]) {
      categories[source.category] =
        categoryNames[source.category] ??
        startCase(source.category.toLowerCase())
    }
  })

  return {
    sources: [...sources],
    providers: [...providers],
    categories,
  }
}

function formatImageProvenance(
  imageProvenance: DataMetadataImageProvenanceResponse[],
): ImageProvenanceMetadata {
  const sources = new Set<string>()
  const providers = new Set<string>()
  const platforms = new Set<string>()
  const sensors = new Set<string>()

  imageProvenance.forEach((image) => {
    sources.add(image.source)
    providers.add(image.provider)
    if (image.platform) {
      platforms.add(image.platform)
    }
    sensors.add(image.sensor)
  })

  return {
    sources: [...sources],
    providers: [...providers],
    platforms: [...platforms],
    sensors: [...sensors],
  }
}

export async function getDataMetadata() {
  const response = await getSearchRequest<DataMetadataResponse>('v2/metadata')
  const categoryProvenance = formatCategoryProvenance(
    response.data.categoryProvenance,
    response.data.categories,
  )
  const imageProvenance = formatImageProvenance(response.data.imageProvenance)

  return {
    ...response.data,
    categoryProvenance,
    categoryProvenanceRelations: response.data.categoryProvenance,
    imageProvenance,
    imageProvenanceRelations: response.data.imageProvenance,
  }
}
