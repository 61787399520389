import * as React from 'react'
import { useDebouncedValue } from '@mantine/hooks'
import { OrgUsersQuery } from '@/api/user/gql'
import type { OrgUsersQuery as OrgUsersQueryType } from '@/gql/graphql'
import { useFeatureFlag } from '@/hooks/use-feature-flag'
import { FEATURE_FLAG_VALUES } from '@/utils/constants'
import { useQuery } from '@apollo/client'

export const useUsersQuery = ({
  searchTerm,
  pageSize,
  nextToken,
  previousToken,
}: {
  searchTerm: string
  pageSize: number
  nextToken: string
  previousToken: string
}): {
  users: NonNullable<OrgUsersQueryType['me']>['org']['users']['nodes']
  totalCount: number
  pageInfo?: NonNullable<OrgUsersQueryType['me']>['org']['users']['pageInfo']
} => {
  const { accountManagement } = FEATURE_FLAG_VALUES

  const shouldSupportAutocompletion = useFeatureFlag(accountManagement)
  const [debouncedSearchTerm] = useDebouncedValue(searchTerm, 300)

  const { data } = useQuery(OrgUsersQuery, {
    variables: {
      ...(debouncedSearchTerm && { search: debouncedSearchTerm }),
      ...(pageSize && { first: pageSize }),
      ...(nextToken && { after: nextToken }),
      ...(previousToken && { before: previousToken }),
    },
    skip: !shouldSupportAutocompletion,
  })

  const users = React.useMemo(
    () => data?.me?.org?.users?.nodes ?? [],
    [data?.me?.org?.users?.nodes],
  )

  const pageInfo = data?.me?.org?.users?.pageInfo
  const totalCount = React.useMemo(() => users.length ?? 0, [users])
  return {
    users,
    pageInfo,
    totalCount,
  }
}
