import { Stack, Text, Title } from '@mantine/core'
import { SOVDataDisplay } from './DataDisplay'

import styles from './view-template.module.css'

export interface SOVContentProps {
  title?: React.ReactNode
  details?: string
  detailsLabel?: string
  detailsElement?: React.ReactNode
  data?: {
    title?: React.ReactNode
    value?: React.ReactNode
  }[]
}
export function SOVContent({
  data,
  details,
  detailsLabel,
  detailsElement,
  title,
}: SOVContentProps) {
  return (
    <div className={styles['sov-content']}>
      <Stack>
        {title && (
          <Title c="#3e3f42" className={styles['sov-title']}>
            {title}
          </Title>
        )}
      </Stack>
      {detailsLabel && details && (
        <Text fw="bold" className={styles['sov-danti-summary-label']}>
          {detailsLabel}
        </Text>
      )}
      {details && <Text>{details}</Text>}
      {detailsElement}
      {data && <SOVDataDisplay fields={data} />}
    </div>
  )
}
