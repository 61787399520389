import { Tooltip } from '@mantine/core'
import type React from 'react'

export interface ConditionalTooltipProps {
  label?: string
  children: React.ReactNode
}

// Conditionally wraps children <Tooltip> if label is present
export function ConditionalTooltip({
  label,
  children,
}: ConditionalTooltipProps) {
  return label ? <Tooltip label={label}>{children}</Tooltip> : children
}
