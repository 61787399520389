import { Title } from '@mantine/core'
import { modals } from '@mantine/modals'

export function openSearchFiltersModal() {
  modals.openContextModal({
    size: 1000,
    modal: 'searchFilters',
    styles: {
      header: {
        padding:
          'var(--mantine-spacing-xl) var(--mantine-spacing-xl) var(--mantine-spacing-xs)',
      },
    },
    withCloseButton: false,
    title: (
      <Title component="p" order={2} pb="0">
        Filters
      </Title>
    ),
    centered: true,
    innerProps: {},
  })
}
