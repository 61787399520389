import React from 'react'
import { Button, Group, Stack, Textarea, TextInput } from '@mantine/core'
import type { ContextModalProps } from '@mantine/modals'
import { type Collection, useCollections } from '@/api/collections'

export interface UpdateCollectionModalParams {
  collection: Collection
}

export function UpdateCollectionModal({
  context,
  id,
  innerProps: { collection },
}: ContextModalProps<UpdateCollectionModalParams>) {
  const { update } = useCollections()

  const [name, setName] = React.useState(collection.name)
  const [desc, setDesc] = React.useState(collection.desc)

  async function handleUpdate() {
    await update({ id: collection.id, name, desc })
    context.closeModal(id)
  }

  return (
    <>
      <Stack gap="sm">
        <TextInput
          label="Name"
          size="xs"
          required
          withAsterisk
          value={name}
          onChange={(event) => setName(event.currentTarget.value)}
          data-cy="create-collection-name"
        />
        <Textarea
          label="Description"
          size="xs"
          value={desc}
          onChange={(event) => setDesc(event.currentTarget.value)}
          data-cy="create-collection-description"
        />
      </Stack>

      <Group justify="space-between" mt="md">
        <Button variant="subtle" onClick={() => context.closeModal(id)}>
          Cancel
        </Button>
        <Button disabled={!name} onClick={() => void handleUpdate()}>
          Update
        </Button>
      </Group>
    </>
  )
}
