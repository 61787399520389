import { oidcClientId } from '@/utils/constants.ts'
import posthog from 'posthog-js'
import { useAuth } from 'react-oidc-context'

export function useDantiAuth() {
  const auth = useAuth()

  const { isLoading, isAuthenticated, error, user } = auth ?? {}

  function logIn() {
    void auth.signinRedirect()
  }
  async function logOut() {
    posthog.reset()
    window.localStorage.removeItem('UserStore')
    window.localStorage.removeItem('Results')
    window.localStorage.removeItem('ChatStore')
    window.document.cookie = ''
    await auth.revokeTokens()
    await auth.removeUser()
  }

  async function refreshUser() {
    await auth.signinSilent()
  }

  function hasResourceAccess() {
    return user && user.profile && user.profile['resource_access']
  }

  function isAuthorizedUser() {
    if (hasResourceAccess()) {
      const resourceAccess = user!.profile['resource_access'] as {
        [key: string]: { roles: string[] }
      }
      const roles = resourceAccess[oidcClientId].roles
      return roles.includes('full-access')
    }
    return false
  }

  return {
    user,
    logIn,
    logOut,
    refreshUser,
    isAuthorizedUser,
    hasResourceAccess,
    isLoading: isLoading ?? true,
    isAuthenticated,
    error,
  }
}
