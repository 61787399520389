export const messages = {
  ANSWER: 'Answer',
  READ_MORE: 'Read more',
  HIDE: 'Hide',
  TITLE: `Not the results you're looking for?`,
  INSTRUCTIONS: 'Tell us what went wrong (optional)',
  THANKS: 'Thanks for your feedback!',
  CUSTOM_TITLE: 'Something else',
  CUSTOM_PLACEHOLDER: 'Enter a custom reason',
  SUBMIT: 'SUBMIT',
  SUBMIT_FAILED: 'Failed to submit feedback. Try again in a minute.',
}
