import React, { type MutableRefObject } from 'react'
import { useMapLayers } from '@/features/DantiMap/hooks'
import { makeImageryResultPolygons } from '@/features/DantiMap/utils'
import { useSortPreference } from '@/stores/queries-store'
import { usePostfilterImageResults } from '@/stores/results-filter-store'
import { orderBy } from 'lodash'

type UseStyleLoadedCallbackProps = {
  map: MutableRefObject<mapboxgl.Map | null>
}

export const useImageryExtents: (
  props: UseStyleLoadedCallbackProps,
) => void = ({ map }) => {
  const sortPreference = useSortPreference()
  const imageResults = usePostfilterImageResults()
  const sortedResults = React.useMemo(() => {
    return orderBy(imageResults, sortPreference || undefined, 'desc')
  }, [imageResults, sortPreference])
  const extentsData = makeImageryResultPolygons(sortedResults.slice(0, 6))
  useMapLayers({
    map: map.current,
    layers: {
      'extent-fills': {
        sourceId: 'extents',
        type: 'fill',
        data: extentsData,
        isVisible: true,
      },
      'extent-outlines': {
        sourceId: 'extents',
        type: 'line',
        data: extentsData,
        isVisible: true,
      },
    },
  })
}
