import * as React from 'react'
import { formatTimeDisplay, messages } from '@/features/AppLayout'

export interface ConfigureEmailNotificationsProps {
  emailNotification: string | null
  setEmailNotification: (value: string | null) => void
}

export interface UseConfigureEmailNotificationsHookResponse {
  messages: { none: string }
  formattedTime: string
  handleSelectNone: () => void
  timeInputRef: React.RefObject<HTMLInputElement> | null
  handleTimeChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  showPlatformTimePicker: () => void
}

export function useConfigureEmailNotifications({
  emailNotification,
  setEmailNotification,
}: ConfigureEmailNotificationsProps): UseConfigureEmailNotificationsHookResponse {
  const timeInputRef = React.useRef<HTMLInputElement>(null)

  const showPlatformTimePicker = () => timeInputRef.current?.showPicker()

  const handleSelectNone = React.useCallback(() => {
    setEmailNotification(null)
  }, [setEmailNotification])

  const handleTimeChange = React.useCallback(
    ({ currentTarget: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      setEmailNotification(value)
    },
    [setEmailNotification],
  )

  const formattedTime = formatTimeDisplay(emailNotification)

  return {
    messages,
    formattedTime,
    handleSelectNone,
    timeInputRef,
    handleTimeChange,
    showPlatformTimePicker,
  }
}
