import { graphql } from '@/gql'

export const OrgUsersQuery = graphql(`
  query OrgUsers(
    $search: String
    $first: Int
    $after: String
    $before: String
  ) {
    me {
      id
      org {
        id
        users(search: $search, first: $first, after: $after, before: $before) {
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
          nodes {
            id
            firstName
            lastName
            email
            role
          }
        }
      }
    }
  }
`)
