import { Divider, Stack, Text } from '@mantine/core'
import { modals } from '@mantine/modals'
import { Icon } from '@/components/lib/Icon'

import styles from './external-link-modal.module.css'

export function warnExternalLink(href?: string) {
  if (!href) {
    return
  }

  const url = new URL(href)
  const { host } = url

  return modals.openConfirmModal({
    title: (
      <>
        Warning
        <Icon size={24} weight={500} className={styles.icon} name="warning" />
      </>
    ),
    children: (
      <>
        <Divider className={styles.divider} />
        <Stack className={styles.information}>
          <Text className={styles.headline}>You are leaving Danti</Text>
          <Text>
            By clicking this link, you will open a new tab to{' '}
            <strong>{host}</strong>. Are you sure you want to continue?
          </Text>
        </Stack>
      </>
    ),
    labels: {
      confirm: 'YES, CONTINUE',
      cancel: 'NO, BACK TO DANTI',
    },
    onConfirm: () => {
      window.open(href, '_blank')
    },
    className: styles.modal,
    classNames: {
      header: styles.header,
      title: styles.title,
      content: styles.content,
      body: styles.body,
    },
    groupProps: {
      className: styles.footer,
    },
    cancelProps: {
      size: 'xs',
      className: styles.button,
      leftSection: <Icon weight={300} name="close" />,
      variant: 'subtle',
      color: 'dark',
    },
    confirmProps: {
      size: 'xs',
      className: styles.button,
      color: 'red',
    },
    withCloseButton: false,
  })
}
