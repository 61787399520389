import { type MutableRefObject, useEffect } from 'react'
import { formatMapboxStyle } from '@/features/DantiMap/utils'
import { useBaseLayer } from '@/stores/map-store'

export const useBaseLayerSelection = (
  map: MutableRefObject<mapboxgl.Map | null>,
) => {
  const baseLayer = useBaseLayer()
  useEffect(() => {
    map.current?.setStyle(formatMapboxStyle(baseLayer))
  }, [baseLayer, map])
}
