import React from 'react'
import { Flex, Group, Popover, Slider, Stack, Text } from '@mantine/core'
import { Icon } from '@/components/lib/Icon'
import { useCloudCoverFilter, useSetFilters } from '@/stores/filters-store'

export function CloudCoverSelect() {
  const cloudCover = useCloudCoverFilter()
  const setFilters = useSetFilters()
  const [cloudCoverValue, setCloudCoverValue] = React.useState(cloudCover || 0)

  React.useEffect(() => {
    setCloudCoverValue(cloudCover || 0)
  }, [cloudCover])

  const selectedText = `${cloudCoverValue}% or less`

  return (
    <Popover position="bottom" width="target">
      <Popover.Target>
        <Group justify="space-between" style={{ cursor: 'pointer' }}>
          <Text fz="sm">{selectedText}</Text>
          <Icon name="keyboard_arrow_down" />
        </Group>
      </Popover.Target>
      <Popover.Dropdown>
        <Stack>
          <Text fw="bold" fz="sm">
            {cloudCoverValue}%
          </Text>
          <Flex justify="space-between">
            <Icon name="wb_sunny" />
            <Icon name="partly_cloudy_day" />
            <Icon name="wb_cloudy" />
          </Flex>
          <Slider
            min={0}
            max={100}
            step={10}
            value={cloudCoverValue}
            label={(value) => `${value}% or less`}
            aria-label="Cloud Cover"
            onChange={(value) => setCloudCoverValue(value)}
            onChangeEnd={(value) => setFilters({ cloudCover: value })}
          />
          <Flex justify="space-between" c="#00000060">
            <Text fz="sm">0%</Text>
            <Text fz="sm">100%</Text>
          </Flex>
        </Stack>
      </Popover.Dropdown>
    </Popover>
  )
}
