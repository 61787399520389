import { useKeyedState } from '@/hooks/use-keyed-state'

export const stateKeys = {
  primary: 'primary',
  secondary: 'secondary',
}

export const useSelectedImages = () => {
  const [primary, setPrimary] = useKeyedState<string | null>(
    stateKeys.primary,
    null,
  )
  const [secondary, setSecondary] = useKeyedState<string | null>(
    stateKeys.secondary,
    null,
  )
  return {
    selectedImages: { primary, secondary },
    setSelectedImages: {
      primary: setPrimary,
      secondary: setSecondary,
    },
  }
}
