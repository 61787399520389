import { createTheme, type MantineThemeOther } from '@mantine/core'
import { COLORS } from './colors'

export const theme: MantineThemeOther = createTheme({
  colors: COLORS,
  white: '#fff',
  black: '#424242',
  primaryColor: 'blue',
  primaryShade: 6,
  fontFamily: 'Roboto, sans-serif',
  fontFamilyMonospace: 'Roboto Mono, monospace',
  radius: {
    xs: '0.125rem',
    sm: '0.25rem',
    md: '0.375rem',
    lg: '0.5rem',
    xl: '0.875rem',
  },
  spacing: {
    xxs: '0.25rem',
    xs: '0.5rem',
    sm: '1rem',
    md: '1.5rem',
    lg: '2rem',
    xl: '3rem',
  },
  breakpoints: {
    xs: '36em',
    sm: '48em',
    md: '62em',
    lg: '75em',
    xl: '88em',
  },
  headings: {
    fontFamily: 'Roboto, sans-serif',
    sizes: {
      h1: { fontSize: '2.25rem', lineHeight: '1.25', fontWeight: '700' },
      h2: { fontSize: '1.5rem', lineHeight: '1.35', fontWeight: '700' },
      h3: { fontSize: '1.5rem', lineHeight: '1.35', fontWeight: '700' },
      h4: { fontSize: '1.5rem', lineHeight: '1.35', fontWeight: '400' },
      h5: { fontSize: '1.5rem', lineHeight: '1.35', fontWeight: '400' },
      h6: { fontSize: '1.5rem', lineHeight: '1.35', fontWeight: '400' },
    },
  },
  other: {
    colorGrayText: COLORS.gray[7],
  },
})
