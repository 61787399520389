import { formatImageQuality, SEARCH_CATEGORIES } from '@/api/results'
import type { ImageryResult } from '@/utils/types/result-types'
import { ReferenceCard } from './components/ReferenceCard'

export const ImageReferenceCard = ({
  result,
  withActions,
}: {
  result: ImageryResult
  withActions?: boolean
}) => {
  const quality = formatImageQuality(result.properties._dQuality)
  return (
    <ReferenceCard
      heroMapImage={result}
      title={result.source}
      authoredOn={result.properties.datetime}
      description={quality || ''}
      withActions={withActions}
      category={SEARCH_CATEGORIES.IMAGE}
      result={result}
    />
  )
}
