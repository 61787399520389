import { extractProvenance } from '@/stores/results-filter-store/utils'
import type { OrthoImageryLayer } from '@/utils/types/ortho-imagery-types'
import type { ImageryResult } from '@/utils/types/result-types'
import type { Polygon } from 'geojson'

export const makeUP42OrthoLayer = (r: ImageryResult): OrthoImageryLayer => {
  const featureId = (r.properties.featureId as string) || r.id
  return {
    source: 'UP42' as const,
    id: featureId,
    tileUrl: r.properties._dPreview as string,
    authoredOn: r.authoredOn,
    geometry: r.geometry as Polygon,
    documentId: r.documentId,
    imageResult: r,
    groupKey: featureId,
    score: r.score,
    formattedSource: `${extractProvenance(r)}`,
  }
}
