import React from 'react'
import { useInsightResults, useQueryId } from '@/api/results'
import { toastError } from '@/components/toasts'
import { PANEL_STATES } from '@/features/InsightPanel/constants'
import { messages } from '@/features/InsightPanel/messages'
import { NEW_INSIGHT_FEEDBACK_MUTATION } from '@/features/InsightPanel/mutations'
import type {
  DefaultReasons,
  InsightFeedbackHookResponse,
} from '@/features/InsightPanel/types'
import {
  useInsightFeedbackSubmitted,
  useSetInsightFeedbackSubmitted,
} from '@/stores/queries-store'
import { captureEvent, POSTHOG_EVENTS } from '@/utils/posthog'
import { useMutation } from '@apollo/client'
import { singletonHook } from 'react-singleton-hook'

function useInsightFeedbackImpl(): InsightFeedbackHookResponse {
  const insightId = useInsightResults()[0]?.id
  const queryId = useQueryId()
  const isSubmitted = useInsightFeedbackSubmitted()
  const setIsSubmitted = useSetInsightFeedbackSubmitted()

  const [submitFeedback] = useMutation(NEW_INSIGHT_FEEDBACK_MUTATION)

  const [selectedReasons, setSelectedReasons] = React.useState<
    DefaultReasons[]
  >([])
  const [customReason, setCustomReason] = React.useState<string | null>(null)
  const [isOpen, setIsOpen] = React.useState(false)
  const [isSubmitting, setIsSubmitting] = React.useState(false)

  function resetState() {
    setIsOpen(false)
    setSelectedReasons([])
    setCustomReason(null)
  }

  // Only reset if a new search has cleared the `isSubmitted` state
  React.useEffect(() => {
    if (!isSubmitted) {
      resetState()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function openPanel() {
    captureEvent(POSTHOG_EVENTS.FEEDBACK.INSIGHT_OPENED)
    setIsOpen(true)
  }

  // Close the panel and reset the component state
  function closePanel() {
    captureEvent(POSTHOG_EVENTS.FEEDBACK.INSIGHT_CLOSED)
    resetState()
  }

  async function handleSubmit() {
    if (!insightId || !queryId) {
      return
    }
    setIsSubmitting(true)
    const payload = {
      queryId,
      id: insightId,
      feedback: selectedReasons,
      customFeedback: customReason,
    }
    captureEvent(POSTHOG_EVENTS.FEEDBACK.INSIGHT_SUBMITTED, {
      ...payload,
      feedback: JSON.stringify(payload.feedback),
    })

    try {
      await submitFeedback({ variables: { newFeedback: payload } })
      setIsSubmitted(true)
      setIsOpen(false)
    } catch (error) {
      console.error(error)
      toastError({
        message: messages.SUBMIT_FAILED,
      })
    } finally {
      setIsSubmitting(false)
    }
  }

  function handleSelectReason(value: string[]) {
    setSelectedReasons(value as DefaultReasons[])
  }

  const panelState = isSubmitted
    ? PANEL_STATES.DONE
    : isOpen
      ? PANEL_STATES.PANEL
      : PANEL_STATES.BUTTON

  return {
    panelState,
    selectedReasons,
    isSubmitting,
    openPanel,
    closePanel,
    handleSelectReason,
    handleSubmit,
    customReason: {
      value: customReason,
      handleChange: (event: React.ChangeEvent<HTMLInputElement>) => {
        setCustomReason(event.currentTarget.value)
      },
    },
  }
}

export const useInsightFeedback = singletonHook(
  () => ({
    panelState: PANEL_STATES.BUTTON,
    selectedReasons: [] as DefaultReasons[],
    isSubmitting: false,
    openPanel: () => {},
    closePanel: () => {},
    handleSubmit: () => Promise.resolve(),
    handleSelectReason: () => {},
    customReason: {
      value: null,
      handleChange: () => {},
    },
  }),
  useInsightFeedbackImpl,
)
