import { Button, Menu, Stack } from '@mantine/core'
import { Icon } from '@/components/lib/Icon'

export const ActionsMenu = ({ children }: { children: React.ReactNode }) => (
  <Menu position="bottom-start" trigger="click-hover">
    <Menu.Target>
      <Button
        size="xs"
        style={{ fontWeight: '500', borderColor: '#0091EA' }}
        leftSection={<Icon name="add_circle_outline" size="xs" filled />}
        variant="filled"
      >
        ACTIONS
      </Button>
    </Menu.Target>
    <Menu.Dropdown>
      <Stack gap="xs">{children}</Stack>
    </Menu.Dropdown>
  </Menu>
)
