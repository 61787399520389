import type { Filters } from '@/stores/filters-store'
import { makePlatformFilter } from '@/utils/search-filters/make-platform-filter.ts'
import { makeSourceFilter } from '@/utils/search-filters/make-source-filter.ts'
import {
  type SearchApiFilterValue,
  TimePreset,
} from '@/utils/types/filter-types.ts'
import { makeCloudCoverFilter } from './make-cloud-cover-filter'
import { makeGeocodeFilter } from './make-geocode-filter'
import { makeKeywordsFilter } from './make-keywords-filter'
import { makeLocationFilter } from './make-location-filter'
import { makeLocationTypeFilter } from './make-location-type-filter'
import { makeNadirFilter } from './make-nadir-filter'
import { makeOrientationsFilter } from './make-orientation-filter'
import { makeProviderTypeFilter } from './make-provider-type-filter'
import { makeQualityFilter } from './make-quality-filter'
import { makeSensorTypeFilter } from './make-sensor-filter'
import { makeTimeFilter } from './make-time-filter'

export const makeApiFilters = (
  store: Partial<Filters>,
): SearchApiFilterValue[] => {
  const filters: SearchApiFilterValue[] = []
  // Timeframe
  if (
    store.timePreset &&
    (store.timePreset !== TimePreset.None ||
      (store.customTimeStart && store.customTimeEnd))
  ) {
    const timeFilter = makeTimeFilter({
      timePreset: store.timePreset || TimePreset.Last30Days,
      customTimeStart: store.customTimeStart || new Date(),
      customTimeEnd: store.customTimeEnd,
    })
    if (timeFilter) {
      filters.push(timeFilter)
    }
  }

  // Sensor Type
  if (store.sensors && store.sensors.length > 0) {
    const sensorTypeFilter = makeSensorTypeFilter(store.sensors)
    if (sensorTypeFilter) {
      filters.push(sensorTypeFilter)
    }
  }

  // Sources
  if (store.sources && store.sources.length > 0) {
    const sourcesFilter = makeSourceFilter(store.sources)
    if (sourcesFilter) {
      filters.push(sourcesFilter)
    }
  }

  // Providers
  if (store.providers && store.providers.length > 0) {
    const providerTypeFilter = makeProviderTypeFilter(store.providers)
    if (providerTypeFilter) {
      filters.push(providerTypeFilter)
    }
  }

  // Platforms
  if (store.platforms && store.platforms.length > 0) {
    const platformsFilter = makePlatformFilter(store.platforms)
    if (platformsFilter) {
      filters.push(platformsFilter)
    }
  }

  // Quality
  if (store.quality && store.quality !== '5000') {
    const qualityFilter = makeQualityFilter(store.quality)
    if (qualityFilter) {
      filters.push(qualityFilter)
    }
  }

  // Cloud Cover
  if (
    store.cloudCover !== undefined &&
    store.cloudCover !== null &&
    store.cloudCover !== 100
  ) {
    const cloudCoverFilter = makeCloudCoverFilter(store.cloudCover)
    if (cloudCoverFilter) {
      filters.push(cloudCoverFilter)
    }
  }

  // Orientation
  if (store.orientations && store.orientations.length > 0) {
    const orientationsFilter = makeOrientationsFilter(store.orientations)
    if (orientationsFilter) {
      filters.push(orientationsFilter)
    }
  }

  // Nadir
  if (store.angle && store.angle !== '50') {
    const nadirFilter = makeNadirFilter(store.angle)
    if (nadirFilter) {
      filters.push(nadirFilter)
    }
  }

  // Location
  if (store.location && store.location !== '') {
    const locationFilter = makeLocationFilter(store.location)
    if (locationFilter) {
      filters.push(locationFilter)
    }
  }

  // Location Type
  if (store.locationType && store.locationType !== '') {
    const locationTypeFilter = makeLocationTypeFilter(store.locationType)
    if (locationTypeFilter) {
      filters.push(locationTypeFilter)
    }
  }

  // Geojson
  if (store.radius) {
    filters.push(store.radius)
  }

  // Keywords Type
  if (store.keywords && store.keywords.length > 0) {
    const keywordsFilter = makeKeywordsFilter(store.keywords)
    if (keywordsFilter) {
      filters.push(keywordsFilter)
    }
  }

  // Geocode Formatted Address Filter
  if (store.formattedAddress) {
    const formattedAddressFilter = makeGeocodeFilter(
      'formattedAddress',
      store.formattedAddress,
    )
    if (formattedAddressFilter) {
      filters.push(formattedAddressFilter)
    }
  }

  // Geocode PlaceID Filter
  if (store.placeId) {
    const placeIdFilter = makeGeocodeFilter('placeId', store.placeId)
    if (placeIdFilter) {
      filters.push(placeIdFilter)
    }
  }
  return filters
}
