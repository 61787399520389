import { isValidDatetime } from '@/utils/format-datetime'
import type { JSONValue } from '@/utils/types/result-types'

export const valueToString = (value: JSONValue) => {
  if (typeof value === 'string') {
    if (isValidDatetime(value)) {
      const date = new Date(value)
      return date.toLocaleString('en-US', {
        dateStyle: 'full',
        timeStyle: 'medium',
        hour12: true,
      })
    } else {
      return value
    }
  } else if (value != undefined) {
    return JSON.stringify(value)
      .replaceAll(/["[\]]/g, '')
      .replaceAll(',', ', ')
  }
  return ''
}
