import { getFireCategoryCardProps, SEARCH_CATEGORIES } from '@/api/results'
import type { FireResult } from '@/utils/types/result-types'
import { ReferenceCard } from './components/ReferenceCard'

export const FireReferenceCard = ({
  result,
  withActions,
}: {
  result: FireResult
  withActions?: boolean
}) => {
  const { geometry, datetime, latitude, longitude } =
    getFireCategoryCardProps(result)
  return (
    <ReferenceCard
      result={result}
      category={SEARCH_CATEGORIES.FIRE}
      heroGeometry={geometry}
      title="Wildfire"
      authoredOn={datetime}
      details={`${latitude}, ${longitude}`}
      withActions={withActions}
    />
  )
}
