import * as React from 'react'
export { type HistoryItem } from '@/api/history/types'
import { usePageHistory, useRecentHistory } from '@/api/history/hooks'
import type { DeleteHistoryParams, HistoryItem } from '@/api/history/types'
import { useDantiAuth } from '@/hooks/use-danti-auth'
import { useKeyedState } from '@/hooks/use-keyed-state'
import { singletonHook } from 'react-singleton-hook'
import { useHistoryMutations } from './use-history-mutations'

export interface UseHistoryHookResponse {
  recentItems: HistoryItem[]
  totalCount: number
  pagedItems: HistoryItem[]
  isLoading: boolean
  isRecentHistoryLoading: boolean
  currentPage: number
  pageSize: number
  nextPage: () => void
  previousPage: () => void
  deleteHistory: (params: DeleteHistoryParams) => void
  search: (term: string) => void
  searchTerm: string
}

export const stateKeys = {
  currentPage: 'currentPage',
  searchTerm: 'searchTerm',
}

export const useHistoryHookImpl = (): UseHistoryHookResponse => {
  const { isAuthenticated } = useDantiAuth()

  const [currentPage, setCurrentPage] = useKeyedState(stateKeys.currentPage, 0)
  const [searchTerm, setSearchTerm] = useKeyedState(stateKeys.searchTerm, '')

  const pageSize = 50

  const { data: recentHistory, isLoading: isRecentHistoryLoading } =
    useRecentHistory({ isAuthenticated })

  const { data: pageHistory, isLoading } = usePageHistory({
    currentPage,
    searchTerm,
    isAuthenticated,
  })

  const recentItems = React.useMemo(
    () => recentHistory?.items ?? [],
    [recentHistory],
  )

  const pagedItems = React.useMemo(
    () => pageHistory?.items ?? [],
    [pageHistory?.items],
  )

  const totalCount = React.useMemo(() => pageHistory?.total ?? 0, [pageHistory])

  const maxPages = React.useMemo(
    () => Math.ceil(totalCount / pageSize),
    [totalCount],
  )

  const { deleteHistory } = useHistoryMutations()

  const nextPage = () => {
    setCurrentPage((page) => Math.min(maxPages, page + 1))
  }

  const previousPage = () => {
    setCurrentPage((page) => Math.max(0, page - 1))
  }

  const search = React.useCallback((term: string) => {
    setCurrentPage(0)
    setSearchTerm(term)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    recentItems,
    totalCount,
    pagedItems,
    isLoading,
    isRecentHistoryLoading,
    deleteHistory,
    pageSize,
    currentPage,
    nextPage,
    previousPage,
    search,
    searchTerm,
  }
}

export const defaultValues = {
  recentItems: [],
  totalCount: 0,
  pagedItems: [],
  isLoading: false,
  isRecentHistoryLoading: false,
  deleteHistory: () => {},
  pageSize: 0,
  currentPage: 0,
  nextPage: () => {},
  previousPage: () => {},
  search: () => {},
  searchTerm: '',
}

export const useHistory = singletonHook<UseHistoryHookResponse>(
  () => defaultValues,
  useHistoryHookImpl,
)
