import { lazy } from 'react'
import {
  type KcContext,
  kcContext as kcLoginThemeContext,
} from './keycloak-theme/login/KcContext'

const KcLoginThemeApp = lazy(() => import('./keycloak-theme/login/KcApp'))

export const Login = () => (
  <KcLoginThemeApp kcContext={kcLoginThemeContext as KcContext} />
)
