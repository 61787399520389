import React from 'react'
import { ActionIcon, Menu } from '@mantine/core'
import { OrgUsersQuery } from '@/api/user'
import { Icon } from '@/components/lib/Icon'
import { graphql } from '@/gql'
import { useUserRole } from '@/hooks/use-user-role'
import { RankedOrgRoles, type User } from '@/utils/types/user-types'
import { useMutation } from '@apollo/client'

export interface UserAdminMenuProps {
  user: User
}

export const RemoveUserFromOrg = graphql(`
  mutation RemoveUserFromOrg($userId: ID!) {
    removeUserFromOrg(userId: $userId)
  }
`)

export function UserAdminMenu({ user }: UserAdminMenuProps) {
  const currentUser = useUserRole()
  const [removeUserFromOrg, { loading }] = useMutation(RemoveUserFromOrg)

  const disabled = React.useMemo(() => {
    if (!user.id || !currentUser) {
      return true
    }

    const isCurrentUser = user.id === currentUser?.id
    if (isCurrentUser) {
      return true
    }

    const currentUserRank = RankedOrgRoles.indexOf(
      currentUser.role.toUpperCase(),
    )
    const userRank = RankedOrgRoles.indexOf(user.role.toUpperCase())

    return currentUserRank <= userRank
  }, [user, currentUser])

  const handleRemoveUserFromOrg = () => {
    void removeUserFromOrg({
      variables: {
        userId: user.id,
      },
      refetchQueries: [OrgUsersQuery],
    })
  }

  if (disabled) {
    return <></>
  }

  return (
    <Menu trigger="hover" shadow="md" position="bottom-start">
      <Menu.Target data-cy="resultActionMenu">
        <ActionIcon
          variant="white"
          color="black"
          mt="xs"
          onClick={(event) => event.stopPropagation()}
        >
          <Icon name="more_horiz" size="xl" />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item onClick={handleRemoveUserFromOrg} p="xs" disabled={loading}>
          Remove User
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  )
}
