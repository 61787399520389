import { useState } from 'react'
import { isEmail, useForm } from '@mantine/form'
import type { ContextModalProps } from '@mantine/modals'
import { type ShareRoleType, useCollections } from '@/api/collections'
import type {
  Collection,
  ShareWithVendorForm,
  UnshareWithVendorParams,
} from '@/api/collections/types.ts'
import { captureException } from '@sentry/react'

export function useShareModalData({
  context,
  id,
  innerProps: { collection },
}: ContextModalProps<{ collection: Collection }>) {
  const { shareWithVendor, unShareWithVendor } = useCollections()
  const [isErrorState, setErrorState] = useState(false)
  const collectionId = collection.id

  async function handleShare(values: ShareWithVendorForm) {
    const { emails, role, message } = values
    try {
      await shareWithVendor({ emails, role, message, id: collectionId })
      context.closeModal(id)
    } catch {
      setErrorState(true)
      captureException(
        `Could not share with vendor, fields: ${emails.join(',')}, ${role}, ${message}, ${collectionId}`,
      )
      console.dir('error in share collection')
    }
  }

  async function handleRoleUpdate(email: string, role: ShareRoleType) {
    try {
      await shareWithVendor({
        id: collectionId,
        emails: [email],
        role,
        message: '',
      })
    } catch {
      setErrorState(true)
      captureException(
        `Could not update role, fields: ${email}, ${role}, ${collectionId}`,
      )
      console.dir('error in share collection')
    }
  }

  async function handleUnshare(params: UnshareWithVendorParams) {
    const { emails, role } = params

    try {
      await unShareWithVendor({ id: collectionId, emails, role })
    } catch {
      setErrorState(true)
      captureException(
        `Could not unshare with vendor, fields: ${emails.join(',')}, ${role}, ${collectionId}`,
      )
      console.dir('error in share collection')
    }
  }

  const form = useForm<ShareWithVendorForm>({
    initialValues: { emails: [], consent: false, message: '', role: 'viewer' },

    // functions will be used to validate values at corresponding key
    validate: {
      emails: (value) => {
        if (value.length === 0) {
          return 'Must include emails'
        }

        const invalidEmails = value.filter(
          (x) => void isEmail('Invalid email')(x),
        )
        return invalidEmails.length > 0 ? 'Invalid email' : null
      },
      consent: (value) => (value ? null : 'Must acknowledge consent'),
    },
  })

  const onSubmit = form.onSubmit((values: ShareWithVendorForm) => {
    return void handleShare(values)
  })

  const closeModal = () => context.closeModal(id)

  return {
    form,
    handleShare,
    handleUnshare,
    handleRoleUpdate,
    isErrorState,
    onSubmit,
    closeModal,
  }
}

export default useShareModalData
