import { getShipDetailCardProps, SEARCH_CATEGORIES } from '@/api/results'
import type { ShipResult } from '@/utils/types/result-types'
import { ReferenceCard } from './components/ReferenceCard'

export const ShipReferenceCard = ({
  result,
  withActions,
}: {
  result: ShipResult
  withActions?: boolean
}) => {
  const { title, geometry, datetime, latitude, longitude } =
    getShipDetailCardProps(result)
  return (
    <ReferenceCard
      heroGeometry={geometry}
      title={title}
      authoredOn={datetime}
      {...(latitude && longitude && { details: `${latitude}, ${longitude}` })}
      result={result}
      category={SEARCH_CATEGORIES.SHIP}
      withActions={withActions}
    />
  )
}
