import type { CardProps } from '@mantine/core'
import type { CollectionItem } from '@/api/collections/types'
import { getNewsImages } from '@/api/results'
import { BentoCard } from '@/features/BentoResults/components'
import { HIDE_ACTIONS_BY_CATEGORY } from '@/features/ResultActions/constants'
import { RESULT_CATEGORIES } from '@/utils/constants'
import { formatDate, formatTime } from '@/utils/format-datetime'
import type { PublicationResult } from '@/utils/types/result-types.ts'

const category = RESULT_CATEGORIES.PUBLICATION
const actionsToHide = HIDE_ACTIONS_BY_CATEGORY[category]

export interface NewsBentoCardProps extends CardProps {
  item?: CollectionItem
  result: PublicationResult
  totalResults?: number
  size?: string
}

export const NewsBentoCard = ({ result, ...props }: NewsBentoCardProps) => {
  const { datetime } = result.properties
  const datetimeDay = formatDate(datetime)
  const datetimeTime = formatTime(datetime)
  const images = getNewsImages(result)
  const hasImage = images.length > 0
  return (
    <BentoCard
      result={result}
      resultCategory={category}
      hideActions={actionsToHide}
      icon="newspaper"
      highlightIcon
      content={{
        heading: hasImage ? undefined : result.source,
        subHeading: (
          <>
            {datetimeDay}
            <br />
            {datetimeTime}
          </>
        ),
        color: '#d9d9d9',
        fontColor: hasImage ? 'white' : 'black',
      }}
      image={
        hasImage
          ? {
              thumbnail: getNewsImages(result)[0].url,
              title: result.title,
              source: result.source,
            }
          : undefined
      }
      {...props}
    />
  )
}
