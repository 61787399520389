import React from 'react'
import {
  Box,
  Button,
  Divider,
  Flex,
  Group,
  ModalTitle,
  Progress,
  ScrollArea,
  Stack,
} from '@mantine/core'
import type { ContextModalProps } from '@mantine/modals'
import { useAddToCollection } from '@/api/collections'
import { Icon } from '@/components/lib/Icon'
import { CollectionChooser } from '@/features/Collections'
import pluralize from 'pluralize'
import { MESSAGES } from './constants'
import { FileUploadButton } from './FileUploadButton'
import {
  useAreFilesUploading,
  useClearFiles,
  useFileError,
  useFileProgress,
  useFiles,
  useFileUploadError,
  useRemoveFile,
  useUploadFiles,
} from './state'

const errorColor = 'rgba(211, 47, 47, 0.5)'
const successColor = 'rgba(76, 175, 80, 0.5)'

interface ErrorMessageProps {
  message: string
}

function ErrorMessage({ message }: ErrorMessageProps) {
  return <Box style={{ color: errorColor, fontSize: '.8rem' }}>{message}</Box>
}

interface FileProps {
  file: File
}

function File({ file }: FileProps) {
  const fileProgress = useFileProgress(file.name)
  const fileError = useFileError(file.name)
  const removeFile = useRemoveFile()

  const hasCompletedUploading = fileProgress === 100

  return (
    <Box mb="sm">
      <Flex justify="space-between" align="center" flex={1}>
        {file.name}
        <Flex align="center" flex={1}>
          <Progress
            value={fileError ? 50 : fileProgress}
            color={fileError ? errorColor : undefined}
            display="flex"
            mx="sm"
            miw="6rem"
            flex={1}
          />
          <Icon
            style={{ cursor: hasCompletedUploading ? 'auto' : 'pointer' }}
            name={hasCompletedUploading ? 'check_circle' : 'close'}
            color={hasCompletedUploading ? successColor : undefined}
            onClick={() => {
              if (!hasCompletedUploading) {
                removeFile(file.name)
              }
            }}
          />
        </Flex>
      </Flex>
      {fileError && <ErrorMessage message={fileError} />}
    </Box>
  )
}

function CloseButton({ closeModal }: { closeModal: () => void }) {
  return (
    <Button
      onClick={closeModal}
      leftSection={<Icon name="close" />}
      variant="subtle"
      color="gray"
    >
      {MESSAGES.cancel}
    </Button>
  )
}

interface FileUploadModalProps {
  collectionId: string | null
  closeModal: () => void
}

function UploadButton({ collectionId, closeModal }: FileUploadModalProps) {
  const files = useFiles()
  const uploadFiles = useUploadFiles()
  const areFilesUploading = useAreFilesUploading()

  return (
    <Button
      onClick={() => {
        void uploadFiles(collectionId!, closeModal)
      }}
      loading={areFilesUploading}
      disabled={files.length === 0 || areFilesUploading}
    >
      Upload {files.length} {pluralize('file', files.length)}
    </Button>
  )
}

function FileList({ files }: { files: File[] }) {
  return (
    <ScrollArea mb="sm">
      {files.map((file) => (
        <File file={file} key={file.name} />
      ))}
    </ScrollArea>
  )
}

export function FileUploadModalHeader() {
  const fileUploadError = useFileUploadError()

  return (
    <>
      <ModalTitle mb="xs">{MESSAGES.uploadFiles}</ModalTitle>
      {fileUploadError && <ErrorMessage message={fileUploadError} />}
    </>
  )
}

export function FileUploadModal({
  context,
  innerProps: { collectionId },
}: ContextModalProps<FileUploadModalProps>) {
  const files = useFiles()
  const clearFiles = useClearFiles()
  const { collections } = useAddToCollection()
  const [selectedCollectionId, setSelectedCollectionId] = React.useState(
    collectionId ?? collections[0]?.id ?? null,
  )

  const noFiles = files.length === 0

  // Clear files when modal is closed (unmounted)
  React.useEffect(() => {
    return () => clearFiles()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const closeModal = () => {
    context.closeAll()
  }

  return (
    <Stack gap="sm">
      {!collectionId && collections.length > 0 && (
        <CollectionChooser
          collections={collections}
          selectedCollectionId={selectedCollectionId}
          setSelectedCollectionId={setSelectedCollectionId}
        />
      )}
      {!noFiles && (
        <Stack gap={0}>
          <Divider mb="sm" />
          <FileList files={files} />
          <Divider mb="sm" />
        </Stack>
      )}
      <FileUploadButton
        collectionId={collectionId}
        iconName="add"
        variant="outline"
        w="min-content"
        pl=".2rem"
        size="xs"
        color="gray"
      >
        {noFiles ? MESSAGES.uploadFiles : MESSAGES.addMoreFiles}
      </FileUploadButton>
      <Group justify="space-between">
        <CloseButton closeModal={closeModal} />
        <UploadButton
          collectionId={selectedCollectionId}
          closeModal={closeModal}
        />
      </Group>
    </Stack>
  )
}
