import { geocodeAddress } from '@/api/google-maps/geocode-address'
import type { AutocompleteOption } from '@/api/google-maps/types'

export const separatorString = ' $#$#$# '

export async function resolveLocation(
  value: string,
  callback: (options?: AutocompleteOption[]) => void,
) {
  if (!value) {
    return
  }
  const results = await geocodeAddress(value)
  callback(
    results.map((result) => ({
      label: result.description,
      value: result.description,
      placeId: result.place_id,
      formattedAddress: result.description,
    })),
  )
}
