import { type MutableRefObject, useEffect } from 'react'
import { SEARCH_CATEGORIES } from '@/api/results/constants'
import {
  POINT_ICONS,
  POINT_LAYER_NAMES,
  POINT_SOURCES,
} from '@/features/DantiMap/constants'
import { useMapLayers } from '@/features/DantiMap/hooks'
import { actOnMap, makeResultPinPoints } from '@/features/DantiMap/utils'
import { usePointViewPort } from '@/stores/map-store'
import { useTimelineFilteredResults } from '@/stores/results-filter-store'
import type { ResultWithId } from '@/utils/types/result-types'
import { usePointInteractionListeners } from './use-point-interaction-listeners'

export const INITIAL_ZOOM = 15

export const usePointData = (
  map: MutableRefObject<mapboxgl.Map | null>,
  view: 'Embedded' | 'Explore',
  showPointData: boolean,
) => {
  const pointViewPort = usePointViewPort()

  const results = useTimelineFilteredResults().categorized
  const shipResults = results[SEARCH_CATEGORIES.SHIP]
  const fireResults = results[SEARCH_CATEGORIES.FIRE]
  const publicationResults = results[SEARCH_CATEGORIES.PUBLICATION]
  const socialMediaResults = results[SEARCH_CATEGORIES.SOCIAL_MEDIA]
  const makePointList = (results: ResultWithId[]) =>
    makeResultPinPoints(view === 'Embedded' ? results.slice(0, 3) : results)

  const genericProps = {
    type: 'symbol',
    isVisible: showPointData,
    events: usePointInteractionListeners(map),
    clearSourceWithLayer: true,
  }

  // Handle pointViewPort change (for point results)
  useEffect(() => {
    if (pointViewPort) {
      const [cx, cy] = pointViewPort
      actOnMap(() => {
        map.current?.flyTo({ center: [cx, cy], zoom: INITIAL_ZOOM })
      })
    }
  }, [map, pointViewPort])

  useMapLayers({
    map: map.current,
    layers: {
      [POINT_LAYER_NAMES.NEWS]: {
        ...genericProps,
        data: makePointList(publicationResults),
        sourceId: POINT_SOURCES.news,
        pointIcon: POINT_ICONS.news,
      },
      [POINT_LAYER_NAMES.SOCIAL]: {
        ...genericProps,
        data: makePointList(socialMediaResults),
        sourceId: POINT_SOURCES.social,
        pointIcon: POINT_ICONS.social,
      },
      [POINT_LAYER_NAMES.SHIP]: {
        ...genericProps,
        data: makePointList(shipResults),
        sourceId: POINT_SOURCES.ship,
        pointIcon: POINT_ICONS.ship,
      },
      [POINT_LAYER_NAMES.FIRE]: {
        ...genericProps,
        data: makePointList(fireResults),
        sourceId: POINT_SOURCES.fire,
        pointIcon: POINT_ICONS.fire,
      },
    },
  })
}
