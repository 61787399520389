import { Container, Group } from '@mantine/core'
import {
  TimelineCompare,
  TimelineComponent,
  TimelineDrawer,
  TimelineInspect,
  ViewToggleComponent,
} from './components'
import { VIEWS } from './constants'
import { useSelectedDate, useSelectedImages, useSelectedView } from './hooks'
import { TimelapseView } from './views/Timelapse'
export const TimelineView = () => {
  const { selectedView, setSelectedView } = useSelectedView()
  const { selectedDate, setSelectedDate } = useSelectedDate()
  const { selectedImages, setSelectedImages } = useSelectedImages()
  return (
    <Container>
      {selectedView === VIEWS.timelapse ? (
        <TimelapseView setSelectedView={setSelectedView} />
      ) : (
        <>
          <Group justify="end">
            <ViewToggleComponent {...{ selectedView, setSelectedView }} />
          </Group>
          {selectedView === VIEWS.inspect && (
            <TimelineInspect
              selectedImage={selectedImages.primary}
              setSelectedImage={setSelectedImages.primary}
            />
          )}
          {selectedView === VIEWS.compare && (
            <TimelineCompare {...{ selectedImages, setSelectedImages }} />
          )}
          <TimelineComponent
            {...{
              selectedImages,
              selectedDate,
              setSelectedDate,
              selectedView,
            }}
          />
          <TimelineDrawer
            {...{
              selectedView,
              selectedDate,
              selectedImages,
              setSelectedImages,
            }}
          />
        </>
      )}
    </Container>
  )
}
