import { RESULT_CATEGORIES } from '@/utils/constants'
import { AttomBentoCard } from './AttomBentoCard'
import { CoreLogicPropertyBentoCard } from './CoreLogicPropertyBentoCard'
import { CoreLogicRiskBentoCard } from './CoreLogicRiskBentoCard'
import { FireBentoCard } from './FireBentoCard'
import { HazardPropertyBentoCard } from './HazardPropertyBentoCard'
import { HazardRiskBentoCard } from './HazardRiskBentoCard'
import { ImageBentoCard } from './ImageBentoCard'
import { NewsBentoCard } from './NewsBentoCard'
import { RegridBentoCard } from './RegridBentoCard'
import { ShipBentoCard } from './ShipBentoCard'
import { SocialBentoCard } from './SocialBentoCard'
import { VexcelBentoCard } from './VexcelBentoCard'

export const renderers = {
  [RESULT_CATEGORIES.ATTOM]: AttomBentoCard,
  [RESULT_CATEGORIES.FIRE]: FireBentoCard,
  [RESULT_CATEGORIES.HAZARD_PROPERTY]: HazardPropertyBentoCard,
  [RESULT_CATEGORIES.VEXCEL]: VexcelBentoCard,
  [RESULT_CATEGORIES.HAZARD_RISK]: HazardRiskBentoCard,
  [RESULT_CATEGORIES.CORELOGIC_PROPERTY]: CoreLogicPropertyBentoCard,
  [RESULT_CATEGORIES.CORELOGIC_RISK]: CoreLogicRiskBentoCard,
  [RESULT_CATEGORIES.IMAGE]: ImageBentoCard,
  [RESULT_CATEGORIES.PUBLICATION]: NewsBentoCard,
  [RESULT_CATEGORIES.REGRID]: RegridBentoCard,
  [RESULT_CATEGORIES.SHIP]: ShipBentoCard,
  [RESULT_CATEGORIES.SOCIAL_MEDIA]: SocialBentoCard,
} as { [key: string]: React.FC<any> }
