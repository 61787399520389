import { Paper, type PaperProps } from '@mantine/core'

import styles from './skeleton.module.css'

interface SkeletonProps extends PaperProps {
  style?: React.CSSProperties
}

export function Skeleton({ style, ...props }: SkeletonProps) {
  return (
    <Paper
      radius="sm"
      pos="relative"
      style={{ overflow: 'hidden', height: '100%', width: '100%', ...style }}
      {...props}
    >
      <div className={styles.shimmer} />
    </Paper>
  )
}
