import type { ImageryResult } from '@/utils/types/result-types'
import { wordBreak } from '@/utils/ui-helpers'
import { valueToString } from '@/utils/value-to-string'
import dayjs from 'dayjs'
import { camelCase, startCase } from 'lodash'

export const formatImageQuality = (quality: number) =>
  quality
    ? quality < 1
      ? `${Math.round(quality * 100)}cm`
      : `${Math.round(quality)}m`
    : null

export const getImageMetaDetails = (item: ImageryResult) => {
  const { properties } = item
  return Object.keys(properties)
    .filter((key) => !key.startsWith('_d'))
    .map((key) => ({
      label: startCase(camelCase(key)),
      value: wordBreak(valueToString(properties[key])),
    }))
}

export const imagesByDate = (results: ImageryResult[]) =>
  results.reduce(
    (object, image) => {
      const date = image.authoredOn
        ? dayjs(image.authoredOn).format('YYYY-MM-DD')
        : 'Unknown'
      if (!object[date]) {
        object[date] = []
      }
      object[date].push(image)
      return object
    },
    {} as Record<string, ImageryResult[]>,
  )
