import { useCollectionItemsStore } from './store'

export const useCollectionItemsState = () =>
  useCollectionItemsStore((state) => state)

export const useCollectionItemsCurrentPage = () =>
  useCollectionItemsStore((state) => state.currentPage)
export const useCollectionItemsNextToken = () =>
  useCollectionItemsStore((state) => state.nextToken)
export const useCollectionItemsPreviousToken = () =>
  useCollectionItemsStore((state) => state.previousToken)
export const useCollectionItemsSort = () =>
  useCollectionItemsStore((state) => state.sort)
export const useCollectionItemsSearchTerm = () =>
  useCollectionItemsStore((state) => state.searchTerm)
export const useCollectionItemsSelectedCategories = () =>
  useCollectionItemsStore((state) => state.selectedCategories)

export const useResetCollectionItemsState = () =>
  useCollectionItemsStore((state) => state.reset)
export const useCollectionItemsSetCurrentPage = () =>
  useCollectionItemsStore((state) => state.setCurrentPage)
export const useCollectionItemsSetNextToken = () =>
  useCollectionItemsStore((state) => state.setNextToken)
export const useCollectionItemsSetPreviousToken = () =>
  useCollectionItemsStore((state) => state.setPreviousToken)
export const useCollectionItemsSetSort = () =>
  useCollectionItemsStore((state) => state.setSort)
export const useCollectionItemsSetSearchTerm = () =>
  useCollectionItemsStore((state) => state.setSearchTerm)
export const useCollectionItemsSetSelectedCategories = () =>
  useCollectionItemsStore((state) => state.setSelectedCategories)
