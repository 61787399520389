import { ICONS } from '@/utils/constants'
import type { NavItem } from './types'

export enum TerrapinLocationType {
  latLong = 'latLong',
  polygon = 'polygon',
  mgrs = 'mgrs',
}

export const NAV_ITEMS: NavItem[] = [
  {
    id: 'explore',
    icon: ICONS.search,
    label: 'Explore',
    path: '/',
  },
  {
    id: 'map',
    icon: ICONS.map,
    label: 'Map',
    path: '/map',
  },
  {
    id: 'timeline',
    icon: ICONS.timeline,
    label: 'Timeline',
    path: '/timeline',
  },
  {
    id: 'collections',
    icon: ICONS.collectionsBookmark,
    label: 'Saved Searches',
    path: '/collections',
  },
  {
    id: 'history',
    icon: ICONS.history,
    label: 'History',
    path: '/history',
  },
]
