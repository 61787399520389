import { MantineProvider, Stack, Text } from '@mantine/core'
import { formatDate } from '@/utils/format-datetime'
import type { ResultWithId } from '@/utils/types/result-types'
import type React from 'react'

import styles from '@/features/DantiMap/danti-map.module.css'

type MapPointInfoTooltipProps = {
  result: ResultWithId
}

export const MapPointInfoTooltip: React.FC<MapPointInfoTooltipProps> = ({
  result,
}) => {
  return (
    <MantineProvider>
      <div className={styles.pointTooltip}>
        <Stack className={styles.tooltipWrapper} gap={1}>
          <Text className={styles.date}>
            {formatDate(result?.properties.datetime)}
          </Text>
          <Text className={styles.title}>{result?.title}</Text>
        </Stack>
      </div>
    </MantineProvider>
  )
}
