import {
  Button,
  Loader,
  Space,
  Stack,
  Text,
  Textarea,
  Title,
} from '@mantine/core'
import type {
  BulkSearchGeolocation,
  ResolveLocationsResponse,
} from '@/api/search'
import { TerrapinLocationPanel } from '@/features/AppLayout/Modals/SaveSearchModal'
import type { TerrapinLocation } from '@/hooks/use-terrapin'

type AddLocationsProps = {
  isLocationsLoading: boolean
  searchLocations: string
  setSearchLocations: (value: string) => void
  searchLocationError: string
  setSearchLocationError: (value: string) => void
  parsedLocations: ResolveLocationsResponse['geolocations'] | undefined
}

// The current implementation of TerrapinLocationPanel only accepts
// locations with filters of type 'LOCATION' or 'LOCATIONTYPE'.
// Therefore, we must convert our Radius filters to Location filters.
const convertRadiusFilterToLocationFilter = (
  location: BulkSearchGeolocation,
): TerrapinLocation['filters'] => {
  const filter = location.filters[0]

  if (filter.type === 'RADIUS') {
    const point = filter.geometry as GeoJSON.Point
    const [long, lat] = point.coordinates

    return [
      {
        type: 'LOCATION',
        value: `[${lat}, ${long}]`,
      },
      { type: 'LOCATIONTYPE', value: 'latLong' },
    ]
  }

  return location.filters as TerrapinLocation['filters']
}

export function AddLocations({
  isLocationsLoading,
  searchLocations,
  setSearchLocations,
  searchLocationError,
  setSearchLocationError,
  parsedLocations,
}: AddLocationsProps) {
  const terrapinLocations: TerrapinLocation[] =
    parsedLocations?.map((location) => ({
      name: location.name,
      filters: convertRadiusFilterToLocationFilter(location),
    })) || []

  if (isLocationsLoading)
    return (
      <Stack
        align="center"
        justify="center"
        gap="xl"
        p="xl"
        style={{
          border: '1px solid var(--mantine-color-gray-3)',
          borderRadius: 'var(--mantine-radius-lg)',
        }}
      >
        <Title>Validating your input</Title>
        <Loader />
        <Text c="gray.6" size="lg" ta="center">
          Danti is processing the locations you entered. <br />
          Please wait just a moment.
        </Text>
      </Stack>
    )

  if (!parsedLocations) {
    return (
      <>
        <Text>
          Enter your search locations below, one location per line - to search
          every location at once. <br />
          You can also upload a file with the button below. <br />
          After Danti verifies your input, you can add more preferences and
          filters. Once you&apos;re done, we&apos;ll create a Saved Search
          folder for you containing the search results.
        </Text>
        <Space h="md" />
        <Textarea
          placeholder="Latitude, Longitude (6.3979, 3.5673) or MGRS (4QFJ12345678) format"
          resize="vertical"
          autosize
          maxRows={8}
          minRows={8}
          value={searchLocations}
          error={searchLocationError}
          onChange={({ currentTarget }) => {
            setSearchLocationError('')
            setSearchLocations(currentTarget.value)
          }}
        />
        <Space h="md" />
        <Button onClick={() => alert('transmitting...')}>Upload File</Button>
      </>
    )
  }

  // Step 1b: Locations List & Map
  return <TerrapinLocationPanel locations={terrapinLocations} pointsOnly />
}
