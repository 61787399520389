import type { CardProps } from '@mantine/core'
import { BentoCard } from '@/features/BentoResults/components'
import { HIDE_ACTIONS_BY_CATEGORY } from '@/features/ResultActions/constants'
import { RESULT_CATEGORIES } from '@/utils/constants'
import type { RegridResult } from '@/utils/types/result-types'

export interface RegridBentoCardProps extends CardProps {
  result: RegridResult
  size: string
  totalResults: number
}

const category = RESULT_CATEGORIES.REGRID
const actionsToHide = HIDE_ACTIONS_BY_CATEGORY[category]

export const RegridBentoCard = ({ result, ...props }: RegridBentoCardProps) => (
  <BentoCard
    result={result}
    hideActions={actionsToHide}
    geometry={result.geometry}
    data-cy="regridResult"
    resultCategory={category}
    {...props}
  />
)
export default RegridBentoCard
