import { ResultImage } from '@/features/ResultImage'

export interface BentoCardImageContentParams {
  image?: {
    title: string
    source: string
    thumbnail?: string
  }
}
// Note: this component is separated so that we do not perform the hook loading behavior for the images unless there is image data present
export const BentoCardImageContent = ({ image }: BentoCardImageContentParams) =>
  image && <ResultImage item={image} style={{ cursor: 'pointer' }} />

export default BentoCardImageContent
