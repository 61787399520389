import {
  Checkbox,
  CheckboxGroup,
  Flex,
  Paper,
  Popover,
  Stack,
  Text,
} from '@mantine/core'
import { Icon } from '@/components/lib/Icon'
import { useSensorsFilter, useSetFilters } from '@/stores/filters-store'
import { Sensor } from '@/utils/types/filter-types'

export function SensorsSelect() {
  const sensors = useSensorsFilter()
  const setFilters = useSetFilters()
  const updateSensors = (selectedSensors: string[]) => {
    setFilters({ sensors: selectedSensors })
  }
  const sensorOptions = Object.entries(Sensor).map(([value, label]) => ({
    value,
    label,
  }))

  const selectedSensors = sensorOptions
    .filter((p) => sensors.includes(p.value))
    .map((item) => item.label)
    .join(', ')

  const sensorTypes = [
    {
      title: 'Electro Optical',
      description: 'images capture the visible light spectrum',
    },
    {
      title: 'Synthetic Aperture Radar (SAR)',
      description: 'uses microwave radiation to create images',
    },
    {
      title: 'Infrared',
      description: 'detects infrared radiation emitted by objects',
    },
    {
      title: 'Multispectral',
      description: 'images capture data across multiple distinct bands',
    },
  ]

  return (
    <Popover width={540} trapFocus position="bottom-end" shadow="md">
      <Popover.Target>
        <Flex justify="space-between" style={{ cursor: 'pointer' }}>
          <Text size="sm">
            {sensors.length > 0 ? selectedSensors : 'Select sensor'}
          </Text>
          <Icon name="keyboard_arrow_down" />
        </Flex>
      </Popover.Target>
      <Popover.Dropdown>
        <Paper>
          <Flex flex={1} gap={30}>
            <Stack fz="xs" maw={250} gap={10}>
              {sensorTypes.map((item) => (
                <span key={item.title}>
                  <b>{item.title}</b> {item.description}
                </span>
              ))}
            </Stack>
            <CheckboxGroup
              defaultValue={sensors}
              onChange={(value) => updateSensors(value)}
            >
              <Stack gap={20}>
                {sensorOptions.map((item) => (
                  <Checkbox
                    key={item.value}
                    value={item.value}
                    label={`${item.label}`}
                  />
                ))}
              </Stack>
            </CheckboxGroup>
          </Flex>
        </Paper>
      </Popover.Dropdown>
    </Popover>
  )
}
