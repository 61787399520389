import { Anchor, type AnchorProps } from '@mantine/core'
import type React from 'react'
import { warnExternalLink } from './warn-external-link'

interface ExternalLinkProps extends AnchorProps {
  href?: string
  children: React.ReactNode
}

export function ExternalLink({ href, children, ...props }: ExternalLinkProps) {
  function handleClick(event: React.MouseEvent<HTMLAnchorElement>) {
    event.preventDefault()
    warnExternalLink(href)
  }

  return (
    <Anchor
      href={href}
      onClick={handleClick}
      target="_blank"
      rel="noreferrer"
      {...props}
    >
      {children}
    </Anchor>
  )
}
