import { notifications } from '@mantine/notifications'
import { postRequest, putRequestNoAuth } from '@/api/danti-requests'
import { MAX_FILE_SIZE_5GB } from '@/components/lib/FileUpload/constants'
import * as Sentry from '@sentry/react'
import type { AxiosError, AxiosProgressEvent } from 'axios'
import { atom, useAtomValue, useSetAtom } from 'jotai'
import { atomFamily } from 'jotai/utils'
import pluralize from 'pluralize'

function isFileTooLarge(file: File) {
  return file.size > MAX_FILE_SIZE_5GB
}

function convertBytesToGB(bytes: number) {
  return bytes / 1024 / 1024 / 1024
}

function calculateProgress(progressEvent: AxiosProgressEvent) {
  const ratio = progressEvent.loaded / (progressEvent.total ?? 1)
  const percentage = ratio * 100
  const roundedPercentage = Math.round(percentage)

  return Math.max(1, Math.min(100, roundedPercentage))
}

const filesAtom = atom<File[]>([])
const areFilesUploadingAtom = atom(false)
const isOpenAtom = atom<boolean>(false)
const fileUploadError = atom<string | null>(null)
const fileProgressAtom = atomFamily((_fileName: string) => atom<number>(0))
const fileErrorAtom = atomFamily((_fileName: string) =>
  atom<string | null>(null),
)

const resetFileStateAtom = atom(null, (_get, set, fileName: string) => {
  set(fileProgressAtom(fileName), 0)
  set(fileErrorAtom(fileName), null)
})
const removeFileAtom = atom(null, (get, set, fileName: string) => {
  set(resetFileStateAtom, fileName)
  set(
    filesAtom,
    get(filesAtom).filter((file) => file.name !== fileName),
  )
})
const clearFilesAtom = atom(null, (_get, set) => {
  set(filesAtom, [])
})

const addFilesAtom = atom(null, (_get, set, newFiles: File[]) => {
  set(fileUploadError, null)

  const fileSizeMessages: string[] = []
  const validFiles = newFiles.filter((file) => {
    if (isFileTooLarge(file)) {
      fileSizeMessages.push(
        `${file.name} size exceeds the limit of 5GB (current size: ${convertBytesToGB(file.size).toFixed(2)}GB)`,
      )
      return false
    }
    return true
  })

  if (fileSizeMessages.length > 0) {
    set(fileUploadError, fileSizeMessages.join('\n'))
    return
  }

  set(filesAtom, (currentFiles) => [
    ...currentFiles,
    ...validFiles.filter(
      (file) => !currentFiles.some((f) => f.name === file.name),
    ),
  ])
})

const uploadFilesAtom = atom(
  null,
  async (get, set, collectionId: string, closeModal: () => void) => {
    const files = get(filesAtom)
    const erroredFiles: File[] = []
    set(areFilesUploadingAtom, true)
    try {
      await Promise.all(
        files.map(async (rawFile) => {
          try {
            set(resetFileStateAtom, rawFile.name)
            set(fileUploadError, null)

            const response = await postRequest<string>('/files/upload', {
              label: rawFile.name,
              fileType: rawFile.type,
              fileSize: `${rawFile.size}`,
              collectionId,
            })

            const uploadURL = response.data

            await putRequestNoAuth(uploadURL, rawFile, {
              onUploadProgress: (progressEvent) => {
                set(
                  fileProgressAtom(rawFile.name),
                  calculateProgress(progressEvent),
                )
              },
            })
          } catch (error) {
            Sentry.captureException(error)
            set(
              fileErrorAtom(rawFile.name),
              (error as AxiosError)?.message ?? 'An error occurred',
            )
            erroredFiles.push(rawFile)
          }
        }),
      )

      closeModal()
      notifications.show({
        title: 'Success',
        message: `${files.length} ${pluralize('file', erroredFiles.length)} uploaded successfully`,
        color: 'green',
        autoClose: 5000,
      })
    } catch (error) {
      Sentry.captureException(error)
      set(fileUploadError, 'An error occurred')
    } finally {
      set(areFilesUploadingAtom, false)
      if (erroredFiles.length > 0) {
        set(fileUploadError, 'Some files failed to upload')
        notifications.show({
          title: 'Error',
          message: `${erroredFiles.length} ${pluralize('file', erroredFiles.length)} failed to upload. Please try again.`,
          color: 'red',
          autoClose: 10000,
        })
        // Remove successfully uploaded files
        set(
          filesAtom,
          get(filesAtom).filter((file) => erroredFiles.includes(file)),
        )
      } else {
        // Reset file states and clear filesAtom
        files.forEach((file) => {
          set(resetFileStateAtom, file.name)
        })
        set(filesAtom, [])
        set(isOpenAtom, false)
      }
    }
  },
)

export const useAreFilesUploading = () => useAtomValue(areFilesUploadingAtom)
export const useFiles = () => useAtomValue(filesAtom)
export const useFileProgress = (fileName: string) =>
  useAtomValue(fileProgressAtom(fileName))
export const useFileError = (fileName: string) =>
  useAtomValue(fileErrorAtom(fileName))
export const useAddFiles = () => useSetAtom(addFilesAtom)
export const useRemoveFile = () => useSetAtom(removeFileAtom)
export const useClearFiles = () => useSetAtom(clearFilesAtom)
export const useUploadFiles = () => useSetAtom(uploadFilesAtom)
export const useFileUploadError = () => useAtomValue(fileUploadError)
