import React from 'react'
import { Divider, Group, Popover, Stack, Text } from '@mantine/core'
import { Icon } from '@/components/lib/Icon'
import { Slider } from '@/components/lib/Slider'
import { useAngleFilter, useSetFilters } from '@/stores/filters-store'

const nadirAngles = Array.from({ length: 11 }, (_, index) => ({
  label: `${index * 5}°`,
  value: index * 5,
}))

export function CameraAngleSelect() {
  const nadirAngle = useAngleFilter()
  const setFilters = useSetFilters()
  const [angleOffNadir, setAngleOffNadir] = React.useState(Number(nadirAngle))

  React.useEffect(() => {
    setAngleOffNadir(Number(nadirAngle))
  }, [nadirAngle])

  const selectedText = `${angleOffNadir < 50 ? `${angleOffNadir}° of nadir or less` : 'Any'}`

  return (
    <Popover position="bottom" width="target">
      <Popover.Target>
        <Group justify="space-between" style={{ cursor: 'pointer' }}>
          <Text size="sm">{selectedText}</Text>
          <Icon name="keyboard_arrow_down" />
        </Group>
      </Popover.Target>
      <Popover.Dropdown>
        <Stack>
          <Text size="sm">{selectedText}</Text>
          <Slider
            mt="0.375rem"
            min={0}
            max={50}
            step={5}
            marks={nadirAngles}
            value={angleOffNadir}
            label={selectedText}
            aria-label="Angle Off Nadir"
            onChange={(value) => setAngleOffNadir(value)}
            onChangeEnd={(value) => setFilters({ angle: value.toString() })}
          />
          <Group justify="space-between" gap={0}>
            {nadirAngles.map((angle) => {
              return (
                <span
                  key={angle.value}
                >{`${angle.value < 50 ? angle.value : '>45'}`}</span>
              )
            })}
          </Group>
          <Divider />
          <Stack>
            <Text size="xs">
              &quot;Off nadir&quot; in satellite imagery refers to the angle of
              the satellite sensor relative to the point directly below it (the
              nadir point). While 0˚ is most common, off nadir imagery can be
              intentional to see the side of buildings or terrain.
            </Text>
          </Stack>
        </Stack>
      </Popover.Dropdown>
    </Popover>
  )
}
