import * as React from 'react'
import { list } from '@/api/collections'
import type { Collection } from '@/api/collections/types.ts'
import { useKeyedState } from '@/hooks/use-keyed-state'
import { singletonHook } from 'react-singleton-hook'

interface UseCollectionsHookResponse {
  collections: Collection[]
  isLoading: boolean
  hasMore: boolean
  loadMore: () => Promise<void>
  refresh: () => Promise<void>
}

export const STATE_KEYS = {
  collections: 'collections',
  isLoading: 'isLoading',
  hasMore: 'hasMore',
  nextToken: 'nextToken',
}

export const useAddToCollectionImpl = (): UseCollectionsHookResponse => {
  const [collections, setCollections] = useKeyedState<Collection[]>(
    STATE_KEYS.collections,
    [],
  )
  const [isLoading, setIsLoading] = useKeyedState<boolean>(
    STATE_KEYS.isLoading,
    false,
  )
  const [nextToken, setNextToken] = useKeyedState<string | undefined>(
    STATE_KEYS.nextToken,
    '',
  )
  const [hasMore, setHasMore] = useKeyedState<boolean>(STATE_KEYS.hasMore, true)

  const loadMore = React.useCallback(async () => {
    if (isLoading || !hasMore) {
      return
    }

    setIsLoading(true)
    const { next, ...response } = await list({
      limit: 100,
      next: nextToken,
    })
    setCollections(response.collections ?? [])

    setNextToken(next)
    setHasMore(next !== '')
    setIsLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextToken, isLoading, hasMore])

  React.useEffect(() => {
    loadMore().catch((error) => {
      console.error(error)
    })
  }, [loadMore])

  const refresh = async () => {
    setNextToken(undefined)
    setHasMore(true)
    await loadMore().catch((error) => {
      console.error(error)
    })
  }

  return {
    collections,
    isLoading,
    hasMore,
    loadMore,
    refresh,
  }
}

export const useAddToCollection = singletonHook<UseCollectionsHookResponse>(
  () => ({
    collections: [],
    isLoading: false,
    hasMore: false,
    loadMore: async () => {},
    refresh: async () => {},
  }),
  useAddToCollectionImpl,
)
