import { useQueryId } from '@/api/results'
import { getInsightResult } from '@/api/results/api'
import type { InsightResponse } from '@/api/results/types'
import { useQuery } from '@tanstack/react-query'

export const useInsightQuery = (): {
  insight: InsightResponse
  isLoading: boolean
  status: string
} => {
  const queryId = useQueryId()
  const { data, isLoading, status } = useQuery({
    queryKey: ['insight', queryId],
    queryFn: () => getInsightResult(queryId),
    enabled: !!queryId,
    retry: true,
    retryDelay: 1500,
  })
  return {
    status,
    insight: data as InsightResponse,
    isLoading,
  }
}

export const useIsInsightLoading = () => useInsightQuery().isLoading
export const useInsightStatus = () => useInsightQuery().status
