import { Link } from 'react-router-dom'
import { Anchor, GridCol, Text } from '@mantine/core'
import {
  CATEGORY_ROUTES,
  COMMERCIAL_CATEGORY_STRINGS,
  GOV_CATEGORY_STRINGS,
} from '@/features/BentoResults/constants'
import { useFeatureFlag } from '@/hooks/use-feature-flag'
import { FEATURE_FLAG_VALUES, ROUTES } from '@/utils/constants'
import { BentoSection } from './BentoSection'

import styles from './bento-section.module.css'

export interface OverflowBentoCellProps {
  type: string
  index: number
  firstId: string
  'data-testid'?: string
}

export const OverflowBentoCell = ({
  type,
  index,
  firstId,
}: OverflowBentoCellProps) => {
  let target = CATEGORY_ROUTES[type]
  if (!target) {
    target = ROUTES.resultsItem.replace(':itemId', firstId)
  }

  const isCommercial = useFeatureFlag(FEATURE_FLAG_VALUES.commercial)
  return (
    <GridCol
      span={{ base: 6, md: 4 }}
      key={index}
      className={styles['overflow-container']}
    >
      <BentoSection isOverflow>
        <Anchor component={Link} to={target} c="var(--mantine-text-color)">
          <Text style={{ margin: '10px' }} size="sm" ta="center">
            {isCommercial
              ? COMMERCIAL_CATEGORY_STRINGS[type]
              : GOV_CATEGORY_STRINGS[type]}
          </Text>
        </Anchor>
      </BentoSection>
    </GridCol>
  )
}

export default OverflowBentoCell
