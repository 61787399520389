import { getCoreLogicRiskDataFields } from '@/api/results'
import { HIDE_ACTIONS_BY_CATEGORY } from '@/features/ResultActions/constants'
import { SingleObjectViewTemplate } from '@/features/SingleObjectView/components/SingleObjectViewTemplate'
import { RESULT_CATEGORIES } from '@/utils/constants'
import { formatDatetime } from '@/utils/format-datetime'
import type {
  CoreLogicRiskResult,
  ResultWithId,
} from '@/utils/types/result-types'

const category = RESULT_CATEGORIES.CORELOGIC_RISK
const hideActions = HIDE_ACTIONS_BY_CATEGORY[category]

export function CoreLogicRiskObjectView({
  item: rawItem,
}: {
  item: ResultWithId
}) {
  const item = rawItem as CoreLogicRiskResult

  return (
    <SingleObjectViewTemplate<CoreLogicRiskResult, string>
      {...{
        item,
        hideActions,
        hero: {
          metaDetails: [formatDatetime(item.authoredOn)],
        },
        content: {
          title: item.properties.address,
          details:
            'Comprehensive property risk assessment including crime, natural disasters, and environmental hazards.',
          data: getCoreLogicRiskDataFields(item),
        },
      }}
    />
  )
}
