import { graphql } from '@/gql'

export const NEW_INSIGHT_FEEDBACK_MUTATION = graphql(`
  mutation SubmitFeedback($newFeedback: NewFeedback!) {
    submitFeedback(newFeedback: $newFeedback) {
      id
      queryId
      feedback
      customFeedback
    }
  }
`)
