import { notifications } from '@mantine/notifications'
import { emitter, EVENTS } from '@/events'
import { useClearChat } from '@/stores/chat-store'
import { useResetFilters } from '@/stores/filters-store'
import {
  useClearSearchHereMarker,
  useClearSearchPolygon,
  useSetCurrentSearchGeometry,
  useSetIsDrawing,
  useSetUploadedFeature,
  useStopDrawingShape,
} from '@/stores/map-draw-store'
import {
  useClearActiveLayers,
  useClearOrthoLayerGroupIds,
  useSetMapBbox,
} from '@/stores/map-store'
import {
  useClearQuery,
  useSetInsightFeedbackSubmitted,
  useSetSearchMetadata,
} from '@/stores/queries-store'
import { useResetPostfilters } from '@/stores/results-filter-store'

export function useClearAll() {
  const clearSearchPolygon = useClearSearchPolygon()
  const clearSearchHereMarker = useClearSearchHereMarker()
  const stopDrawingShape = useStopDrawingShape()
  const setIsDrawing = useSetIsDrawing()
  const clearOrthoLayerIds = useClearOrthoLayerGroupIds()
  const setGeoLocation = useSetMapBbox()
  const clearActiveLayers = useClearActiveLayers()
  const clearQuery = useClearQuery()
  const resetPostFilters = useResetPostfilters()
  const setInsightFeedbackSubmitted = useSetInsightFeedbackSubmitted()
  const clearChat = useClearChat()
  const resetFilters = useResetFilters()
  const setCurrentSearchPolygon = useSetCurrentSearchGeometry()
  const setSearchMetadata = useSetSearchMetadata()
  const setUploadedFeature = useSetUploadedFeature()
  const setCurrentSearchGeometry = useSetCurrentSearchGeometry()

  // First: closes socket to stop receiving results
  // Clears all search state: results, query, filters, chat, and map
  function clearAll() {
    notifications.clean()
    clearQuery()
    resetFilters()
    clearChat()
    stopDrawingShape()
    setIsDrawing(false)
    setGeoLocation(null)
    setSearchMetadata(null)
    setUploadedFeature(null)
    clearOrthoLayerIds()

    //clear map draw state
    clearSearchPolygon()
    setCurrentSearchGeometry(null)
    setCurrentSearchPolygon(null)
    clearSearchHereMarker()

    setInsightFeedbackSubmitted(false)
    clearActiveLayers()
    resetPostFilters()
    emitter.emit(EVENTS.clearAll)
  }

  return clearAll
}
