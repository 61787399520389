import { getRequest } from '@/api/danti-requests'
import { PROXY_REQUIRED, SOURCE_MAP } from '@/api/thumbnail/constants'
import type { AnalyticResponse } from '@/api/thumbnail/types'
import { apiUrl, appEnv } from '@/utils/constants'

export async function getProxiedUrl(params: {
  thumbnail: string | URL
  source: string
}): Promise<string | undefined> {
  // check if this item should be proxied
  const url = new URL(params.thumbnail)
  if (!PROXY_REQUIRED.has(url.hostname) && params.source != 'Danti Analytic') {
    return url.toString()
  }

  const source = params.source.startsWith('NUDL ') ? 'NUDL' : params.source
  // pull the correct proxy path based on the provider
  const provider = SOURCE_MAP.get(source)

  if (!provider) {
    console.error(`couldn't find provider for ${source}`)
    return undefined
  }

  if (provider == 'analytic') {
    const signingUrl = new URL(apiUrl)
    signingUrl.pathname = `/provider/${provider}${url.pathname}`
    signingUrl.search = url.search

    const response = await getRequest<AnalyticResponse>(signingUrl.toString())

    return response.data.url

    // if thumbnail url is not already a provider request, rewrite the thumbnail url to the proxy provider hostname and path prefix
  } else if (!url.pathname.includes('provider/')) {
    const thumbnailUrl = new URL(apiUrl)
    thumbnailUrl.pathname = `/provider/${provider}${url.pathname}`
    thumbnailUrl.search = url.search
    return thumbnailUrl.toString()
  }

  // Update hostname for correct api workload; test -> api.dev.danti
  // otherwise use the thumbnailUrl as is
  if (appEnv != 'local' && appEnv != 'test') {
    url.hostname = `api.${appEnv}.danti.ai`
  }
  return url.toString()
}
