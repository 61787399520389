import React from 'react'
import { Slider, Stack, Text } from '@mantine/core'
import houseImage from '@/assets/overhead-house.png'
import type { FilterArgs } from '@/features/Filters/types'

const qualities = [
  { label: '5cm', value: '0.05' },
  { label: '10cm', value: '0.1' },
  { label: '15cm', value: '0.15' },
  { label: '30cm', value: '0.3' },
  { label: '50cm', value: '0.5' },
  { label: '1m', value: '1' },
  { label: '3m', value: '3' },
  { label: '10m', value: '10' },
  { label: '10m+', value: '5000' },
]

function getQualityIndex(value: string) {
  const qualityIndex = qualities.findIndex((quality) => quality.value === value)

  return qualityIndex > -1 ? qualityIndex : qualities.length - 1
}

export function Quality({ value, setter }: FilterArgs<string>) {
  const [qualityIndex, setQualityIndex] = React.useState(getQualityIndex(value))

  React.useEffect(() => {
    setQualityIndex(getQualityIndex(value))
  }, [value])

  return (
    <Stack gap="xs" w="100%">
      <Text size="md" mb="xs">
        Quality
      </Text>

      <Text size="sm" fw="bold" c="blue">
        {qualityIndex === qualities.length - 1
          ? 'Any quality'
          : `${qualities[qualityIndex].label} and better`}
      </Text>
      <div
        style={{
          width: '100%',
          height: '8rem',
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        {
          // This establishes 2 image layers on top of each other to prevent the
          // edges of the image from showing as semi-transparent
          [0, 1].map((value) => (
            <img
              key={value}
              alt="blur"
              src={houseImage}
              style={{
                objectFit: 'cover',
                position: 'absolute',
                left: '-5%',
                top: '-5%',
                width: '110%',
                height: '110%',
                filter: `blur(${qualityIndex}px)`,
              }}
            />
          ))
        }
      </div>
      <Slider
        styles={{ markLabel: { fontSize: '12px' } }}
        restrictToMarks
        mb="sm"
        label={(value) => qualities[value].label}
        value={qualityIndex}
        onChange={(value) => setQualityIndex(value)}
        onChangeEnd={(value) => setter(qualities[value].value)}
        marks={qualities.map(({ label }, index) => ({ value: index, label }))}
        min={0}
        max={qualities.length - 1}
      />
    </Stack>
  )
}
