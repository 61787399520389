import { useState } from 'react'
import { Card, Text } from '@mantine/core'
import { Icon } from '@/components/lib/Icon'
import { GeometryStaticDisplay } from '@/features/GeometryStaticDisplay'
import { ResultActions } from '@/features/ResultActions'
import { HIDE_ACTIONS_BY_CATEGORY } from '@/features/ResultActions/constants'
import { ResultImage, type ResultImageObject } from '@/features/ResultImage'
import { formatDate, formatTime } from '@/utils/format-datetime'
import type { SymbolName } from '@/utils/types/material-icons'
import type { ImageryResult, ResultWithId } from '@/utils/types/result-types'
import type { MultiPolygon, Point, Polygon } from 'geojson'

import style from './reference-card.module.css'

export const ReferenceCard = ({
  heroImage,
  heroMapImage,
  heroImageUrl,
  heroIcon,
  heroIconName,
  heroGeometry,
  result,
  category,
  title,
  authoredOn,
  details,
  description,
  withActions = true,
}: {
  result: ResultWithId
  category: string
  heroMapImage?: ImageryResult
  heroImage?: ResultImageObject
  heroImageUrl?: string | null
  heroIcon?: React.ReactNode
  heroIconName?: SymbolName
  heroGeometry?: Point | Polygon | MultiPolygon
  title: React.ReactNode
  authoredOn: string
  details?: string
  description?: string
  withActions?: boolean
}) => {
  const [hideImage, setHideImage] = useState(false)
  const hasHero = (heroImage || heroImageUrl || heroGeometry) && !hideImage
  const absoluteGeometry =
    heroGeometry && (heroImage || heroImageUrl) && !hideImage
  let hero
  if (heroGeometry) {
    hero = (
      <GeometryStaticDisplay
        className={style.geometry}
        geometry={heroGeometry}
      />
    )
  } else if (heroMapImage) {
    hero = <ResultImage rootClassName={style.image} item={heroMapImage} />
  } else if (heroImage) {
    hero = <ResultImage rootClassName={style.image} item={heroImage} />
  } else if (heroImageUrl && !hideImage) {
    hero = (
      <img
        className={absoluteGeometry ? style.absoluteGeometry : style.image}
        src={heroImageUrl}
        alt={heroImageUrl}
        loading="lazy"
        onError={() => setHideImage(true)}
      />
    )
  } else if (heroIcon) {
    hero = <div className={style.icon}>{heroIcon}</div>
  } else if (heroIconName) {
    hero = (
      <div className={style.icon}>
        <Icon name={heroIconName} />
      </div>
    )
  }

  const card = (
    <Card className={style.card} padding={0}>
      {hero}
      <Text className={style.title}>{title}</Text>
      <Text className={style.authoredOn}>
        {formatDate(authoredOn)} {formatTime(authoredOn)}
      </Text>
      {details && <Text className={style.details}>{details}</Text>}
      {description && (
        <Text className={style.description} lineClamp={hasHero ? 3 : 6}>
          {description}
        </Text>
      )}
    </Card>
  )

  return withActions ? (
    <div className={style.hiddenPanel}>
      {card}
      <ResultActions
        className={style.reveal}
        iconClassName={style.clickable}
        result={result}
        hideActions={
          HIDE_ACTIONS_BY_CATEGORY[
            category as keyof typeof HIDE_ACTIONS_BY_CATEGORY
          ]
        }
      />
    </div>
  ) : (
    card
  )
}
