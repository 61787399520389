import { useQueryClient } from '@tanstack/react-query'
import { useRefreshCollections } from './use-refresh-collections'

export const useRefreshCollection = () => {
  const queryClient = useQueryClient()
  const refresh = useRefreshCollections()

  return (collectionId: string) => {
    void queryClient.invalidateQueries({
      queryKey: ['collection', collectionId],
    })
    refresh()
  }
}
