import { useImageById } from '@/api/results'
import { SingleImageMap } from '@/features/DantiMap'

export const TimelineCompare = ({
  selectedImages,
  // setSelectedImages,
}: {
  selectedImages: {
    primary: string | null
    secondary: string | null
  }
  setSelectedImages: {
    primary: (image: string | null) => void
    secondary: (image: string | null) => void
  }
}) => {
  const primary = useImageById(selectedImages.primary)
  const secondary = useImageById(selectedImages.secondary)
  return (
    <div>
      <h2>Compare Component</h2>
      {primary && (
        <SingleImageMap
          key={primary.id}
          item={primary}
          style={{
            width: '50%',
            display: 'inline-block',
            border: '4px solid blue',
            borderRight: 'none',
          }}
        />
      )}
      {secondary && (
        <SingleImageMap
          key={secondary.id}
          item={secondary}
          style={{
            width: '50%',
            display: 'inline-block',
            border: '4px solid red',
            borderLeft: 'none',
          }}
        />
      )}
    </div>
  )
}
