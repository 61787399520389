import * as React from 'react'
import { useSearchQuery } from '@/api/results'
import { useClearAll } from '@/hooks/use-clear-all'
import { useDantiAuth } from '@/hooks/use-danti-auth'
import {
  type Filters,
  useGetFilters,
  useResetFilters,
  useSetFilters,
} from '@/stores/filters-store'
import {
  MAP_LAYER_OPTIONS,
  useSetActiveLayers,
  useSetMapBbox,
} from '@/stores/map-store'
import {
  useCurrentQuery,
  useSetQuery,
  useSetQueryFilters,
  useSetQueryStarted,
  useSetTotalProcessedResults,
} from '@/stores/queries-store'
import { debug } from '@/utils/debug'
import { captureEvent, POSTHOG_EVENTS } from '@/utils/posthog'
import { makeApiFilters } from '@/utils/search-filters/make-api-filters'
import { makeSemanticQueryFilters } from '@/utils/search-filters/make-semantic-filters'
import type { RadiusApiFilterValue } from '@/utils/types/filter-types'
import { useQueryClient } from '@tanstack/react-query'
import { singletonHook } from 'react-singleton-hook'

export function useSearchImpl() {
  const queryClient = useQueryClient()
  const { isAuthenticated } = useDantiAuth()

  const setQuery = useSetQuery()
  const setQueryFilters = useSetQueryFilters()
  const setTotalProcessedResults = useSetTotalProcessedResults()

  const setMapBbox = useSetMapBbox()
  const setActiveLayers = useSetActiveLayers()
  const setFilters = useSetFilters()
  const filters = useGetFilters()
  const resetFilters = useResetFilters()
  const currentQuery = useCurrentQuery()
  const setQueryStarted = useSetQueryStarted()
  useSearchQuery()

  const clearAll = useClearAll()

  const doSearch = React.useCallback(
    (params: { query: string; filters: Partial<Filters> }) => {
      if (!isAuthenticated) {
        throw new Error('Attempted to search before login')
      }
      clearAll()
      setTotalProcessedResults(0)
      setFilters(params.filters)
      const apiFilters = makeApiFilters(params.filters)
      setQuery(params.query)
      setQueryFilters(apiFilters)
      setQueryStarted()

      captureEvent(POSTHOG_EVENTS.SEARCH.SUBMIT, {
        query: params.query,
        filters: JSON.stringify(params.filters),
      })

      debug(
        `Running search for ${params.query} with filters ${JSON.stringify(
          apiFilters,
        )}`,
      )

      Object.values(MAP_LAYER_OPTIONS).map((layer) => setActiveLayers(layer))
      // Slight delay on backend when this shows up in opensearch
      setTimeout(() => {
        void queryClient.invalidateQueries({ queryKey: ['history'] })
        void queryClient.invalidateQueries({ queryKey: ['history', 0, ''] })
      }, 1000)
    },
    // TODO: investigate why setActiveLayers is not included
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      isAuthenticated,
      queryClient,
      clearAll,
      setTotalProcessedResults,
      setQuery,
      setQueryFilters,
      setQueryStarted,
      setMapBbox,
    ],
  )

  const doTextSearch = React.useCallback(
    (searchString: string) => {
      resetFilters()
      return doSearch({ query: searchString, filters: {} })
    },
    [doSearch, resetFilters],
  )

  const doLocationSearch = React.useCallback(
    (location: string, locationType: string) => {
      const locationFilter: Partial<Filters> = { location, locationType }
      return doSearch({ query: '', filters: locationFilter })
    },
    [doSearch],
  )

  const doRadiusSearch = React.useCallback(
    (geojson: RadiusApiFilterValue) => {
      const locationFilter: Partial<Filters> = { radius: geojson }
      return doSearch({ query: '', filters: locationFilter })
    },
    [doSearch],
  )

  const doSplitSearch = React.useCallback(
    (
      location: string,
      formatted: string,
      query: string,
      geocodeValues: {
        formattedAddress: string
        placeId: string
      },
    ) => {
      const combinedQuery = `${formatted}${query ? ` - ${query}` : ''}`
      const { locationType, keywords } = makeSemanticQueryFilters(combinedQuery)
      const splitFilters = {
        ...filters,
        location,
        locationType,
        keywords,
        ...geocodeValues,
      }

      return doSearch({ query: combinedQuery, filters: splitFilters })
    },
    [doSearch, filters],
  )

  const doLastSearchWithFilters = React.useCallback(() => {
    const filterPayload = makeApiFilters(filters)
    captureEvent(POSTHOG_EVENTS.EXPLORE.ADVANCED_SEARCH, {
      filters: JSON.stringify(filterPayload),
    })
    return doSearch({ query: currentQuery, filters })
  }, [currentQuery, doSearch, filters])

  return {
    doTextSearch,
    doLocationSearch,
    doRadiusSearch,
    doSplitSearch,
    doLastSearchWithFilters,
  }
}

export const useSearch = singletonHook<{
  doTextSearch: (searchString: string) => void
  doLocationSearch: (location: string, locationType: string) => void
  doRadiusSearch: (geojson: RadiusApiFilterValue) => void
  doSplitSearch: (
    location: string,
    formatted: string,
    query: string,
    geocodeValues: {
      formattedAddress: string
      placeId: string
    },
  ) => void
  doLastSearchWithFilters: () => void
}>(
  () => ({
    doTextSearch: () => {},
    doLocationSearch: () => {},
    doRadiusSearch: () => {},
    doSplitSearch: () => {},
    doLastSearchWithFilters: () => {},
  }),
  useSearchImpl,
)
