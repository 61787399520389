export const COLOR_SIZE = 8
export const TABS_MAP = {
  images: 'IMAGE',
  ortho: 'ORTHO',
  news: 'PUBLICATION',
  social: 'SOCIAL_MEDIA',
  data: 'DATA',
  fire: 'FIRE',
  ship: 'SHIP',
  property: 'PROPERTY',
}
