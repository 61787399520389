import type { CardProps } from '@mantine/core'
import { BentoCard } from '@/features/BentoResults/components'
import { HIDE_ACTIONS_BY_CATEGORY } from '@/features/ResultActions/constants'
import { RESULT_CATEGORIES } from '@/utils/constants'
import type { CoreLogicPropertyResult } from '@/utils/types/result-types'

export interface CoreLogicPropertyBentoCardProps extends CardProps {
  result: CoreLogicPropertyResult
  subEntryIndex: number
  size: string
}

const category = RESULT_CATEGORIES.CORELOGIC_PROPERTY
const actionsToHide = HIDE_ACTIONS_BY_CATEGORY[category]

export const CoreLogicPropertyBentoCard = ({
  result,
  subEntryIndex,
  ...props
}: CoreLogicPropertyBentoCardProps) => {
  return (
    <BentoCard
      {...props}
      result={result}
      index={subEntryIndex}
      hideActions={actionsToHide}
      resultCategory={RESULT_CATEGORIES.CORELOGIC_PROPERTY}
      image={{
        title: result.title,
        source: result.source,
      }}
    />
  )
}

export default CoreLogicPropertyBentoCard
