import { Image } from '@mantine/core'
import { useIntersection } from '@mantine/hooks'
import { useThumbnailProxy } from '@/api/thumbnail'
import { DantiNoResultMark } from '@/components/lib/DantiNoResultMark'
import { IMAGE_STYLE_PROPS } from '@/features/ResultImage/constants'
import type React from 'react'

import styles from './result-image.module.css'

export type ResultImageObject = {
  thumbnail?: string
  title: string
  source: string
}

interface ResultImageProps {
  item: ResultImageObject
  style?: React.CSSProperties
  onClick?: () => void
  className?: string
  rootClassName?: string
}

export function ResultImage({
  item,
  style,
  rootClassName = '',
  ...props
}: ResultImageProps) {
  const { ref, entry } = useIntersection({
    rootMargin: '0px',
    threshold: 0,
  })
  const { title, thumbnail, source } = item

  const { data } = useThumbnailProxy(
    { thumbnail: thumbnail ?? '', source },
    Boolean(thumbnail) && entry?.isIntersecting,
  )

  return (
    <div
      ref={ref}
      className={`${styles['result-image']} ${rootClassName}`}
      style={style}
    >
      {data ? (
        <Image
          alt={title}
          src={data}
          style={{ borderRadius: '8px' }}
          {...IMAGE_STYLE_PROPS}
          {...props}
          data-cy="result-image-thumbnail"
        />
      ) : (
        <DantiNoResultMark />
      )}
    </div>
  )
}

export default ResultImage
