import type { InputFields } from '@/features/Search/types'
import { useKeyedRef } from '@/hooks/use-keyed-ref'
import { useKeyedState } from '@/hooks/use-keyed-state'
import { useStoredSplitSearch } from '@/stores/queries-store'

export const stateKeys = {
  location: 'location',
  subject: 'subject',
}

export const useInputFields = (): InputFields => {
  const storedSplitSearch = useStoredSplitSearch()

  const [locationValue, setLocationValue] = useKeyedState(
    stateKeys.location,
    storedSplitSearch?.location ?? '',
  )
  const [subjectValue, setSubjectValue] = useKeyedState(
    stateKeys.subject,
    storedSplitSearch?.subject ?? '',
  )

  const locationRef = useKeyedRef<HTMLInputElement | null>(
    stateKeys.location,
    null,
  )
  const subjectRef = useKeyedRef<HTMLInputElement | null>(
    stateKeys.subject,
    null,
  )

  const blur = () => {
    locationRef.current?.blur()
    subjectRef.current?.blur()
  }

  const focus = () => {
    locationRef.current?.focus()
  }

  const handleOptionSubmit = (value: string) => {
    setLocationValue(value)
    subjectRef.current?.focus()
  }

  return {
    blur,
    focus,
    locationRef,
    subjectRef,
    locationValue,
    setLocationValue,
    subjectValue,
    setSubjectValue,
    handleOptionSubmit,
    subjectProps: {
      ref: subjectRef,
      value: subjectValue,
      onChange: setSubjectValue,
    },
  }
}
