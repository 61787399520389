import { Fragment, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  BackgroundImage,
  Box,
  Button,
  Container,
  em,
  Group,
  Stack,
  Title,
} from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import {
  commercialBackgroundImages,
  govBackgroundImages,
} from '@/assets/images'
import { SearchBar } from '@/features/Search'
import { SEARCH_SUGGESTIONS } from '@/features/Search/constants'
import type { SearchSuggestion } from '@/features/Search/types'
import { SearchSuggestionCell } from '@/features/Search/views/SearchSuggestion/SearchSuggestion'
import { useDantiAuth } from '@/hooks/use-danti-auth'
import { useFeatureFlag } from '@/hooks/use-feature-flag'
import {
  useSetStoredLocation,
  useSetStoredSplitSearch,
} from '@/stores/queries-store'
import { FEATURE_FLAG_VALUES } from '@/utils/constants'

import styles from './explore.module.css'
import searchStyles from '@/features/Search/search-bar.module.css'

const intervalDuration = 10000
const fadeTransition = 1000
const delayFadeOut = intervalDuration - fadeTransition

export function Explore() {
  const location = useLocation()
  const isLogIn = location.pathname === '/log-in'
  const isMobile = useMediaQuery(`(max-width: ${em(750)})`)
  const setStoredLocation = useSetStoredLocation()
  const { logIn, isAuthenticated } = useDantiAuth()
  const navigate = useNavigate()

  const setStoredSplitSearch = useSetStoredSplitSearch()

  const [imageIndex, setImageIndex] = useState(0)
  const backgroundRef = useRef<HTMLImageElement>(null)
  const isCommercial = useFeatureFlag(FEATURE_FLAG_VALUES.commercial)
  const backgroundImages = isCommercial
    ? commercialBackgroundImages
    : govBackgroundImages

  useEffect(() => {
    setStoredLocation(null)
    const backgroundInterval = setInterval(() => {
      setImageIndex((index) => index + 1)
    }, intervalDuration)

    return () => {
      clearInterval(backgroundInterval)
    }
  }, [setStoredLocation])

  useLayoutEffect(() => {
    if (backgroundRef.current) {
      const timeout = setTimeout(() => {
        backgroundRef.current?.classList.add(styles['fadeOut'])
      }, delayFadeOut)

      return () => {
        clearTimeout(timeout)
      }
    }
  }, [imageIndex])

  const handleSuggestionClick = (suggestion: SearchSuggestion) => {
    const { location, subject } = suggestion
    const { formattedAddress } = location
    const search = [formattedAddress, subject].join(' - ')
    const params = new URLSearchParams({ search })

    setStoredSplitSearch({
      location: formattedAddress,
      subject,
    })
    navigate(`/overview?${params.toString()}`)
  }

  return (
    <Container size="100%" p={0} className={searchStyles.explorePage}>
      <Box mx="auto" className={styles.container}>
        <Stack h="calc(100vh - 230px)" justify="flex-end">
          <Title
            size={isMobile ? 55 : 98}
            c="white"
            lh={1}
            p={isMobile ? 'md' : undefined}
            mb="1rem"
            style={{
              textShadow: '3px 3px 7px #0006',
              letterSpacing: '-0.03em',
            }}
          >
            Search {isCommercial ? 'property' : `Earth's`} data like never
            before.
          </Title>
          {isCommercial && !isAuthenticated && (
            <Button variant="primary" onClick={logIn} w={140} ml={8} mt={-24}>
              Start Exploring
            </Button>
          )}
          {isCommercial && isAuthenticated && (
            <Group visibleFrom="md" gap="xs">
              {SEARCH_SUGGESTIONS.map((x) => (
                <SearchSuggestionCell
                  key={x.title}
                  suggestion={x}
                  onClick={handleSuggestionClick}
                />
              ))}
            </Group>
          )}
          {!isLogIn && (
            <SearchBar
              style={{
                textShadow: 'none',
                width: '100%',
                minWidth: 300,
              }}
            />
          )}
        </Stack>
        <Fragment key={imageIndex}>
          <BackgroundImage
            src={backgroundImages[(imageIndex + 1) % backgroundImages.length]}
            pos="absolute"
            h="100%"
            top={0}
            left={0}
            style={{
              zIndex: -1,
              backgroundImage: 'linear-gradient(180deg, #0000 60%, #000b 100%)',
            }}
          />
          <BackgroundImage
            src={backgroundImages[imageIndex % backgroundImages.length]}
            ref={backgroundRef}
            pos="absolute"
            h="100%"
            top={0}
            left={0}
            style={{
              zIndex: -1,
              opacity: 1,
              backgroundImage: 'linear-gradient(180deg, #0000 60%, #000b 100%)',
            }}
          />
          <div
            style={{
              backgroundImage: 'linear-gradient(180deg, #0000 60%, #000b 100%)',
              position: 'absolute',
              inset: 0,
              zIndex: -1,
            }}
          />
        </Fragment>
      </Box>
    </Container>
  )
}

export default Explore
