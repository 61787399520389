import { useState } from 'react'
import { isEmail, useForm } from '@mantine/form'
import type { ContextModalProps } from '@mantine/modals'
import { OrgUsersQuery } from '@/api/user'
import { OrgRole } from '@/gql/graphql.ts'
import { InviteUsersMutation } from '@/pages/Account/mutations'
import type { InviteUsersForm } from '@/utils/types/user-types'
import { useMutation } from '@apollo/client'
import { captureException } from '@sentry/react'

export function useInviteUserModalData({ context, id }: ContextModalProps) {
  const [inviteUsers] = useMutation(InviteUsersMutation)

  const [isErrorState, setErrorState] = useState(false)

  function handleShare(values: InviteUsersForm) {
    const { emails, role } = values
    void inviteUsers({
      variables: {
        emails,
        role,
      },
      refetchQueries: [OrgUsersQuery],
      onCompleted() {
        context.closeModal(id)
      },
      onError(_error) {
        setErrorState(true)
        captureException(
          `Could not invite users, fields: ${emails.join(',')}, ${role}`,
        )
        console.dir('error in user invite')
      },
    })
  }

  const form = useForm<InviteUsersForm>({
    initialValues: { emails: [], role: OrgRole.Viewer },

    // functions will be used to validate values at corresponding key
    validate: {
      emails: (value) => {
        if (value.length === 0) {
          return 'Must include emails'
        }

        const invalidEmails = value.filter(
          (x) => void isEmail('Invalid email')(x),
        )
        return invalidEmails.length > 0 ? 'Invalid email' : null
      },
    },
  })

  const onSubmit = form.onSubmit((values: InviteUsersForm) => {
    return void handleShare(values)
  })

  const closeModal = () => context.closeModal(id)

  return {
    form,
    handleShare,
    isErrorState,
    onSubmit,
    closeModal,
  }
}

export default useInviteUserModalData
