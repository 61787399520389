import { Button } from '@mantine/core'
import { Icon } from '@/components/lib/Icon'
import { PANEL_STATES } from '@/features/InsightPanel/constants'
import { useInsightFeedback } from '@/features/InsightPanel/hooks'
import { messages } from '@/features/InsightPanel/messages'
import { InsightFeedbackForm } from './InsightFeedbackForm'

export function InsightFeedbackPanel() {
  const { openPanel, panelState } = useInsightFeedback()

  const buttonProps = {
    variant: 'outline',
    color: 'gray.5',
    w: 'max-content',
  }

  const openButton = (
    <Button
      {...buttonProps}
      onClick={openPanel}
      fw="normal"
      leftSection={<Icon name="thumb_down" size="sm" />}
    >
      {messages.TITLE}
    </Button>
  )

  const sentButton = (
    <Button disabled {...buttonProps} leftSection={<Icon name="check" />}>
      {messages.THANKS}
    </Button>
  )

  switch (panelState) {
    case PANEL_STATES.BUTTON:
      return openButton
    case PANEL_STATES.PANEL:
      return <InsightFeedbackForm />
    case PANEL_STATES.DONE:
      return sentButton
  }
}
