import { ActionIcon } from '@mantine/core'
import { Icon } from '@/components/lib/Icon'

import styles from './hero.module.css'

export interface SOVHeroNavProps {
  toNext?: () => void
  toPrevious?: () => void
}

export function SOVHeroNav({ toNext, toPrevious }: SOVHeroNavProps) {
  return (
    <>
      {toNext && (
        <ActionIcon
          onClick={toNext}
          className={styles['next-arrow']}
          variant="transparent"
        >
          <Icon name="chevron_right" onClick={toNext} size="xl" />
        </ActionIcon>
      )}
      {toPrevious && (
        <ActionIcon
          onClick={toPrevious}
          className={styles['prev-arrow']}
          variant="transparent"
        >
          <Icon name="chevron_left" onClick={toPrevious} size="xl" />
        </ActionIcon>
      )}
    </>
  )
}
