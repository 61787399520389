import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  BackgroundImage,
  Box,
  Button,
  Container,
  Text,
  Title,
} from '@mantine/core'
import AccessDeniedImage from '@/assets/access-denied.png'
import { useLastAttemptedRoute } from '@/features/AppRouting/hooks'
import { useDantiAuth } from '@/hooks/use-danti-auth.ts'
import { useHasActiveSubscription } from '@/hooks/use-has-active-subscription.ts'

import styles from './access-denied.module.css'

export function AccessDenied() {
  const { refreshUser, isAuthorizedUser, isLoading } = useDantiAuth()
  const { hasActiveSubscription } = useHasActiveSubscription()
  const [lastAttemptedRoute, setLastAttemptedRoute] = useLastAttemptedRoute()
  const navigate = useNavigate()

  useEffect(() => {
    async function handleRefreshUser() {
      await refreshUser()

      if (isAuthorizedUser() && !isLoading && hasActiveSubscription) {
        const temporaryLastAttemptedRoute = lastAttemptedRoute ?? '/'
        setLastAttemptedRoute(null)
        navigate(temporaryLastAttemptedRoute)
      }
    }

    void handleRefreshUser()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container size="100%" p={0}>
      <BackgroundImage
        src={AccessDeniedImage}
        pos="absolute"
        h="100%"
        top={0}
        left={0}
        style={{
          backgroundImage: 'linear-gradient(180deg, #0000 60%, #000b 100%)',
        }}
      />
      <Box className={styles.accessDeniedContent}>
        <Title size={48} lh={1} mb="1rem">
          403 Access Denied
        </Title>
        <Text>
          Sorry, you do not have permission to access this resource. Please
          contact the administrator for assistance.
        </Text>
        <Button onClick={() => navigate(-1)} mt="1rem" variant="outline">
          Go Back
        </Button>
      </Box>
    </Container>
  )
}
