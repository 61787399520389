import React, { useEffect } from 'react'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'
import { useQueryId } from '@/api/results'
import { useDantiAuth } from '@/hooks/use-danti-auth.ts'
import { useCurrentQuery } from '@/stores/queries-store'
import { appEnv, isGov, isLocal, isProduction } from '@/utils/constants'
import { debug } from '@/utils/debug'
import { captureEvent, POSTHOG_EVENTS } from '@/utils/posthog'
import * as Sentry from '@sentry/react'

// Defined separately so we can use it in the SentryFeedbackButton
export const feedbackModule = Sentry.feedbackIntegration({
  autoInject: false,
  submitButtonLabel: 'Submit feedback',
  formTitle: 'Send Danti feedback',
  messagePlaceholder:
    "Describe what you are experiencing, and tell us what you'd expect or prefer.",
  isEmailRequired: false,
  isNameRequired: false,
  onSubmitSuccess: () => captureEvent(POSTHOG_EVENTS.FEEDBACK.SUBMITTED),
  enableScreenshot: false,
  showBranding: false,
})

export const configureSentry = () => {
  if (isLocal) {
    debug("Not configuring Sentry because we're on localhost")
    return
  }
  Sentry.init({
    // This is the "Default" key from Sentry Settings > danti > danti > Client Keys
    //  Don't use the one the docs auto-populate for you, it's the wrong one.
    dsn: 'https://555b81ad4cf64420916662f38d4f8c4e@o4505330790825984.ingest.us.sentry.io/4505330797445120',
    integrations: [
      feedbackModule,
      Sentry.replayIntegration({
        maskAllText: false,
      }),
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    replaysSessionSampleRate: isProduction || isGov ? 0.1 : 1,
    replaysOnErrorSampleRate: 1,
    environment: appEnv,
    // Performance Monitoring
    tracesSampleRate: isProduction || isGov ? 0.2 : 1, // Capture 100% of the transactions, reduce in production!
  })
}

export const useSentryMetadataSyncing = () => {
  const { user } = useDantiAuth()
  const query = useCurrentQuery()
  const queryId = useQueryId()

  useEffect(() => {
    Sentry.setUser({ email: user?.profile.email })
  }, [user?.profile.email])

  useEffect(() => {
    Sentry.setContext('searchQuery', { query, queryId })
  }, [query, queryId])
}
