import * as React from 'react'
import { usePropertyResults, useResults } from '@/api/results'
import {
  GOV_BENTO_ORDER,
  PROPERTY_BENTO_ORDER,
} from '@/features/BentoResults/constants'
import type { BentoCardData } from '@/features/BentoResults/types'
import { RESULT_CATEGORIES } from '@/utils/constants'
import type { AttomResult, ResultWithId } from '@/utils/types/result-types'

export function useOverflowCategories(results: BentoCardData[]) {
  const allResults = useResults()
  const isProperty = usePropertyResults().length > 0
  const order = isProperty ? PROPERTY_BENTO_ORDER : GOV_BENTO_ORDER
  return React.useMemo(() => {
    const newOverflowResults = [] as string[]
    order.forEach((category) => {
      if (
        (allResults[category] || []).length === 0
        // results.some((result) => result.type === category)
      ) {
        return
      }
      const result = allResults[category][0] as ResultWithId
      if (
        category === RESULT_CATEGORIES.ATTOM &&
        (result as AttomResult).properties?.buildingPermits.length === 0
      ) {
        return
      }
      newOverflowResults.push(category)
    })
    return newOverflowResults
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results])
}
