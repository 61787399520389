import { Divider, Modal, Stack, Text } from '@mantine/core'

export interface ItemModalMenuProps {
  content: React.ReactNode
  name: string
  onClose: () => void
}

export const ItemModalMenu = ({
  content,
  name,
  onClose,
}: ItemModalMenuProps) => (
  <Modal.Root opened={true} onClose={onClose} centered>
    <Modal.Overlay />
    <Modal.Content>
      <Modal.Header>
        <Modal.Title>
          <Text c="#000000DE" size="18px" style={{ fontWeight: 500 }}>
            {name}
          </Text>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Stack gap={0}>
          <Divider mb="md" />
          {content}
        </Stack>
      </Modal.Body>
    </Modal.Content>
  </Modal.Root>
)
