import type { CardProps } from '@mantine/core'
import { BentoCard } from '@/features/BentoResults/components'
import { HIDE_ACTIONS_BY_CATEGORY } from '@/features/ResultActions/constants'
import { RESULT_CATEGORIES } from '@/utils/constants'
import type { VexcelResult } from '@/utils/types/result-types'

export interface VexelBentoCardProps extends CardProps {
  result: VexcelResult
  subEntryIndex: number
  size: string
}

const category = RESULT_CATEGORIES.HAZARD_PROPERTY
const actionsToHide = HIDE_ACTIONS_BY_CATEGORY[category]

export const VexcelBentoCard = ({
  result,
  subEntryIndex,
  ...props
}: VexelBentoCardProps) => {
  const images =
    result.properties.vexcel_images?.vexcel_images.map((x) => x.url) ?? []

  return (
    <BentoCard
      {...props}
      result={result}
      index={subEntryIndex}
      hideActions={actionsToHide}
      resultCategory={RESULT_CATEGORIES.VEXCEL}
      image={{
        title: result.title,
        source: result.source,
        thumbnail: images[subEntryIndex],
      }}
      totalResults={images?.length}
    />
  )
}
export default VexcelBentoCard
