import type { SearchApiFilterValue } from '@/utils/types/filter-types.ts'

export const makePlatformFilter = (
  platforms: string[],
): SearchApiFilterValue => {
  return {
    type: 'PLATFORM',
    ids: platforms,
  }
}
