import { Slider as MantineSlider, type SliderProps } from '@mantine/core'

export function Slider(props: SliderProps) {
  return (
    <MantineSlider
      size={4}
      styles={(theme) => ({
        markLabel: { display: 'none' },
        mark: {
          height: '0.125rem',
          width: '0.125rem',
          translate: '0 50%',
          borderWidth: '0',
        },
        thumb: {
          height: '1rem',
          width: '1rem',
          backgroundColor: theme.colors.blue[6],
          borderWidth: '0',
          boxShadow: '0 1px 3px 1px rgba(0, 0, 0, 0.15)',
        },
      })}
      {...props}
    />
  )
}
