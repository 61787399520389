import { extractProvenance } from '@/stores/results-filter-store/utils'
import { apiUrl } from '@/utils/constants.ts'
import type { OrthoImageryLayer } from '@/utils/types/ortho-imagery-types.ts'
import type { ImageryResult } from '@/utils/types/result-types.ts'
import type { Polygon } from 'geojson'

const SKYSAT_TYPE = 'SkySatScene'

export const getCspoSourceId = (ir: ImageryResult) => {
  const match = ir.thumbnail.match(/items\/([^/]+)\/thumb/)
  return match ? match[1] : null // TODO: rely on _dSourceId when available
}

export const isCspoPlanet = (ir: ImageryResult) =>
  ir.source.toLowerCase() === 'cspo' &&
  ir.properties._dSource?.toLowerCase().includes('planet')

export const isPlanet = (ir: ImageryResult) =>
  ir.source.toLowerCase() === 'planet labs' || isCspoPlanet(ir)

export const makePlanetOrthoLayer = (ir: ImageryResult): OrthoImageryLayer => {
  const planetType =
    /item-types\/([^/]*)\//.exec(ir.thumbnail)?.[1] || SKYSAT_TYPE
  const dId = ir.properties._dId
  const planetId = isCspoPlanet(ir) ? getCspoSourceId(ir) : dId || ir.id

  return {
    source: 'Planet Labs' as const,
    formattedSource: `${extractProvenance(ir)}`,
    score: ir.score,
    id: `${planetType}/${planetId}`,
    tileUrl: `${apiUrl}/provider/planet/data/v1/${planetType}/${planetId}/{z}/{x}/{y}.png`,
    documentId: ir.documentId,
    authoredOn: ir.authoredOn,
    geometry: ir.geometry as Polygon,
    imageResult: ir,
    groupKey: planetId as string,
  }
}
