import { Grid, GridCol } from '@mantine/core'
import { useAllResults, useResults, useResultTotals } from '@/api/results'
import {
  OverflowBentoCell,
  PrimaryBentoCell,
  SecondaryBentoCell,
} from '@/features/BentoResults/components/BentoBox'
import {
  useBentoResults,
  useOverflowCategories,
} from '@/features/BentoResults/hooks'
import { renderers } from '@/features/BentoResults/views'
import { EmbeddedMap } from '@/features/DantiMap'
import type { ResultWithId } from '@/utils/types/result-types'

export interface BentoResultsProps {
  isLoading: boolean
}

export function BentoResults({ isLoading }: BentoResultsProps) {
  const { cardData, isProperty } = useBentoResults()
  const bentoCards = isProperty ? cardData : cardData.slice(0, 5)
  const overflowCategories = useOverflowCategories(bentoCards)

  const allResults = useAllResults()
  const hasResults = allResults.length > 0
  const categorizedResults = useResults() as Record<string, ResultWithId[]>
  const totals = useResultTotals()

  const cardChildren = Array.from({ length: 6 })
    .fill(null)
    .map((_, index) => {
      if (isLoading || !hasResults) {
        return
      }
      if (!isProperty && index === 2) {
        return <EmbeddedMap key="map" />
      }

      const card =
        bentoCards[isProperty ? index : index < 2 ? index : index - 1]
      if (!card) {
        return
      }

      const Renderer = renderers[card.type]
      const key = `bento-card-${index}`
      return (
        <Renderer
          key={key}
          size={index < 2 ? 'lg' : 'md'}
          result={card.result}
          subEntryIndex={card.subEntryIndex}
          totalResults={totals[card.type].value}
        />
      )
    }) as JSX.Element[]

  const cellProps = (index: number) => ({
    isLoading,
    'data-testid': `bento-cell-${index + 1}`,
    children: cardChildren[index],
  })

  const gridStyle = {
    width: '294px',
    marginLeft: 'auto',
    marginRight: 'auto',
  }

  return (
    <div
      style={{ margin: '20px 0', overflow: 'hidden' }}
      className="bento-results"
    >
      <Grid ml="auto" mr="auto" maw="905px" ta="center" gutter={8}>
        <PrimaryBentoCell {...cellProps(0)} />
        <PrimaryBentoCell {...cellProps(1)} />
        <GridCol span={{ base: 12, lg: 4 }}>
          <Grid gutter={8} style={gridStyle}>
            <SecondaryBentoCell {...cellProps(2)} />
            <SecondaryBentoCell {...cellProps(3)} />
          </Grid>
          <Grid gutter={8} style={gridStyle}>
            <SecondaryBentoCell {...cellProps(4)} />
            <SecondaryBentoCell {...cellProps(5)} />
          </Grid>
        </GridCol>
        {hasResults &&
          overflowCategories.map((category, index) => (
            <OverflowBentoCell
              key={category}
              type={category}
              index={index}
              firstId={categorizedResults[category][0].id}
            />
          ))}
      </Grid>
    </div>
  )
}
