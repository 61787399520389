import type { ConfigurationTablePropsType } from './types'

export const CONFIGURATION_TABLE_PROPS: ConfigurationTablePropsType = {
  rowStyle: { borderBottom: '1px solid var(--table-border-color)' },
  firstColumn: {
    fw: 'bold',
    fz: 'sm',
    pl: 'sm',
    py: 'xs',
    w: '50%',
  },
  secondColumn: {
    pr: 'sm',
    w: '50%',
    px: 0,
  },
}
