export const ZOOM_BUFFER_FACTOR = 1.25

export const MAP_DRAW_MODES = {
  POLYGON: 'draw_polygon',
  POINT: 'draw_point',
  CIRCLE: 'draw_circle',
  SELECT: 'simple_select',
}

export enum POINT_LAYER_NAMES {
  NEWS = 'news-point-layer',
  FIRE = 'fire-point-layer',
  SHIP = 'ship-point-layer',
  SOCIAL = 'social-point-layer',
}

export const POINT_SOURCES = {
  news: 'news-point',
  fire: 'fire-point',
  ship: 'ship-point',
  social: 'social-point',
}

export const POINT_ICONS = {
  news: 'Point Data Icon-News',
  fire: 'Point Data Icon-Fire',
  ship: 'Point Data Icon-Ship',
  social: 'Point Data Icon-Social',
}

export const GROUP_EXTENT_SOURCE_ID = 'highlighted_group_extent'
export const RESULT_SOURCE_PREFIX = 'orthoresult_'
