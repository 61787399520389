import type { FilterValues } from '@/stores/filters-store/types.ts'
import { makeApiFilters } from '@/utils/search-filters/make-api-filters'
import { initialFilterState, useFiltersStore } from './store'

export const useGetFilters = () => useFiltersStore((s) => s)

export const useSetFilters = () => useGetFilters().setFilters
export const useHasDefaultFilterValues = () => useGetFilters().hasDefaultValues
export const useMakeSearchFilters = () =>
  useGetFilters().makeSearchFiltersFromCurrentPanelValues
export const useSetTimePreset = () => useGetFilters().setTimePreset
export const useSetSensors = () => useGetFilters().setSensors
export const useSetSources = () => useGetFilters().setSources
export const useSetProviders = () => useGetFilters().setProviders
export const useSetPlatforms = () => useGetFilters().setPlatforms
export const useSetCategories = () => useGetFilters().setCategories
export const useSetLocation = () => useGetFilters().setLocation
export const useResetFilters = () => useGetFilters().resetFilters
export const useSetEmailUpdateFrequency = () =>
  useGetFilters().setEmailUpdateFrequency

export const useCloudCoverFilter = () => useGetFilters().cloudCover
export const useSensorsFilter = () => useGetFilters().sensors
export const useSourcesFilter = () => useGetFilters().sources
export const useProvidersFilter = () => useGetFilters().providers
export const usePlatformsFilter = () => useGetFilters().platforms
export const useCategoriesFilter = () => useGetFilters().categories
export const useLocationFilter = () => useGetFilters().location
export const useLocationTypeFilter = () => useGetFilters().locationType
export const useKeywordsFilter = () => useGetFilters().keywords
export const useQualityFilter = () => useGetFilters().quality
export const useAngleFilter = () => useGetFilters().angle
export const useOrientationsFilter = () => useGetFilters().orientations
export const useFrequencyFilter = () => useGetFilters().frequency
export const useCustomTimeStartFilter = () => useGetFilters().customTimeStart
export const useCustomTimeEndFilter = () => useGetFilters().customTimeEnd
export const useTimePresetFilter = () => useGetFilters().timePreset

export const useMakeSearchFiltersFromCurrentPanelValues = () =>
  makeApiFilters(useGetFilters())

export const useHaveFiltersUpdated = () => {
  const filters = useGetFilters()
  return JSON.stringify(filters) !== JSON.stringify(initialFilterState)
}
export const useActiveFilters = () => {
  const filters = useGetFilters()
  return Object.keys(filters).reduce((count, key) => {
    const filterKey = key as keyof FilterValues
    if (Array.isArray(filters[filterKey])) {
      const array = filters[filterKey] as Array<string>
      return array.length > 0 ? count + 1 : count
    } else {
      if (!(key in initialFilterState)) return count
      return filters[filterKey] === initialFilterState[filterKey]
        ? count
        : count + 1
    }
  }, 0)
}
