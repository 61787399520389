import type { BaseLayerOption } from '@/stores/map-store'
import { MAP_BASE_LAYERS, mapboxToken } from '@/utils/constants'
import polyline from '@mapbox/polyline'
import * as turf from '@turf/turf'
import type { MultiPolygon, Point, Polygon } from 'geojson'
import queryString from 'query-string'

interface Polyline {
  encode: (coordinates: Array<[number, number]>) => string
}

interface StaticMapOptions {
  geometry: Point | Polygon | MultiPolygon
  baseMap?: BaseLayerOption
  size?: [number, number]
  padding?: number
  color?: string
  label?: string
  zoom?: number
  attribution?: boolean
}

export const mapboxApiUrl = 'https://api.mapbox.com/styles/v1/'

export const pointPath = (point: Point, zoom: number, color?: string) => {
  const [lon, lat] = point.coordinates
  return `pin-s+${color}(${lon},${lat})/${lon},${lat},${zoom}`
}

export const polygonPath = (polygon: Polygon) => {
  const path = (polyline as Polyline).encode(
    polygon.coordinates[0].map(([lon, lat]) => [lat, lon]),
  )
  const urlEncodedPath = encodeURIComponent(path)
  return `path-2+fa531c(${urlEncodedPath})/auto`
}

export const multiPolygonPath = (multiPolygon: MultiPolygon, zoom: number) => {
  const collectionCoords = multiPolygon.coordinates.map((coords) =>
    turf.polygon(coords),
  )
  const collection = turf.featureCollection(collectionCoords)
  const centroid = turf.centroid(collection).geometry.coordinates
  const urlEncodedCollection = encodeURIComponent(JSON.stringify(collection))
  return `geojson(${urlEncodedCollection})/${centroid[0]},${centroid[1]},${zoom}`
}

/**
 * This function creates a static map URL for a given geometry.
 * @param {boolean} [attribution=true] - Whether to include attribution.
 *   This can only be legally disabled if another Mapbox instance on the same
 *   page already includes full attribution. If in doubt, leave this as true.
 */
export function createStaticMapUrl({
  geometry,
  baseMap = MAP_BASE_LAYERS.dantiStreets,
  size = [480, 200],
  padding = 10,
  color = 'fa531c',
  zoom = 8,
  attribution = true,
}: StaticMapOptions) {
  const [width, height] = size.map(Math.floor)
  const basePath = `${mapboxApiUrl}${baseMap}/static/`
  const query = queryString.stringify({
    // eslint-disable-next-line camelcase
    access_token: mapboxToken,
    ...(attribution && { attribution: false, logo: false }),
    ...(geometry.type === 'Polygon' && { padding }),
  })
  const params = `/${width}x${height}@2x?${query}`

  if (geometry.type === 'Point') {
    return `${basePath}${pointPath(geometry, zoom, color)}${params}`
  }
  if (geometry.type === 'MultiPolygon') {
    return `${basePath}${multiPolygonPath(geometry, zoom)}${params}`
  }
  if (geometry.type === 'Polygon') {
    return `${basePath}${polygonPath(geometry)}${params}`
  }
  throw new Error('Unsupported geometry type')
}
