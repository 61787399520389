import { useParams } from 'react-router-dom'
import { Button, Group, Stack, Text, Title } from '@mantine/core'
import { useCollectionItem } from '@/api/collections/collection-items/use-collection-item'
import { createStaticMapUrl } from '@/api/mapbox'
import { DantiNoResultMark } from '@/components/lib/DantiNoResultMark'
import { DataDisplay } from '@/components/lib/DataDisplay'
import { Icon } from '@/components/lib/Icon'
import {
  openAddToCollectionModal,
  openAnalyzeItemModal,
  openDownloadItemModal,
} from '@/features/AppLayout/Modals'
import { useBackNavigation } from '@/features/AppRouting/hooks'
import { SingleImageMap } from '@/features/DantiMap'
import { ResultImage } from '@/features/ResultImage'
import { renderers } from '@/features/SingleObjectView/views'
import { useIsDownloadable } from '@/hooks/results/use-is-downloadable'
import { isVexcel } from '@/utils/types/result-type-checkers'
import { type ImageryResult, isImageryResult } from '@/utils/types/result-types'
import { wordBreak } from '@/utils/ui-helpers'
import dayjs from 'dayjs'

import style from './result-image.module.css'

function ItemDetailComponent({ item }: { item: ImageryResult }) {
  const onGoBack = useBackNavigation()
  const isDownloadable = useIsDownloadable(item)

  return (
    <>
      <Stack>
        <Title size="h1" fw="bold" c="#3e3f42" mb="sm">
          {wordBreak(item.title ?? 'Unknown')}
        </Title>
        {item.subtitle && (
          <Text size="xl" c="#6b6c6f" mb="md">
            {item.subtitle}
          </Text>
        )}
        <Text size="md" fw="bold" c="#3e3f42" mb="sm">
          {dayjs(item.authoredOn).format('MM/DD/YYYY [at] h:mmA [GMT]Z')}
        </Text>
      </Stack>

      <Group
        justify="space-between"
        pb="md"
        style={{ borderBottom: '1px solid #acb4c1' }}
      >
        <Button
          variant="outlined"
          leftSection={<Icon name="arrow_left_alt" />}
          onClick={onGoBack}
        >
          Back
        </Button>
        <Group gap="sm">
          <Button
            variant="outlined"
            onClick={() => openAddToCollectionModal(item)}
          >
            Add to Saved Search
          </Button>
          <Button
            variant="primary"
            onClick={() => openDownloadItemModal(item)}
            disabled={!isDownloadable}
          >
            {isDownloadable === undefined
              ? 'Checking Ability to Download'
              : isDownloadable
                ? 'Download'
                : 'Download Unavailable'}
          </Button>
          <Button
            variant="primary"
            onClick={() => openAnalyzeItemModal(item)}
            disabled={!isDownloadable}
          >
            {isDownloadable === undefined
              ? 'Checking Ability to Analyze'
              : isDownloadable
                ? 'Analyze'
                : 'Analysis Unavailable'}
          </Button>
        </Group>
      </Group>

      <Stack mt="lg">
        {isVexcel(item) ? (
          item.properties._dPreview ? (
            <div>
              <ResultImage
                item={item}
                rootClassName={style.resultImage}
                data-cy="single-image"
              />
            </div>
          ) : (
            <DantiNoResultMark />
          )
        ) : (
          <SingleImageMap isFullScreen={false} tallMap item={item} />
        )}
        <Group>
          <div style={{ flex: 1 }}>
            <DataDisplay data={item.properties} />
          </div>
          <div
            style={{
              width: '33%',
              marginLeft: '32px',
              alignSelf: 'flex-start',
            }}
          >
            <Text
              style={{
                fontSize: '12px',
                color: '#6b6c6f',
                textTransform: 'uppercase',
              }}
            >
              Map Reference
            </Text>
            <img
              src={createStaticMapUrl({ geometry: item.geometry })}
              alt="Map"
              style={{ width: '100%', marginTop: '8px' }}
            />
          </div>
        </Group>
      </Stack>
    </>
  )
}

export default function ItemDetail() {
  const { itemId, collectionId, terrapinItemId } = useParams()
  const { item, resultCategory } = useCollectionItem({
    itemId,
    collectionId,
    terrapinItemId,
  })

  if (!item) {
    return <Text>Loading...</Text>
  }

  if (isImageryResult(item)) {
    return <ItemDetailComponent item={item} />
  }

  if (!resultCategory) {
    return <Text>Loading...</Text>
  }

  const Renderer = renderers[resultCategory as keyof typeof renderers]

  if (!Renderer || !item) {
    return <Text>Loading...</Text>
  }

  return <Renderer item={item} />
}
