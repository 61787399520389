import type { LayerConfig } from '@/features/DantiMap/types'

export const addLayerEvents = ({
  map,
  layerId,
  events,
}: LayerConfig & { map: mapboxgl.Map | null; layerId: string }) => {
  // load interactions
  if (map === null) {
    return
  }
  if (events) {
    if (events.handleMouseover) {
      map.on('mouseover', layerId, events.handleMouseover)
    }
    if (events.handleMousemove) {
      map.on('mousemove', layerId, events.handleMousemove)
    }
    if (events.handleMouseout) {
      map.on('mouseleave', layerId, events.handleMouseout)
    }
    if (events.handleClick) {
      map.on('click', layerId, events.handleClick)
    }
  }
}

export const clearLayerEvents = ({
  map,
  layerId,
  events,
}: LayerConfig & { map: mapboxgl.Map | null; layerId: string }) => {
  if (!map) return
  if (events) {
    if (events.handleMouseover) {
      map.off('mouseover', layerId, events.handleMouseover)
    }
    if (events.handleMousemove) {
      map.off('mousemove', layerId, events.handleMousemove)
    }
    if (events.handleMouseout) {
      map.off('mouseleave', layerId, events.handleMouseout)
    }
    if (events.handleClick) {
      map.off('click', layerId, events.handleClick)
    }
  }
}

export const loadMapEvents = (
  map: mapboxgl.Map | null,
  layers: Record<string, LayerConfig>,
) => {
  Object.keys(layers).forEach((layerId) => {
    addLayerEvents({ map, layerId, ...layers[layerId] })
  })
}

export const clearMapEvents = (
  map: mapboxgl.Map | null,
  layers: Record<string, LayerConfig>,
) => {
  Object.keys(layers).forEach((layerId) => {
    clearLayerEvents({ map, layerId, ...layers[layerId] })
  })
}
