export const getFirstSymbolId = (map: mapboxgl.Map) => {
  const layers = map?.getStyle()?.layers
  let firstSymbolId
  if (layers) {
    for (const layer of layers) {
      if (layer.type === 'symbol') {
        firstSymbolId = layer.id
        break
      }
    }
  }
  return firstSymbolId
}
