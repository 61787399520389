import { useCollectionsStore } from './store'

export const useCollectionsState = () => useCollectionsStore((state) => state)

export const useCollectionCurrentPage = () =>
  useCollectionsStore((state) => state.currentPage)
export const useCollectionNextToken = () =>
  useCollectionsStore((state) => state.nextToken)
export const useCollectionPreviousToken = () =>
  useCollectionsStore((state) => state.previousToken)
export const useCollectionsSort = () =>
  useCollectionsStore((state) => state.sort)
export const useCollectionsSearchTerm = () =>
  useCollectionsStore((state) => state.searchTerm)

export const useCollectionSetCurrentPage = () =>
  useCollectionsStore((state) => state.setCurrentPage)
export const useCollectionSetNextToken = () =>
  useCollectionsStore((state) => state.setNextToken)
export const useCollectionSetPreviousToken = () =>
  useCollectionsStore((state) => state.setPreviousToken)
export const useCollectionSetSort = () =>
  useCollectionsStore((state) => state.setSort)
export const useCollectionSetSearchTerm = () =>
  useCollectionsStore((state) => state.setSearchTerm)
export const useResetCollectionsState = () =>
  useCollectionsStore((state) => state.reset)
export const useCollectionToNextPage = () =>
  useCollectionsStore((state) => state.toNextPage)
export const useCollectionToPreviousPage = () =>
  useCollectionsStore((state) => state.toPreviousPage)
export const useCollectionSearch = () =>
  useCollectionsStore((state) => state.search)
