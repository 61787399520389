import { Autocomplete } from '@mantine/core'
import { Icon } from '@/components/lib/Icon'
import {
  AUTOCOMPLETE_INPUT_PROPS,
  AUTOCOMPLETE_SUBJECT_PLACEHOLDER,
} from '@/features/Search/constants'
import type { InputFields } from '@/features/Search/types'
import type * as React from 'react'

import styles from '@/features/Search/search-bar.module.css'

interface SubjectAutocompleteProps {
  handleInlineHotkeyPress: (event: KeyboardEvent | React.KeyboardEvent) => void
  inputs: InputFields
}

export const SubjectAutocomplete = ({
  handleInlineHotkeyPress,
  inputs,
}: SubjectAutocompleteProps) => (
  <Autocomplete
    id="subject-search"
    placeholder={AUTOCOMPLETE_SUBJECT_PLACEHOLDER}
    onKeyDown={handleInlineHotkeyPress}
    leftSection={<Icon name="search" />}
    classNames={{
      input: styles.subjectInput,
      root: styles.subjectInputWrapper,
    }}
    {...AUTOCOMPLETE_INPUT_PROPS}
    {...inputs.subjectProps}
  />
)
