import { useNavigate } from 'react-router-dom'
import { warnExternalLink } from '@/features/ExternalLink'

export const MarkdownLinkWrapper = ({
  href,
  children,
}: {
  href: string
  children: React.ReactNode
}) => {
  const navigate = useNavigate()

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault()

    // Check if the link is internal (starts with '/')
    if (href.startsWith('/')) {
      navigate(href)
    } else {
      warnExternalLink(href)
    }
  }

  return (
    <a href={href} onClick={handleClick}>
      {children}
    </a>
  )
}
