export const providerObject = (list: string[]) => {
  return list.reduce(
    (accumulator, current) => {
      const displayName = current.replaceAll('_', ' ').toUpperCase()
      accumulator[current] = displayName
      return accumulator
    },
    {} as Record<string, string>,
  )
}
