import type { MultiPolygon, Point, Polygon } from 'geojson'
import { SOVHeroDetails, type SOVHeroDetailsProps } from './HeroDetails'
import { SOVHeroGeometry } from './HeroGeometry'
import { SOVHeroIcon, type SOVHeroIconProps } from './HeroIcon'
import { SOVHeroImage, type SOVHeroImageProps } from './HeroImage'

export interface SOVHeroContentProps<Type>
  extends SOVHeroIconProps,
    SOVHeroImageProps,
    SOVHeroDetailsProps {
  item: Type
  geometry?: Polygon | Point | MultiPolygon
}

export function SOVHeroContent<T>(props: SOVHeroContentProps<T>) {
  const {
    icon,
    color,
    staticImage,
    geometry,
    svgIcon,
    socialIconName,
    details,
    imageAlt,
    fallbackIcon,
    vexcelImage,
    vexcelSource,
    mapImage,
    thumbnailImage,
    item,
  } = props

  if (geometry) {
    return <SOVHeroGeometry {...{ item, geometry, color }} />
  }
  if (
    [staticImage, vexcelImage, mapImage, thumbnailImage, fallbackIcon].some(
      (item) => !!item,
    )
  ) {
    return (
      <SOVHeroImage
        staticImage={staticImage}
        imageAlt={imageAlt}
        fallbackIcon={fallbackIcon}
        vexcelImage={vexcelImage}
        vexcelSource={vexcelSource}
        mapImage={mapImage}
        thumbnailImage={thumbnailImage}
      />
    )
  }
  if (icon || svgIcon || socialIconName) {
    return (
      <SOVHeroIcon
        icon={icon}
        color={color}
        svgIcon={svgIcon}
        socialIconName={socialIconName}
      />
    )
  }
  if (details) {
    return <SOVHeroDetails details={details} />
  }
  return null
}
