import * as React from 'react'
import { useSearchParams } from 'react-router-dom'

interface LocalNavigation<T> {
  toNext?: () => void
  toPrevious?: () => void
  selectItem: (item: T, index: number) => void
  current?: T
  localIndex?: number
}

export const nullMethod = () => {}

export const useLocalNavigation = <T>(items: T[]): LocalNavigation<T> => {
  const [searchParams] = useSearchParams()
  const queryIndex = searchParams.get('i')

  const [localIndex, setLocalIndex] = React.useState(() =>
    queryIndex == null ? 0 : Number.parseInt(queryIndex),
  )
  const navigateCallback = (offset: number) => () => {
    setLocalIndex(localIndex + offset)
  }

  return items.length === 0
    ? {
        selectItem: nullMethod,
      }
    : {
        current: items[localIndex],
        selectItem: (_, index: number) => setLocalIndex(index),
        localIndex,
        toPrevious: localIndex > 0 ? navigateCallback(-1) : undefined,
        toNext: localIndex < items.length - 1 ? navigateCallback(1) : undefined,
      }
}
