import { type MutableRefObject, useCallback, useEffect } from 'react'
import * as turf from '@turf/turf'
import type { FeatureCollection } from 'geojson'

export const useTerrapinLocationsLayer = (
  map: MutableRefObject<mapboxgl.Map | null>,
  aois: FeatureCollection,
) => {
  const sourceId = 'terrapin-locations'
  const pointLayer = 'terrapin-locations-point-layer'
  const polygonLayer = 'terrapin-locations-polygon-layer'
  const handleLayers = useCallback(() => {
    if (!aois) {
      return
    }
    const terrapinLocations = map.current?.getSource(
      sourceId,
    ) as mapboxgl.GeoJSONSource
    if (terrapinLocations) {
      terrapinLocations.setData(turf.flip(aois))
    } else {
      map?.current?.addSource(sourceId, {
        type: 'geojson',
        data: turf.flip(aois),
      })
    }
    if (map?.current?.getSource(sourceId)) {
      if (map?.current?.getLayer(pointLayer)) {
        return
      } else {
        map?.current?.addLayer({
          id: pointLayer,
          type: 'symbol',
          source: sourceId,
          layout: {
            visibility: 'visible',
            'icon-image': 'Point Data Icon-Query',
            'icon-size': 1,
          },
          filter: ['==', '$type', 'Point'],
        })
      }
      if (map?.current?.getLayer(polygonLayer)) {
        return
      } else {
        map?.current?.addLayer({
          id: polygonLayer,
          type: 'fill',
          source: sourceId,
          layout: {
            visibility: 'visible',
          },
          paint: {
            'fill-color': '#F2970A',
            'fill-opacity': 0.5,
          },
          filter: ['==', '$type', 'Polygon'],
        })
      }
    }
  }, [map, aois])

  const waitForMapStyle = useCallback(() => {
    if (!map.current?.isStyleLoaded()) {
      setTimeout(() => waitForMapStyle(), 100)
      return
    }
    handleLayers()
  }, [map, handleLayers])

  useEffect(() => {
    if (!aois) {
      return
    }
    waitForMapStyle()
  }, [aois, waitForMapStyle])
}
