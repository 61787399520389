import type { CardProps } from '@mantine/core'
import { getFireLocation } from '@/api/results'
import { BentoCard } from '@/features/BentoResults/components'
import { HIDE_ACTIONS_BY_CATEGORY } from '@/features/ResultActions/constants'
import { RESULT_CATEGORY_COLORS } from '@/theme/colors'
import { ICONS, RESULT_CATEGORIES } from '@/utils/constants'
import type { FireResult } from '@/utils/types/result-types'

const category = RESULT_CATEGORIES.FIRE
const actionsToHide = HIDE_ACTIONS_BY_CATEGORY[category]

export interface FireBentoCardProps extends CardProps {
  result: FireResult
  size: string
  totalResults: number
}

export const FireBentoCard = ({ result, ...props }: FireBentoCardProps) => {
  const { latitude, longitude } = getFireLocation(result)
  return (
    <BentoCard
      {...props}
      result={result}
      hideActions={actionsToHide}
      icon={ICONS.localFireDepartment}
      content={{
        heading: 'Wildfire',
        subHeading: (
          <>
            {latitude}
            <br />
            {longitude}
          </>
        ),
        color: RESULT_CATEGORY_COLORS.fire,
      }}
      resultCategory={category}
      data-cy="fireResult"
    />
  )
}

export default FireBentoCard
