import {
  type FC,
  type MouseEventHandler,
  type MutableRefObject,
  useEffect,
} from 'react'
import { ActionIcon, Button, Group, Menu, Text } from '@mantine/core'
import { Icon } from '@/components/lib/Icon'
import { openSaveSearchModal } from '@/features/AppLayout/Modals'
import { MAP_DRAW_MODES } from '@/features/DantiMap/constants'
import { useProviders } from '@/features/Filters'
import { openSearchFiltersModal } from '@/features/Filters/open-search-filters-modal'
import { useSearch } from '@/features/Search/hooks'
import { useClearAll } from '@/hooks/use-clear-all'
import {
  useDrawController,
  useDrawMode,
  useIsDrawing,
  useSetDrawMode,
  useStartDrawingShape,
  useStopDrawingShape,
} from '@/stores/map-draw-store'
import {
  MAP_LAYER_OPTIONS,
  useActiveLayers,
  useBaseLayer,
  useSetActiveLayers,
  useSetBaseLayer,
} from '@/stores/map-store'
import { useCurrentQuery } from '@/stores/queries-store'
import { MAP_BASE_LAYERS, SAVED_SEARCH_MODES } from '@/utils/constants'

import styles from '@/features/DantiMap/danti-map.module.css'

type MapControlPanelProps = {
  openFileDialog: MouseEventHandler<HTMLElement>
  map: MutableRefObject<mapboxgl.Map | null>
}

export const MapControlPanel: FC<MapControlPanelProps> = ({
  map,
  openFileDialog,
}) => {
  const currentQuery = useCurrentQuery()
  const baseLayer = useBaseLayer()
  const setBaseLayer = useSetBaseLayer()
  const activeLayers = useActiveLayers()
  const setActiveLayers = useSetActiveLayers()
  const isDrawing = useIsDrawing()
  const startDrawingShape = useStartDrawingShape()
  const stopDrawingShape = useStopDrawingShape()
  const setDrawMode = useSetDrawMode()
  const { doLocationSearch } = useSearch()
  const clearAll = useClearAll()
  const { newProviders } = useProviders()
  const drawController = useDrawController()
  const drawMode = useDrawMode()

  const handleClearClick = () => {
    clearAll()
  }

  useEffect(() => {
    drawController?.changeMode(drawMode)
  }, [drawController, drawMode])

  const doSearchInWindow = () => {
    const b = map.current?.getBounds()
    if (b) {
      const poly = [
        [b.getNorthWest().lng, b.getNorthWest().lat],
        [b.getNorthEast().lng, b.getNorthEast().lat],
        [b.getSouthEast().lng, b.getSouthEast().lat],
        [b.getSouthWest().lng, b.getSouthWest().lat],
        [b.getNorthWest().lng, b.getNorthWest().lat],
      ]
      doLocationSearch(JSON.stringify(poly), 'polygon')
    }
  }

  return (
    <>
      <Group
        className={styles.mapControls}
        style={{ zIndex: 'var(--mantine-z-index-app)' }}
        justify="space-between"
        data-cy="map-controls"
      >
        <style>{`body { --danti-head-height: 120px; }`}</style>
        <Group justify="flex-start">
          <Group gap="xs">
            <Text fz="xs">Search By:</Text>
            <Button.Group>
              <Button
                size="xs"
                leftSection={<Icon name="upload" />}
                onClick={openFileDialog}
                data-cy="map-controls-file-upload"
              >
                File
              </Button>
              <Menu>
                <Menu.Target>
                  <Button
                    size="xs"
                    leftSection={<Icon name="polyline" />}
                    data-cy="map-controls-search-tools-dropdown"
                  >
                    Shape
                  </Button>
                </Menu.Target>
                <Menu.Dropdown className={styles.searchByControls}>
                  <Menu.Item
                    leftSection={<Icon name="polyline" />}
                    onClick={() => {
                      if (isDrawing && drawMode === MAP_DRAW_MODES.POLYGON) {
                        stopDrawingShape()
                      } else {
                        setDrawMode(MAP_DRAW_MODES.POLYGON)
                        startDrawingShape()
                      }
                    }}
                    data-cy="map-controls-polygon-draw"
                    {...(isDrawing &&
                      drawMode === MAP_DRAW_MODES.POLYGON && {
                        'data-active': true,
                      })}
                  >
                    Polygon
                  </Menu.Item>
                  <Menu.Item
                    leftSection={<Icon name="screen_search_desktop" />}
                    onClick={() => void doSearchInWindow()}
                    data-cy="map-controls-search-in-window"
                  >
                    Current View
                  </Menu.Item>
                  <Menu.Item
                    leftSection={<Icon name="location_on" />}
                    onClick={() => {
                      if (isDrawing && drawMode === MAP_DRAW_MODES.POINT) {
                        stopDrawingShape()
                      } else {
                        setDrawMode(MAP_DRAW_MODES.POINT)
                        startDrawingShape()
                      }
                    }}
                    data-cy="map-controls-point-draw"
                    {...(isDrawing &&
                      drawMode === MAP_DRAW_MODES.POINT && {
                        'data-active': true,
                      })}
                  >
                    Point
                  </Menu.Item>
                  <Menu.Item
                    leftSection={<Icon name="circle" />}
                    onClick={() => {
                      if (isDrawing && drawMode === MAP_DRAW_MODES.CIRCLE) {
                        stopDrawingShape()
                      } else {
                        setDrawMode(MAP_DRAW_MODES.CIRCLE)
                        startDrawingShape()
                      }
                    }}
                    data-cy="map-controls-circle-draw"
                    {...(isDrawing &&
                      drawMode === MAP_DRAW_MODES.CIRCLE && {
                        'data-active': true,
                      })}
                  >
                    Circle
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
              <Button
                size="xs"
                leftSection={<Icon name="delete" />}
                onClick={handleClearClick}
                data-cy="map-controls-clear"
              >
                Clear
              </Button>
            </Button.Group>
          </Group>

          <Group gap="xs">
            <Text fz="xs">LAYERS:</Text>
            <Button.Group>
              <Button
                size="xs"
                data-active={activeLayers.includes(MAP_LAYER_OPTIONS.EXTENTS)}
                onClick={() => setActiveLayers(MAP_LAYER_OPTIONS.EXTENTS)}
                leftSection={<Icon name="rectangle" />}
                data-cy="map-controls-layer-extents-toggle"
              >
                EXTENTS
              </Button>
              <Button
                size="xs"
                data-active={activeLayers.includes(MAP_LAYER_OPTIONS.POINT)}
                onClick={() => setActiveLayers(MAP_LAYER_OPTIONS.POINT)}
                leftSection={<Icon name="location_pin" />}
              >
                POINT
              </Button>
            </Button.Group>
          </Group>

          <Group gap="xs">
            <Text fz="xs">Map Type:</Text>
            <Button.Group>
              <Button
                size="xs"
                data-active={baseLayer === MAP_BASE_LAYERS.dantiStreets}
                onClick={() => setBaseLayer(MAP_BASE_LAYERS.dantiStreets)}
                data-cy="map-controls-mode-light"
                leftSection={<Icon name="map" />}
              >
                Default
              </Button>
              <Button
                size="xs"
                data-active={baseLayer === MAP_BASE_LAYERS.satellite}
                onClick={() => setBaseLayer(MAP_BASE_LAYERS.satellite)}
                data-cy="map-controls-mode-satellite"
                leftSection={<Icon name="public" />}
              >
                Satellite
              </Button>
            </Button.Group>
          </Group>
        </Group>

        <Menu trigger="click">
          <Menu.Target>
            <ActionIcon variant="subtle" c="gray">
              <Icon name="more_horiz" />
            </ActionIcon>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item onClick={openSearchFiltersModal}>Filters</Menu.Item>
            <Menu.Item
              onClick={() =>
                openSaveSearchModal({
                  isNew: true,
                  providers: newProviders,
                  params: {
                    mode: SAVED_SEARCH_MODES.feed,
                    name: currentQuery,
                    query: currentQuery,
                    locations: [],
                  },
                })
              }
            >
              Save this recurring search
            </Menu.Item>
            <Menu.Item
              onClick={() =>
                openSaveSearchModal({
                  isNew: true,
                  providers: newProviders,
                  params: {
                    mode: SAVED_SEARCH_MODES.search,
                    name: currentQuery,
                    query: currentQuery,
                    locations: [],
                  },
                })
              }
            >
              Save this search
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Group>
    </>
  )
}
