import { Box, Table } from '@mantine/core'
import type { ConfigurationTablePropsType } from '@/features/AppLayout/Modals/SaveSearchModal'
import {
  CameraAngleSelect,
  CloudCoverSelect,
  OrientationSelect,
  QualitySelect,
  SensorsSelect,
} from '@/features/Filters'

const settingsComponentMap = {
  'Sensor type': SensorsSelect,
  'Camera Angle': CameraAngleSelect,
  'Cloud Cover': CloudCoverSelect,
  Quality: QualitySelect,
  Orientation: OrientationSelect,
}

export function ConfigureImageOptions({
  tableProps,
}: {
  tableProps: ConfigurationTablePropsType
}) {
  return (
    <Box
      p="sm"
      pt="xs"
      style={{
        border: '1px solid var(--mantine-color-gray-3)',
        borderRadius: 'var(--mantine-radius-sm)',
      }}
    >
      <Table>
        <Table.Thead>
          <Table.Tr style={tableProps.rowStyle}>
            <Table.Th colSpan={2} px={0}>
              Image options
            </Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody px="sm" style={tableProps.rowStyle}>
          {Object.entries(settingsComponentMap).map(
            ([filterName, Component]) => (
              <Table.Tr key={filterName}>
                <Table.Td {...tableProps.firstColumn}>{filterName}</Table.Td>
                <Table.Td {...tableProps.secondColumn}>
                  <Component />
                </Table.Td>
              </Table.Tr>
            ),
          )}
        </Table.Tbody>
      </Table>
    </Box>
  )
}
