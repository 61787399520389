import { useQueryStore } from '@/stores/queries-store/store'

export const useClearQuery = () => useQueryStore((s) => s.clear)
export const useSetQuery = () => useQueryStore((s) => s.setQuery)
export const useSetStatus = () => useQueryStore((s) => s.setStatus)
export const useSearchMetadata = () => useQueryStore((s) => s.searchMetadata)
export const useSetSearchMetadata = () =>
  useQueryStore((s) => s.setSearchMetadata)
export const useSetQueryStarted = () => useQueryStore((s) => s.setQueryStarted)
export const useSetTotalProcessedResults = () =>
  useQueryStore((s) => s.setTotalProcessedResults)
export const useSetQueryFilters = () => useQueryStore((s) => s.setQueryFilters)
export const useSetPostSortPreference = () =>
  useQueryStore((s) => s.setPostSortPreference)
export const useSetSortPreference = () =>
  useQueryStore((s) => s.setSortPreference)
export const useSetStoredLocation = () =>
  useQueryStore((s) => s.setStoredLocation)
export const useSetStoredSplitSearch = () =>
  useQueryStore((s) => s.setStoredSplitSearch)
export const usePages = () => useQueryStore((s) => s.pages)

export const useSetCategoryPage = () => useQueryStore((s) => s.setPage)
export const useQueriesState = () => useQueryStore((s) => s)
export const useCurrentQuery = () => useQueryStore((s) => s.currentQuery)
export const useCurrentQueryFilters = () =>
  useQueryStore((s) => s.currentQueryFilters)
export const useQueryStatus = () => useQueryStore((s) => s.queryStatus)
export const useCurrentQueryStart = () =>
  useQueryStore((s) => s.currentQueryStart)
export const useInsightFeedbackSubmitted = () =>
  useQueryStore((s) => s.insightFeedbackSubmitted)
export const useSetInsightFeedbackSubmitted = () =>
  useQueryStore((s) => s.setInsightFeedbackSubmitted)
export const useTotalProcessedResults = () =>
  useQueryStore((s) => s.totalProcessedResults)
export const usePostSortPreference = () =>
  useQueryStore((s) => s.postSortPreference)
export const useSortPreference = () => useQueryStore((s) => s.sortPreference)
export const useStoredLocation = () => useQueryStore((s) => s.storedLocation)
export const useStoredSplitSearch = () =>
  useQueryStore((s) => s.storedSplitSearch)
