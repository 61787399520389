import React from 'react'
import {
  ActionIcon,
  type ActionIconProps,
  Group,
  Popover,
  Tooltip,
} from '@mantine/core'
import { DatePicker } from '@mantine/dates'
import { Icon } from '@/components/lib/Icon'
import type { SymbolName } from '@/utils/types/material-icons'

const PlaybackIcon = React.forwardRef(
  (
    props: ActionIconProps & {
      name: SymbolName
      onClick: (event: React.MouseEvent) => void
      title: string
      isActive?: boolean
      opticalSize?: number
    },
    forwardedRef: React.ForwardedRef<HTMLButtonElement>,
  ) => {
    const { name, onClick, title, isActive, opticalSize, ...passProps } = props

    return (
      <Tooltip label={title} withArrow>
        <ActionIcon
          ref={forwardedRef}
          variant="transparent"
          color={isActive ? 'blue.6' : 'white'}
          size={26}
          {...{ onClick, ...passProps }}
        >
          <Icon size={26} {...{ name, opticalSize }} />
        </ActionIcon>
      </Tooltip>
    )
  },
)
PlaybackIcon.displayName = 'PlaybackIcon'

export function PlaybackControls(props: {
  isPlaying: boolean
  isLooping: boolean
  selectedIndex: number
  totalResults: number
  setIsPlaying: React.Dispatch<React.SetStateAction<boolean>>
  setIsLooping: React.Dispatch<React.SetStateAction<boolean>>
  handleTimeChange: (value: number) => void
}) {
  const {
    isPlaying,
    isLooping,
    selectedIndex,
    totalResults,
    setIsPlaying,
    setIsLooping,
    handleTimeChange,
  } = props

  function goToFirst() {
    setIsPlaying(false)
    handleTimeChange(0)
  }
  function goToPrevious() {
    setIsPlaying(false)
    handleTimeChange(selectedIndex > 0 ? selectedIndex - 1 : totalResults - 1)
  }
  function togglePlay() {
    setIsPlaying((state) => !state)
  }
  function goToNext() {
    setIsPlaying(false)
    handleTimeChange((selectedIndex + 1) % totalResults)
  }
  function goToLast() {
    setIsPlaying(false)
    handleTimeChange(totalResults - 1)
  }
  function toggleLoop() {
    setIsLooping((state) => !state)
  }

  return (
    <Group justify="space-between">
      <Popover>
        <Popover.Target>
          <PlaybackIcon
            title="Date range"
            disabled
            name="calendar_month"
            onClick={(event) => event.preventDefault()}
          />
        </Popover.Target>
        <Popover.Dropdown>
          <DatePicker />
        </Popover.Dropdown>
      </Popover>

      <Group w="fit-content">
        <PlaybackIcon
          title="Rewind"
          onClick={goToFirst}
          name="first_page"
          opticalSize={20}
        />
        <PlaybackIcon
          title="Previous"
          onClick={goToPrevious}
          name="fast_rewind"
        />

        <PlaybackIcon
          title={isPlaying ? 'Pause' : 'Play'}
          px="xl"
          isActive={isPlaying}
          onClick={togglePlay}
          name={isPlaying ? 'pause_circle' : 'play_circle'}
        />

        <PlaybackIcon title="Next" onClick={goToNext} name="fast_forward" />
        <PlaybackIcon
          title="End"
          onClick={goToLast}
          name="last_page"
          opticalSize={20}
        />
      </Group>

      <PlaybackIcon
        title="Loop Playback"
        isActive={isLooping}
        name="repeat"
        onClick={toggleLoop}
      />
    </Group>
  )
}
