import { Stack, Text, Title } from '@mantine/core'
import { format } from 'date-fns'

export function HistoryEmptySection() {
  const date = new Date()
  const offsetInMinutes = date.getTimezoneOffset()
  date.setTime(date.getTime() + offsetInMinutes * 60 * 1000)

  const today = format(date, 'yyyy-MM-dd')

  return (
    <Stack gap={6}>
      <Title size={25}>{today}</Title>
      <Text>No items</Text>
    </Stack>
  )
}
