import { graphql } from '@/gql'
import { useQuery } from '@apollo/client'

const UserRoleQuery = graphql(`
  query UserRole {
    me {
      id
      role
    }
  }
`)

export function useUserRole() {
  const { data } = useQuery(UserRoleQuery)

  return data?.me
}
