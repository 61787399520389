import { useResultsFilterStore } from './store'

export const useSetHexgridSelectedIds = () =>
  useResultsFilterStore((s) => s.setHexgridSelectedIds)
export const useSetTimelineSelectedIds = () =>
  useResultsFilterStore((s) => s.setTimelineSelectedIds)
export const useSetOmittedSources = () =>
  useResultsFilterStore((s) => s.setOmittedSources)
export const useSetOmittedRepos = () =>
  useResultsFilterStore((s) => s.setOmittedRepos)
export const useSetOmittedAssets = () =>
  useResultsFilterStore((s) => s.setOmittedAssets)
export const useSetOmittedSensors = () =>
  useResultsFilterStore((s) => s.setOmittedSensors)
export const useSetQualityCeil = () =>
  useResultsFilterStore((s) => s.setQualityCeil)
export const useSetCloudCeil = () =>
  useResultsFilterStore((s) => s.setCloudCeil)
export const useResetPostfilters = () =>
  useResultsFilterStore((s) => s.resetPostfilters)

export const useOmittedSources = () =>
  useResultsFilterStore((s) => s.omittedSources)
export const useOmittedSensors = () =>
  useResultsFilterStore((s) => s.omittedSensors)
export const useOmittedRepos = () =>
  useResultsFilterStore((s) => s.omittedRepos)
export const useOmittedAssets = () =>
  useResultsFilterStore((s) => s.omittedAssets)
export const useQualityCeil = () => useResultsFilterStore((s) => s.qualityCeil)
export const useCloudCeil = () => useResultsFilterStore((s) => s.cloudCeil)
export const useTimelineSelectedIds = () =>
  useResultsFilterStore((s) => s.timelineSelectedIds)
