import * as React from 'react'
import { Container, Divider, Group, Stack, Title } from '@mantine/core'
import { VIEW_TYPES } from '@/features/Collections//constants'
import { BackButton, ListDisplay } from '@/features/Collections/components'
import { useCollectionDetails } from '@/features/Collections/hooks'
import { useResetCollectionItemsState } from '@/stores/collection-items'
import { useCollectionItemsState } from '@/stores/collection-items/hooks'
import { CollectionDetailActions } from './CollectionDetailActions'
import { CollectionDetailGrid } from './CollectionDetailGrid'
import { CollectionDetailHeader } from './CollectionDetailHeader'
import { CollectionDetailListItem } from './CollectionDetailListItem'
import { CollectionDetailListItemModalMenu } from './CollectionDetailListItemModalMenu'
import { CollectionDetailTable } from './CollectionDetailTable'

export function CollectionDetail() {
  const initialize = useResetCollectionItemsState()
  const { selectedCategories, setSort } = useCollectionItemsState()
  const {
    collection,
    columnFilters,
    handleClick,
    handleToggleSelectedCategory,
    hasCollection,
    items,
    setViewType,
    totalCount,
    viewType,
  } = useCollectionDetails()

  const listedItems = selectedCategories.length === 0 ? [] : items

  React.useEffect(() => {
    initialize()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!hasCollection || !collection) {
    return <></>
  }

  return (
    <>
      <Group pos="absolute" left={16} visibleFrom="md">
        <BackButton />
      </Group>
      <Container size="lg">
        <Stack w="100%">
          <CollectionDetailHeader {...{ collection, totalCount }} />
          <Title c="#303030" size={48}>
            {collection.name}
          </Title>
          <CollectionDetailActions {...{ collection, viewType, setViewType }} />
          <Divider />
        </Stack>

        {viewType === VIEW_TYPES.grid ? (
          <CollectionDetailGrid items={listedItems} onClick={handleClick} />
        ) : (
          <>
            <ListDisplay
              items={listedItems}
              ItemComponent={CollectionDetailListItem}
              MenuComponent={CollectionDetailListItemModalMenu}
            />
            <CollectionDetailTable
              items={listedItems}
              columnFilters={columnFilters}
              setSort={setSort}
              onCategoryToggle={handleToggleSelectedCategory}
              onClick={handleClick}
            />
          </>
        )}
      </Container>
    </>
  )
}
