import React from 'react'
import { Group, Slider, Stack, Text } from '@mantine/core'
import type { FilterArgs } from '@/features/Filters/types'

const cloudCoverSteps = Array.from({ length: 5 }).map((_, index) => ({
  label: `${index * 25}%`,
  value: index * 25,
}))

export function CloudCover({ value, setter }: FilterArgs<number>) {
  const [cloudCoverValue, setCloudCoverValue] = React.useState(value)

  React.useEffect(() => {
    setCloudCoverValue(value)
  }, [value])

  return (
    <Stack gap="xs" w="100%">
      <Group justify="space-between">
        <Text size="md">Cloud Cover</Text>
        <Text size="sm">
          {cloudCoverValue === 0
            ? 'None'
            : cloudCoverValue < 100
              ? `${cloudCoverValue}% or less`
              : 'Any amount'}
        </Text>
      </Group>
      <Slider
        styles={{ markLabel: { fontSize: '12px' } }}
        my="sm"
        min={0}
        max={100}
        marks={cloudCoverSteps}
        value={cloudCoverValue}
        label={(clouds) => `${clouds}%`}
        aria-label="Cloud Cover"
        onChange={(value) => setCloudCoverValue(value)}
        onChangeEnd={(value) => setter(value)}
      />
    </Stack>
  )
}
