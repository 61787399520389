import type { LayerConfig } from '@/features/DantiMap/types'
import { addLayer } from './add-layer'
import { addSource } from './add-source'

export const loadMapLayers = ({
  map,
  layers,
}: {
  map: mapboxgl.Map | null
  layers: Record<string, LayerConfig>
}) => {
  const layerIds = Object.keys(layers)

  // load source and layer
  const loadLayer = (layerId: string, layer: LayerConfig) => {
    if (map === null) {
      return
    }
    addSource({ map, ...layer })
    addLayer({ map, layerId, ...layer })
  }
  layerIds.forEach((layerId) => loadLayer(layerId, layers[layerId]))
}
