import { Icon } from '@/components/lib/Icon'
import type { SymbolName } from '@/utils/types/material-icons'
import { SocialIcon } from 'react-social-icons'

import styles from './hero.module.css'

export interface SOVHeroIconProps {
  icon?: string
  svgIcon?: React.ReactNode
  socialIconName?: string
  color?: string
}
export const SOVHeroIcon = ({
  icon,
  color,
  svgIcon,
  socialIconName,
}: SOVHeroIconProps) => {
  if (!(icon || svgIcon || socialIconName)) {
    return null
  }

  const iconElement = icon && (
    <Icon
      name={icon as SymbolName}
      size="2rem"
      className={styles['sov-hero-icon']}
    />
  )

  return (
    <div
      className={styles['sov-hero-icon-wrapper']}
      style={{ backgroundColor: color }}
    >
      {icon && iconElement}
      {svgIcon && <div className={styles['svg-icon-wrapper']}>{svgIcon}</div>}
      {socialIconName && (
        <div className={styles['social-icon-wrapper']}>
          <SocialIcon
            network={socialIconName}
            style={{ width: '200px', height: '200px' }}
          />
        </div>
      )}
    </div>
  )
}

export default SOVHeroIcon
