import { useCallback, useMemo } from 'react'
import { usePaginatedResults } from '@/api/results'
import { SEARCH_CATEGORIES } from '@/api/results/constants'
import type { TimelineHistogramConfig } from '@/features/ResultsTimeline/types'
import { getConfigForRange } from '@/features/ResultsTimeline/utils'
import type {
  RawSearchResult,
  ResultWithId,
} from '@/utils/types/result-types.ts'
import { scaleBand, scaleLinear, scaleTime } from '@visx/scale'
import { bin, extent, max } from '@visx/vendor/d3-array'
import { useDeepCompareMemoize } from 'use-deep-compare-effect'

const extractDate = (r: RawSearchResult) => new Date(r.authoredOn)

export const useTimelineHistogram = (props: {
  startDate: Date | undefined
  endDate: Date | undefined
  setStartDate: (date: Date) => void
  setEndDate: (date: Date) => void
  xMax: number
  yMax: number
  isolateType?: (result: ResultWithId) => boolean
}): TimelineHistogramConfig => {
  const categorized = usePaginatedResults().results
  const typeFilter = useCallback(
    (result: ResultWithId) => {
      return props.isolateType?.(result) ?? true
    },
    [props],
  )

  const results = useMemo(
    () =>
      [
        ...categorized[SEARCH_CATEGORIES.IMAGE],
        ...categorized[SEARCH_CATEGORIES.PUBLICATION],
        ...categorized[SEARCH_CATEGORIES.SOCIAL_MEDIA],
        ...categorized[SEARCH_CATEGORIES.FIRE],
        ...categorized[SEARCH_CATEGORIES.SHIP],
        ...categorized[SEARCH_CATEGORIES.ATTOM],
        ...categorized[SEARCH_CATEGORIES.HAZARD_RISK],
        ...categorized[SEARCH_CATEGORIES.HAZARD_PROPERTY],
        ...categorized[SEARCH_CATEGORIES.CORELOGIC_PROPERTY],
        ...categorized[SEARCH_CATEGORIES.CORELOGIC_RISK],
        ...categorized[SEARCH_CATEGORIES.REGRID],
      ].filter(typeFilter),
    [categorized, typeFilter],
  )
  const memoResults = useDeepCompareMemoize(results)

  const dateExtent = useMemo(() => {
    const newExtent = extent(results, extractDate) as [Date, Date]
    props.setStartDate(newExtent[0])
    props.setEndDate(newExtent[1])
    return newExtent
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memoResults])

  const { paddedExtent, timeBins, timeBucketConfig } = useMemo(() => {
    const timeBucketConfig = getConfigForRange(dateExtent[0], dateExtent[1])
    const paddedExtent: [Date, Date] = [
      timeBucketConfig.offsetFn(dateExtent[0], -1),
      timeBucketConfig.offsetFn(dateExtent[1], 1),
    ]
    const timeBins = timeBucketConfig.rangeFn(paddedExtent[0], paddedExtent[1])
    return { paddedExtent, timeBins, timeBucketConfig }
  }, [dateExtent])

  const histogramGenerator = useMemo(() => {
    return bin<ResultWithId, Date>()
      .value((r) => new Date(r.authoredOn))
      .domain(paddedExtent)
      .thresholds(timeBins)
  }, [paddedExtent, timeBins])

  const dateBandScale = scaleBand<string>({
    domain: timeBins.map((s) => s.toISOString()),
    range: [0, props.xMax],
    paddingInner: 0.2,
  })

  const dateContinuousScale = scaleTime<number>({
    domain: paddedExtent,
    range: [0, props.xMax],
  })

  const mergedHistogramResult = histogramGenerator(results)

  const countScale = scaleLinear<number>({
    domain: [0, max(mergedHistogramResult, (d) => d.length) as number],
    range: [props.yMax, 0],
  })

  const resultsByType = {
    IMAGE: histogramGenerator(
      categorized[SEARCH_CATEGORIES.IMAGE].filter(typeFilter),
    ),
    FIRE: histogramGenerator(
      categorized[SEARCH_CATEGORIES.FIRE].filter(typeFilter),
    ),
    SHIP: histogramGenerator(
      categorized[SEARCH_CATEGORIES.SHIP].filter(typeFilter),
    ),
    PUBLICATION: histogramGenerator(
      categorized[SEARCH_CATEGORIES.PUBLICATION].filter(typeFilter),
    ),
    SOCIAL_MEDIA: histogramGenerator(
      categorized[SEARCH_CATEGORIES.SOCIAL_MEDIA].filter(typeFilter),
    ),
    INSIGHT: histogramGenerator(
      categorized[SEARCH_CATEGORIES.INSIGHT].filter(typeFilter),
    ),
    PROPERTY: histogramGenerator(
      [
        ...categorized[SEARCH_CATEGORIES.HAZARD_PROPERTY],
        ...categorized[SEARCH_CATEGORIES.ATTOM],
        ...categorized[SEARCH_CATEGORIES.HAZARD_RISK],
        ...categorized[SEARCH_CATEGORIES.CORELOGIC_PROPERTY],
        ...categorized[SEARCH_CATEGORIES.CORELOGIC_RISK],
        ...categorized[SEARCH_CATEGORIES.REGRID],
      ].filter(typeFilter),
    ),
  }

  const visualizationData = mergedHistogramResult.map((bin, index) => {
    return {
      IMAGE: resultsByType.IMAGE[index]?.length || 0,
      FIRE: resultsByType.FIRE[index]?.length || 0,
      SHIP: resultsByType.SHIP[index]?.length || 0,
      PUBLICATION: resultsByType.PUBLICATION[index]?.length || 0,
      SOCIAL_MEDIA: resultsByType.SOCIAL_MEDIA[index]?.length || 0,
      INSIGHT: resultsByType.INSIGHT[index]?.length || 0,
      PROPERTY: resultsByType.PROPERTY[index]?.length || 0,
      x0: bin.x0,
      x1: bin.x1,
    }
  })

  return {
    visualizationData,
    mergedHistogramResult,
    dateBandScale,
    dateContinuousScale,
    countScale,
    timeBins,
    timeBucketConfig,
  }
}
