import { NavLink } from '@mantine/core'

export const CollectionNavLink = ({
  icon,
  label,
  onClick,
  ...rest
}: {
  icon: React.ReactNode
  label: string
  onClick: () => void
  [key: string]: any
}) => {
  return (
    <NavLink
      leftSection={icon}
      label={label}
      px={0}
      {...rest}
      onClick={(event) => {
        event.stopPropagation()
        onClick()
      }}
    />
  )
}
