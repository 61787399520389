import styles from './data-display.module.css'

export function DataDisplayCard({
  title = '',
  value = '--',
}: {
  title?: React.ReactNode
  value?: React.ReactNode
}) {
  return (
    <div className={styles['sov-data-display-card']}>
      <p className={styles['title']}>{title}</p>
      <p className={styles['value']}>{value}</p>
    </div>
  )
}
