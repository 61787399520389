import { createStore } from '@/utils/create-store.ts'

export interface Config {
  showScores: boolean
  toggleShowScores: () => void
}

export const initialConfigState = {
  showScores: false,
}

export const useConfigStore = createStore<Config>({
  initialState: initialConfigState,
  storageKey: 'ConfigStore',
  version: 0.03,
  actions: (set, get): Partial<Config> => ({
    toggleShowScores: () => {
      const currentShowScores = get().showScores
      set({ showScores: !currentShowScores })
    },
  }),
})
