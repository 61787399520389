import { executeQuery } from '@/api/results/api'
import { SEARCH_CATEGORIES } from '@/api/results/constants'
import type { SearchResult } from '@/api/results/types'
import {
  useCurrentQuery,
  useCurrentQueryFilters,
  useCurrentQueryStart,
  usePostSortPreference,
  useSortPreference,
} from '@/stores/queries-store'
import { useQuery } from '@tanstack/react-query'

export const useSearchQuery = () => {
  const queryStart = useCurrentQueryStart()
  const sortPreference =
    useSortPreference() === 'score' ? 'relevance' : 'authorshipTime'
  const postSortPreference =
    usePostSortPreference() === 'score' ? 'relevance' : 'authorshipTime'
  const params = {
    query: useCurrentQuery(),
    filters: useCurrentQueryFilters(),
    order: {
      [SEARCH_CATEGORIES.IMAGE]: sortPreference,
      [SEARCH_CATEGORIES.FIRE]: sortPreference,
      [SEARCH_CATEGORIES.SHIP]: sortPreference,
      [SEARCH_CATEGORIES.PUBLICATION]: postSortPreference,
      [SEARCH_CATEGORIES.SOCIAL_MEDIA]: postSortPreference,
    },
  }
  const enabled = params.query !== '' || params.filters.length > 0
  return useQuery({
    queryKey: ['search', params, queryStart],
    queryFn: () => executeQuery(params),
    enabled: !!queryStart && enabled,
  })
}

export const useSearchData = () => useSearchQuery().data as SearchResult
export const useSearchResults = () => useSearchData()?.resultDocuments || []
export const useSearchMetadata = () => useSearchData()?.queryMetadata

export const useSearchStatus = () => useSearchQuery().status || 'idle'

export const useQueryId = () => useSearchData()?.queryId || ''
