import { Text } from '@mantine/core'
import { getRiskColor } from '@/api/results'
import { HIDE_ACTIONS_BY_CATEGORY } from '@/features/ResultActions/constants'
import { HazardRiskReferenceCard } from '@/features/ResultCards/ReferenceCards'
import { SingleObjectViewTemplate } from '@/features/SingleObjectView/components/SingleObjectViewTemplate'
import {
  useHazardRiskList,
  useLocalNavigation,
} from '@/features/SingleObjectView/hooks'
import type {
  LoadedRisk,
  ObjectViewProps,
} from '@/features/SingleObjectView/types'
import { RESULT_CATEGORIES } from '@/utils/constants'
import { formatDatetime } from '@/utils/format-datetime'
import { RISK_ICONS } from '@/utils/svg-icons'
import type { HazardRiskResult } from '@/utils/types/result-types'

const category = RESULT_CATEGORIES.HAZARD_RISK
const hideActions = HIDE_ACTIONS_BY_CATEGORY[category]

export function HazardRiskObjectView({ item: rawItem }: ObjectViewProps) {
  const riskList = useHazardRiskList(rawItem)
  const item = rawItem as HazardRiskResult

  const { toNext, toPrevious, selectItem, current } =
    useLocalNavigation<LoadedRisk>(riskList)

  if (!current) {
    return null
  }

  return (
    <SingleObjectViewTemplate<HazardRiskResult, LoadedRisk>
      {...{
        item,
        hideActions,
        hero: {
          details: {
            title: current.title,
            icon: RISK_ICONS[current.key],
            color: getRiskColor(current),
            leftDetail: current.risk,
            rightDetail: current.score ? `Score: ${current.score}` : undefined,
            leftDetailsColumn: current.description
              ? [
                  <Text key="desc-label" fw="bold">
                    Description
                  </Text>,
                  <Text key="desc-value">{current.description}</Text>,
                ]
              : undefined,
          },
          metaDetails: [formatDatetime(item.authoredOn)],
          toNext,
          toPrevious,
        },
        content: {
          title: `Summary of Risks and Hazard for ${item.properties.address}`,
        },
        carousel: {
          title: 'All Risks & Hazards',
          items: riskList,
          renderCard: (item: LoadedRisk) => (
            <HazardRiskReferenceCard result={item} />
          ),
          limit: riskList.length,
          selectItem,
          grid: true,
        },
      }}
    />
  )
}
