import type { CollectionItem } from '@/api/collections/types'
import { ListItem } from '@/features/Collections/components'
import type { ResultWithId } from '@/utils/types/result-types'
import { CollectionDetailResultIcon } from './CollectionDetailResultIcon'

export interface CollectionDetailListItemProps {
  data: CollectionItem
  onSelect: (item: CollectionItem) => void
}

export function CollectionDetailListItem({
  data,
  onSelect,
}: CollectionDetailListItemProps) {
  const { name, createdOn } = data
  const result = JSON.parse(data.doc) as ResultWithId
  return (
    <ListItem<CollectionItem>
      data={data}
      onSelect={onSelect}
      icon={<CollectionDetailResultIcon result={result} />}
      date={createdOn}
      name={name}
    />
  )
}
