import { Text } from '@mantine/core'
import type { ReactNode } from 'react'

import styles from './bento-card.module.css'

interface BentoCardTextContentParams {
  content?: {
    heading?: ReactNode
    subHeading?: ReactNode
    color?: string
    fontColor?: string
  }
  size: string
}
export const BentoCardTextContent = ({
  content,
  size = 'md',
}: BentoCardTextContentParams) =>
  content && (
    <div className={styles[`content-${size}`]}>
      {content?.heading && (
        <Text
          className={styles['heading']}
          lineClamp={2}
          c={content.fontColor || 'white'}
        >
          {content.heading}
        </Text>
      )}
      {content?.subHeading && (
        <Text
          className={styles['sub-heading']}
          lineClamp={2}
          c={content.fontColor || 'white'}
        >
          {content.subHeading}
        </Text>
      )}
    </div>
  )

export default BentoCardTextContent
