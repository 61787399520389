import { useMapLayers, useOrthoImagerySources } from '@/features/DantiMap/hooks'
import { usePostfilterImageResults } from '@/stores/results-filter-store'
import type { ImageryResult } from '@/utils/types/result-types'
import * as turf from '@turf/turf'
import type { MutableRefObject } from 'react'
import { useEventListeners } from './use-event-listeners'

type UseExtentsLayerProps = {
  map: MutableRefObject<mapboxgl.Map | null>
  showExtents: boolean
}

export const useExtentsLayer: (props: UseExtentsLayerProps) => void = ({
  map,
  showExtents,
}) => {
  const sources = useOrthoImagerySources()
  const postfilterImageResults = usePostfilterImageResults()
  const events = useEventListeners()

  const idToGroupKey = sources.orthoLayerGroups.reduce(
    (accumulator, group) => {
      group.layers.forEach((layer) => {
        accumulator[layer.imageResult.documentId] = layer.groupKey
      })
      return accumulator
    },
    {} as Record<string, string>,
  )

  const extentResults = postfilterImageResults.filter(
    (r) => r.geometry.type === 'Polygon' || r.geometry.type === 'MultiPolygon',
  )
  const makeFeature = (r: ImageryResult) =>
    turf.feature(r.geometry, { documentId: r.id, groupKey: idToGroupKey[r.id] })

  const extentsData = turf.featureCollection(extentResults.map(makeFeature))

  useMapLayers({
    map: map.current,
    layers: {
      'extent-fills': {
        sourceId: 'extents',
        type: 'fill',
        data: extentsData,
        events: events['extent-fills'],
        isVisible: showExtents,
        clearSourceWithLayer: true,
      },
      'extent-outlines': {
        sourceId: 'extents',
        type: 'line',
        data: extentsData,
        events: events['extent-outlines'],
        isVisible: showExtents,
        clearSourceWithLayer: true,
      },
    },
  })
}
