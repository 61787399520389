import { Badge, Tabs, type TabsTabProps } from '@mantine/core'
import { BAR_FILL_COLOR } from '@/features/ResultsTimeline/constants'
import { TABS_MAP } from './constants'

import style from './results-panel.module.css'

export function Tab({
  total,
  value,
  ...props
}: TabsTabProps & {
  total: number
  value: keyof typeof TABS_MAP
}) {
  const tabConst = TABS_MAP[value] as keyof typeof BAR_FILL_COLOR
  const tabColor = BAR_FILL_COLOR[tabConst].normal

  return (
    <Tabs.Tab
      {...props}
      value={value}
      tt="uppercase"
      className={style.tab}
      leftSection={
        <span
          className={style.leftSection}
          style={{ backgroundColor: tabColor }}
        />
      }
      rightSection={
        total > 0 ? (
          <Badge
            variant="filled"
            className={style.badge}
            size="xs"
            data-cy="result-tab-count"
          >
            {total}
          </Badge>
        ) : undefined
      }
      disabled={total === 0}
      data-cy="result-tab"
    >
      {value}
    </Tabs.Tab>
  )
}
