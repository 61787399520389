import dayjs from 'dayjs'
import type { Point, Polygon } from 'geojson'
import { toPoint } from 'mgrs'
import { TerrapinLocationType } from './constants'

export const messages = {
  none: 'None',
}

export function formatTimeDisplay(timeIn24Hour: string | null) {
  if (timeIn24Hour) {
    const [hoursString, minutes] = timeIn24Hour.split(':')
    const hours = Number(hoursString)
    if (hours >= 12) {
      const formattedHours = hours === 12 ? hours : hours - 12

      return `Daily at ${formattedHours}:${minutes} PM`
    }
    return `Daily at ${hours || 12}:${minutes} AM`
  }
  return messages.none
}

export function formatLocalNotificationSchedule(
  emailNotification: string | null,
) {
  if (emailNotification) {
    // Format from 'DAILY(12:00)' to '12:00'
    const formattedFrequency = emailNotification.replace(
      /^.*(\d{2}:\d{2}).*$/,
      '$1',
    )
    const [hours, minutes] = formattedFrequency.split(':').map(Number)
    // Format from UTC (server) to local time
    const timeAsDate = dayjs.utc().hour(hours).minute(minutes)
    const localScheduledTime = timeAsDate.local().format('HH:mm')
    return localScheduledTime
  }
  return null
}

export const formatGeojson = (
  type: TerrapinLocationType,
  value: string | undefined,
) => {
  switch (type) {
    case TerrapinLocationType.latLong:
      return {
        type: 'Point',
        coordinates: value ? JSON.parse(value) : [0, 0],
      } as Point
    case TerrapinLocationType.polygon:
      return {
        type: 'Polygon',
        coordinates: value
          ? JSON.parse(value)
          : [
              [0, 0],
              [1, 0],
            ],
      } as Polygon
    case TerrapinLocationType.mgrs: {
      const point = value ? toPoint(value) : [0, 0]
      return {
        type: 'Point',
        coordinates: point,
      } as Point
    }
    default:
      return {
        type: 'Point',
        coordinates: [0, 0],
      } as Point
  }
}
