import type { OrthoImageryLayer } from '@/utils/types/ortho-imagery-types'
import {
  isBlackSkyResult,
  isCapella,
  isSatellogic,
  isSentinelResult,
  isUmbra,
  isUp42Result,
} from '@/utils/types/result-type-checkers'
import type { ImageryResult } from '@/utils/types/result-types'
import * as Sentry from '@sentry/react'
import { makeBlackSkyOrthoLayer } from './black-sky'
import { isGegd, makeGegdOrthoLayer } from './gegd'
import { makePngImageOrthoLayer } from './make-png-image-ortho-layer'
import { isPlanet, makePlanetOrthoLayer } from './planet'
import { makeSentinelOrthoLayer } from './sentinel'
import { makeUP42OrthoLayer } from './up42'

export const makeOrthoLayer = (
  ir: ImageryResult,
): OrthoImageryLayer | undefined => {
  if (!ir.thumbnail && Object.keys(ir.asset).length === 0) {
    return
  }
  if (isGegd(ir)) {
    return makeGegdOrthoLayer(ir)
  }
  if (isPlanet(ir)) {
    return makePlanetOrthoLayer(ir)
  }
  if (isUp42Result(ir)) {
    return makeUP42OrthoLayer(ir)
  }
  if (isBlackSkyResult(ir)) {
    return makeBlackSkyOrthoLayer(ir)
  }
  if (isSentinelResult(ir)) {
    return makeSentinelOrthoLayer(ir)
  }
  if (isSatellogic(ir.source) || isCapella(ir.source) || isUmbra(ir.source)) {
    return makePngImageOrthoLayer(ir)
  }

  Sentry.captureMessage(
    `Could not find ortho layer provider for Image ID ${ir.documentId}`,
  )
  return undefined
}
