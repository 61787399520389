import { type FC, useState } from 'react'
import { Box, Stack } from '@mantine/core'
import { useSearchStatus } from '@/api/results'
import {
  ResultsTimelineBarGraph,
  ResultsTimelineLoading,
  ResultsTimelineTooltip,
} from '@/features/ResultsTimeline/components'
import {
  BAR_FILL_COLOR,
  FOCUS_HIST_HEIGHT,
  TIMELINE_INSET,
} from '@/features/ResultsTimeline/constants'
import {
  useTimelineHistogram,
  useTimelineInteractions,
} from '@/features/ResultsTimeline/hooks'
import type {
  TimelineDoctypes,
  TooltipData,
} from '@/features/ResultsTimeline/types'
import { formatDateWithWordMonths as formatDate } from '@/utils/format-datetime'
import type { ResultWithId } from '@/utils/types/result-types'
import { scaleOrdinal } from '@visx/scale'
import { useTooltip } from '@visx/tooltip'

type ResultsTimelineProps = {
  width: number
  isSmall: boolean
  isolateType?: (result: ResultWithId) => boolean
}

export const ResultsTimeline: FC<ResultsTimelineProps> = ({
  width,
  isSmall,
  isolateType,
}) => {
  const [startDate, setStartDate] = useState<Date | undefined>()
  const [endDate, setEndDate] = useState<Date | undefined>()

  const searchStatus = useSearchStatus()
  const isReadyForInteraction = ['success', 'idle'].includes(searchStatus || '')

  const focusHistogramConfig = useTimelineHistogram({
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    xMax: width,
    yMax: FOCUS_HIST_HEIGHT,
    isolateType,
  })

  const interactions = useTimelineInteractions({ focusHistogramConfig })

  const barTooltipParams = useTooltip<TooltipData>()
  const dateHoverTooltipParams = useTooltip<{ xDate: Date }>()

  const colorScale = scaleOrdinal<TimelineDoctypes, string>({
    domain: [
      'IMAGE',
      'PUBLICATION',
      'SOCIAL_MEDIA',
      'FIRE',
      'SHIP',
      'PROPERTY',
    ],
    range: [
      BAR_FILL_COLOR['IMAGE'].normal,
      BAR_FILL_COLOR['PUBLICATION'].normal,
      BAR_FILL_COLOR['SOCIAL_MEDIA'].normal,
      BAR_FILL_COLOR['FIRE'].normal,
      BAR_FILL_COLOR['SHIP'].normal,
      BAR_FILL_COLOR['PROPERTY'].normal,
    ],
  })

  if (width === 0) {
    return null
  }

  return (
    <Stack
      w={width}
      style={{
        position: 'relative',
        paddingTop: 16,
        paddingBottom: 4,
        backgroundColor: isReadyForInteraction
          ? 'white'
          : 'var(--mantine-color-gray-1)',
      }}
    >
      <Box style={{ position: 'relative' }}>
        {startDate &&
          endDate &&
          `${formatDate(startDate.toString())} - ${formatDate(endDate.toString())}`}
        {isReadyForInteraction ? (
          <ResultsTimelineBarGraph
            width={width}
            focusHistogramConfig={focusHistogramConfig}
            {...interactions}
            x={(bin: { x0?: Date }) => (bin.x0 ? bin.x0?.toISOString() : '')}
            color={colorScale}
            small={isSmall}
            barTooltipParams={barTooltipParams}
            dateHoverTooltipParams={dateHoverTooltipParams}
          />
        ) : (
          <ResultsTimelineLoading
            width={width - TIMELINE_INSET}
            small={isSmall}
            numBars={50}
          />
        )}
      </Box>
      <ResultsTimelineTooltip
        timeBins={focusHistogramConfig.timeBins}
        dateFormatter={focusHistogramConfig.timeBucketConfig.displayStringFn}
        useTooltipParams={barTooltipParams}
      />
    </Stack>
  )
}
