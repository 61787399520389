import {
  Checkbox,
  CheckboxGroup,
  Flex,
  Popover,
  Stack,
  Text,
} from '@mantine/core'
import { Icon } from '@/components/lib/Icon'
import { useOrientationsFilter, useSetFilters } from '@/stores/filters-store'

type Segment = 'N' | 'NE' | 'E' | 'SE' | 'S' | 'SW' | 'W' | 'NW'

const allSegments: Segment[] = ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW']

const segmentLabels: { [key in Segment]: string } = {
  N: 'North',
  NE: 'North East',
  E: 'East',
  SE: 'South East',
  S: 'South',
  SW: 'South West',
  W: 'West',
  NW: 'North West',
}

function segmentsToRanges(segments: Segment[]) {
  return segments.map((segment) => {
    const index = allSegments.indexOf(segment)
    return { start: index * 45 - 22.5, end: index * 45 + 22.5 }
  })
}

export function OrientationSelect() {
  const setFilters = useSetFilters()
  const clearSelected = (_c: React.ChangeEvent<HTMLInputElement>) => {
    setFilters({ orientations: [] })
  }
  const orientationsFilter = useOrientationsFilter()
  const selectedSegments: Segment[] = orientationsFilter
    .map((orientation) =>
      allSegments.find(
        (segment) =>
          orientation.start === allSegments.indexOf(segment) * 45 - 22.5,
      ),
    )
    .filter((segment): segment is Segment => segment !== undefined)

  const handleOrientationChange = (selectedValues: string[]) => {
    const newSelection: Segment[] = selectedValues.filter(
      (value): value is Segment => allSegments.includes(value as Segment),
    )
    setFilters({ orientations: segmentsToRanges(newSelection) })
  }

  return (
    <Popover trapFocus position="bottom-end" shadow="md">
      <Popover.Target>
        <Flex justify="space-between" style={{ cursor: 'pointer' }}>
          <Text size="sm">
            {selectedSegments.length > 0
              ? selectedSegments
                  .map((segment) => segmentLabels[segment])
                  .join(', ')
              : 'Any'}
          </Text>
          <Icon name="keyboard_arrow_down" />
        </Flex>
      </Popover.Target>
      <Popover.Dropdown>
        <Flex flex={1} gap={30}>
          <Stack style={{ fontSize: '12px' }} maw={250} gap={10}>
            <span>
              Orientation refers to the alignment or positioning of the image
              relative to the Earth&apos;s surface
            </span>
          </Stack>
          <Stack>
            <Checkbox
              checked={selectedSegments.length === 0}
              label="Select All"
              onChange={clearSelected}
            />
            <CheckboxGroup
              value={selectedSegments as string[]}
              onChange={handleOrientationChange}
            >
              <Stack>
                {allSegments.map((segment) => (
                  <Checkbox
                    key={segment}
                    value={segment}
                    label={segmentLabels[segment]}
                  />
                ))}
              </Stack>
            </CheckboxGroup>
          </Stack>
        </Flex>
      </Popover.Dropdown>
    </Popover>
  )
}
