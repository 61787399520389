import { Box, Button, Radio, Stack } from '@mantine/core'
import { useAddToCollection } from '@/api/collections'
import { Icon } from '@/components/lib/Icon'
import type React from 'react'

export interface CollectionSelectorProps {
  collections: { id: string; name: string }[]
  selectedCollectionId?: string
  setSelectedCollectionId: (id?: string) => void
  onNewFolder: () => void
}

export function CollectionSelector({
  collections,
  selectedCollectionId,
  setSelectedCollectionId,
  onNewFolder,
}: CollectionSelectorProps) {
  const { isLoading, hasMore, loadMore } = useAddToCollection()
  const handleScroll = (event: React.MouseEvent<HTMLDivElement>) => {
    const bottom =
      Math.floor(
        event.currentTarget.scrollHeight - event.currentTarget.scrollTop,
      ) === event.currentTarget.clientHeight

    if (bottom && !isLoading && hasMore) {
      loadMore().catch((error) => {
        console.error(error)
      })
    }
  }

  const handleOnNewFolder = () => {
    setSelectedCollectionId(undefined)
    onNewFolder()
  }

  return (
    <Box>
      <Box
        style={{
          border: '1px solid var(--mantine-color-gray-3)',
          borderRadius: 'var(--mantine-radius-sm)',
          overflowY: 'auto',
        }}
        mah={300}
        px="sm"
        py="xs"
        onScroll={handleScroll}
      >
        <Button
          variant="transparent"
          pl={0}
          mb="xs"
          fw="normal"
          onClick={handleOnNewFolder}
          leftSection={<Icon name="add" />}
        >
          Create a new folder
        </Button>
        <Radio.Group
          value={selectedCollectionId}
          onChange={setSelectedCollectionId}
        >
          <Stack gap="sm">
            {collections?.map(({ id, name }) => (
              <Radio
                key={id}
                value={id}
                label={name}
                data-cy="collection-option"
              />
            ))}
          </Stack>
        </Radio.Group>
      </Box>
    </Box>
  )
}
