import React from 'react'
import { Chip } from '@mantine/core'
import { messages } from '@/features/InsightPanel/messages'
import { InsightFeedbackTextInput } from './InsightFeedbackTextInput'

export function InsightCustomReason() {
  const [isOpen, setIsOpen] = React.useState(false)

  return isOpen ? (
    <InsightFeedbackTextInput handleClose={() => setIsOpen(false)} />
  ) : (
    <Chip onClick={() => setIsOpen(true)} variant="outline">
      {messages.CUSTOM_TITLE}
    </Chip>
  )
}
