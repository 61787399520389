import type { FireData } from '@/features/SingleObjectView/types'
import { FIRE_CONFIDENCE_LEVELS } from '@/utils/constants'
import type { FireResult } from '@/utils/types/result-types.ts'

export const getFireLocation = (result: FireResult) => {
  const { latitude, longitude } = result.properties.firms_metadata
  return { latitude, longitude }
}

export const getFireProps = (fire: FireResult): FireData => ({
  datetime: fire.properties.datetime,
  latitude: fire.properties.firms_metadata.latitude,
  longitude: fire.properties.firms_metadata.longitude,
  geometry: fire.geometry,
  track: fire.properties.firms_metadata.track,
  brightness: fire.properties.firms_metadata.bright_ti4,
  confidence: fire.properties.firms_metadata.confidence,
  instrument: fire.properties.firms_metadata.instrument,
})

export const getFireCategoryCardProps = (fire: FireResult) => {
  const fireProps = getFireProps(fire)
  return {
    datetime: fireProps.datetime,
    latitude: fireProps.latitude,
    longitude: fireProps.longitude,
    geometry: fireProps.geometry,
  }
}

export const getFireDataFields = (fire: FireResult) => {
  const fireProps = getFireProps(fire)
  let confidenceString = fireProps.confidence
  const confidenceLevel =
    FIRE_CONFIDENCE_LEVELS[
      fireProps.confidence as keyof typeof FIRE_CONFIDENCE_LEVELS
    ] || null

  confidenceString = confidenceLevel || confidenceString
  return [
    {
      title: 'location',
      value: `${fireProps.latitude} ${fireProps.longitude}`,
    },
    { title: 'track', value: fireProps.track },
    { title: 'brightness', value: fireProps.brightness },
    { title: 'confidence', value: confidenceString },
    { title: 'instrument', value: fireProps.instrument },
  ]
}
