import { MAP_DRAW_MODES } from '@/features/DantiMap/constants.ts'
import {
  useDrawController,
  useDrawMode,
  useSetDrawMode,
  useSetIsDrawing,
} from './hooks'
import { useClearSearchPolygon } from './use-clear'

export const useStartDrawingShape = () => {
  const clear = useClearSearchPolygon()
  const setIsDrawing = useSetIsDrawing()
  const drawController = useDrawController()
  const drawMode = useDrawMode()
  return () => {
    clear()
    drawController?.changeMode(drawMode)
    setIsDrawing(true)
  }
}
export const useStopDrawingShape = () => {
  const setIsDrawing = useSetIsDrawing()
  const drawController = useDrawController()
  const clear = useClearSearchPolygon()
  const setDrawMode = useSetDrawMode()
  return () => {
    clear()
    setIsDrawing(false)
    setDrawMode(MAP_DRAW_MODES.SELECT)
    drawController?.changeMode(MAP_DRAW_MODES.SELECT, { featureIds: [] })
  }
}
