import { extractProvenance } from '@/stores/results-filter-store/utils'
import type { OrthoImageryLayer } from '@/utils/types/ortho-imagery-types.ts'
import type { ImageryResult } from '@/utils/types/result-types.ts'
import type { MultiPolygon } from 'geojson'
import { normalizeUrl } from './make-png-image-ortho-layer'

export const makeSentinelOrthoLayer = (r: ImageryResult): OrthoImageryLayer => {
  const featureId = (r.properties.featureId as string) || r.id

  return {
    source: 'SENTINEL_HUB' as const,
    id: featureId,
    tileUrl: normalizeUrl(r.properties._dPreview as string),
    authoredOn: r.authoredOn,
    geometry: r.geometry as MultiPolygon,
    documentId: r.documentId,
    imageResult: r,
    groupKey: featureId,
    score: r.score,
    formattedSource: `${extractProvenance(r)}`,
  }
}
