import * as React from 'react'
import type { CollectionSortType } from '@/api/collections'
import { list } from '@/api/collections/collection-items/api'
import { PAGE_SIZE } from '@/api/collections/collection-items/constants'
import { useDantiAuth } from '@/hooks/use-danti-auth'
import { useQuery } from '@tanstack/react-query'

export function useCollectionItemsQuery({
  collectionId,
  nextToken,
  previousToken,
  sort,
  searchTerm,
  selectedCategories,
}: {
  collectionId?: string
  nextToken?: string
  previousToken?: string
  sort?: CollectionSortType
  searchTerm?: string
  selectedCategories: string[]
}) {
  const { isAuthenticated } = useDantiAuth()

  const filter = React.useMemo(() => {
    if (selectedCategories.length === 0) {
      return
    }
    return selectedCategories.join(',').toLowerCase()
  }, [selectedCategories])

  const { data, isLoading } = useQuery({
    queryKey: [
      'collections',
      collectionId,
      'items',
      nextToken,
      previousToken,
      sort,
      filter,
      searchTerm,
    ],
    queryFn: () =>
      list(collectionId!, {
        limit: PAGE_SIZE,
        next: nextToken,
        prev: previousToken,
        term: searchTerm,
        sort,
        filter,
      }),
    enabled: isAuthenticated && !!collectionId,
    // TODO: Refetch more judiciously once Notification Service is online
    refetchInterval: 10000,
  })

  const totalCount = data?.total ?? 0
  const maxPages = Math.ceil(totalCount / PAGE_SIZE)

  const { next, prev, items = [] } = data ?? {}
  return {
    next,
    prev,
    isLoading,
    items: items ?? [],
    totalCount,
    maxPages,
    filter,
  }
}
