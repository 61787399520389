import type { ComboboxStringItem } from '@mantine/core'
import { useDebouncedValue } from '@mantine/hooks'
import { type AutocompleteOption, separatorString } from '@/api/google-maps'
import type { AutoCompleteValues } from '@/features/Search/types'
import { useKeyedState } from '@/hooks/use-keyed-state'
import {
  type StoredLocation,
  useSetStoredLocation,
  useStoredLocation,
} from '@/stores/queries-store'

export const DEBOUNCE_TIME = 350

export const stateKeys = {
  locationOptions: 'locationOptions',
  debouncedLocationValue: 'debouncedLocationValue',
  formattedAddress: 'formattedAddress',
  placeId: 'placeId',
  errorMessage: 'errorMessage',
  suggestionSelected: 'suggestionSelected',
}

export const useAutoCompleteValues = (
  locationValue: string,
): AutoCompleteValues => {
  const storedLocation = useStoredLocation()
  const setStoredLocation = useSetStoredLocation()
  const [debouncedLocationValue] = useDebouncedValue(
    locationValue,
    DEBOUNCE_TIME,
  )

  const [locationOptions, setLocationOptions] = useKeyedState<
    AutocompleteOption[]
  >(stateKeys.locationOptions, [])

  const [formattedAddress, setFormattedAddress] = useKeyedState(
    stateKeys.formattedAddress,
    storedLocation?.formattedAddress ?? '',
  )

  const [placeId, setPlaceId] = useKeyedState(
    stateKeys.placeId,
    storedLocation?.placeId ?? '',
  )
  const [errorMessage, setErrorMessage] = useKeyedState(
    stateKeys.errorMessage,
    '',
  )
  const [suggestionSelected, setSuggestionSelected] = useKeyedState(
    stateKeys.suggestionSelected,
    !!storedLocation,
  )

  const handleLocationUpdate = (options?: AutocompleteOption[]) => {
    if (!options?.[0]) {
      setFormattedAddress('')
      setPlaceId('')
      setStoredLocation(null)
      setLocationOptions([])
      return
    }
    const option = options[0]
    setFormattedAddress(option.formattedAddress ?? '')
    setPlaceId(option.placeId ?? '')
    setStoredLocation((option as StoredLocation) ?? null)
    setLocationOptions(options)
  }

  const errorOption = { label: errorMessage, value: 'error', disabled: true }
  const data = errorMessage
    ? [...locationOptions, errorOption]
    : locationOptions

  const handleLocationChange = (value: string) => {
    const selected = locationOptions.find((option) => option.label === value)
    setSuggestionSelected(!!selected)
  }

  const handleOptionSubmit = () => {
    setSuggestionSelected(true)
    setErrorMessage('')
  }

  const customErrorOption = (option: ComboboxStringItem) =>
    ({
      isError: option.value === 'error',
      value: option.value
        .replaceAll(separatorString, ' ')
        .replace(placeId, '')
        .replace('error', errorMessage),
    }) as { isError: boolean; value: string }

  const searchArgs = {
    formattedAddress,
    placeId,
  }

  return {
    errorMessage,
    customErrorOption,
    suggestionSelected,
    formattedAddress,
    setErrorMessage,
    setSuggestionSelected,
    searchArgs,
    handleLocationUpdate,
    debouncedLocationValue,
    handleLocationChange,
    handleOptionSubmit,
    data,
  }
}
