import { maintainRatio } from '@/utils/ui-helpers.tsx'
import type React from 'react'

interface LogoProps extends React.SVGProps<SVGSVGElement> {
  width?: string | number
  height?: string | number
  color?: string
}

export function DantiLogoMark({
  width,
  height,
  color = '#000',
  ...props
}: LogoProps) {
  const { width: newWidth, height: newHeight } = maintainRatio(
    {
      width,
      height,
    },
    45,
    39,
  )

  return (
    <svg
      {...props}
      width={newWidth ?? 45}
      height={newHeight ?? 39}
      viewBox="0 0 45 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.9242 8.29487L11.4616 0.153702H33.6879L45 19.5061L33.6879 38.846H11.4616L15.9242 30.7173H28.7156L35.4656 19.5061L28.7156 8.29487H15.9242Z"
        fill={color}
      />
      <path
        d="M-7.62939e-06 30.6576L4.42997 38.923L15.8461 19.5792L4.55406 -4.19617e-05L-7.62939e-06 8.20342L6.44021 19.5792L-7.62939e-06 30.6576Z"
        fill={color}
      />
    </svg>
  )
}
