import { graphql } from '@/gql'

export const UpdateProfileMutation = graphql(`
  mutation UpdateProfile($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      firstName
      lastName
      address
      address2
      city
      state
      zip
      country
      email
    }
  }
`)

export const UpdateUserRoleMutation = graphql(`
  mutation UpdateUserRole($userId: ID!, $role: OrgRole!) {
    updateUserRole(userId: $userId, role: $role) {
      id
    }
  }
`)

export const InviteUsersMutation = graphql(`
  mutation InviteUsers($emails: [String!]!, $role: OrgRole!) {
    inviteUsers(emails: $emails, role: $role)
  }
`)
