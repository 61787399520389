import { createStore } from '@/utils/create-store.ts'

export interface ResultsFilterValues {
  timelineSelectedIds: string[] | null

  omittedSources: string[]
  omittedRepos: string[]
  omittedAssets: string[]
  omittedSensors: string[]
  qualityCeil: number | null
  cloudCeil: number | null
}

export interface ResultsFilterActions {
  setHexgridSelectedIds: (ids: string[] | null) => void
  setTimelineSelectedIds: (ids: string[] | null) => void

  setOmittedSources: (omittedSources: string[]) => void
  setOmittedRepos: (omittedRepos: string[]) => void
  setOmittedAssets: (omittedAssets: string[]) => void
  setOmittedSensors: (omittedSensors: string[]) => void
  setQualityCeil: (qualityCeil: number | null) => void
  setCloudCeil: (cloudCeil: number | null) => void
  resetPostfilters: () => void
}

export interface ResultsFilterState
  extends ResultsFilterValues,
    ResultsFilterActions {}

export const initialState = {
  timelineSelectedIds: null,

  omittedSources: [],
  omittedRepos: [],
  omittedAssets: [],
  omittedSensors: [],
  qualityCeil: null,
  cloudCeil: null,
}
export const useResultsFilterStore = createStore<ResultsFilterState>({
  initialState,
  actions: (set): Partial<ResultsFilterState> => ({
    setTimelineSelectedIds: (ids: string[] | null) =>
      set({ timelineSelectedIds: ids }),

    setOmittedSources: (omittedSources) => set({ omittedSources }),
    setOmittedRepos: (omittedRepos) => set({ omittedRepos }),
    setOmittedAssets: (omittedAssets) => set({ omittedAssets }),
    setOmittedSensors: (omittedSensors) => set({ omittedSensors }),
    setQualityCeil: (qualityCeil) => set({ qualityCeil }),
    setCloudCeil: (cloudCeil) => set({ cloudCeil }),
    resetPostfilters: () => set(initialState),
  }),
})
