import type { CollectionSortType } from '@/api/collections/types'
import { createStore } from '@/utils/create-store'

export interface CollectionsState {
  currentPage: number
  nextToken: string
  previousToken: string
  sort: CollectionSortType
  search: (term: string) => void
  searchTerm: string
  setCurrentPage: (value: number) => void
  setNextToken: (value: string) => void
  setPreviousToken: (value: string) => void
  setSort: (value: CollectionSortType) => void
  setSearchTerm: (value: string) => void
  toNextPage: (next: string, maxPages: number) => void
  toPreviousPage: (previous: string) => void
  reset: () => void
}

export const initialState = {
  currentPage: 0,
  nextToken: '',
  previousToken: '',
  sort: '' as CollectionSortType,
  searchTerm: '',
} as Partial<CollectionsState>

export const useCollectionsStore = createStore<CollectionsState>({
  initialState,
  storageKey: 'Collections',
  version: 0.01,
  actions: (set, get): Partial<CollectionsState> => ({
    reset: () => set(initialState),
    setCurrentPage: (value) => set({ currentPage: value }),
    setNextToken: (value) => set({ nextToken: value }),
    setPreviousToken: (value) => set({ previousToken: value }),
    setSort: (value) => set({ sort: value }),
    setSearchTerm: (value) => set({ searchTerm: value }),
    toNextPage: (next: string, maxPages: number) => {
      if (!next) return
      const currentPage = get().currentPage as number
      set({
        currentPage: Math.min(maxPages, currentPage + 1),
        nextToken: next,
        previousToken: '',
      })
    },
    toPreviousPage: (previous: string) => {
      if (!previous) return
      const currentPage = get().currentPage as number
      set({
        currentPage: Math.max(0, currentPage - 1),
        nextToken: '',
        previousToken: previous,
      })
    },
    search: (term: string) =>
      set({
        nextToken: '',
        previousToken: '',
        currentPage: 0,
        searchTerm: term,
      }),
  }),
})
