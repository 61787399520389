export const separatorString = ' $#$#$# '

export function formatGeocodedAddress(result: {
  formatted_address: string
  place_id: string
}) {
  const formattedAddress = result.formatted_address
  const placeId = result.place_id

  return {
    label: result.formatted_address,
    value: [formattedAddress, placeId].join(separatorString),
    isUsAddress:
      formattedAddress.endsWith(', USA') || formattedAddress.endsWith(', US'),
    formattedAddress,
    placeId,
  }
}
