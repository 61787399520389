import { debug } from '@/utils/debug'
import * as Sentry from '@sentry/react'
import type { Feature, FeatureCollection, Geometry } from 'geojson'

const isPolygon = (geometry: Geometry): geometry is GeoJSON.Polygon => {
  return geometry.type === 'Polygon'
}

export const extractPolygonFeature = (object: unknown): Feature | undefined => {
  const geoObject = object as { type: string }
  if (geoObject.type === 'FeatureCollection') {
    const featureCollection = object as FeatureCollection

    const features = featureCollection.features.filter((f) =>
      isPolygon(f.geometry),
    )

    debug(`Found ${features.length} feature${features.length > 1 ? 's' : ''}`)

    return features[0]
  }
}

export const processDroppedFiles = async (
  acceptedFiles: File[],
): Promise<Feature | undefined> => {
  if (acceptedFiles.length > 1) {
    console.warn('Only using first dropped file.')
  }
  try {
    const fileString = await acceptedFiles[0].text()
    const fileJson = JSON.parse(fileString)

    return extractPolygonFeature(fileJson)
  } catch (error) {
    Sentry.captureException(error)
  }
}
