import { useState } from 'react'
import {
  Box,
  Button,
  Divider,
  Group,
  Modal,
  Stack,
  Stepper,
  Text,
  Title,
} from '@mantine/core'
import { resolveLocations, type ResolveLocationsResponse } from '@/api/search'
import { Icon } from '@/components/lib/Icon'
import { ImageProvidersCheckboxes } from '@/features/ImageProvidersCheckboxes'
import { QueryAndData } from '@/features/QueryAndData'
import { AddLocations } from './components'

import styles from './advanced-search.module.css'

interface AdvancedSearchProps {
  opened: boolean
  handleClose: () => void
}

export function AdvancedSearch({ opened, handleClose }: AdvancedSearchProps) {
  const [isLocationsLoading, setIsLocationsLoading] = useState(false)
  const [activeStep, setActiveStep] = useState(0)
  const [highestStepVisited, setHighestStepVisited] = useState(activeStep)
  const [searchLocations, setSearchLocations] = useState('')
  const [parsedLocations, setParsedLocations] =
    useState<ResolveLocationsResponse['geolocations']>()
  const [searchLocationError, setSearchLocationError] = useState('')

  const reset = () => {
    // This function should serve as cleanup as new capability is added
    // Ex. set all filters back to default, reset stepper to 0, etc.
    setActiveStep(0)
    setHighestStepVisited(0)
    setSearchLocations('')
    setParsedLocations(undefined)
    setSearchLocationError('')
    setIsLocationsLoading(false)
  }

  const STEPS = [
    {
      stepperLabel: 'Locations',
      stepperDescription: 'Upload and verify locations',
      title: 'Add your locations',
      content: (
        <AddLocations
          isLocationsLoading={isLocationsLoading}
          searchLocations={searchLocations}
          setSearchLocations={setSearchLocations}
          searchLocationError={searchLocationError}
          setSearchLocationError={setSearchLocationError}
          parsedLocations={parsedLocations}
        />
      ),
    },
    {
      stepperLabel: 'Query and Data',
      stepperDescription: 'Add a query and select data types',
      title: 'Add a search query and select data',
      content: <QueryAndData />,
    },
    {
      stepperLabel: 'Image Providers',
      stepperDescription: 'Satellite imagery Providers',
      title: 'Image providers',
      content: (
        <ImageProvidersCheckboxes
          providers={new Set()}
          sensors={new Set()}
          platforms={new Set()}
          sources={new Set()}
          setter={() => {}}
        />
      ),
    },
    {
      stepperLabel: 'Image Filters',
      stepperDescription: 'Add optional image filters',
      title: 'Image filters',
      content: 'Step 4',
    },
  ]

  const nextStep = () =>
    setActiveStep((current) => {
      const completedStep = STEPS.length

      // If on Completed Step, close the modal
      if (current === completedStep) {
        handleClose()
        reset()
        return current
      }

      // Step 1a
      if (current === 0 && !parsedLocations) {
        setIsLocationsLoading(true)

        resolveLocations(searchLocations)
          .then(({ data }) => {
            if (data.geolocations.length === 0) {
              throw new Error('No locations')
            }

            setParsedLocations(data.geolocations)
          })
          .catch(() => {
            setSearchLocationError(
              'There was an error validating your input. Please try again.',
            )
          })
          .finally(() => setIsLocationsLoading(false))

        return current
      }

      // All other steps
      setHighestStepVisited((hSC) => Math.max(hSC, current + 1))
      return current + 1
    })

  const previousStep = () =>
    setActiveStep((current) => {
      // If on Step 1, close the modal
      if (current === 0) {
        handleClose()
        reset()
        return current
      }

      // Otherwise, set the active step to previous
      return current - 1
    })

  // Allow the user to freely go back and forth between visited steps.
  const shouldAllowSelectStep = (step: number) =>
    highestStepVisited >= step && activeStep !== step

  return (
    <Modal
      size="90%"
      opened={opened}
      onClose={() => {
        handleClose()
        reset()
      }}
      classNames={{
        content: styles.modalContent,
        body: styles.modalBody,
        header: styles.modalHeader,
      }}
      padding="xl"
    >
      <Stepper active={activeStep} onStepClick={setActiveStep}>
        {STEPS.map((step, index) => (
          <Stepper.Step
            key={step.stepperLabel}
            className={
              activeStep === index || shouldAllowSelectStep(index)
                ? ''
                : styles.disabledLabel
            }
            allowStepSelect={shouldAllowSelectStep(index)}
            maw={200}
            label={step.stepperLabel}
            description={step.stepperDescription}
          >
            <Title size="32px">{step.title}</Title>
            <Divider mt="sm" mb="md" />
            {step.content}
          </Stepper.Step>
        ))}
        <Stepper.Completed>
          <Stack
            align="center"
            justify="center"
            gap="xl"
            p={80}
            style={{
              border: '1px solid var(--mantine-color-gray-3)',
              borderRadius: 'var(--mantine-radius-lg)',
            }}
          >
            <Title>Your Saved Search is complete</Title>
            <Icon
              name="folder"
              size={100}
              style={{ color: 'var(--mantine-color-blue-6' }}
            />
            <Text c="gray.6" size="xl">
              Danti is gathering the results of your Saved Search. After you
              click finish, Danti will create a Saved Search folder and add the
              results for each location inside.
            </Text>
          </Stack>
        </Stepper.Completed>
      </Stepper>
      <Box>
        <Divider my="md" />
        <Group justify="space-between">
          <Button
            disabled={isLocationsLoading}
            variant="outline"
            onClick={previousStep}
          >
            {activeStep > 0 ? '< Back' : 'Cancel'}
          </Button>
          <Button
            disabled={isLocationsLoading || !searchLocations}
            onClick={nextStep}
          >
            {activeStep === STEPS.length - 1 ? 'Finish' : 'Next'}
          </Button>
        </Group>
      </Box>
    </Modal>
  )
}

export default AdvancedSearch
