import type { SearchSuggestion } from './types'

export const AUTOCOMPLETE_TOOLTIP_PROPS = {
  color: 'red',
  position: 'top-start',
  withArrow: true,
} as const

export const AUTOCOMPLETE_LOCATION_PLACEHOLDER =
  'What location do you want to search?'
export const AUTOCOMPLETE_SUBJECT_PLACEHOLDER = 'What do you want to know?'

export const AUTOCOMPLETE_INPUT_PROPS = {
  radius: '1rem',
  size: 'md',
}

export enum SEARCH_STATUSES {
  NOT_STARTED = 'NOT_STARTED',
  STARTED_RECENTLY = 'STARTED_RECENTLY',
  STARTED_LONG_AGO = 'STARTED_LONG_AGO',
  EDS_IN_PROGRESS = 'EDS_IN_PROGRESS',
  NO_RESULTS = 'NO_RESULTS',
  READY = 'READY',
}

export enum SEARCH_STYLE {
  General = 'General',
  Location = 'Location',
}

export const SEARCH_SUGGESTIONS: SearchSuggestion[] = [
  {
    icon: 'house',
    title: 'Search property details',
    location: {
      formattedAddress: '2111 S Flagler Dr, West Palm Beach, FL 33401',
      placeId: 'ChIJ8dz_effW2IgRRoPqhh8Ymn8',
    },
    subject: 'What is the roof age?',
  },
  {
    icon: 'construction',
    title: 'Search permits',
    location: {
      formattedAddress: '4841 Alonzo Ave, Encino, CA 91316',
      placeId: 'ChIJw1iOdVKYwoARFnDRRavirE4',
    },
    subject: 'Summarize the permits',
  },
  {
    icon: 'storm',
    title: 'Search property risks',
    location: {
      formattedAddress: '4754 Rivercliff Lndg, Marietta, GA 30067',
      placeId: 'ChIJi1mMXwoO9YgR3GDBtxSOhE4',
    },
    subject: 'What are the risks?',
  },
]
