import React, { useEffect, useState } from 'react'
import { emitter, EVENTS } from '@/events'
import { actOnMap, safeSetLayoutProperty } from '@/features/DantiMap/utils'
import { useSearchStatus } from '@/features/Search/hooks'
import { MAP_LAYER_OPTIONS, useActiveLayers } from '@/stores/map-store'

export const useHandleMapToggling = (
  map: React.MutableRefObject<mapboxgl.Map | null>,
) => {
  const [isResultsExpanded, setIsResultsExpanded] = React.useState(false)
  const [showPointData, setShowPointData] = useState(true)
  const [showExtents, setShowExtents] = useState(true)
  const { isSearching } = useSearchStatus()
  const activeLayers = useActiveLayers()

  useEffect(() => {
    if (showExtents !== undefined) {
      actOnMap(() => {
        const visValue = showExtents ? 'visible' : 'none'
        void safeSetLayoutProperty(map, 'extents-fills', 'visibility', visValue)
        void safeSetLayoutProperty(map, 'extent-point', 'visibility', visValue)
        void safeSetLayoutProperty(
          map,
          'extents-outlines',
          'visibility',
          visValue,
        )
      })
    }
  }, [map, showExtents])

  React.useEffect(() => {
    setIsResultsExpanded(isSearching)
  }, [isSearching])

  React.useEffect(() => {
    setShowPointData(activeLayers.includes(MAP_LAYER_OPTIONS.POINT))
    setShowExtents(activeLayers.includes(MAP_LAYER_OPTIONS.EXTENTS))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    const handleSetLayers = ({ activeLayers }: { activeLayers: string[] }) => {
      setShowPointData(activeLayers.includes(MAP_LAYER_OPTIONS.POINT))
      const newShowExtents = activeLayers.includes(MAP_LAYER_OPTIONS.EXTENTS)
      setShowExtents(newShowExtents)
    }
    emitter.on(EVENTS.mapStore.activeLayersUpdated, handleSetLayers)
    return () => {
      emitter.off(EVENTS.mapStore.activeLayersUpdated, handleSetLayers)
    }
  }, [map, showExtents, showPointData])

  const toggleResultsPanel = () => {
    setIsResultsExpanded((state) => !state)
  }

  return {
    isResultsExpanded,
    showPointData,
    showExtents,
    toggleResultsPanel,
  }
}
