import { add, remove } from '@/api/collections/collection-items/api'
import type {
  AddItemParams,
  DeleteItemParams,
} from '@/api/collections/collection-items/types'
import { useMutation, type useQueryClient } from '@tanstack/react-query'

export function useCollectionItemsMutations(
  queryClient: ReturnType<typeof useQueryClient>,
) {
  function refreshCollection() {
    void queryClient.invalidateQueries({
      queryKey: ['collections'],
    })
  }

  const addMutation = useMutation({ mutationFn: add })
  const deleteMutation = useMutation({ mutationFn: remove })

  async function addItem(params: AddItemParams) {
    await addMutation.mutateAsync(params, {
      onSuccess: refreshCollection,
    })
  }

  async function removeItem(params: DeleteItemParams) {
    await deleteMutation.mutateAsync(params, {
      onSuccess: refreshCollection,
    })
  }

  return {
    addItem,
    removeItem,
  }
}
