import { Avatar, Box, Group, Table, Text } from '@mantine/core'
import { useUsers } from '@/api/user'
import { useUserRole } from '@/hooks/use-user-role'
import type { User } from '@/utils/types/user-types'
// import { Icon } from '@/components/lib/Icon'
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { OrgRoleSelect } from './OrgRoleSelect'
import { UserAdminMenu } from './UserAdminMenu'

const columnHelper = createColumnHelper<User>()

const columns = [
  columnHelper.accessor((row) => row, {
    header: 'Name',
    size: 150,
    cell: (info) => {
      const { firstName, lastName } = info.getValue()
      const names = []

      if (firstName) {
        names.push(firstName)
      }
      if (lastName) {
        names.push(lastName)
      }

      const name = names.length > 0 ? names.join(' ') : '--'
      const initials = names.map((name) => name[0]).join(' ')

      return (
        <Group pr="sm">
          <Avatar variant="filled" color="#BDBDBD" radius="50%" size="sm">
            {initials}
          </Avatar>
          <Text size="md">{name}</Text>
        </Group>
      )
    },
  }),
  columnHelper.accessor((row) => row, {
    header: 'Email',
    cell: (info) => {
      /* eslint-disable react-hooks/rules-of-hooks */
      const currentUser = useUserRole()
      const { id, email } = info.getValue()
      const isCurrentUser = currentUser?.id === id

      return (
        <Group pr="sm" h={36}>
          <Text>
            <a
              href={`mailto:${email}`}
              style={{ color: '#0091EA', textDecoration: 'none' }}
            >
              {email}
            </a>
          </Text>
          {isCurrentUser && (
            <Box
              size="xs"
              bg="#1B5E20"
              style={{ borderRadius: 20, color: '#fff', fontSize: 13 }}
              px={10}
              py={1}
            >
              You
            </Box>
          )}
        </Group>
      )
    },
  }),
  columnHelper.accessor((row) => row, {
    header: 'Role',
    size: 70,
    cell: (info) => <OrgRoleSelect user={info.getValue()} />,
  }),
  columnHelper.accessor((row) => row, {
    id: 'actions',
    header: '',
    size: 10,
    cell: (info) => <UserAdminMenu user={info.getValue()} />,
  }),
]

export function UserAdminTable() {
  const { users } = useUsers()

  const table = useReactTable({
    data: users,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getRowId: (user) => user.id,
  })

  return (
    <>
      <Table w={800}>
        <Table.Thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <Table.Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <Table.Th key={header.id} w={header.getSize()} pt={8} pb={8}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                </Table.Th>
              ))}
            </Table.Tr>
          ))}
        </Table.Thead>
        <Table.Tbody>
          {table.getRowModel().rows.map((row) => (
            <Table.Tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <Table.Td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Table.Td>
              ))}
            </Table.Tr>
          ))}
        </Table.Tbody>
      </Table>
      {/* {users.length === 0 && (
        <Stack align="center" gap="sm" mt={100}>
          <Text>There are no other users in your team</Text>
          <Button
            size="md"
            leftSection={<Icon name="add" />}
            // onClick={onInviteUser}
          >
            Invite a user
          </Button>
        </Stack>
      )} */}
    </>
  )
}
