import { downloadFromURL } from '@/api/collections'
import { getRequest } from '@/api/danti-requests'
import * as Sentry from '@sentry/react'
import queryString from 'query-string'

export const handleSearchLocationDownload = (searchLocationId: string) => {
  const query = queryString.stringify({
    id: searchLocationId,
  })

  return getRequest<{ features: Blob }>(`terrapin/export?${query}`)
    .then((response) => {
      let downloadName = `${searchLocationId}.json`
      if (response.headers['content-disposition']) {
        const contentDisposition = response.headers[
          'content-disposition'
        ] as string
        const filenameMatch = contentDisposition.match(/filename="(.+)"/)
        if (filenameMatch && filenameMatch.length > 1) {
          downloadName = filenameMatch[1]
        }
      }

      const dataBlob = new Blob([JSON.stringify(response.data.features)])
      const url = window.URL.createObjectURL(dataBlob)

      downloadFromURL(url, downloadName)
    })
    .catch(() => {
      Sentry.captureMessage('Failed to download location data', {
        extra: {
          searchLocationId,
        },
      })
    })
}
