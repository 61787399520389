import { ActionIcon, TextInput } from '@mantine/core'
import { Icon } from '@/components/lib/Icon'
import { TEST_IDS } from '@/features/InsightPanel/constants'
import { useInsightFeedback } from '@/features/InsightPanel/hooks'
import { messages } from '@/features/InsightPanel/messages'

export function InsightFeedbackTextInput(props: { handleClose: () => void }) {
  const { customReason } = useInsightFeedback()
  const { handleChange, value } = customReason

  const hasCustomReason = (value?.trim().length ?? 0) > 0

  return (
    <TextInput
      flex={1}
      size="xs"
      radius="1rem" // Match the radius of the Chip
      autoFocus
      placeholder={messages.CUSTOM_PLACEHOLDER}
      onChange={handleChange}
      rightSection={
        <ActionIcon
          data-testid={TEST_IDS.customReasonClear}
          onClick={props.handleClose}
          variant="transparent"
          mr="xs"
        >
          <Icon name="cancel" size="sm" />
        </ActionIcon>
      }
      styles={{
        input: {
          fontSize: 14,
          paddingInline: 'var(--mantine-spacing-sm)',
          borderColor: `var(--mantine-color-${
            hasCustomReason ? 'blue-outline' : 'gray-3'
          })`,
        },
      }}
    />
  )
}
