import { Button, ButtonGroup } from '@mantine/core'
import { useStopDrawingShape } from '@/stores/map-draw-store'
import type { FC, MouseEventHandler } from 'react'

import styles from '@/features/DantiMap/danti-map.module.css'

type MapSearchHereButtonProps = {
  onClick: MouseEventHandler
}

export const MapSearchActionButtons: FC<MapSearchHereButtonProps> = ({
  onClick,
}) => {
  const stopDrawingShape = useStopDrawingShape()
  return (
    <ButtonGroup>
      <Button
        variant="filled"
        onClick={onClick}
        size="sm"
        className={styles.mapSearchHereButton}
      >
        Search Here
      </Button>
      <Button
        variant="white"
        onClick={stopDrawingShape}
        size="sm"
        className={styles.mapSearchHereButton}
      >
        Cancel
      </Button>
    </ButtonGroup>
  )
}
