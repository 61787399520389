import type {
  CollectionsResponse,
  CreateCollectionParams,
  ListCollectionsParams,
  ShareCollectionParams,
  UnshareCollectionParams,
  UpdateCollectionParams,
} from '@/api/collections/types'
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from '@/api/danti-requests'
import { omitBy } from 'lodash'

export const urls = {
  collection: '/collection',
  share: (id: string) => `/collection/${id}/share`,
  unshare: (id: string) => `/collection/${id}/share`,
  update: (id: string) => `/collection/${id}`,
  remove: (id: string) => `/collection/${id}`,
}

export async function list(
  params: ListCollectionsParams,
): Promise<CollectionsResponse> {
  const response = await getRequest<CollectionsResponse>(urls.collection, {
    params: omitBy(params, (value) => !value),
  })
  return response.data
}

export const create = (params: CreateCollectionParams) =>
  postRequest(urls.collection, params)
export const share = ({ id, ...params }: ShareCollectionParams) =>
  postRequest(urls.share(id), params)
export const unshare = ({ id, ...data }: UnshareCollectionParams) =>
  deleteRequest(urls.unshare(id), { data })
export const update = ({ id, ...params }: UpdateCollectionParams) =>
  putRequest(urls.update(id), params)
export const remove = (id: string) => deleteRequest(urls.remove(id))

export const fetchCollection = async (collectionId: string) => {
  const response = await getRequest(`/collection/${collectionId}`)
  return response.data
}
