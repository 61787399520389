import { Anchor, Group, Text } from '@mantine/core'
import type { SortPreferenceOption } from '@/stores/queries-store'
import { orderBy } from 'lodash'

import styles from './view-template.module.css'

export interface SOVCarouselProps<ItemType> {
  title: string
  items: ItemType[]
  renderCard: (item: ItemType, index: number) => JSX.Element
  selectItem: (item: ItemType, index: number) => void
  grid?: boolean
  limit?: number
  categoryUrl?: string
  categoryLabel?: string
  sortPreference?: SortPreferenceOption
}

export function SOVCarousel<T>({
  title,
  items,
  renderCard,
  selectItem,
  grid = false,
  limit = 4,
  categoryUrl,
  categoryLabel,
  sortPreference = 'authoredOn',
}: SOVCarouselProps<T>) {
  const sortedResults =
    items.length === 0
      ? []
      : orderBy(items, sortPreference || undefined, 'desc')
  if (items.length === 0) {
    return null
  }
  const handleClick = (item: T, index: number) => () => selectItem(item, index)

  return (
    <div className={styles['sov-carousel']}>
      <Text className={styles['sov-category-title']} w="100%">
        {title}
      </Text>
      {categoryUrl && categoryLabel && (
        <Anchor href={categoryUrl} className={styles['sov-category-link']}>
          {categoryLabel}
        </Anchor>
      )}
      <div className={styles['sov-category-listing']}>
        <Group
          wrap={grid ? 'wrap' : 'nowrap'}
          style={{ alignItems: 'start' }}
          gap="0px"
        >
          {(grid ? sortedResults : sortedResults.slice(0, limit)).map(
            (item, index) => {
              const key = index

              return (
                <div
                  className={styles['sov-category-card']}
                  key={key}
                  onClick={handleClick(item, index)}
                >
                  {renderCard(item, index)}
                </div>
              )
            },
          )}
        </Group>
      </div>
    </div>
  )
}
