import React from 'react'
import { Group, Slider, Stack, Text } from '@mantine/core'
import type { FilterArgs } from '@/features/Filters/types'

const nadirAngles = Array.from({ length: 6 }).map((_, index) => ({
  label: index < 5 ? `${index * 10}°` : 'Any',
  value: index * 10,
}))

export function CameraAngle({ value, setter }: FilterArgs<string>) {
  const [angleOffNadir, setAngleOffNadir] = React.useState(Number(value) || 50)

  React.useEffect(() => {
    setAngleOffNadir(Number(value) || 50)
  }, [value])

  return (
    <Stack gap="xs" w="100%">
      <Group justify="space-between">
        <Text size="md">Camera Angle</Text>
        <Text size="sm">
          {angleOffNadir === 0
            ? 'Nadir'
            : angleOffNadir < 50
              ? `${angleOffNadir}° off Nadir or less`
              : 'Any angle'}
        </Text>
      </Group>
      <Slider
        styles={{ markLabel: { fontSize: '12px' } }}
        my="sm"
        min={0}
        max={50}
        step={1}
        marks={nadirAngles}
        value={angleOffNadir}
        label={(angle) => (angle < 50 ? `${angle}°` : 'Any')}
        aria-label="Angle Off Nadir"
        onChange={(value) => setAngleOffNadir(value)}
        onChangeEnd={(value) => setter(value.toString())}
      />
    </Stack>
  )
}
