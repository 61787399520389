import { Button, Group, Stack } from '@mantine/core'
import { useImagesByDate } from '@/api/results'
import { ResultImage } from '@/features/ResultImage'
import { VIEWS } from '@/features/TimelineView/constants'

export const TimelineDrawer = ({
  selectedDate,
  selectedView,
  // selectedImages,
  setSelectedImages,
}: {
  selectedDate: string | null
  selectedView: string
  selectedImages: {
    primary: string | null
    secondary: string | null
  }
  setSelectedImages: {
    primary: (id: string | null) => void
    secondary: (id: string | null) => void
  }
}) => {
  const images = useImagesByDate()[selectedDate || ''] || []
  if (!selectedDate) {
    return null
  }
  return (
    <div>
      <h3>Selected Date: {selectedDate}</h3>
      <Group>
        {images.map((image) => (
          <Stack key={image.id} gap="4px">
            <ResultImage
              style={{ width: '100px', height: '100px' }}
              item={image}
            />
            {selectedView === VIEWS.inspect && (
              <Button
                onClick={() => {
                  setSelectedImages.primary(image.id)
                }}
                color="blue"
              >
                Select
              </Button>
            )}

            {selectedView === VIEWS.compare && (
              <Group gap={0} mt={0} pt={0}>
                <Button
                  onClick={() => {
                    setSelectedImages.primary(image.id)
                  }}
                  w="50%"
                  color="blue"
                >
                  {'<'}
                </Button>
                <Button
                  onClick={() => {
                    setSelectedImages.secondary(image.id)
                  }}
                  w="50%"
                  color="red"
                >
                  {'>'}
                </Button>
              </Group>
            )}
          </Stack>
        ))}
      </Group>
    </div>
  )
}
