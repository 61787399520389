import React, { type ForwardedRef } from 'react'
import { Menu, type MenuItemProps } from '@mantine/core'
import { Icon } from '@/components/lib/Icon'
import type { SymbolName } from '@/utils/types/material-icons'

import styles from '../header.module.css'

type HeaderMenuItemProps = MenuItemProps & {
  iconName?: SymbolName
  onClick?: () => void
}

export const HeaderMenuItem = React.forwardRef(
  (
    { children, iconName, ...props }: HeaderMenuItemProps,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => (
    <Menu.Item
      c="white"
      classNames={{ item: styles.menuItem, itemLabel: styles.menuItem }}
      {...(iconName && { leftSection: <Icon name={iconName} /> })}
      {...props}
      ref={ref}
    >
      {children}
    </Menu.Item>
  ),
)
HeaderMenuItem.displayName = 'HeaderMenuItem'
