import { useEffect } from 'react'
import {
  Avatar,
  Button,
  Center,
  Group,
  Select,
  Stack,
  Text,
  TextInput,
} from '@mantine/core'
import { isEmail, useForm } from '@mantine/form'
import { toastError, toastSuccess } from '@/components/toasts'
import type { UpdateUserInput } from '@/gql/graphql'
import { useProfile } from '@/pages/Account/hooks/use-profile'
import { UpdateProfileMutation } from '@/pages/Account/mutations'
import { useAvatarDetails } from '@/stores/user-store'
import { countries, states } from '@/utils/list-data'
import { useMutation } from '@apollo/client'
import { captureException } from '@sentry/react'

export function Profile() {
  const user = useProfile()
  const [updateUserMutation, { loading }] = useMutation(UpdateProfileMutation)
  const { avatarBackgroundColor, userInitials } = useAvatarDetails()

  const form = useForm<Omit<UpdateUserInput, 'id'>>({
    initialValues: {
      firstName: user?.firstName ?? '',
      lastName: user?.lastName ?? '',
      address: user?.address ?? '',
      address2: user?.address2 ?? '',
      city: user?.city ?? '',
      state: user?.state ?? '',
      zip: user?.zip ?? '',
      country: user?.country ?? '',
      email: user?.email ?? '',
    },
    validate: {
      email: isEmail('Invalid Email') as (
        value: unknown,
      ) => React.ReactNode | undefined,
    },
  })

  useEffect(() => {
    form.setValues({
      firstName: user?.firstName ?? '',
      lastName: user?.lastName ?? '',
      address: user?.address ?? '',
      address2: user?.address2 ?? '',
      city: user?.city ?? '',
      state: user?.state ?? '',
      zip: user?.zip ?? '',
      country: user?.country ?? '',
      email: user?.email ?? '',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    user?.address,
    user?.address2,
    user?.city,
    user?.country,
    user?.email,
    user?.firstName,
    user?.lastName,
    user?.state,
    user?.zip,
  ])

  function handleProfileSubmit(values: Partial<UpdateUserInput>) {
    void updateUserMutation({
      variables: {
        input: {
          id: user.id,
          ...values,
        },
      },
      onError: (error) => {
        console.error(error)
        captureException(`Could not update Account Profile`)
        toastError({ message: 'Error updating Profile Info' })
      },
      onCompleted: () => {
        toastSuccess({ message: 'Profile Info Updated' })
      },
    })
  }

  return (
    <>
      <Center inline>
        <Avatar
          variant="filled"
          color={avatarBackgroundColor}
          radius="50%"
          h="156px"
          w="156px"
        >
          <span style={{ fontSize: '60px' }}>{userInitials}</span>
        </Avatar>
      </Center>
      <Stack style={{ borderBottom: 'solid 1px rgba(0, 0, 0, 0.12)' }}>
        <Text fs="20px" fw="500" lh="160%" lts="0.15px">
          Personal Information
        </Text>
      </Stack>
      <form onSubmit={form.onSubmit((values) => handleProfileSubmit(values))}>
        <Stack gap="sm">
          <Group justify="space-between">
            <TextInput
              variant="filled"
              label="First Name"
              {...form.getInputProps('firstName')}
              data-cy="profile-first-name"
              required
              withAsterisk
            />
            <TextInput
              variant="filled"
              label="Last Name"
              {...form.getInputProps('lastName')}
              data-cy="profile-last-name"
              required
              withAsterisk
            />
          </Group>
          <TextInput
            variant="filled"
            label="Address"
            {...form.getInputProps('address')}
            data-cy="address"
          />
          <TextInput
            variant="filled"
            label="Address Line 2"
            {...form.getInputProps('address2')}
            data-cy="address2"
          />
          <TextInput
            variant="filled"
            label="City"
            {...form.getInputProps('city')}
            data-cy="city"
          />
          <Group grow preventGrowOverflow={false} wrap="nowrap">
            <Select
              variant="filled"
              label="State"
              {...form.getInputProps('state')}
              data={states}
            />
            <TextInput
              variant="filled"
              label="Zip"
              {...form.getInputProps('zip')}
              data-cy="zip"
            />
          </Group>
          <Select
            variant="filled"
            label="Country"
            {...form.getInputProps('country')}
            data={countries}
          />
          <TextInput
            variant="filled"
            label="Email"
            {...form.getInputProps('email')}
            data-cy="email"
          />
          <Button type="submit" disabled={loading}>
            SAVE
          </Button>
        </Stack>
      </form>
    </>
  )
}
