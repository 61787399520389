import { oidcAuthority, oidcClientId } from '@/utils/constants.ts'
import { User } from 'oidc-client-ts'

export function getUserAuthToken() {
  const oidcUser = window.localStorage.getItem(
    `oidc.user:${oidcAuthority}:${oidcClientId}`,
  )
  if (!oidcUser) {
    return
  }

  return User.fromStorageString(oidcUser).access_token
}
