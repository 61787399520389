import { default as beachHouses } from './aerial-beach-houses.webp'
import { default as beachHouses2 } from './aerial-beach-houses2.webp'
import { default as commercialConstruction } from './aerial-commercial-construction.webp'
import { default as commercialOcean } from './aerial-commercial-ocean.webp'
import { default as commercialOffice } from './aerial-commercial-office.webp'
import { default as commercialRoads } from './aerial-commercial-roads.webp'
import { default as commercialWater } from './aerial-commercial-water.webp'
import { default as desertRoad } from './aerial-desert-road.webp'
import { default as forestRoad } from './aerial-forest-road.webp'
import { default as houses } from './aerial-houses.webp'
import { default as houses1 } from './aerial-houses-1.webp'
import { default as houses2 } from './aerial-houses-2.webp'
import { default as map } from './aerial-map.webp'
import { default as planes } from './aerial-planes.webp'
import { default as portContainers } from './aerial-port-containers.webp'
import { default as road } from './aerial-road.webp'
import { default as winterRoad } from './aerial-winter-road.webp'

export const govBackgroundImages = [
  road,
  map,
  commercialWater,
  portContainers,
  winterRoad,
  beachHouses2,
  planes,
  desertRoad,
  beachHouses,
  forestRoad,
  commercialRoads,
  commercialOcean,
]

export const commercialBackgroundImages = [
  beachHouses,
  commercialOcean,
  commercialWater,
  commercialConstruction,
  commercialOffice,
  commercialRoads,
  houses1,
  houses2,
  houses,
]
