import { extractProvenance } from '@/stores/results-filter-store/utils'
import { appEnv } from '@/utils/constants.ts'
import type {
  OrthoImageryLayer,
  OrthoImageryProvider,
} from '@/utils/types/ortho-imagery-types.ts'
import type { ImageryResult } from '@/utils/types/raw-result-types.ts'
import type { MultiPolygon, Polygon } from 'geojson'

type Geometry = Polygon | MultiPolygon

export const normalizeUrl = (url: string) => {
  const normalizedUrl = new URL(url)
  // Ensure api url reflects the correct environment, knowing test -> api.dev.danti
  // Images that are not proxied (sentinel 1) should not have hostname rewritten
  if (appEnv != 'local' && appEnv != 'test' && url.includes('danti.ai')) {
    normalizedUrl.hostname = `api.${appEnv}.danti.ai`
  }
  return normalizedUrl.toString()
}

export const makePngImageOrthoLayer = (r: ImageryResult): OrthoImageryLayer => {
  const featureId = (r.properties.featureId as string) || r.id

  return {
    source: r.source as OrthoImageryProvider,
    id: r.documentId,
    tileUrl: normalizeUrl(r.properties._dPreview as string),
    authoredOn: r.authoredOn,
    geometry: r.geometry as Geometry,
    documentId: r.documentId,
    imageResult: r,
    groupKey: featureId,
    score: r.score,
    formattedSource: `${extractProvenance(r)}`,
  }
}
