import React, { useEffect } from 'react'
import { useDantiAuth } from '@/hooks/use-danti-auth.ts'
import { stripePublicKey, stripeTableId } from '@/utils/constants.ts'

export function PricingTable() {
  const { user } = useDantiAuth()

  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://js.stripe.com/v3/pricing-table.js'
    script.async = true
    document.body.append(script)

    return () => {
      script.remove()
    }
  }, [])

  return React.createElement('stripe-pricing-table', {
    'pricing-table-id': stripeTableId as string,
    'publishable-key': stripePublicKey as string,
    'customer-email': user?.profile.email ?? '',
    'success-url': `${window.location.origin}/plan-selection?session_id={CHECKOUT_SESSION_ID}`,
  })
}
