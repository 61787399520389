import { TextInput } from '@mantine/core'
import { Icon } from '@/components/lib/Icon'

export const SearchInput = ({
  searchTerm,
  setSearchTerm,
}: {
  searchTerm: string
  setSearchTerm: (value: string) => void
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value)
  }
  return (
    <TextInput
      placeholder="Search"
      withAsterisk
      leftSection={<Icon name="search" />}
      value={searchTerm}
      onChange={handleChange}
      miw={300}
      styles={{
        root: { maxHeight: '32px' },
        wrapper: { height: '32px' },
        input: { height: '32px' },
      }}
    />
  )
}
