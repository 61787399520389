import type { Collection } from '@/api/collections/types'
import { useDantiAuth } from '@/hooks/use-danti-auth'
import { useQuery } from '@tanstack/react-query'
import { fetchCollection } from './api'

export const useFetchCollection = (collectionId: string) => {
  const isAuthenticated = useDantiAuth()
  return useQuery({
    queryKey: ['collection', collectionId],
    queryFn: async () => fetchCollection(collectionId),
    enabled: isAuthenticated && !!collectionId,
  }) as { data: Collection | undefined; isLoading: boolean }
}
