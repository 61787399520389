import { axiosClient } from '@/api/danti-requests/axios-client'
import { providerObject } from '@/features/Filters'
import { useFeatureFlag } from '@/hooks/use-feature-flag'
import { useProvidersFilter, useSetProviders } from '@/stores/filters-store'
import { FEATURE_FLAG_VALUES, searchApiUrl } from '@/utils/constants'
import { useQuery } from '@apollo/client'
import * as reactQuery from '@tanstack/react-query'
import { SourcesByUserQuery } from './constants'
import type { SearchMetadataResponse } from './types'

export function useProviders() {
  const shouldSupportDynamicProviders = useFeatureFlag(
    FEATURE_FLAG_VALUES.dynamicProviders,
  )
  const selectedProviders = useProvidersFilter()
  const setProviders = useSetProviders()
  const { data } = useQuery(SourcesByUserQuery, {
    skip: !shouldSupportDynamicProviders,
  })

  const { data: searchMetadata } = reactQuery.useQuery({
    queryKey: ['search-metadata'],
    queryFn: () =>
      axiosClient.get<SearchMetadataResponse>('/search/metadata', {
        baseURL: searchApiUrl,
      }),
  })

  const providersList =
    data?.me?.sources.nodes.map((source) => source.provider) || []
  const providers = providerObject(providersList)

  const updateProviders = (selectedProviders: string[]) => {
    const providersArray = selectedProviders.map((provider) => {
      const providerKey = Object.keys(providers).find((key) => key === provider)
      return providerKey || ''
    })
    setProviders(providersArray)
  }

  return {
    providers,
    newProviders: searchMetadata?.data.sources ?? [],
    updateProviders,
    selectedProviders,
  }
}
