import { SegmentedControl } from '@mantine/core'
import { Icon } from '@/components/lib/Icon'
import type { ViewType } from '@/features/Collections/types'

export const TypeControl = ({
  viewType,
  setViewType,
}: {
  viewType: ViewType
  setViewType: (value: ViewType) => void
}) => {
  const icons = {
    list: <Icon name="view_list" color="black" />,
    grid: <Icon name="grid_view" />,
  }
  const handleSetViewType = (value: ViewType) => {
    setViewType(value)
  }
  return (
    <SegmentedControl
      bg="gray.4"
      size="xs"
      value={viewType}
      onChange={handleSetViewType}
      styles={{ label: { height: 28 } }}
      data={[
        { value: 'list', label: icons.list },
        { value: 'grid', label: icons.grid },
      ]}
    />
  )
}
