import { GridCol } from '@mantine/core'
import { BentoSection } from './BentoSection'

export interface PrimaryBentoCellProps {
  isLoading?: boolean
  children?: React.ReactNode
  'data-testid'?: string
}

export const PrimaryBentoCell = ({
  isLoading = false,
  children,
}: PrimaryBentoCellProps) => (
  <GridCol span={{ base: 12, lg: 4 }}>
    <BentoSection isPrimary isLoading={isLoading}>
      {children}
    </BentoSection>
  </GridCol>
)

export default PrimaryBentoCell
