import { type NotificationData, notifications } from '@mantine/notifications'

export function toastSuccess({ message }: NotificationData) {
  return notifications.show({
    title: 'Success',
    color: 'green',
    message: message,
    autoClose: 10000,
    withCloseButton: true,
  })
}

export function toastError({ message }: NotificationData) {
  return notifications.show({
    title: 'Error',
    color: 'red',
    message: message,
    autoClose: 10000,
    withCloseButton: true,
  })
}
