import { graphql } from '@/gql'
import type { ProviderColumn } from './types'

export const NON_DEFAULT_CATEGORIES = [
  'IMAGE',
  'SOCIAL_MEDIA',
  'WEB_ARTICLE',
  'PROPERTY',
  'HAZARD_PROPERTY',
  'HAZARD_RISK',
]

export const PROVIDER_COLUMN_TEMPLATE: ProviderColumn[] = [
  {
    label: 'Images',
    categories: ['IMAGE'],
    providers: [],
  },
  {
    label: 'News & Social',
    categories: ['SOCIAL_MEDIA', 'WEB_ARTICLE'],
    providers: [],
  },
  {
    label: 'Measurements',
    categories: ['*'],
    providers: [],
  },
  {
    label: 'Properties',
    categories: ['PROPERTY', 'HAZARD_PROPERTY', 'HAZARD_RISK'],
    providers: [],
  },
]

export const SourcesByUserQuery = graphql(`
  query SourcesByUser {
    me {
      id
      sources {
        nodes {
          provider
        }
      }
    }
  }
`)
