import React, { type ChangeEvent } from 'react'
import {
  ActionIcon,
  Checkbox,
  type CheckboxProps,
  Collapse,
  Group,
} from '@mantine/core'
import { Icon } from '@/components/lib/Icon'

export interface CheckboxItemProps {
  value: string
  label: string
}

export interface CheckboxGroupProps extends Omit<CheckboxProps, 'onChange'> {
  children?: React.ReactNode
  group: string
  items: CheckboxItemProps[]
  selected: string[]
  onChange: (value: string) => void
}

export function CheckboxGroup({
  children,
  group,
  items,
  selected,
  onChange,
}: CheckboxGroupProps) {
  const [isGroupOpen, setIsGroupOpen] = React.useState(false)
  const indeterminate = items.some(
    ({ value }) => selected.includes(value) && !selected.includes(group),
  )

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const isGroup = event.currentTarget.value === group
    if (isGroup && !isGroupOpen) {
      setIsGroupOpen(true)
    }
    onChange(event.currentTarget.value)
  }

  return (
    <Group gap={0}>
      <Group justify="normal" gap="xs" p="xs" w="100%">
        <Checkbox
          value={group}
          indeterminate={indeterminate}
          checked={selected.includes(group)}
          label={group}
          onChange={handleChange}
        />
        <ActionIcon
          m={0}
          size="xs"
          variant="transparent"
          c="gray"
          onClick={() => setIsGroupOpen((opened) => !opened)}
          style={{ cursor: 'pointer' }}
          title={isGroupOpen ? 'Close' : 'Open'}
        >
          <Icon
            name={isGroupOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
          />
        </ActionIcon>
      </Group>
      <Collapse in={isGroupOpen}>
        {items.map(({ value, label }) => (
          <Checkbox
            checked={selected.includes(value) || selected.includes(group)}
            key={value}
            value={value}
            label={label}
            onChange={handleChange}
            p="xs"
            ml="2.25rem"
          />
        ))}
        {children}
      </Collapse>
    </Group>
  )
}
