import type GeoJSON from 'geojson'
import type {
  ExpressionSpecification,
  PropertyValueSpecification,
} from 'mapbox-gl'
import type { MutableRefObject } from 'react'

type MapLayoutPropertyName =
  | 'fill-sort-key'
  | 'visibility'
  | 'line-cap'
  | 'line-join'
  | 'line-miter-limit'
  | 'line-round-limit'
  | 'line-sort-key'
  | 'line-z-offset'
  | 'symbol-placement'
  | 'symbol-spacing'
  | 'clip-layer-scope'

type MapLayoutPropertyValue =
  | number
  | ExpressionSpecification
  | PropertyValueSpecification<number>
  | 'visible'
  | 'none'

export const RETRY_DELAY_MS = 500
export const delay = (ms: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms)
  })
}
export const safeSetLayoutProperty = async (
  map: MutableRefObject<mapboxgl.Map | null>,
  layer: string,
  name: MapLayoutPropertyName,
  value: MapLayoutPropertyValue,
) => {
  try {
    while (map.current?.getLayer(layer) === undefined) {
      // eslint-disable-next-line no-await-in-loop
      await delay(RETRY_DELAY_MS)
    }
  } catch {
    /* empty */
  }

  map.current?.setLayoutProperty(layer, name, value)
}
export const safeSetMapSourceData = async (
  map: MutableRefObject<mapboxgl.Map | null>,
  sourceName: string,
  data: GeoJSON.FeatureCollection<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>,
) => {
  try {
    while (map.current?.getSource(sourceName) === undefined) {
      // eslint-disable-next-line no-await-in-loop
      await delay(RETRY_DELAY_MS)
    }
  } catch {
    /* empty */
  }
  const source = map.current?.getSource(sourceName) as mapboxgl.GeoJSONSource
  source.setData(data)
}

export const safeRemoveMapSourceData = async (
  map: MutableRefObject<mapboxgl.Map | null>,
  sourceName: string,
) => {
  try {
    while (map.current?.getSource(sourceName) === undefined) {
      // eslint-disable-next-line no-await-in-loop
      await delay(RETRY_DELAY_MS)
    }
  } catch {
    /* empty */
  }
  map.current?.removeSource(sourceName)
}
