import { postRequest } from '@/api/danti-requests'
import type { GoogleMapsAutocompleteResponse } from '@/api/google-maps/types'

export async function geocodeAddress(value: string) {
  const { data } = await postRequest<string>('/provider/googlemaps', {
    address: value,
    // eslint-disable-next-line camelcase
    api_request_type: 'geocode',
  })
  const response = JSON.parse(atob(data)) as GoogleMapsAutocompleteResponse
  return response.predictions
}
