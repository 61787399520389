import { SegmentedControl } from '@mantine/core'
import { VIEWS } from '@/features/TimelineView/constants'

import styles from './timeline-view.module.css'

export const ViewToggleComponent = ({
  selectedView,
  setSelectedView,
}: {
  selectedView: string
  setSelectedView: (view: string) => void
}) => {
  const views = [
    { label: 'Timelapse', value: VIEWS.timelapse },
    { label: 'Inspect', value: VIEWS.inspect },
    { label: 'Compare', value: VIEWS.compare },
  ]
  const activeView = views.findIndex((view) => view.value === selectedView)
  const indicatorClass =
    activeView === 0
      ? styles['indicator-left']
      : activeView === views.length - 1
        ? styles['indicator-right']
        : styles['indicator']

  return (
    <SegmentedControl
      value={selectedView}
      onChange={setSelectedView}
      classNames={{
        root: styles['root'],
        control: styles['control'],
        indicator: indicatorClass,
        innerLabel: styles['label'],
      }}
      data={views}
    />
  )
}
