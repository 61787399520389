import React from 'react'
import { Autocomplete, Box, Button, Group } from '@mantine/core'
import type { ContextModalProps } from '@mantine/modals'
import { postServiceRequest } from '@/api/danti-requests'
import { CheckboxGroup } from '@/components/lib/CheckboxGroup'
import { Icon } from '@/components/lib/Icon.tsx'
import { ErrorBoundary } from '@sentry/react'
import { groupBy } from 'lodash'

const analysisTypes = [
  {
    value: 'xviewDetector',
    label: 'xView General Detector (YOLOv8)',
    group: 'General Detectors',
  },
  {
    value: 'aircraft.danti',
    label: 'Danti aircraft detection v1.0 (YOLOv5)',
    group: 'Aircraft',
  },
]

const analysisGroups = Object.entries(groupBy(analysisTypes, 'group')).map(
  ([groupName, items]) => ({
    group: groupName,
    items: items.map(({ label, value }) => ({ label, value })),
  }),
)

export interface AnalyzeCollectionModalProps {
  collectionId: string
}

export function AnalyzeCollectionModal({
  context,
  id: modalId,
  innerProps: { collectionId },
}: ContextModalProps<AnalyzeCollectionModalProps>) {
  const [selectedAnalytics, setSelectedAnalytics] = React.useState<string[]>([
    'General Detectors',
  ])

  async function handleAnalyze() {
    await postServiceRequest('/download/analytic/', {
      // eslint-disable-next-line camelcase
      collection_id: collectionId,
    })
    context.closeModal(modalId)
  }

  function handleClick(value: string) {
    if (selectedAnalytics.includes(value)) {
      setSelectedAnalytics((selected) =>
        selected.filter((selectedValue) => selectedValue !== value),
      )
    } else {
      setSelectedAnalytics((selected) => [...selected, value])
    }
  }

  return (
    <>
      {/* No fallback necessary yet. We can just skip rendering the search. */}
      <ErrorBoundary fallback={<span />}>
        <Autocomplete
          leftSection={<Icon name="search" />}
          placeholder="Search for imagery analysis models"
          data={analysisGroups}
        />
      </ErrorBoundary>
      <Box
        mt="sm"
        mih="10rem"
        style={(theme) => ({
          overflowY: 'auto',
          border: `1px solid ${theme.colors.gray[2]}`,
        })}
      >
        {analysisGroups.map(({ group, items }) => (
          <CheckboxGroup
            key={group}
            group={group}
            items={items}
            selected={selectedAnalytics}
            onChange={handleClick}
          />
        ))}
      </Box>
      <Group justify="space-between" mt="md">
        <Button variant="subtle" onClick={() => context.closeModal(modalId)}>
          Cancel
        </Button>
        <Button onClick={() => void handleAnalyze()}>Start Analysis</Button>
      </Group>
    </>
  )
}
