import { ActionIcon, Group } from '@mantine/core'
import { Icon } from '@/components/lib/Icon'
import { useBackNavigation } from '@/features/AppRouting/hooks'
import { ResultActions } from '@/features/ResultActions'
import { MENU_OPTION_KEYS } from '@/features/ResultActions/constants'
import type { ActionMenuOption } from '@/features/ResultActions/types'

import styles from './view-template.module.css'

export interface SOVActionsProps<Type> {
  hideActions: ActionMenuOption[]
  result: Type
}

export function SOVActions<Type>({
  hideActions,
  result,
}: SOVActionsProps<Type>) {
  const onGoBack = useBackNavigation()
  return (
    <Group className={styles['action-row']} justify="space-between">
      <ActionIcon onClick={onGoBack} variant="subtle" c="dark">
        <Icon size={24} name="arrow_left_alt" />
      </ActionIcon>
      <ResultActions
        {...{
          result,
          hideActions: [...hideActions, MENU_OPTION_KEYS.viewDetails],
        }}
        iconClassName={styles['action-menu']}
      />
    </Group>
  )
}
