import { useActiveOrthoLayerGroupIds } from '@/stores/map-store'
import type {
  OrthoImageryLayer,
  OrthoImageryLayerGroup,
} from '@/utils/types/ortho-imagery-types'

export const useExpectedOrthoLayers = (
  orthoLayerGroups: OrthoImageryLayerGroup[],
): Record<string, OrthoImageryLayer> => {
  const activeOrthoLayerGroupIds = useActiveOrthoLayerGroupIds()
  return activeOrthoLayerGroupIds
    .map((groupId) => orthoLayerGroups.find((olg) => groupId === olg.id))
    .filter((olg): olg is OrthoImageryLayerGroup => !!olg)
    .flatMap((olg) => olg.layers)
    .reduce(
      (accumulator, current) => ({
        ...accumulator,
        ...(current.id && current.tileUrl && { [current.id]: current }),
      }),
      {},
    )
}
