import { useEffect, useRef } from 'react'
import { useDantiAuth } from '@/hooks/use-danti-auth'
import { apiUrl } from '@/utils/constants'

export const useTransformMapRequest = () => {
  const { user } = useDantiAuth()
  const userRef = useRef(user)
  useEffect(() => {
    userRef.current = user
  }, [user])
  return (urlString: string, resourceType: string) => {
    const url = new URL(urlString)
    const apiUrlUrl = new URL(apiUrl)
    const isExceptionResource = ['Tile', 'Image'].includes(resourceType)
    const shouldTransform =
      isExceptionResource && url.origin === apiUrlUrl.origin
    const request = { url: urlString }
    const headers = {
      Authorization: `Bearer ${userRef.current?.access_token}`,
      Accept: 'image/png',
    }
    return shouldTransform ? { ...request, headers } : request
  }
}
