import type { CardProps } from '@mantine/core'
import { getSortedPermits } from '@/api/results'
import { BentoCard } from '@/features/BentoResults/components'
import { HIDE_ACTIONS_BY_CATEGORY } from '@/features/ResultActions/constants'
import { RESULT_CATEGORY_COLORS } from '@/theme/colors'
import { ICONS, RESULT_CATEGORIES } from '@/utils/constants'
import type { AttomResult } from '@/utils/types/result-types'

const category = RESULT_CATEGORIES.ATTOM
const actionsToHide = HIDE_ACTIONS_BY_CATEGORY[category]

export interface AttomBentoCardProps extends CardProps {
  result: AttomResult
  subEntryIndex: number
  size: string
}

export const AttomBentoCard = ({
  result,
  subEntryIndex,
  ...props
}: AttomBentoCardProps) => {
  const permits = getSortedPermits(result)
  return (
    <BentoCard
      {...props}
      result={result}
      index={subEntryIndex}
      hideActions={actionsToHide}
      resultCategory={category}
      content={{
        heading: 'Permits',
        subHeading: permits[subEntryIndex].type,
        color: RESULT_CATEGORY_COLORS.attom,
      }}
      icon={ICONS.construction}
      totalResults={permits.length}
    />
  )
}

export default AttomBentoCard
