import { useEffect, useState } from 'react'
import { Group } from '@mantine/core'
import type { DataMetadataImageProvenanceResponse } from '@/api/data-metadata/types'
import { useDataMetadata } from '@/api/data-metadata/use-data-metadata'
import { Categories } from '../constants'
import type {
  EnabledCheckboxes,
  ImageProvidersCheckboxesProps,
  SelectedCheckboxes,
} from '../types'
import { Category } from './Category'

import styles from './image-providers-checkboxes.module.css'

const CATEGORY_ORDER = [
  Categories.PROVIDERS,
  Categories.SOURCES,
  Categories.PLATFORMS,
  Categories.SENSORS,
]

const DEFAULT_SELECTED_CHECKBOXES: SelectedCheckboxes = {
  providers: new Set(),
  sources: new Set(),
  platforms: new Set(),
  sensors: new Set(),
}

const DEFAULT_ENABLED_CHECKBOXES: EnabledCheckboxes = {
  sources: new Set(),
  platforms: new Set(),
  sensors: new Set(),
}

const convertValuesToArray = (setObject: Record<string, Set<string>>) =>
  Object.fromEntries(
    Object.entries(setObject).map(([key, value]) => [key, [...value]]),
  )

// We need to get the singular form of the category (ex. providers -> provider)
const getSingularCategory = (category: string) =>
  category.slice(0, -1) as keyof DataMetadataImageProvenanceResponse

// Implementation requires that the CATEGORY_ORDER dictates the hierarchy
// This function returns the child categories of a given category. ex. sources -> [platforms, sensors]
const getChildCategories = (category: Categories) => {
  const categoryIndex = CATEGORY_ORDER.indexOf(category)
  return CATEGORY_ORDER.slice(categoryIndex + 1)
}

export const ImageProvidersCheckboxes = ({
  providers,
  sources,
  platforms,
  sensors,
  setter,
  resetter,
}: ImageProvidersCheckboxesProps) => {
  const { data } = useDataMetadata()
  const [selectedCheckboxes, setSelectedCheckboxes] = useState(
    DEFAULT_SELECTED_CHECKBOXES,
  )
  const [enabledCheckboxes, setEnabledCheckboxes] = useState(
    DEFAULT_ENABLED_CHECKBOXES,
  )
  const [childrenOfHoveredCheckbox, setChildrenOfHoveredCheckbox] = useState(
    DEFAULT_ENABLED_CHECKBOXES,
  )

  // Reset state when resetter is updated
  useEffect(() => {
    setSelectedCheckboxes(DEFAULT_SELECTED_CHECKBOXES)
    setEnabledCheckboxes(DEFAULT_ENABLED_CHECKBOXES)
    setChildrenOfHoveredCheckbox(DEFAULT_ENABLED_CHECKBOXES)
  }, [resetter])

  // This function dictates which child checkboxes should be enabled based on the selected checkboxes
  const updateEnabledChildCheckboxes = (
    updatedCheckboxes: SelectedCheckboxes,
    category: Categories,
    isHover: boolean = false,
  ) => {
    const childCategories = getChildCategories(category)
    const updatedEnabledChildren = { ...enabledCheckboxes }

    // Reset all child categories to an empty set
    childCategories.forEach((childCategory) => {
      updatedEnabledChildren[childCategory as keyof EnabledCheckboxes] =
        new Set()
    })

    // Go through all image provenance relations to find which child checkboxes should be enabled
    data?.imageProvenanceRelations.forEach((relation) => {
      const singularCategory = getSingularCategory(category)

      // Check if the value for the category in the relation exists in the list of selected checkboxes
      // If it exists, we go through the child categories and add them to the list of enabled checkboxes
      if (updatedCheckboxes[category].has(relation[singularCategory] || '')) {
        childCategories.forEach((childCategory) => {
          const singularChildCategory = getSingularCategory(childCategory)

          // This check is required because Platform can have a value of null
          // If value is not null, we add it to the list of enabled checkboxes for that category
          if (relation[singularChildCategory] !== null) {
            updatedEnabledChildren[
              childCategory as keyof EnabledCheckboxes
            ].add(relation[singularChildCategory])
          }
        })
      }
    })

    // This function can also be triggered when hovering over a checkbox
    if (isHover) {
      setChildrenOfHoveredCheckbox(updatedEnabledChildren)
    } else {
      setEnabledCheckboxes(updatedEnabledChildren)
    }
  }

  const handleSelectAllClick = (checked: boolean, category: Categories) => {
    if (!data) return

    const childCategories = getChildCategories(category)
    const updatedSelectedCheckboxes = {
      ...selectedCheckboxes,
    }

    if (checked) {
      // select all items in that category
      updatedSelectedCheckboxes[category] = new Set(
        data.imageProvenance[category],
      )

      // go through child categories and select all items
      childCategories.forEach((childCategory) => {
        updatedSelectedCheckboxes[childCategory] = new Set(
          data.imageProvenance[childCategory],
        )
      })
    } else {
      // deselect all items in that category
      updatedSelectedCheckboxes[category] = new Set()

      // go through child categories and deselect all items
      childCategories.forEach((childCategory) => {
        updatedSelectedCheckboxes[childCategory] = new Set()
      })
    }

    setSelectedCheckboxes(updatedSelectedCheckboxes)
    setter(convertValuesToArray(updatedSelectedCheckboxes))
    updateEnabledChildCheckboxes(updatedSelectedCheckboxes, category)
  }

  const handleItemCheckboxChange = (
    checked: boolean,
    category: Categories,
    item: string,
  ) => {
    if (!data) return

    let updatedSelectedCheckboxes: SelectedCheckboxes

    if (checked) {
      updatedSelectedCheckboxes = {
        ...selectedCheckboxes,
        [category]: new Set(selectedCheckboxes[category]).add(item),
      }
      setSelectedCheckboxes(updatedSelectedCheckboxes)
    } else {
      const updatedCategorySet = new Set(selectedCheckboxes[category])
      updatedCategorySet.delete(item)
      updatedSelectedCheckboxes = {
        ...selectedCheckboxes,
        [category]: updatedCategorySet,
      }
      setSelectedCheckboxes(updatedSelectedCheckboxes)
    }

    setter(convertValuesToArray(updatedSelectedCheckboxes))
    updateEnabledChildCheckboxes(updatedSelectedCheckboxes, category)
  }

  const handleHover = (category: Categories, item?: string) => {
    if (item) {
      updateEnabledChildCheckboxes(
        { ...DEFAULT_SELECTED_CHECKBOXES, [category]: new Set([item]) },
        category,
        true,
      )
    } else {
      setChildrenOfHoveredCheckbox(DEFAULT_ENABLED_CHECKBOXES)
    }
  }

  return (
    <Group className={styles.imageProvidersContainer}>
      {data && (
        <>
          <Category
            items={data.imageProvenance.providers}
            selectedItems={new Set(providers)}
            enabledItems={new Set(data.imageProvenance.providers)}
            category={Categories.PROVIDERS}
            labelsList={data.providers}
            handleSelectAllClick={handleSelectAllClick}
            handleItemCheckboxChange={handleItemCheckboxChange}
            handleHover={handleHover}
          />
          <Category
            items={data.imageProvenance.sources}
            selectedItems={new Set(sources)}
            enabledItems={
              new Set([
                ...enabledCheckboxes.sources,
                ...childrenOfHoveredCheckbox.sources,
              ])
            }
            category={Categories.SOURCES}
            labelsList={data.sources}
            handleSelectAllClick={handleSelectAllClick}
            handleItemCheckboxChange={handleItemCheckboxChange}
            handleHover={handleHover}
          />
          <Category
            items={data.imageProvenance.platforms}
            selectedItems={new Set(platforms)}
            enabledItems={
              new Set([
                ...enabledCheckboxes.platforms,
                ...childrenOfHoveredCheckbox.platforms,
              ])
            }
            category={Categories.PLATFORMS}
            labelsList={data.platform}
            handleSelectAllClick={handleSelectAllClick}
            handleItemCheckboxChange={handleItemCheckboxChange}
            handleHover={handleHover}
          />
          <Category
            items={data.imageProvenance.sensors}
            selectedItems={new Set(sensors)}
            enabledItems={
              new Set([
                ...enabledCheckboxes.sensors,
                ...childrenOfHoveredCheckbox.sensors,
              ])
            }
            category={Categories.SENSORS}
            labelsList={data.sensors}
            handleSelectAllClick={handleSelectAllClick}
            handleItemCheckboxChange={handleItemCheckboxChange}
            handleHover={handleHover}
          />
        </>
      )}
    </Group>
  )
}
