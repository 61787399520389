import { Button, Checkbox, Menu } from '@mantine/core'
import type { CategoryTypes } from '@/utils/types/raw-result-types'

export interface FilterCategoryTypeCellProps {
  label: string
  selectedCategories?: CategoryTypes[]
  onCategoryToggle: (categoryTypes: CategoryTypes) => void
  filterItems: {
    value: CategoryTypes
    label: string
  }[]
}

export function FilterCategoryTypeCell({
  label,
  selectedCategories,
  onCategoryToggle,
  filterItems,
}: FilterCategoryTypeCellProps) {
  return (
    <Menu shadow="md" width={200}>
      <Menu.Target>
        <Button size="xs" radius="xl" bg="#FAFAFA" style={{ color: 'black' }}>
          {label}
        </Button>
      </Menu.Target>

      <Menu.Dropdown p="sm">
        {selectedCategories &&
          filterItems.map(({ value, label }) => (
            <Checkbox
              key={label}
              p="xs"
              label={label}
              onChange={() => onCategoryToggle(value)}
              checked={selectedCategories.includes(value)}
            />
          ))}
      </Menu.Dropdown>
    </Menu>
  )
}
