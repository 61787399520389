import { useLocalFeatureFlags } from '@/hooks/use-local-feature-flags'
import type { FeatureFlagKeys } from '@/utils/constants'
import { useFeatureFlagEnabled } from 'posthog-js/react'

/**
 * @description Get the value of a feature flag.
 *
 *   Prioritizes local values over remote values.
 *
 *   See `README.md` for details on implementing new flags.
 * @param {string} feature - The feature flag key directly from PostHog
 */
export function useFeatureFlag(feature: FeatureFlagKeys) {
  const localValue = useLocalFeatureFlags()[feature]
  const remoteValue = useFeatureFlagEnabled(feature)

  return localValue ?? remoteValue ?? false
}
