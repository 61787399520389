import { Image } from '@mantine/core'

import styles from './hero.module.css'

export type StaticImageHeroProps = {
  image: string
  title: string
}
export function StaticImageHero({ image, title }: StaticImageHeroProps) {
  return (
    <Image
      className={styles['static-image-hero']}
      src={image}
      alt={`${title} Hero Image`}
    />
  )
}
