import { useEffect, useMemo } from 'react'
import { emitter, EVENTS } from '@/events'
import { makeOrthoLayer } from '@/features/DantiMap/utils/ortho-imagery'
import { useSortPreference } from '@/stores/queries-store'
import { usePostfilterImageResults } from '@/stores/results-filter-store'
import type {
  OrthoImageryLayer,
  OrthoImageryLayerGroup,
} from '@/utils/types/ortho-imagery-types'
import * as Sentry from '@sentry/react'
import * as turf from '@turf/turf'
import type { MultiPolygon } from 'geojson'
import { groupBy, sortBy } from 'lodash'
import { useDeepCompareMemoize } from 'use-deep-compare-effect'

export const useOrthoImagerySources = () => {
  const imageResults = usePostfilterImageResults()
  const sortPreference = useSortPreference()

  const orthoImages: OrthoImageryLayer[] = imageResults
    .map((oi) => makeOrthoLayer(oi))
    .filter(Boolean)

  const orthoLayers: OrthoImageryLayer[] = useDeepCompareMemoize([
    ...orthoImages,
  ])
  // Creates Mosiac of Layers
  const orthoLayerGroups: OrthoImageryLayerGroup[] = useMemo(() => {
    const groups = groupBy(orthoLayers, (ol) => ol.groupKey)
    const sortedResults = sortBy(
      Object.keys(groups).map((groupKey) => {
        const layers = groups[groupKey]
        const skySatCollectLayer = layers.find((l) =>
          l.tileUrl.includes('SkySatCollect'),
        )
        if (skySatCollectLayer) {
          return {
            source: skySatCollectLayer.source,
            formattedSource: skySatCollectLayer.formattedSource,
            layers: [skySatCollectLayer],
            id: groupKey,
            documentId: skySatCollectLayer.documentId,
            authoredOn: skySatCollectLayer.authoredOn,
            score: skySatCollectLayer.score,
            geometry: skySatCollectLayer.geometry,
          }
        }
        return {
          source: layers[0].source,
          formattedSource: layers[0].formattedSource,
          layers,
          id: groupKey,
          documentId: layers[0].documentId,
          authoredOn: layers[0].authoredOn,
          score: layers[0].score,
          geometry: layers
            .map((l) => l.geometry)
            .reduce((result, nextGeometry) => {
              try {
                return turf.union(
                  turf.featureCollection([
                    turf.feature(nextGeometry),
                    turf.feature(result),
                  ]),
                )?.geometry as MultiPolygon
              } catch (error) {
                Sentry.captureException(error, { extra: { layers, groupKey } })
                console.error('Failed to union geometries', error)
                return result
              }
            }, turf.multiPolygon([]).geometry),
        }
      }),
      (olg) => olg[sortPreference],
    )
    return sortedResults.reverse()
  }, [orthoLayers, sortPreference])

  useEffect(() => {
    emitter.emit(EVENTS.map.orthoLayersUpdated, {
      orthoLayers,
      orthoLayerGroups,
    })
  }, [orthoLayers, orthoLayerGroups])

  return {
    orthoLayerGroups,
    orthoLayers,
  }
}
