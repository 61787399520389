import { Button, Divider, Group, Menu, Text } from '@mantine/core'
import type { ShareRoleType } from '@/api/collections'
import { Icon } from '@/components/lib/Icon'

const labelByShareRoleType: Record<ShareRoleType, string> = {
  viewer: 'Can View',
  editor: 'Can Edit',
  owner: 'Can Share',
  creator: 'Can Share',
} as const

interface ShareRoleSelectProps {
  role: ShareRoleType
  setRole: (role: ShareRoleType) => void
  disabled?: boolean
  onRevokeAccess?: () => void
}

export function ShareRoleSelect({
  role,
  setRole,
  disabled = false,
  onRevokeAccess,
}: ShareRoleSelectProps) {
  if (disabled) {
    return (
      <Text size="xs" style={{ color: '#00000099', cursor: 'default' }} pr={38}>
        {labelByShareRoleType[role]}
      </Text>
    )
  }

  return (
    <Menu shadow="md" offset={-32}>
      <Menu.Target>
        <Button
          variant="transparent"
          color="black"
          rightSection={<Icon name="arrow_drop_down" />}
        >
          <Text size="xs" style={{ fontWeight: 'bold' }}>
            {labelByShareRoleType[role]}
          </Text>
        </Button>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item key={role} p={6} pr={0}>
          <Group gap={8}>
            <Text size="xs" style={{ fontWeight: 'bold' }}>
              {labelByShareRoleType[role]}
            </Text>
            <Icon name="arrow_drop_down" />
          </Group>
        </Menu.Item>
        {Object.keys(labelByShareRoleType)
          .filter((x) => x != role && x != 'creator')
          .map((role) => (
            <Menu.Item
              key={role}
              color="black"
              onClick={() => setRole(role as ShareRoleType)}
              p={6}
            >
              <Text size="xs">
                {labelByShareRoleType[role as ShareRoleType]}
              </Text>
            </Menu.Item>
          ))}
        {onRevokeAccess && (
          <>
            <Divider py={2} />
            <Menu.Item color="black" p={6} onClick={onRevokeAccess}>
              <Text size="xs">Revoke Access</Text>
            </Menu.Item>
          </>
        )}
      </Menu.Dropdown>
    </Menu>
  )
}
