import * as React from 'react'
import { getSortedRiskKeys } from '@/api/results'
import type { HazardRiskResult, ResultWithId } from '@/utils/types/result-types'

export const useHazardRiskList = (rawItem: ResultWithId) => {
  const item = rawItem as HazardRiskResult
  const allRisks = item.properties.risks
  const sortedRisks = getSortedRiskKeys(item)
  const riskList = React.useMemo(
    () => sortedRisks.map((x) => ({ ...allRisks[x], key: x })),
    [allRisks, sortedRisks],
  )

  return riskList
}
