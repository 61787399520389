import { type MouseEventHandler, useRef, useState } from 'react'
import { BAR_FILL_COLOR } from '@/features/ResultsTimeline/constants'
import type {
  TimelineDoctypes,
  TimelineHistogramConfig,
  VisualizationDatum,
} from '@/features/ResultsTimeline/types'
import { useSetTimelineSelectedIds } from '@/stores/results-filter-store'
import type BaseBrush from '@visx/brush/lib/BaseBrush'
import type { Bounds, Point } from '@visx/brush/lib/types'
import type { BarGroupBar, SeriesPoint } from '@visx/shape/lib/types'
import { isNumber, max, min, range, uniq } from 'lodash'

export const FOCUS_HIST_HEIGHT = 60
export const INNER_MARGIN = 10
export const CONTEXT_HIST_HEIGHT = 25
export const SVG_TOP_PADDING = 20

export type ResultTimelineBarFillHandler = (
  bar: Omit<BarGroupBar<TimelineDoctypes>, 'value' | 'key'> & {
    bar: SeriesPoint<VisualizationDatum>
    key: TimelineDoctypes
  },
) => string

export const useTimelineInteractions = ({
  focusHistogramConfig,
}: {
  focusHistogramConfig: TimelineHistogramConfig
}) => {
  const [selectionStartIndex, setSelectionStartIndex] = useState<
    number | null
  >()
  const [selectionEndIndex, setSelectionEndIndex] = useState<number | null>()
  const [brushStart, setBrushStart] = useState<Point | null>(null)
  const [selectedBinIndexes, setSelectedBinIndexes] = useState<number[]>([])
  const setTimelineSelectedIds = useSetTimelineSelectedIds()

  const brushRef = useRef<BaseBrush>(null)

  const syncSelections = () => {
    if (selectedBinIndexes.length > 0) {
      const ids = focusHistogramConfig.mergedHistogramResult
        .filter((_, index) => selectedBinIndexes.includes(index))
        .flatMap((bin) => bin.map((r) => r.id))

      setTimelineSelectedIds(ids)
      return
    }

    if (selectionStartIndex && selectionEndIndex) {
      const ids = focusHistogramConfig.mergedHistogramResult
        .filter(
          (_, index) =>
            index >= selectionStartIndex && index <= selectionEndIndex,
        )
        .flatMap((bin) => bin.map((r) => r.id))
      setTimelineSelectedIds(ids)
      return
    }

    setTimelineSelectedIds(null)
  }

  const clearSelection = () => {
    setSelectionStartIndex(null)
    setSelectionEndIndex(null)
    if (brushRef.current) {
      brushRef.current.reset()
    }
  }

  const onBarClick: (binIndex: number) => MouseEventHandler<SVGRectElement> =
    (binIndex) => (event) => {
      clearSelection()

      if (event.shiftKey && selectedBinIndexes.length > 0) {
        const start =
          min(selectedBinIndexes)! < binIndex
            ? min(selectedBinIndexes)!
            : binIndex
        const end =
          max(selectedBinIndexes)! > binIndex
            ? max(selectedBinIndexes)!
            : binIndex + 1
        const newBinIndexes = range(start, end)
        setSelectedBinIndexes(uniq([...selectedBinIndexes, ...newBinIndexes]))
        syncSelections()
        return
      }

      if (event.metaKey) {
        if (selectedBinIndexes.includes(binIndex)) {
          setSelectedBinIndexes(
            selectedBinIndexes.filter((index) => index !== binIndex),
          )
          syncSelections()
        } else {
          setSelectedBinIndexes([...selectedBinIndexes, binIndex])
        }
        return
      }

      if (selectedBinIndexes.includes(binIndex)) {
        setSelectedBinIndexes([])
        syncSelections()
      } else {
        setSelectedBinIndexes([binIndex])
        syncSelections()
      }
    }

  const onBrushChange = (domain: Bounds | null) => {
    if (!domain) {
      setSelectionStartIndex(null)
      setSelectionEndIndex(null)
      syncSelections()
      return
    }

    if (!brushStart) {
      return
    }

    const scaleStep = focusHistogramConfig.dateBandScale.step()
    const startIndex = Math.floor(domain.x0 / scaleStep)
    const endIndex = Math.floor(domain.x1 / scaleStep)

    setSelectionStartIndex(startIndex)
    setSelectionEndIndex(endIndex)
    setSelectedBinIndexes([])
    syncSelections()
  }

  const makeBarFillColor: ResultTimelineBarFillHandler = (
    bar: Omit<BarGroupBar<TimelineDoctypes>, 'value' | 'key'> & {
      bar: SeriesPoint<VisualizationDatum>
      key: TimelineDoctypes
    },
  ): string => {
    const isIndividuallySelected = selectedBinIndexes.includes(bar.index)
    const isRangeSelected =
      isNumber(selectionStartIndex) &&
      isNumber(selectionEndIndex) &&
      bar.index - 1 >= selectionStartIndex &&
      bar.index - 1 <= selectionEndIndex

    return isIndividuallySelected || isRangeSelected
      ? BAR_FILL_COLOR[bar.key].selected
      : BAR_FILL_COLOR[bar.key].normal
  }

  return {
    onBrushStart: (start: Point) => setBrushStart(start),
    onBrushEnd: () => setBrushStart(null),
    onChange: onBrushChange,
    onClick: () => setSelectedBinIndexes([]),
    innerRef: brushRef,
    clearSelection,
    makeOnBarClick: onBarClick,
    makeBarFillColor: makeBarFillColor,
    pointerEvents: brushStart === null,
  }
}
