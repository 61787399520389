import { Stack, Text } from '@mantine/core'
import { MarkdownLinkWrapper } from '@/features/ExternalLink/components/MarkdownLinkWrapper.tsx'
import { InsightFeedbackPanel } from '@/features/InsightPanel/components/InsightFeedback'
import type { InsightsPanelProps } from '@/features/InsightPanel/types'
import { useFeatureFlag } from '@/hooks/use-feature-flag'
import { FEATURE_FLAG_VALUES } from '@/utils/constants'
import ReactMarkdown from 'react-markdown'

import styles from './insight-panel.module.css'

export function InsightPanel({ result }: InsightsPanelProps) {
  const isFeedbackEnabled = useFeatureFlag(FEATURE_FLAG_VALUES.insightFeedback)

  return (
    <Stack component="div" p={0}>
      <Text className={styles.markdown} span>
        <ReactMarkdown
          components={{
            a: ({
              href,
              children,
              ...props
            }: React.AnchorHTMLAttributes<HTMLAnchorElement>) => (
              <MarkdownLinkWrapper href={href || ''} {...props}>
                {children}
              </MarkdownLinkWrapper>
            ),
          }}
        >
          {result.description}
        </ReactMarkdown>
      </Text>
      {isFeedbackEnabled && <InsightFeedbackPanel />}
    </Stack>
  )
}
