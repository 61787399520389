import { avatarColorOptions } from '@/theme/colors'
import { createStore } from '@/utils/create-store.ts'
import type { User } from 'oidc-client-ts'

export interface UserValues {
  avatarBackgroundColor: string
  id: string | undefined
  username: string
  firstName: string
  lastName: string
  email: string
}
export interface UserActions {
  setProfile: (authUser?: User | null) => void
}

export interface UserState extends UserValues, UserActions {}

export const initialState = {
  avatarBackgroundColor:
    avatarColorOptions[Math.floor(Math.random() * avatarColorOptions.length)],
  id: undefined,
  username: '',
  firstName: '',
  lastName: '',
  email: '',
}

export const useUserStore = createStore<UserState>({
  storageKey: 'UserStore',
  version: 0.1,
  initialState,
  actions: (set): Partial<UserState> => ({
    setProfile: (authUser) => {
      const profile: Partial<User['profile']> = authUser?.profile ?? {}
      set({
        id: profile.sub,
        username: profile.preferred_username ?? '',
        firstName: profile.given_name ?? '',
        lastName: profile.family_name ?? '',
        email: profile.email ?? '',
      })
    },
  }),
})
