import type { SOVHeroContentProps } from './HeroContent'

export function isContentAvailable<T>(props: SOVHeroContentProps<T>) {
  const {
    icon,
    staticImage,
    geometry,
    svgIcon,
    socialIconName,
    details,
    fallbackIcon,
    vexcelImage,
    mapImage,
    thumbnailImage,
  } = props

  return [
    geometry,
    staticImage,
    vexcelImage,
    mapImage,
    thumbnailImage,
    fallbackIcon,
    icon,
    svgIcon,
    socialIconName,
    details,
  ].some(Boolean)
}
