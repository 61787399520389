import { useState } from 'react'
import {
  Button,
  Chip,
  Group,
  Radio,
  Stack,
  Text,
  TextInput,
} from '@mantine/core'
import { DatePickerInput } from '@mantine/dates'
import { Icon } from '@/components/lib/Icon'
import { type DateValue, TimePreset } from '@/utils/types/filter-types'

import styles from './query-and-data.module.css'

type TimePeriod = [DateValue, DateValue]

type Filters = {
  query: string
  name: string
  resultTypes: string[]
  timePeriod: TimePreset
  customTimePeriod: TimePeriod
}

const RESULT_TYPES = [
  'Images',
  'News',
  'Social Media',
  'Ships',
  'Fire',
  'Weather',
]

const TIME_OPTIONS = [
  { label: 'Any time range', value: TimePreset.None },
  { label: 'Past 24 hours', value: TimePreset.Last24Hours },
  { label: 'Past 7 days', value: TimePreset.Last7Days },
  { label: 'Past 30 days', value: TimePreset.Last30Days },
  { label: 'Past 90 days', value: TimePreset.Last90Days },
  { label: 'Custom', value: TimePreset.Custom },
]

const DEFAULT_FILTERS: Filters = {
  query: '',
  name: '',
  resultTypes: RESULT_TYPES,
  timePeriod: TimePreset.None,
  customTimePeriod: [null, null],
}

export function QueryAndData() {
  const [filters, setFilters] = useState(DEFAULT_FILTERS)

  return (
    <Stack gap="md">
      <TextInput
        label="Search query"
        labelProps={{ className: styles.boldLabel }}
        placeholder="Add an optional question"
        leftSection={<Icon name="search" />}
        value={filters.query}
        onChange={({ currentTarget }) =>
          setFilters({ ...filters, query: currentTarget.value })
        }
      />
      <TextInput
        label="Name this Saved Search"
        labelProps={{ className: styles.boldLabel }}
        placeholder="Give the Saved Search folder a name"
        value={filters.name}
        onChange={({ currentTarget }) =>
          setFilters({ ...filters, name: currentTarget.value })
        }
      />
      <Stack gap="xs">
        <Text size="sm" fw={600} lh="h1">
          Edit your search result types
        </Text>
        <Text size="xs" c="dimmed">
          Uncheck a data type to exclude it from your search results
        </Text>
        <Group mt="xs" gap="sm">
          <Chip.Group
            multiple
            value={filters.resultTypes}
            onChange={(updatedValues) =>
              setFilters({ ...filters, resultTypes: updatedValues })
            }
          >
            {RESULT_TYPES.map((type) => (
              <Chip
                key={type}
                variant="light"
                size="sm"
                radius="sm"
                icon={<Icon name="check" size={16} weight={400} />}
                value={type}
              >
                {type}
              </Chip>
            ))}
          </Chip.Group>
        </Group>
      </Stack>
      <Stack gap="xs">
        <Text size="sm" fw={600} lh="h1">
          Select a time range
        </Text>
        <Text size="xs" c="dimmed">
          By default, Danti will give you 1000 of the most relevant results from
          each data type. You can narrow the range with the options below.
        </Text>
        <Group gap="sm" mt="xs">
          {TIME_OPTIONS.map(({ label, value }) => (
            <Radio
              key={label}
              label={label}
              size="xs"
              classNames={{
                label: styles.optionInputLabel,
              }}
              checked={filters.timePeriod === value}
              onChange={() => setFilters({ ...filters, timePeriod: value })}
            />
          ))}
        </Group>
      </Stack>
      {filters.timePeriod === TimePreset.Custom && (
        <DatePickerInput
          type="range"
          label="Custom date range"
          labelProps={{ className: styles.boldLabel }}
          placeholder="Select a date"
          value={filters.customTimePeriod}
          onChange={(dates) =>
            setFilters({ ...filters, customTimePeriod: dates })
          }
          leftSection={<Icon name="calendar_clock" />}
          leftSectionPointerEvents="none"
          rightSection={<Icon name="keyboard_arrow_down" />}
          rightSectionPointerEvents="none"
        />
      )}
      <Button
        variant="transparent"
        className={styles.resetButton}
        onClick={() => setFilters(DEFAULT_FILTERS)}
      >
        Reset these filters to default
      </Button>
    </Stack>
  )
}
