import { HandleRedirect } from '@/features/AppRouting/components'
import { useHasActiveSubscription } from '@/hooks/use-has-active-subscription.ts'
import type { PropsWithChildren, ReactNode } from 'react'

export function RequireSubscription({
  children,
}: PropsWithChildren): ReactNode {
  const { hasActiveSubscription, isOwner, loading } = useHasActiveSubscription()

  if (loading) {
    return null
  }

  if (!hasActiveSubscription && isOwner) {
    return <HandleRedirect to="/plan-selection" />
  }

  if (!hasActiveSubscription && !isOwner) {
    return <HandleRedirect to="/access-denied" />
  }

  return children
}
