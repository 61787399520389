import { type MutableRefObject, useMemo, useState } from 'react'
import {
  GROUP_EXTENT_SOURCE_ID,
  RESULT_SOURCE_PREFIX,
} from '@/features/DantiMap/constants'
import { useMapLayers, useOrthoImagerySources } from '@/features/DantiMap/hooks'
import { useHoveredLayerId } from '@/stores/map-store'
import * as turf from '@turf/turf'
import type { Feature, MultiPolygon } from 'geojson'
import { useDeepCompareMemoize } from 'use-deep-compare-effect'
import { loadResult } from './load-result'
import { useExpectedOrthoLayers } from './use-expected-ortho-layers'

const createSourceId = (id: string) => `${RESULT_SOURCE_PREFIX}${id}`

export const useOrthoLayers = (
  map: MutableRefObject<mapboxgl.Map | null>,
  showExtents?: boolean,
) => {
  const orthoLayerGroups = useOrthoImagerySources().orthoLayerGroups || []
  const hoveredLayerId = useHoveredLayerId()

  let highlightData: Feature<MultiPolygon> | undefined
  if (hoveredLayerId) {
    const group = orthoLayerGroups.find((lg) => lg.id === hoveredLayerId)
    highlightData = turf.feature(
      group?.geometry ?? null,
    ) as Feature<MultiPolygon>
  }

  const expectedOrthoLayers = useExpectedOrthoLayers(orthoLayerGroups)
  const [imageUrls, setImageUrls] = useState<Record<string, string>>({})

  const layerPrereqs = useDeepCompareMemoize({
    expectedOrthoLayers,
    imageUrls,
  })

  const layers = useMemo(() => {
    // Filter out the layers that are already on the map and add the new ones
    const loadedLayers = Object.entries(expectedOrthoLayers)
      .map(([_orthoLayerId, orthoLayer]) => {
        const sourceId = createSourceId(orthoLayer.id)
        return loadResult({
          orthoLayer,
          sourceId,
          imageUrls,
          setImageUrls,
        })
      })
      .filter((layer) => layer !== null)
    return Object.fromEntries(
      loadedLayers.map((layer) => [layer.layerId as string, layer]),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layerPrereqs])

  useMapLayers({
    map: map.current,
    layers: {
      ...layers,
      ...(highlightData && {
        [`${GROUP_EXTENT_SOURCE_ID}_layer`]: {
          type: 'line',
          sourceId: GROUP_EXTENT_SOURCE_ID,
          lineColor: '#11CC00',
          lineWidth: 2,
          data: highlightData,
          isVisible: showExtents,
        },
      }),
    },
  })
}
