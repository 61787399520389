import React from 'react'
import { Box, Button, Divider, Group, Stack, TextInput } from '@mantine/core'
import { useUsers } from '@/api/user'
import { Icon } from '@/components/lib/Icon'
import { openInviteUserModal } from '@/features/AppLayout/Modals'
import { useUserRole } from '@/hooks/use-user-role'
// import { Pager } from '@/components/pagination'

export function UserAdminHeader() {
  const currentUser = useUserRole()
  const {
    // totalCount,
    // pageSize,
    // currentPage,
    // nextPage,
    // previousPage,
    setSearchTerm,
    searchTerm,
  } = useUsers()

  const canInvite = React.useMemo(() => {
    if (!currentUser) {
      return false
    }

    const currentUserRole = currentUser.role

    return currentUserRole == 'Admin' || currentUserRole == 'Owner'
  }, [currentUser])

  return (
    <Stack w="100%">
      <Group>
        <TextInput
          placeholder="Search"
          withAsterisk
          leftSection={<Icon name="search" />}
          value={searchTerm}
          onChange={(event) => setSearchTerm(event.target.value)}
          miw={300}
        />
        {canInvite && (
          <Button
            leftSection={<Icon name="add" />}
            variant="outline"
            color="blue"
            onClick={() => openInviteUserModal()}
          >
            INVITE A USER
          </Button>
        )}
        <Box flex={1} />
        {/* <Pager
          currentPage={currentPage}
          pageSize={pageSize}
          totalCount={totalCount}
          nextPage={nextPage}
          previousPage={previousPage}
        /> */}
      </Group>
      <Divider />
    </Stack>
  )
}
