import type { Feature, Polygon } from 'geojson'
import type {
  ImageLayer,
  ResultsByDay,
  SortedAndGroupedTimelapseResults,
} from './types'

export const initialTimelapseResults: SortedAndGroupedTimelapseResults = {
  bounds: {
    polygon: {} as Feature<Polygon>,
    bbox: [],
  },
  sortedImageLayers: [],
}
export const sourceIdPrefix = 'timelapse_image_'

export const initialSortedResults = { sortedResults: [], resultsByDay: {} } as {
  sortedResults: ImageLayer[]
  resultsByDay: ResultsByDay
}
