import { Anchor, Modal } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { GeometryStaticDisplay } from '@/features/GeometryStaticDisplay'
import type { MultiPolygon, Point, Polygon } from 'geojson'

import styles from './single-object-view.module.css'

export interface ImageDetailsModalProps {
  geometry: Point | Polygon | MultiPolygon
  details: { label: string; value: React.ReactNode }[]
}

export function ImageDetailsModal({
  geometry,
  details,
}: ImageDetailsModalProps) {
  const [opened, { close, toggle }] = useDisclosure()
  return (
    <>
      <Anchor onClick={toggle}>Show details</Anchor>
      <Modal size="auto" opened={opened} onClose={close} title="Image details">
        <div className={styles['sov-image-modal']}>
          {opened && (
            <GeometryStaticDisplay
              geometry={geometry}
              className={styles['sov-geometry']}
            />
          )}
          <div className={styles['sov-image-modal-details-container']}>
            {details.map(({ label, value }) => (
              <div key={label} className={styles['sov-image-modal-details']}>
                <div className={styles['sov-image-modal-details-label']}>
                  {label}
                </div>
                <div className={styles['sov-image-modal-details-value']}>
                  {value}
                </div>
              </div>
            ))}
          </div>
        </div>
      </Modal>
    </>
  )
}
