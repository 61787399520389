import { SEARCH_CATEGORIES } from '@/api/results/constants'
import { ROUTES } from '@/utils/constants'
import {
  isFireResult,
  isImageryResult,
  isPublicationResult,
  isShipResult,
  isSocialMediaResult,
} from '@/utils/types/result-types'

export const ROUTE_CATEGORIES = {
  [ROUTES.fires]: SEARCH_CATEGORIES.FIRE,
  [ROUTES.ships]: SEARCH_CATEGORIES.SHIP,
  [ROUTES.news]: SEARCH_CATEGORIES.PUBLICATION,
  [ROUTES.images]: SEARCH_CATEGORIES.IMAGE,
  [ROUTES.social]: SEARCH_CATEGORIES.SOCIAL_MEDIA,
}

export const TYPE_CONFIG = {
  [SEARCH_CATEGORIES.FIRE]: {
    title: 'All Fires',
    isolateType: isFireResult,
  },
  [SEARCH_CATEGORIES.SHIP]: {
    title: 'All Ships',
    isolateType: isShipResult,
  },
  [SEARCH_CATEGORIES.IMAGE]: {
    title: 'All Images',
    isolateType: isImageryResult,
  },
  [SEARCH_CATEGORIES.PUBLICATION]: {
    title: 'All News',
    isolateType: isPublicationResult,
  },
  [SEARCH_CATEGORIES.SOCIAL_MEDIA]: {
    title: 'All Social',
    isolateType: isSocialMediaResult,
  },
} as const
