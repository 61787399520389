export type Mutation = {
  mutateAsync: (
    params: any,
    options: { onSuccess: () => void },
  ) => Promise<unknown>
}
export const makeApiEvent =
  <T>(mutation: Mutation, { onSuccess }: { onSuccess: () => void }) =>
  async (params: T) => {
    const response = await mutation.mutateAsync(params, { onSuccess })
    return response
  }

export const normalizeDownloadParams = (param: string) => {
  // Remove whitespace characters and make it lowercase
  return param.replaceAll(/\s+/g, '_').toLowerCase()
}
export const downloadFromURL = (url: string, downloadName: string) => {
  const a = document.createElement('a')
  a.style.display = 'none'
  a.href = url
  a.download = downloadName
  document.body.append(a)
  a.click()
  window.URL.revokeObjectURL(url)
  a.remove()
}
