import { Icon } from '@/components/lib/Icon.tsx'
import type { SymbolName } from '@/utils/types/material-icons.ts'

import styles from './bento-card.module.css'

export interface BentoCardIconParams {
  highlightIcon?: boolean
  icon?: string
  svgIcon?: React.ReactNode
  socialIcon?: React.ReactNode
}
export const BentoCardIcon = ({
  icon,
  highlightIcon,
  svgIcon,
  socialIcon,
}: BentoCardIconParams) => {
  const iconSize = highlightIcon ? '1.333rem' : '2.666rem'
  const iconElement = icon && (
    <Icon
      name={icon as SymbolName}
      size={iconSize}
      className={styles[highlightIcon ? 'icon-highlighted' : 'icon']}
    />
  )

  return (
    <>
      {highlightIcon && (
        <div className={styles['icon-wrapper']}>{iconElement}</div>
      )}
      {!highlightIcon && icon && iconElement}

      {svgIcon && <div className={styles['svg-icon-wrapper']}>{svgIcon}</div>}

      {socialIcon && (
        <div className={styles['social-icon-wrapper']}>{socialIcon}</div>
      )}
    </>
  )
}

export default BentoCardIcon
