import type { CollectionSortType } from '@/api/collections'

export const VIEW_TYPES = {
  grid: 'grid',
  list: 'list',
}

export const SORT_TYPES = {
  date: 'date',
  name: 'name',
}

export const SORT_FIELDS = {
  [SORT_TYPES.date]: [
    { value: 'date-desc', label: 'Sort ↓' },
    { value: 'date-asc', label: 'Sort ↑' },
  ],
  [SORT_TYPES.name]: [
    { value: 'alpha-desc', label: 'Sort ↓' },
    { value: 'alpha-asc', label: 'Sort ↑' },
  ],
} as Record<
  ValueOf<typeof SORT_TYPES>,
  { value: CollectionSortType; label: string }[]
>
