import { UPLOADED_FEATURE_ID } from '@/stores/map-draw-store/constants'
import type { CurrentSearchGeometry } from '@/stores/map-draw-store/types'
import { createStore } from '@/utils/create-store.ts'
import type MapboxDraw from '@mapbox/mapbox-gl-draw'
import type { Feature, Polygon } from 'geojson'
import type { Marker } from 'mapbox-gl'

export interface MapDrawValues {
  drawController: MapboxDraw | null
  currentSearchGeometry: CurrentSearchGeometry | null
  uploadedFeature: Feature | null
  searchHereMarker: Marker | null
  isDrawing: boolean
  drawMode: string
}
export interface MapDrawActions {
  setDrawController: (drawController: MapboxDraw | null) => void
  setIsDrawing: (isDrawing: boolean) => void
  setCurrentSearchGeometry: (
    searchGeometry: CurrentSearchGeometry | null,
  ) => void
  setSearchHereMarker: (marker: Marker | null) => void
  setUploadedFeature: (feature: Feature | null) => void
  setDrawMode: (drawMode: string) => void
}
export interface MapDrawState extends MapDrawValues, MapDrawActions {}

export const initialState = {
  drawController: null,
  currentSearchPolygon: null,
  uploadedFeature: null,
  searchHereMarker: null,
  isDrawing: false,
  currentSearchGeometry: null,
  drawMode: 'simple_select',
}

export const useMapDrawStore = createStore<MapDrawState>({
  initialState,
  actions: (set) => ({
    setCurrentSearchGeometry: (
      currentSearchGeometry: CurrentSearchGeometry | null,
    ) => {
      set({ currentSearchGeometry: currentSearchGeometry })
    },
    setDrawMode: (drawMode: string) => {
      set({ drawMode: drawMode })
    },
    setDrawController: (drawController: MapboxDraw | null) => {
      set({ drawController, isDrawing: false })
    },
    setIsDrawing: (isDrawing: boolean) => set({ isDrawing }),

    setSearchHereMarker: (searchHereMarker: Marker | null) =>
      set({ searchHereMarker }),
    setUploadedFeature: (feature: Feature | null) => {
      if (feature) {
        set({
          uploadedFeature: { ...feature, id: UPLOADED_FEATURE_ID },
          currentSearchGeometry: feature.geometry as Polygon,
        })
      } else {
        set({ uploadedFeature: null, currentSearchGeometry: null })
      }
    },
  }),
})
