import { SEARCH_CATEGORIES, useQueryId, useResults } from '@/api/results'
import { getQueryResultsByCategory } from '@/api/results/api'
import { ingestTransforms } from '@/api/results/data-transforms'
import type { Results } from '@/api/results/types'
import { usePages } from '@/stores/queries-store'
import type { RawSearchResult, ResultWithId } from '@/utils/types/result-types'
import { useQuery } from '@tanstack/react-query'

export const PAGE_SIZE = 20

const useCategoryQuery = (queryId: string, category: string, page: number) => {
  return useQuery({
    queryKey: ['search-page', queryId, category, page],
    queryFn: () => {
      return getQueryResultsByCategory(queryId, category, {
        start: (page - 1) * PAGE_SIZE,
        size: PAGE_SIZE,
      })
    },
    enabled: page > 1,
  })
}

export const usePaginatedResults = () => {
  const pages = usePages()
  const allResults = useResults()
  const queryId = useQueryId()
  const ids = [] as string[]

  const addIdToDocument = (result: RawSearchResult) => {
    ids.push(result.properties._dId)
    return { ...result, id: result.properties._dId }
  }

  const loadDocuments = (category: string, rawDocuments: RawSearchResult[]) => {
    const documents = rawDocuments.map(addIdToDocument)
    const uniqueDocuments = documents
      .filter(
        (document, index) =>
          documents.findIndex((d) => d.id === document.id) === index,
      )
      .slice(0, PAGE_SIZE)
    return uniqueDocuments.map((document) => ({
      ...document,
      properties: { ...document.properties, resultCategory: category },
    })) as ResultWithId[]
  }

  const queries = {
    [SEARCH_CATEGORIES.FIRE]: useCategoryQuery(
      queryId,
      SEARCH_CATEGORIES.FIRE,
      pages[SEARCH_CATEGORIES.FIRE] || 1,
    ),
    [SEARCH_CATEGORIES.IMAGE]: useCategoryQuery(
      queryId,
      SEARCH_CATEGORIES.IMAGE,
      pages[SEARCH_CATEGORIES.IMAGE] || 1,
    ),
    [SEARCH_CATEGORIES.SHIP]: useCategoryQuery(
      queryId,
      SEARCH_CATEGORIES.SHIP,
      pages[SEARCH_CATEGORIES.SHIP] || 1,
    ),
    [SEARCH_CATEGORIES.SOCIAL_MEDIA]: useCategoryQuery(
      queryId,
      SEARCH_CATEGORIES.SOCIAL_MEDIA,
      pages[SEARCH_CATEGORIES.SOCIAL_MEDIA] || 1,
    ),
    [SEARCH_CATEGORIES.PUBLICATION]: useCategoryQuery(
      queryId,
      SEARCH_CATEGORIES.PUBLICATION,
      pages[SEARCH_CATEGORIES.PUBLICATION] || 1,
    ),
  }

  const pageValue = (category: keyof typeof queries) => {
    const page = pages[category] || 1
    if (page > 1) {
      const data = queries[category]?.data
      const resultDocuments = data?.resultDocuments
      const documents = resultDocuments?.documents
      return documents || []
    }
    return allResults[category]
  }

  const loadCategory = (category: string) =>
    loadDocuments(category, pageValue(category as keyof typeof queries))

  const categorized = {
    ...allResults,
    [SEARCH_CATEGORIES.IMAGE]: loadCategory(SEARCH_CATEGORIES.IMAGE).map(
      ingestTransforms[SEARCH_CATEGORIES.IMAGE],
    ),
    [SEARCH_CATEGORIES.FIRE]: loadCategory(SEARCH_CATEGORIES.FIRE),
    [SEARCH_CATEGORIES.SHIP]: loadCategory(SEARCH_CATEGORIES.SHIP),
    [SEARCH_CATEGORIES.PUBLICATION]: loadCategory(
      SEARCH_CATEGORIES.PUBLICATION,
    ).map(ingestTransforms[SEARCH_CATEGORIES.PUBLICATION]),
    [SEARCH_CATEGORIES.SOCIAL_MEDIA]: loadCategory(
      SEARCH_CATEGORIES.SOCIAL_MEDIA,
    ),
  } as Results

  const flatResults = Object.values(categorized).flat()
  const resultsById = Object.fromEntries(
    flatResults.map((result) => [result.id, result]),
  )

  return {
    results: categorized,
    ids,
    resultsById,
    pages,
    queries,
  }
}
