import { Divider, Group, Stack, Text } from '@mantine/core'
import { Icon } from '@/components/lib/Icon'
import type { SearchSuggestion } from '@/features/Search/types'

import styles from './search-suggestion.module.css'

export interface SearchSuggestionProps {
  suggestion: SearchSuggestion
  onClick: (suggestion: SearchSuggestion) => void
}

export function SearchSuggestionCell({
  suggestion,
  onClick,
}: SearchSuggestionProps) {
  const {
    title,
    icon,
    location: { formattedAddress },
    subject,
  } = suggestion
  return (
    <Stack
      gap="sm"
      className={styles.searchSuggestionCell}
      onClick={() => onClick(suggestion)}
    >
      <Group gap="sm">
        <Icon name={icon} size="xl" />
        <Text size="lg">{title}</Text>
      </Group>
      <Divider />
      <Group gap="sm">
        <Icon name="my_location" />
        <Text size="sm" className={styles.searchSuggestionLocation}>
          {formattedAddress}
        </Text>
      </Group>
      <Group gap="sm">
        <Icon name="search" />
        <Text size="sm">{subject}</Text>
      </Group>
    </Stack>
  )
}
