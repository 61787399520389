import { Group, Stack, Text } from '@mantine/core'
import { getShipDataFields, getShipDetailsDisplayFields } from '@/api/results'
import { HIDE_ACTIONS_BY_CATEGORY } from '@/features/ResultActions/constants'
import { ShipReferenceCard } from '@/features/ResultCards/ReferenceCards'
import { SingleObjectViewTemplate } from '@/features/SingleObjectView/components/SingleObjectViewTemplate'
import { useResultNavigation } from '@/features/SingleObjectView/hooks'
import { RESULT_CATEGORY_COLORS } from '@/theme/colors'
import { RESULT_CATEGORIES, ROUTES } from '@/utils/constants'
import { formatDatetime } from '@/utils/format-datetime'
import type { ResultWithId, ShipResult } from '@/utils/types/result-types'

import styles from './single-object-view.module.css'

const category = RESULT_CATEGORIES.SHIP
const hideActions = HIDE_ACTIONS_BY_CATEGORY[category]

export function ShipObjectView({ item: rawItem }: { item: ResultWithId }) {
  const item = rawItem as ShipResult
  const { items, toPrevious, toNext, selectItem } = useResultNavigation(item)

  const details = getShipDetailsDisplayFields(item)

  return (
    <SingleObjectViewTemplate<ShipResult, ShipResult>
      {...{
        item,
        hideActions,
        hero: {
          geometry: item.geometry,
          color: RESULT_CATEGORY_COLORS.ship.toLowerCase().slice(1),
          imageAlt: details.name,
          toPrevious,
          toNext,
          metaDetails: [formatDatetime(item.authoredOn)],
        },
        content: {
          title: `${details.unicodeFlagIcon} ${details.name}`,
          detailsElement: (
            <Group className={styles['sov-meta']}>
              <Stack gap={0} className={styles['ship-meta-column']}>
                <Text>{details.regionName}</Text>
                <Text>{details.shipType || '--'}</Text>
                <Text>Call Sign: {details.callSign || 'Unknown'}</Text>
              </Stack>
              <Stack gap={0} className={styles['ship-meta-column']}>
                <Group>
                  <Text className={styles['meta-label']}>IMO:</Text>
                  <Text>{details.imo || '--'}</Text>
                </Group>
                <Group>
                  <Text className={styles['meta-label']}>MMSI:</Text>
                  <Text>{details.mmsi || '--'}</Text>
                </Group>
              </Stack>
            </Group>
          ),
          data: getShipDataFields(item),
        },
        carousel: {
          items,
          title: 'Related Ships',
          renderCard: (ship: ShipResult) => <ShipReferenceCard result={ship} />,
          selectItem,
          categoryUrl: ROUTES.ships,
          categoryLabel: 'All Ships',
        },
      }}
    />
  )
}
