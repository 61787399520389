import type { ChatState } from '@/stores/chat-store/types'
import { createStore } from '@/utils/create-store'
import {
  type ChatMessage as RawChatMessage,
  isChatResponse,
} from '@/utils/types/message-types'

export const initialState = {
  ids: [],
  messages: [],
}

export const useChatStore = createStore<ChatState>({
  initialState,
  actions: (set, get): Partial<ChatState> => ({
    addChatMessage: (message: RawChatMessage) => {
      const { ids } = get()
      const isResponse = isChatResponse(message)

      const duplicateMessage = isResponse && ids?.includes(message.messageId)

      if (!duplicateMessage) {
        set((state) => {
          return {
            messages: [
              ...(state.messages || []),
              { timestamp: Date.now(), ...message },
            ],
            ids: isResponse
              ? [...(state.ids || []), message.messageId]
              : state.ids,
          }
        })
      }
    },
    clearChat: () => {
      set({ messages: [] })
    },
  }),
})
