import {
  create,
  type StateCreator,
  type StoreApi,
  type UseBoundStore,
} from 'zustand'
import { combine, devtools, persist } from 'zustand/middleware'

interface CreateStoreArgs<StateType> {
  initialState?: Partial<StateType>
  actions?: StateCreator<Partial<StateType>>
  storageKey?: string
  storageFormatter?: (state: Omit<NonNullable<unknown>, never>) => object
  version?: number
}

type Store<StateType> = UseBoundStore<StoreApi<StateType>>

export function createStore<StateType extends object>({
  initialState,
  actions,
  storageKey,
  storageFormatter,
  version = 1,
}: CreateStoreArgs<StateType>) {
  const store = combine<Partial<StateType>, Partial<StateType>>(
    initialState ?? {},
    actions ?? (() => ({})),
  )
  return create(
    devtools(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      storageKey
        ? persist<Partial<StateType>>(store, {
            name: storageKey,
            version,
            partialize: storageFormatter ?? ((state) => state),
          })
        : store,
    ),
  ) as Store<StateType>
}
