import MapboxDraw from '@mapbox/mapbox-gl-draw'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import * as MapboxDrawGeodesic from 'mapbox-gl-draw-geodesic'

export const makeMapboxDraw = (map: mapboxgl.Map) => {
  let modes = MapboxDraw.modes
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
  modes = MapboxDrawGeodesic.enable(modes)

  const draw = new MapboxDraw({
    displayControlsDefault: false,
    defaultMode: 'simple_select',
    userProperties: true,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    modes,
  })

  //@ts-expect-error clickAnywhere is specific to SimpleSelect and isn't typed separately
  MapboxDraw.modes.simple_select.clickAnywhere = () => {
    map.dragPan.enable()
  }

  //@ts-expect-error clickNoTarget is specific to SimpleSelect and isn't typed separately
  MapboxDraw.modes.direct_select.clickNoTarget = () => {
    map.dragPan.enable()
  }

  return draw
}
