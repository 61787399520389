import { makeOrthoLayer } from '@/features/DantiMap/utils'
import { useSetHoveredLayerId } from '@/stores/map-store'
import type { ImageryResult } from '@/utils/types/result-types.ts'

export function useImageCardEvents(result: ImageryResult) {
  const setHoveredLayerId = useSetHoveredLayerId()
  const layer = makeOrthoLayer(result)
  return {
    onMouseEnter: () => setHoveredLayerId(layer?.groupKey ?? null),
    onMouseLeave: () => setHoveredLayerId(null),
  }
}

export default useImageCardEvents
