import { Checkbox, Group, Table } from '@mantine/core'
import type { DateValue } from '@mantine/dates'
import type { ConfigurationTablePropsType } from '@/features/AppLayout/Modals/SaveSearchModal'
import { Timeframe } from '@/features/Filters'
import type { TerrapinConfigParams } from '@/hooks/use-terrapin'
import { useSetTimePreset, useTimePresetFilter } from '@/stores/filters-store'
import { TimePreset } from '@/utils/types/filter-types'
import { ConfigureEmailNotifications } from './ConfigureEmailNotifications'
import { ConfigureExpiration } from './ConfigureExpiration'

interface ConfigureTimingsProps {
  savedConfig?: TerrapinConfigParams
  refId?: string
  tableProps: ConfigurationTablePropsType
  expirationDate: string
  setExpirationDate: (expiration: string) => void
  emailNotification: string | null
  setEmailNotification: (value: string | null) => void
  backfill: boolean
  setBackfill: (value: boolean) => void
}

export function ConfigureTimings(props: ConfigureTimingsProps) {
  const {
    savedConfig,
    tableProps,
    expirationDate,
    setExpirationDate,
    emailNotification,
    setEmailNotification,
    backfill,
    setBackfill,
  } = props
  const setTimePreset = useSetTimePreset()
  const timePreset = useTimePresetFilter()

  const toggleBackfill = () => {
    if (backfill) {
      setTimePreset(TimePreset.None, null, null)
    }

    setBackfill(!backfill)
  }

  const handleTimePresetSelect = (
    timePreset: TimePreset,
    start: DateValue | null,
    end: DateValue | null,
  ) => {
    setTimePreset(timePreset, start, end)
  }

  return (
    <Table>
      <Table.Thead>
        <Table.Tr style={tableProps.rowStyle}>
          <Table.Th colSpan={2} px={0}>
            Updates and expiration
          </Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody px="sm">
        <Table.Tr style={tableProps.rowStyle}>
          <Table.Td {...tableProps.firstColumn}>Saved search duration</Table.Td>
          <Table.Td {...tableProps.secondColumn}>
            <ConfigureExpiration
              initialExpirationDate={savedConfig?.expiration}
              expirationDate={expirationDate}
              setExpirationDate={setExpirationDate}
            />
          </Table.Td>
        </Table.Tr>
        <Table.Tr style={tableProps.rowStyle}>
          <Table.Td {...tableProps.firstColumn}>Email notifications</Table.Td>
          <Table.Td {...tableProps.secondColumn}>
            <ConfigureEmailNotifications
              emailNotification={emailNotification}
              setEmailNotification={setEmailNotification}
            />
          </Table.Td>
        </Table.Tr>
        <Table.Tr style={tableProps.rowStyle}>
          <Table.Td {...tableProps.firstColumn}>
            <Group gap={8}>
              <Checkbox
                size="xs"
                checked={backfill}
                onChange={toggleBackfill}
              />
              Backfill with historic data
            </Group>
          </Table.Td>
          <Table.Td {...tableProps.secondColumn}>
            <Timeframe
              onSelect={handleTimePresetSelect}
              disabled={!backfill}
              selectedTimePreset={timePreset}
            />
          </Table.Td>
        </Table.Tr>
      </Table.Tbody>
    </Table>
  )
}
