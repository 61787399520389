import type { CollectionItem } from '@/api/collections/types'
import {
  FileReferenceCard,
  FireReferenceCard,
  ImageReferenceCard,
  NewsReferenceCard,
  ShipReferenceCard,
  SocialMediaReferenceCard,
} from '@/features/ResultCards'
import { isFileResult } from '@/utils/types/result-type-checkers'
import {
  isFireResult,
  isImageryResult,
  isShipResult,
  isSocialMediaResult,
  type ResultWithId,
} from '@/utils/types/result-types'
import { ResultBase } from './ResultBase'

export interface CollectionItemGridResultProps {
  item: CollectionItem
}

export function CollectionItemGridResult({
  item,
}: CollectionItemGridResultProps) {
  const { doc } = item
  const result = JSON.parse(doc) as ResultWithId

  let resultCard: JSX.Element
  if (isImageryResult(result)) {
    resultCard = <ImageReferenceCard result={result} />
  } else if (isSocialMediaResult(result)) {
    resultCard = <SocialMediaReferenceCard result={result} />
  } else if (isFireResult(result)) {
    resultCard = <FireReferenceCard result={result} />
  } else if (isShipResult(result)) {
    resultCard = <ShipReferenceCard result={result} />
  } else if (isFileResult(result)) {
    resultCard = <FileReferenceCard result={result} />
  } else {
    resultCard = <NewsReferenceCard result={result} />
  }
  return (
    <ResultBase item={item} result={result}>
      {resultCard}
    </ResultBase>
  )
}
