import type { SearchApiFilterValue } from '@/utils/types/filter-types.ts'

export const makeGeocodeFilter = (
  type: string,
  value: string,
): SearchApiFilterValue | null => {
  if (!value) return null

  return {
    type: 'GEOCODE',
    subtype: type, // Use subtype to specify the type of geocode data
    value: value,
  }
}
