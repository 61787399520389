import React from 'react'
import { Button, Group, Menu, Text } from '@mantine/core'
import { Icon } from '@/components/lib/Icon'
import type { OrgRole } from '@/gql/graphql.ts'
import { useUserRole } from '@/hooks/use-user-role.ts'
import { RankedOrgRoles } from '@/utils/types/user-types'
import { capitalize } from 'lodash'

interface OrgRoleSelectProps {
  role: OrgRole
  setRole: (role: OrgRole) => void
}

export function OrgRoleSelect({ role, setRole }: OrgRoleSelectProps) {
  const currentUser = useUserRole()

  const orgRoles = React.useMemo(() => {
    if (!currentUser) {
      return []
    }

    const currentUserRank = RankedOrgRoles.indexOf(
      currentUser.role.toUpperCase(),
    )
    return RankedOrgRoles.slice(0, currentUserRank + 1)
  }, [currentUser])

  return (
    <Menu shadow="md" offset={-32}>
      <Menu.Target>
        <Button
          variant="transparent"
          color="black"
          rightSection={<Icon name="arrow_drop_down" />}
        >
          <Text size="xs" style={{ fontWeight: 'bold' }}>
            {capitalize(role)}
          </Text>
        </Button>
      </Menu.Target>
      <Menu.Dropdown w={102}>
        <Menu.Item key={role} p={6} pr={0}>
          <Group gap={8}>
            <Text
              size="xs"
              style={{ fontWeight: 'bold', textTransform: 'capitalize' }}
            >
              {capitalize(role)}
            </Text>
            <Icon name="arrow_drop_down" />
          </Group>
        </Menu.Item>
        {orgRoles
          .filter((x) => x.toLowerCase() !== role.toLowerCase())
          .map((role) => (
            <Menu.Item
              key={role}
              color="black"
              onClick={() => setRole(role)}
              p={6}
            >
              <Text size="xs">{capitalize(role)}</Text>
            </Menu.Item>
          ))}
      </Menu.Dropdown>
    </Menu>
  )
}
