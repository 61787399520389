import { apiUrl } from '@/utils/constants.ts'
import { captureException } from '@sentry/react'
import axios, { type AxiosError } from 'axios'

export const axiosClientNoAuth = axios.create({
  baseURL: apiUrl,
})

axiosClientNoAuth.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    captureException(error)
    return Promise.reject(error)
  },
)
