import { Center, Stack, Text } from '@mantine/core'
import { DantiLogoMark } from '@/components/lib/DantiLogoMark.tsx'
import { COLORS } from '@/theme/colors'

export function DantiNoResultMark() {
  return (
    <Center h="100%">
      <Stack align="center" gap="xs">
        <DantiLogoMark
          height="30%"
          color={COLORS.gray[3]}
          style={{ marginTop: 'calc(var(--mantine-spacing-xs) / 2)' }}
        />
        <Text c="gray.4" size="xs">
          Preview Unavailable
        </Text>
      </Stack>
    </Center>
  )
}
