import { Box, Group, Image, Stack, Text } from '@mantine/core'
import { ViewToggleComponent } from '@/features/TimelineView/components'
import { usePostfilterImageResults } from '@/stores/results-filter-store'
import { TimelineControlPanel } from './TimelineControlPanel'
import { useTimelapse } from './use-timelapse'

export function TimelapseView({
  resultId,
  setSelectedView,
}: {
  resultId?: string
  setSelectedView: (view: string) => void
}) {
  const imageResults = usePostfilterImageResults()
  const {
    resultsByDay,
    isPlaying,
    isLooping,
    selectedDay,
    selectedIndex,
    totalResults,
    markValues,
    messages,
    actions: { setIsPlaying, setIsLooping, handleTimeChange, valueFormatter },
    mapContainer,
    minimap,
  } = useTimelapse({ resultId, imageResults })

  return (
    <>
      <Group justify="space-between" mb="xs">
        <Text lh={1}>{messages.dataSummary}</Text>
        <ViewToggleComponent
          selectedView="timelapse"
          setSelectedView={setSelectedView}
        />
      </Group>
      <Stack
        gap={0}
        style={{ overflow: 'hidden', borderRadius: 'var(--mantine-radius-lg)' }}
      >
        <Box pos="relative">
          {minimap && (
            <Image
              pos="absolute"
              w={150}
              src={minimap}
              alt="minimap"
              className="timelapse-minimap"
              style={{ zIndex: 1 }}
            />
          )}
          <Box
            ref={mapContainer}
            data-testid="timelapse-container"
            style={{ height: '600px' }}
          />
        </Box>
        <TimelineControlPanel
          {...{
            resultsByDay,
            isPlaying,
            isLooping,
            selectedDay,
            selectedIndex,
            totalResults,
            markValues,
            valueFormatter,
            setIsPlaying,
            setIsLooping,
            handleTimeChange,
          }}
        />
      </Stack>
    </>
  )
}
