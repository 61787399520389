import { Box } from '@mantine/core'
import { BAR_FILL_COLOR } from '@/features/ResultsTimeline/constants'
import type { TooltipData } from '@/features/ResultsTimeline/types'
import { defaultStyles } from '@visx/tooltip'
import type { UseTooltipParams } from '@visx/tooltip/lib/hooks/useTooltip'
import type React from 'react'

import style from './tooltip.module.css'

type ResultsTimelineTooltipProps = {
  timeBins: Date[]
  useTooltipParams: UseTooltipParams<TooltipData>
  dateFormatter: (d: Date) => string
}

const tooltipColor = (category: keyof typeof BAR_FILL_COLOR) => ({
  backgroundColor: BAR_FILL_COLOR[category].normal,
})

export const ResultsTimelineTooltip: React.FC<ResultsTimelineTooltipProps> = ({
  timeBins,
  useTooltipParams,
  dateFormatter,
}) => {
  if (!(useTooltipParams.tooltipOpen && useTooltipParams.tooltipData)) {
    return <></>
  }
  return (
    <Box
      bottom={useTooltipParams.tooltipTop! + 45}
      left={useTooltipParams.tooltipLeft! - 40}
      style={{
        ...defaultStyles,
        minWidth: 60,
        backgroundColor: 'rgba(0,0,0,0.7)',
        color: 'white',
        fontSize: 9,
        zIndex: 1000,
      }}
    >
      <div style={{ marginBottom: 16, marginTop: 12 }}>
        {dateFormatter(
          timeBins[useTooltipParams.tooltipData.index - 1] || new Date(),
        )}
      </div>
      {useTooltipParams.tooltipData.bar.data.IMAGE > 0 && (
        <div className={style.tooltipContainer}>
          <span className={style.tooltipBar} style={tooltipColor('IMAGE')} />
          <span>Images: {useTooltipParams.tooltipData.bar.data.IMAGE}</span>
        </div>
      )}

      {useTooltipParams.tooltipData.bar.data.PUBLICATION > 0 && (
        <div className={style.tooltipContainer}>
          <span
            className={style.tooltipBar}
            style={tooltipColor('PUBLICATION')}
          />
          <span>News: {useTooltipParams.tooltipData.bar.data.PUBLICATION}</span>
        </div>
      )}

      {useTooltipParams.tooltipData.bar.data.FIRE > 0 && (
        <div className={style.tooltipContainer}>
          <span className={style.tooltipBar} style={tooltipColor('FIRE')} />
          <span>Fire: {useTooltipParams.tooltipData.bar.data.FIRE}</span>
        </div>
      )}

      {useTooltipParams.tooltipData.bar.data.SHIP > 0 && (
        <div className={style.tooltipContainer}>
          <span className={style.tooltipBar} style={tooltipColor('SHIP')} />
          <span>Ship: {useTooltipParams.tooltipData.bar.data.SHIP}</span>
        </div>
      )}
      {useTooltipParams.tooltipData.bar.data.SOCIAL_MEDIA > 0 && (
        <div className={style.tooltipContainer}>
          <span
            className={style.tooltipBar}
            style={tooltipColor('SOCIAL_MEDIA')}
          />
          <span>
            Social: {useTooltipParams.tooltipData.bar.data.SOCIAL_MEDIA}
          </span>
        </div>
      )}
      {useTooltipParams.tooltipData.bar.data.PROPERTY > 0 && (
        <div className={style.tooltipContainer}>
          <span className={style.tooltipBar} style={tooltipColor('PROPERTY')} />
          <span>
            Property: {useTooltipParams.tooltipData.bar.data.PROPERTY}
          </span>
        </div>
      )}
    </Box>
  )
}
