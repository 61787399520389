import { useRef } from 'react'
import { Stack } from '@mantine/core'
import { useNavigateToMap } from '@/features/AppRouting/hooks'
import {
  useMapboxMap,
  useOrthoLayers,
  usePointData,
} from '@/features/DantiMap/hooks'
import { useInitialBehavior } from '@/features/DantiMap/views/MapSearchPanel/hooks'
import { captureEvent, POSTHOG_EVENTS } from '@/utils/posthog'
import { classes } from '@/utils/ui-helpers'
import { useImageryExtents } from './hooks'

import styles from '@/features/DantiMap/danti-map.module.css'

export const EmbeddedMap = () => {
  const mapContainer = useRef<HTMLDivElement | null>(null)
  const map = useRef<mapboxgl.Map | null>(null)
  const navigateToMap = useNavigateToMap()

  useMapboxMap(map, mapContainer, { isStatic: true })
  useInitialBehavior(map)
  usePointData(map, 'Embedded', true)
  useImageryExtents({ map })
  useOrthoLayers(map)

  const expandMap = () => {
    captureEvent(POSTHOG_EVENTS.EXPLORE.EXPAND_MAP)
    navigateToMap()
  }

  return (
    <Stack className={styles.embeddedMap}>
      <div
        ref={mapContainer}
        className={classes('map-container', styles.embeddedMapContainer)}
        onClick={expandMap}
      />
    </Stack>
  )
}
