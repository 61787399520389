import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Container, Grid, List, ListItem, Stack, Text } from '@mantine/core'
import {
  useHasResults,
  useInsightResults,
  useInsightStatus,
  useIsInsightLoading,
  usePropertyResults,
  useSearchStatus,
} from '@/api/results'
import { Skeleton } from '@/components/lib/Skeleton'
import { QueryHeader } from '@/features/AppLayout'
import { BentoResults } from '@/features/BentoResults'
import { InsightPanel } from '@/features/InsightPanel'
import { useSearch } from '@/features/Search/hooks'
import { usePageTitle } from '@/hooks/use-page-title'
import { useCurrentQuery } from '@/stores/queries-store'
import { wordBreak } from '@/utils/ui-helpers'

import styles from './commercial-results.module.css'

interface StatusAwareSkeletonProps {
  height?: string
  fallback?: React.ReactNode
  condition?: boolean
  children?: React.ReactNode
}

const checkIsLoading = (status: string) => status === 'pending'

/**
 * Reads search status to correctly display a skeleton while searching.
 * Once the search is completed, uses the `condition` to determine whether to
 * display the children or null, or the fallback if the search returned no results.
 * @example
 * <StatusAwareSkeleton condition={hasResults} fallback={<NoResults />}>
 *   <ResultDisplay />
 * </StatusAwareSkeleton>
 */
function StatusAwareSkeleton({
  children = null,
  condition = true,
  fallback = null,
}: StatusAwareSkeletonProps) {
  const status = useSearchStatus()

  const isLoading = checkIsLoading(status)
  const insightStatus = useInsightStatus()
  const isInsightLoading = useIsInsightLoading() || insightStatus === 'pending'
  const hasResults = useHasResults()
  const isProperty = usePropertyResults().length > 0

  if (isLoading || isInsightLoading) {
    return (
      <>
        <Skeleton />
        {isProperty && (
          <Stack align="center" gap={2} mt="lg">
            <Text fw="bold">Collecting data on this property... </Text>
            <Text>This may take a minute.</Text>
          </Stack>
        )}
      </>
    )
  } else {
    if (condition) {
      return children
    } else if (status === 'success' && hasResults) {
      return fallback
    } else {
      return null
    }
  }
}

export function ResultsOverview() {
  const insightResults = useInsightResults()
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const search = params.get('search')
  const status = useSearchStatus()

  const currentQuery = useCurrentQuery()
  const { doTextSearch } = useSearch()
  const insightStatus = useInsightStatus()
  const isInsightLoading = useIsInsightLoading() || insightStatus === 'pending'

  usePageTitle(`${currentQuery} - Danti Search`)

  React.useEffect(() => {
    if (search && search !== currentQuery) {
      void doTextSearch(search)
    }
    // This `useLocation` stateful hook is updated anytime the URL changes.
    // We use location.search as a dependency here because we want to update the
    // query when the user navigates to the same page with a new query string.
    // This ensures expected behavior for forward/back history navigation.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, currentQuery])

  const hasInsights = insightResults.length > 0

  return (
    <>
      <QueryHeader />
      <Container size="md" pt="md">
        <BentoResults isLoading={checkIsLoading(status)} />
        <Grid columns={12} gutter="xl" mt="xl" mb="sm">
          <Grid.Col span={12} mih={48} py={0} mt="md">
            <StatusAwareSkeleton
              condition={!isInsightLoading}
              fallback={
                <>
                  <Text fz={48} fw={700}>
                    {`There's no clear answer`}
                  </Text>
                  <Text>
                    {`Your search didn't return a clear result. Refine your question and try again.`}
                  </Text>
                  <div>
                    <Text fz={20} fw={700}>
                      Some suggestions to get a better answer
                    </Text>
                    <List mt="sm">
                      <ListItem>
                        Be specific. For example, if you are trying to find out
                        about battle damage in a city, ask about specific
                        structures
                      </ListItem>
                      <ListItem>
                        Reference a location like a city, state, country or zip
                        code
                      </ListItem>
                      <ListItem>
                        Phrase your question naturally, as if you were talking
                        to a person
                      </ListItem>
                      <ListItem>Avoid using jargon, slang or acronyms</ListItem>
                      <ListItem>
                        Use latitude, longitude ordering for decimal degree
                        inputs
                      </ListItem>
                      <ListItem>
                        Explore the <Link to="/map">map</Link>
                      </ListItem>
                    </List>
                  </div>
                </>
              }
            >
              <Text
                lineClamp={3}
                mb="md"
                title={insightResults[0]?.title}
                className={styles['insight-title']}
              >
                {hasInsights && wordBreak(insightResults[0].title)}
              </Text>
              {insightResults.map((result) => (
                <InsightPanel key={result.id} result={result} />
              ))}
            </StatusAwareSkeleton>
          </Grid.Col>
        </Grid>
      </Container>
    </>
  )
}

export default ResultsOverview
