import React from 'react'
import { Checkbox, Group, Space, Stack } from '@mantine/core'
import { Slider } from '@/components/lib/Slider'
import { PlaybackControls } from './PlaybackControls'
import type { TimelineControlPanelProps, TimestampedResult } from './types'

function TimelineResultRow({
  label,
  results,
  timeBounds,
}: {
  label: string
  results: TimestampedResult[]
  timeBounds: [number, number]
}) {
  const [isActive, setIsActive] = React.useState(true)
  const marks = results.map((result) => ({
    value: result.timestamp,
    label: result.properties.datetime,
  }))

  return (
    <Group pl="md">
      <Checkbox
        w="calc(150px - var(--mantine-spacing-md))"
        c="white"
        label={label}
        checked={isActive}
        onChange={() => setIsActive(!isActive)}
      />
      <Stack
        flex={1}
        p="md"
        style={{ borderLeft: '2px solid var(--mantine-color-dark-6)' }}
      >
        <Slider
          size={4}
          min={timeBounds[0]}
          max={timeBounds[1]}
          marks={marks}
          label={(value) => value.toFixed(2)}
        />
      </Stack>
    </Group>
  )
}

export function TimelineControlPanel(props: TimelineControlPanelProps) {
  const {
    isPlaying,
    isLooping,
    selectedIndex,
    totalResults,
    markValues,
    valueFormatter,
    setIsPlaying,
    setIsLooping,
    handleTimeChange,
  } = props

  function stopPlayingOnChange(value: number) {
    if (isPlaying) {
      setIsPlaying(false)
    }
    handleTimeChange(value)
  }

  return (
    <Stack bg="#4c4c4c" gap={0}>
      <Group>
        <Space w={150} />
        <Stack
          flex={1}
          p="md"
          style={{ borderLeft: '2px solid var(--mantine-color-dark-6)' }}
        >
          <PlaybackControls
            {...{
              isPlaying,
              isLooping,
              selectedIndex,
              totalResults,
              setIsPlaying,
              setIsLooping,
              handleTimeChange,
            }}
          />
          <Slider
            size={4}
            value={selectedIndex}
            onChange={stopPlayingOnChange}
            min={0}
            max={totalResults - 1}
            marks={markValues}
            label={valueFormatter}
          />
        </Stack>
      </Group>
      <TimelineResultRow label="Images" results={[]} timeBounds={[0, 1]} />
    </Stack>
  )
}
