import React from 'react'
import { Select, Text } from '@mantine/core'
import type { OrgRole } from '@/gql/graphql.ts'
import { useUserRole } from '@/hooks/use-user-role.ts'
import { UpdateUserRoleMutation } from '@/pages/Account/mutations.ts'
import { RankedOrgRoles, type User } from '@/utils/types/user-types.ts'
import { useMutation } from '@apollo/client'
import { capitalize } from 'lodash'

export interface OrgRoleSelectProps {
  user: User
}

export const OrgRoleSelect = ({ user }: OrgRoleSelectProps) => {
  const currentUser = useUserRole()
  const [role, setRole] = React.useState(user.role)
  const [updateUserRole] = useMutation(UpdateUserRoleMutation)

  const disabled = React.useMemo(() => {
    if (!user.id || !currentUser) {
      return true
    }

    const isCurrentUser = user.id === currentUser?.id
    if (isCurrentUser) {
      return true
    }

    const currentUserRole = currentUser.role

    if (currentUserRole !== 'Admin' && currentUserRole !== 'Owner') {
      return true
    }

    const currentUserRank = RankedOrgRoles.indexOf(
      currentUser.role.toUpperCase(),
    )
    const userRank = RankedOrgRoles.indexOf(user.role.toUpperCase())

    return currentUserRank <= userRank
  }, [user, currentUser])

  const orgRoles = React.useMemo(() => {
    if (!currentUser) {
      return []
    }

    const currentUserRank = RankedOrgRoles.indexOf(
      currentUser.role.toUpperCase(),
    )

    return RankedOrgRoles.map((x) => capitalize(x)).slice(
      0,
      currentUserRank + 1,
    )
  }, [currentUser])

  const handleRoleUpdate = (role: string | null) => {
    void updateUserRole({
      variables: {
        userId: user.id,
        role: role?.toUpperCase() as OrgRole,
      },
    })
    setRole(role as OrgRole)
  }

  return (
    <>
      {disabled ? (
        <Text size="sm">{role}</Text>
      ) : (
        <Select
          variant="unstyled"
          data={orgRoles}
          value={role}
          disabled={disabled}
          onChange={handleRoleUpdate}
          allowDeselect={false}
        />
      )}
    </>
  )
}
