import UserGuideCommercial from '@/assets/user-guide.commercial.pdf'
import UserGuideGov from '@/assets/user-guide.gov.pdf'
import { useFeatureFlag } from '@/hooks/use-feature-flag'
import { FEATURE_FLAG_VALUES } from '@/utils/constants'

export function Help() {
  const isCommercial = useFeatureFlag(FEATURE_FLAG_VALUES.commercial)

  return (
    <>
      <embed
        src={isCommercial ? UserGuideCommercial : UserGuideGov}
        width="700px"
        height="800px"
      ></embed>
    </>
  )
}
