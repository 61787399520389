import { Box, Divider, Group, Menu, Stack, Title } from '@mantine/core'
import { useCollections } from '@/api/collections'
import { Icon } from '@/components/lib/Icon'
import { Pager } from '@/components/pagination'
import {
  ActionsMenu,
  SearchInput,
  SortToggleButton,
  TypeControl,
} from '@/features/Collections/components'
import { useCollectionsHeader } from '@/features/Collections/hooks'
import type { ViewType } from '@/features/Collections/types'
import { useCollectionsState } from '@/stores/collections'
import { ICONS } from '@/utils/constants'

export interface CollectionsHeaderProps {
  viewType: ViewType
  setViewType: (value: ViewType) => void
}

export function CollectionsHeader({
  viewType,
  setViewType,
}: CollectionsHeaderProps) {
  const apiData = useCollections()
  const state = useCollectionsState()
  const headerData = useCollectionsHeader()

  const menuItems = [
    {
      icon: <Icon name={ICONS.dashboard} filled />,
      label: 'New saved search folder',
      onClick: headerData.handleNewCollectionClick,
    },
    {
      icon: <Icon name={ICONS.update} />,
      label: 'Create a new AOI saved search',
      onClick: headerData.handleNewFeedClick,
    },
    {
      icon: <Icon name={ICONS.fileUpload} />,
      label: 'Upload a file',
      onClick: headerData.handleUploadFileClick,
    },
  ]
  const { searchTerm, setSearchTerm } = headerData

  return (
    <Stack w="100%" gap={12}>
      <Title c="gray.8" size={60}>
        Saved Searches
      </Title>
      <Group gap="sm">
        <SearchInput {...{ searchTerm, setSearchTerm }} />
        <TypeControl {...{ viewType, setViewType }} />
        <ActionsMenu>
          {menuItems.map(({ icon, label, onClick }) => (
            <Menu.Item key={label} leftSection={icon} onClick={onClick}>
              {label}
            </Menu.Item>
          ))}
        </ActionsMenu>
        <SortToggleButton sort={state.sort} onSortChange={state.setSort} />
        <Box flex={1} />
        <Pager
          currentPage={state.currentPage}
          pageSize={apiData.pageSize}
          totalCount={apiData.totalCount}
          nextPage={apiData.nextPage}
          previousPage={apiData.previousPage}
        />
      </Group>
      <Divider />
    </Stack>
  )
}
