import React from 'react'
import type { CollectionItem } from '@/api/collections/types'
import { getRequest } from '@/api/danti-requests'
import { SEARCH_CATEGORIES, useAllResults } from '@/api/results'
import {
  ingestTransforms,
  loadGenericProps,
} from '@/api/results/data-transforms'
import { RESULT_CATEGORIES } from '@/utils/constants'
import { getResultCategory } from '@/utils/types/result-type-checkers'
import type {
  RawSearchResult,
  ResultWithId,
  VexcelImage,
} from '@/utils/types/result-types'

export interface UseCollectionItemProps {
  itemId?: string
  collectionId?: string
  terrapinItemId?: string
}

export interface UseCollectionItemReturn {
  item?: ResultWithId
  resultCategory?: string
}

export const useCollectionItem = ({
  itemId,
  collectionId,
  terrapinItemId,
}: UseCollectionItemProps): UseCollectionItemReturn => {
  const [_item, setItem] = React.useState<ResultWithId>()
  const results = useAllResults()
  const resultCategory = React.useMemo(() => {
    if (!_item) {
      return
    }
    return getResultCategory(_item)
  }, [_item])

  const item = React.useMemo<ResultWithId | undefined>(() => {
    if (!_item || !resultCategory) {
      return
    }

    const propertyResultCategories: string[] = [
      SEARCH_CATEGORIES.VEXCEL,
      SEARCH_CATEGORIES.REGRID,
      RESULT_CATEGORIES.VEXCEL,
      SEARCH_CATEGORIES.ATTOM,
      SEARCH_CATEGORIES.HAZARD_PROPERTY,
      SEARCH_CATEGORIES.HAZARD_RISK,
      SEARCH_CATEGORIES.CORELOGIC_PROPERTY,
      SEARCH_CATEGORIES.CORELOGIC_RISK,
    ]

    if (resultCategory == RESULT_CATEGORIES.VEXCEL) {
      const vexcelImages: VexcelImage[] =
        _item.properties.vexcel_images?.vexcel_images ?? []

      const [vexcelImage] = vexcelImages
      return {
        ..._item,
        // suffixing id with vexcel beause otherwise result id will be identical to REGRID result
        id: `${_item.id}-vexcel`,
        properties: {
          ...loadGenericProps(_item),
          _dProvider: 'VEXCEL',
          resultCategory: RESULT_CATEGORIES.VEXCEL,
          collection: vexcelImage.collection,
          'estimate-date': vexcelImage['estimate-date'],
          url: vexcelImage.url,
          // eslint-disable-next-line camelcase
          vexcel_images: { vexcel_images: vexcelImages },
        },
      }
    }

    if (!propertyResultCategories.includes(resultCategory)) {
      return _item
    }

    const transformProperty = ingestTransforms[RESULT_CATEGORIES.PROPERTY]

    return transformProperty(_item) as ResultWithId
  }, [_item, resultCategory])

  async function fetchItem(id: string) {
    const { data } = await getRequest<RawSearchResult>(`/results/${id}`)
    setItem({ ...data, id: data.properties._dId })
  }

  async function fetchCollectionItem(collectionId: string, itemId: string) {
    const response = await getRequest<CollectionItem>(
      `/collection/${collectionId}/items/${itemId}`,
    )
    setItem(JSON.parse(response.data.doc) as ResultWithId)
  }

  React.useEffect(() => {
    if (terrapinItemId) {
      void fetchItem(terrapinItemId)
      return
    }

    if (collectionId && itemId) {
      void fetchCollectionItem(collectionId, itemId)
      return
    }

    if (itemId) {
      const _item = results.find(({ id }) => id === itemId)
      if (_item) {
        setItem(_item)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { item, resultCategory }
}
