import { useParams } from 'react-router-dom'
import { SingleObjectView } from '@/features/SingleObjectView'
import ItemDetailComponent from './ItemDetail'

export function ItemDetail() {
  const { collectionId, terrapinItemId } = useParams()
  return collectionId || terrapinItemId ? (
    <ItemDetailComponent />
  ) : (
    <SingleObjectView />
  )
}

export default ItemDetail
