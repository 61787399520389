import {
  ActionIcon,
  Box,
  Divider,
  Group,
  Pill,
  Stack,
  Text,
} from '@mantine/core'
import { Icon } from '@/components/lib/Icon'
import { formatDatetime } from '@/utils/format-datetime'

export interface ListItemProps<T> {
  data: T
  onSelect: (collection: T) => void
  onClick?: () => void
  icon: React.ReactNode
  date: string
  name: string
  status?: string
}

export const ListItem = <T,>({
  data,
  onSelect,
  onClick,
  icon,
  date,
  name,
  status,
}: ListItemProps<T>) => (
  <Stack gap="xs" px="sm" onClick={onClick}>
    <Group gap={6} wrap="nowrap">
      {icon}
      <Text c="#000000DE" size="16px" lineClamp={1}>
        {name}
      </Text>
      <Box flex={1} />
      <ActionIcon
        variant="white"
        onClick={(event) => {
          event.stopPropagation()
          onSelect(data)
        }}
        data-cy="collection-menu"
        bg="transparent"
      >
        <Icon name="more_vert" style={{ color: '#000000' }} />
      </ActionIcon>
    </Group>
    <Group gap="xs" align="center">
      <Text c="#00000099" size="12px">
        {formatDatetime(date)}
      </Text>
      {status === 'NEW' && (
        <Pill key="NEW" size="xs" bg="#F1C21B" fw="bold">
          NEW
        </Pill>
      )}
    </Group>
    <Divider my="xs" />
  </Stack>
)
