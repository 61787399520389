import { GridCol } from '@mantine/core'
import { BentoSection } from './BentoSection'

export interface SecondaryBentoCellProps {
  isLoading?: boolean
  children?: React.ReactNode
  'data-testid'?: string
}

export const SecondaryBentoCell = ({
  isLoading = false,
  children,
}: SecondaryBentoCellProps) => (
  <GridCol span={6}>
    <BentoSection isLoading={isLoading}>{children}</BentoSection>
  </GridCol>
)

export default SecondaryBentoCell
