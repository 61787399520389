import type { CardProps } from '@mantine/core'
import { getShipLocation, getShipName } from '@/api/results'
import { BentoCard } from '@/features/BentoResults/components'
import { HIDE_ACTIONS_BY_CATEGORY } from '@/features/ResultActions/constants'
import { RESULT_CATEGORY_COLORS } from '@/theme/colors'
import { ICONS, RESULT_CATEGORIES } from '@/utils/constants'
import type { ShipResult } from '@/utils/types/result-types'

export interface ShipBentoCardProps extends CardProps {
  result: ShipResult
  totalResults: number
  size: string
}

const category = RESULT_CATEGORIES.SHIP
const actionsToHide = HIDE_ACTIONS_BY_CATEGORY[category]

export function ShipBentoCard({ result, ...props }: ShipBentoCardProps) {
  const position = getShipLocation(result)
  const name = getShipName(result).toLowerCase()
  const { longitude, latitude } = position
  return (
    <BentoCard
      {...props}
      result={result}
      content={{
        heading: name,
        subHeading: (
          <>
            {longitude}
            <br />
            {latitude}
          </>
        ),
        color: RESULT_CATEGORY_COLORS.ship,
      }}
      data-cy="shipResult"
      hideActions={actionsToHide}
      icon={ICONS.anchor}
      resultCategory={category}
    />
  )
}

export default ShipBentoCard
