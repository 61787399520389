import { deleteHistoryItems, getRecentHistory } from '@/api/history/api'
import { KEYS } from '@/api/history/constants'
import type {
  HistoryResponse,
  UsePageHistoryParams,
  UseRecentHistoryParams,
} from '@/api/history/types'
import {
  useMutation,
  useQuery,
  useQueryClient,
  type UseQueryResult,
} from '@tanstack/react-query'

export { useHistory } from './use-history'

export const useRecentHistory = ({
  isAuthenticated,
}: UseRecentHistoryParams): UseQueryResult<HistoryResponse> =>
  useQuery({
    queryKey: [KEYS.history, KEYS.recent],
    queryFn: getRecentHistory,
    enabled: isAuthenticated,
  })

export const usePageHistory = ({
  currentPage,
  searchTerm,
  isAuthenticated,
}: UsePageHistoryParams): UseQueryResult<HistoryResponse> =>
  useQuery({
    queryKey: [KEYS.history, currentPage, searchTerm],
    queryFn: getRecentHistory,
    enabled: isAuthenticated,
  })

export const useDeleteHistoryMutation = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: deleteHistoryItems,
    onSuccess: () => {
      // Slight delay on backend when is updated in opensearch
      setTimeout(() => {
        void queryClient.invalidateQueries({ queryKey: [KEYS.history] })
        void queryClient.invalidateQueries({ queryKey: [KEYS.history, 0, ''] })
      }, 500)
    },
  })
}
