import React from 'react'
import { Button, Flex, Stack, Text } from '@mantine/core'
import type { ContextModalProps } from '@mantine/modals'
import { getRequest } from '@/api/danti-requests'
import { Icon } from '@/components/lib/Icon.tsx'

async function generateShareLink(collectionId: string) {
  const { data: shareToken } = await getRequest<string>(
    `/collection/${collectionId}/share`,
  )

  return `${window.location.origin}/accept-share/${collectionId}/${shareToken}`
}

export function ShareCollectionModal({
  context,
  id: modalId,
  innerProps: { collectionId },
}: ContextModalProps<{ collectionId: string }>) {
  const [shareLink, setShareLink] = React.useState('')
  const [copyButtonText, setCopyButtonText] = React.useState('Copy Link')

  React.useEffect(() => {
    async function handleGetShareLink() {
      const link = await generateShareLink(collectionId)
      setShareLink(link)
    }
    void handleGetShareLink()
  }, [collectionId])

  function handleCopyClick() {
    void navigator.clipboard.writeText(shareLink)
    setCopyButtonText('Copied!')
    setTimeout(() => setCopyButtonText('Copy Link'), 2000)
  }

  return (
    <>
      <Stack gap="sm">
        <Text>Copy a sharable link to this saved search</Text>
      </Stack>
      <Flex justify="space-between" mt="md">
        <Button
          variant="subtle"
          leftSection={<Icon name="link" />}
          onClick={handleCopyClick}
          {...(shareLink ? {} : { disabled: true })}
        >
          {copyButtonText}
        </Button>
        <Button onClick={() => context.closeModal(modalId)} ml="auto">
          Done
        </Button>
      </Flex>
    </>
  )
}
