import { HIDE_ACTIONS_BY_CATEGORY } from '@/features/ResultActions/constants'
import { ResultImage } from '@/features/ResultImage'
import { SingleObjectViewTemplate } from '@/features/SingleObjectView/components/SingleObjectViewTemplate'
import { useLocalNavigation } from '@/features/SingleObjectView/hooks'
import { RESULT_CATEGORIES } from '@/utils/constants'
import type { ResultWithId, VexcelResult } from '@/utils/types/result-types'

import styles from './vexcel.module.css'

const category = RESULT_CATEGORIES.VEXCEL
const hideActions = HIDE_ACTIONS_BY_CATEGORY[category]

interface ItemDetailProps {
  item: ResultWithId
}

export function VexcelObjectView({ item: rawItem }: ItemDetailProps) {
  const item = rawItem as VexcelResult
  const images = (item.properties.vexcel_images?.vexcel_images ?? []).map(
    (x) => x.url,
  )

  const { localIndex, current, selectItem, toNext, toPrevious } =
    useLocalNavigation(images)

  return (
    <SingleObjectViewTemplate<VexcelResult, string>
      {...{
        item,
        hideActions,
        hero: {
          vexcelImage: images.length > 0 ? current : undefined,
          vexcelSource: item.source,
          imageAlt: item.title,
          toNext,
          toPrevious,
          metaAction:
            images.length > 0 && localIndex !== undefined
              ? `${localIndex + 1} of ${images.length}`
              : undefined,
        },
        carousel: {
          items: images,
          title: 'All Images',
          renderCard: (image) => (
            <ResultImage
              item={{
                thumbnail: image,
                title: item.title,
                source: item.source,
              }}
              className={styles['image-container']}
            />
          ),
          selectItem,
          grid: true,
        },
      }}
    />
  )
}
