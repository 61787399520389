import { HandleRedirect } from '@/features/AppRouting/components'
import { useDantiAuth } from '@/hooks/use-danti-auth.ts'
import { ROUTES } from '@/utils/constants'
import type { PropsWithChildren } from 'react'

export function Authenticated({ children }: PropsWithChildren) {
  const { isAuthenticated, isLoading } = useDantiAuth()

  if (isLoading) {
    return null
  }

  return isAuthenticated ? children : <HandleRedirect to={ROUTES.login} />
}
