import { Group, Text } from '@mantine/core'
import type { CollectionSortType } from '@/api/collections'
import type { CollectionItem } from '@/api/collections/types.ts'
import { TableHeaderSortCell } from '@/features/Collections/components'
import { SORT_TYPES } from '@/features/Collections/constants'
import { formatDatetime } from '@/utils/format-datetime'
import {
  CategoryTypes,
  getLabelForResult,
  type ResultWithId,
} from '@/utils/types/raw-result-types'
import { createColumnHelper } from '@tanstack/react-table'
import { CollectionDetailResultIcon } from '../CollectionDetailResultIcon'
import { CollectionDetailMenu } from './CollectionDetailMenu'
import { FilterCategoryTypeCell } from './HeaderFilterCell'

const columnHelper = createColumnHelper<CollectionItem>()

interface ColumnFunctions {
  setSort: (sort: CollectionSortType) => void
  onCategoryToggle: (categoryTypes: CategoryTypes) => void
}

const CATEGORY_FILTER_ITEMS = [
  { label: 'Images', value: CategoryTypes.IMAGE },
  { label: 'Articles', value: CategoryTypes.PUBLICATION },
  { label: 'Data', value: CategoryTypes.DATA },
  { label: 'Social', value: CategoryTypes.SOCIAL_MEDIA },
  { label: 'File', value: CategoryTypes.FILE },
  { label: 'Analytic', value: CategoryTypes.ANALYTIC },
]

export const CollectionDetailTableColumns = [
  columnHelper.accessor((row) => row, {
    id: 'name',
    header: (info) => (
      <TableHeaderSortCell
        setSort={(info.table.options.meta as ColumnFunctions).setSort}
        label="Name"
        sortType={SORT_TYPES.name}
      />
    ),
    size: 400,
    cell: (info) => {
      const item = info.getValue()
      const result = JSON.parse(item.doc) as ResultWithId
      return (
        <Group gap={12} wrap="nowrap">
          <CollectionDetailResultIcon result={result} />
          <Text>{item.name}</Text>
        </Group>
      )
    },
  }),

  columnHelper.accessor('createdOn', {
    header: (info) => (
      <TableHeaderSortCell
        setSort={(info.table.options.meta as ColumnFunctions).setSort}
        label="Created at"
        sortType={SORT_TYPES.date}
      />
    ),
    cell: (info) => <Text py="sm">{formatDatetime(info.getValue())}</Text>,
  }),

  columnHelper.accessor(
    (row) => {
      const { doc } = row
      const result = JSON.parse(doc) as ResultWithId
      return getLabelForResult(result)
    },
    {
      id: 'kind',
      size: 70,
      cell: (info) => <Text>{info.getValue()}</Text>,
      header: (info) => {
        const { getState, options } = info.table
        const selectedCategories = getState().columnFilters.find(
          ({ id }) => id == 'kind',
        )?.value as CategoryTypes[]
        const onCategoryToggle = (options.meta as ColumnFunctions)
          .onCategoryToggle
        return (
          <FilterCategoryTypeCell
            label="Kind"
            {...{
              selectedCategories,
              onCategoryToggle,
              filterItems: CATEGORY_FILTER_ITEMS,
            }}
          />
        )
      },
    },
  ),

  columnHelper.accessor((row) => row, {
    id: 'actions',
    header: '',
    size: 70,
    cell: (info) => <CollectionDetailMenu item={info.getValue()} />,
  }),
]
