import React from 'react'
import { ActionIcon, Checkbox, Group, Menu, Table, Text } from '@mantine/core'
import type { HistoryItem } from '@/api/history'
import { Icon } from '@/components/lib/Icon.tsx'
import { format } from 'date-fns'

export interface HistoryRowProps {
  item: HistoryItem
  isSelected: boolean
  onToggle: (itemId: string) => void
  onClick: (item: HistoryItem) => void
  onRemove: (itemId: string) => void
  onMonitorLocation: (item: HistoryItem) => void
}

export function HistoryRow({
  item,
  isSelected,
  onToggle,
  onClick,
  onRemove,
  onMonitorLocation,
}: HistoryRowProps) {
  const time = React.useMemo(() => {
    // Removes miliseconds
    const components = item.queryTime.split('.')
    components.pop()
    const date = new Date(`${components.join('')}+00:00`)
    return format(date, 'hh:mm aa')
  }, [item])

  const { id, query } = item

  return (
    <Table.Tr key={id}>
      <Table.Td w={40}>
        <Checkbox
          size="xs"
          checked={isSelected}
          onChange={() => onToggle(id)}
        />
      </Table.Td>
      <Table.Td w={100}>{time}</Table.Td>
      <Table.Td flex={1}>
        <Text style={{ cursor: 'pointer' }} onClick={() => onClick(item)}>
          {query}
        </Text>
      </Table.Td>
      <Table.Td w={10}>
        <Menu position="left-start">
          <Menu.Target>
            <ActionIcon variant="subtle" color="black">
              <Icon name="more_horiz" />
            </ActionIcon>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item c="red" onClick={() => onRemove(id)}>
              <Group gap={6}>
                <Icon size="xs" name="delete_outline" />
                <Text size="xs">Delete query</Text>
              </Group>
            </Menu.Item>
            <Menu.Item onClick={() => onMonitorLocation(item)}>
              <Group gap={6}>
                <Icon size="xs" name="location_searching" />
                <Text size="xs">Monitor Location</Text>
              </Group>
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Table.Td>
    </Table.Tr>
  )
}
