import { downloadFromURL } from '@/api/collections'
import { deleteRequest, getRequest, postRequest } from '@/api/danti-requests'
import * as Sentry from '@sentry/react'
import { omitBy } from 'lodash'
import type {
  AddItemParams,
  CollectionItemsResponse,
  DeleteItemParams,
  ListCollectionItemsParams,
} from './types'

export async function list(
  collectionId: string,
  params: ListCollectionItemsParams,
): Promise<CollectionItemsResponse> {
  const response = await getRequest<CollectionItemsResponse>(
    `/collection/${collectionId}/items`,
    {
      params: omitBy(params, (value) => !value),
    },
  )

  return response.data
}

export function add(params: AddItemParams) {
  return postRequest(`/collection/${params.collectionId}/items`, params)
}

export function remove(params: DeleteItemParams) {
  const { collectionId, itemId } = params
  return deleteRequest(`/collection/${collectionId}/items/${itemId}`)
}

export const downloadFile = async (endpoint: string, fileName: string) =>
  getRequest(endpoint)
    .then((response) => {
      const fileURL = response.data as string
      downloadFromURL(fileURL, fileName)
    })
    .catch(() => {
      Sentry.captureMessage('Failed to download extents', {
        extra: {
          endpoint: endpoint,
          ...(fileName && { fileName }),
        },
      })
    })
