import { useEffect } from 'react'
import { usePrevious } from '@mantine/hooks'
import { emitter, EVENTS } from '@/events'
import type { LayerConfig } from '@/features/DantiMap/types'
import { actOnMap } from '@/features/DantiMap/utils'
import { loadMapLayers } from './load-map-layers'
import {
  clearLayerEvents,
  clearMapEvents,
  loadMapEvents,
} from './map-layer-events'

export const useMapLayers = ({
  map,
  layers,
}: {
  map: mapboxgl.Map | null
  layers: Record<string, LayerConfig>
}) => {
  const previousLayers = usePrevious(layers) || {}
  const loadLayers = () => {
    actOnMap(() => {
      loadMapLayers({ map, layers })
    })
  }
  useEffect(() => {
    loadLayers()
    emitter.on(EVENTS.map.styleLoaded, loadLayers)
    return () => {
      emitter.off(EVENTS.map.styleLoaded, loadLayers)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, layers])

  const loadEvents = () => loadMapEvents(map, layers)
  const clearEvents = () => clearMapEvents(map, layers)
  useEffect(() => {
    loadEvents()
    return clearEvents
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layers])

  const removedLayers = Object.keys(previousLayers).filter(
    (layerId) => !layers[layerId],
  )

  const removeLayer = (layerId: string) => {
    actOnMap(() => {
      const layer = previousLayers[layerId]
      clearLayerEvents({ map, layerId, ...layer })
      if (map?.getLayer(layerId)) {
        map?.removeLayer(layerId)
      }
      if (layer.clearSourceWithLayer && map?.getSource(layer.sourceId)) {
        map?.removeSource(layer.sourceId)
      }
    })
  }
  useEffect(() => {
    const removeLayers = () => {
      removedLayers.forEach(removeLayer)
    }
    removeLayers()
    emitter.on(EVENTS.map.styleLoaded, removeLayers)
    return () => {
      emitter.off(EVENTS.map.styleLoaded, removeLayers)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removedLayers])
}
