import { fetchSignedS3URL } from '@/api/thumbnail'
import type { LayerConfig } from '@/features/DantiMap/types'
import { makeImageBounds } from '@/features/DantiMap/utils'
import type { OrthoImageryLayer } from '@/utils/types/ortho-imagery-types'
import {
  isCapella,
  isSatellogic,
  isSentinel,
  isUmbra,
  isUp42,
} from '@/utils/types/result-type-checkers'
import * as turf from '@turf/turf'

const isS3Url = (url: string) => url.includes('s3')

const layerCoords = (layer: OrthoImageryLayer) => {
  const [minX, minY, maxX, maxY] = turf.bbox(layer.geometry)
  return [
    [minX, maxY],
    [maxX, maxY],
    [maxX, minY],
    [minX, minY],
  ] as [[number, number], [number, number], [number, number], [number, number]]
}

const loadS3Config = ({
  imageUrls,
  orthoLayer,
  setImageUrls,
  sourceId,
}: {
  imageUrls: Record<string, string>
  setImageUrls: React.Dispatch<React.SetStateAction<Record<string, string>>>
  sourceId: string
  orthoLayer: OrthoImageryLayer
}) => {
  const coords = layerCoords(orthoLayer)
  void fetchSignedS3URL(orthoLayer.tileUrl).then((url) => {
    setImageUrls((previous) => ({ ...previous, [orthoLayer.id]: url }))
  })
  return imageUrls[orthoLayer.id]
    ? {
        type: 'raster',
        sourceId,
        layerId: `${sourceId}_layer`,
        sourceUrl: imageUrls[orthoLayer.id],
        sourceCoordinates: coords,
        clearSourceWithLayer: true,
      }
    : null
}

const loadImageConfig = (sourceId: string, orthoLayer: OrthoImageryLayer) => {
  const coords = makeImageBounds(orthoLayer.geometry)
  return {
    type: 'raster',
    sourceId,
    layerId: `${sourceId}_layer`,
    sourceUrl: orthoLayer.tileUrl,
    sourceCoordinates: coords,
    clearSourceWithLayer: true,
  }
}

const loadTileConfig = (sourceId: string, orthoLayer: OrthoImageryLayer) => ({
  type: 'raster',
  sourceId,
  layerId: `${sourceId}_layer`,
  sourceTiles: [orthoLayer.tileUrl],
  sourceBounds: turf.bbox(orthoLayer.imageResult.geometry) as [
    number,
    number,
    number,
    number,
  ],
  clearSourceWithLayer: true,
})

export const loadResult = ({
  imageUrls,
  orthoLayer,
  setImageUrls,
  sourceId,
}: {
  imageUrls: Record<string, string>
  setImageUrls: React.Dispatch<React.SetStateAction<Record<string, string>>>
  sourceId: string
  orthoLayer: OrthoImageryLayer
}): LayerConfig | null => {
  if (isS3Url(orthoLayer.tileUrl)) {
    return loadS3Config({ imageUrls, orthoLayer, setImageUrls, sourceId })
  } else if (
    isSentinel(orthoLayer.source) ||
    isSatellogic(orthoLayer.source) ||
    isCapella(orthoLayer.source) ||
    isUmbra(orthoLayer.source) ||
    isUp42(orthoLayer.source)
  ) {
    return loadImageConfig(sourceId, orthoLayer)
  } else {
    return loadTileConfig(sourceId, orthoLayer)
  }
}
