import type { CollectionItem } from '@/api/collections/types'
import { Icon } from '@/components/lib/Icon'
import { openDeleteCollectionModal } from '@/features/AppLayout/Modals'
import {
  CollectionNavLink,
  ItemModalMenu,
} from '@/features/Collections/components'

export interface CollectionDetailListItemModalMenuProps {
  data: CollectionItem
  onClose: () => void
}

export function CollectionDetailListItemModalMenu({
  data,
  onClose,
}: CollectionDetailListItemModalMenuProps) {
  const { name } = data
  const onClickDelete = () => {
    openDeleteCollectionModal(data.id)
  }
  return (
    <ItemModalMenu
      content={
        <>
          <CollectionNavLink
            icon={<Icon name="delete" />}
            label="Remove from Saved Search"
            pb={0}
            c="red.5"
            onClick={onClickDelete}
          />
        </>
      }
      onClose={onClose}
      name={name}
    />
  )
}
