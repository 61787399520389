import type { SearchApiFilterValue } from '@/utils/types/filter-types.ts'

export const makeLocationTypeFilter = (
  locationType: string,
): SearchApiFilterValue => {
  return {
    type: 'LOCATIONTYPE',
    value: locationType,
  }
}
