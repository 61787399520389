import { Button, Group, Stack, Text } from '@mantine/core'
import { useDantiAuth } from '@/hooks/use-danti-auth.ts'

export function SessionConfirmationModal() {
  const { logOut } = useDantiAuth()
  return (
    <Stack>
      <Text size="sm">You need to log in again.</Text>
      <Group justify="flex-end" mt="md">
        <Button data-autofocus type="submit" onClick={() => void logOut()}>
          Return to login
        </Button>
      </Group>
    </Stack>
  )
}
