import { ActionIcon, Checkbox, Menu } from '@mantine/core'
import type { CollectionSortType } from '@/api/collections'
import { Icon } from '@/components/lib/Icon'
import type { SymbolName } from '@/utils/types/material-icons.ts'
import { CategoryTypes } from '@/utils/types/result-types'

export type SortToggleButtonProps = {
  sort: CollectionSortType
  selectedCategories?: CategoryTypes[]
  onSortChange: (type: CollectionSortType) => void
  onCategoryToggle?: (categoryTypes: CategoryTypes) => void
}

type sortOrderOption = {
  label: string
  value: CollectionSortType
  icon: SymbolName
}

const sortOrderOptions: sortOrderOption[] = [
  { label: 'A-Z', value: 'alpha-asc', icon: 'arrow_downward_alt' },
  { label: 'Z-A', value: 'alpha-desc', icon: 'arrow_upward_alt' },
  { label: 'Most recent', value: '', icon: 'schedule' },
]

const checkboxOptions = [
  { label: 'Images', type: CategoryTypes.IMAGE },
  { label: 'Articles', type: CategoryTypes.PUBLICATION },
  { label: 'Data', type: CategoryTypes.DATA },
  { label: 'Social', type: CategoryTypes.SOCIAL_MEDIA },
  { label: 'Files', type: CategoryTypes.FILE },
  { label: 'Property', type: CategoryTypes.PROPERTY },
]

export const SortToggleButton = ({
  sort,
  selectedCategories,
  onSortChange,
  onCategoryToggle,
}: SortToggleButtonProps) => {
  return (
    <Menu trigger="hover" shadow="md" closeOnItemClick={false}>
      <Menu.Target>
        <ActionIcon color="#000000" variant="subtle">
          <Icon name="sort" />
        </ActionIcon>
      </Menu.Target>

      <Menu.Dropdown p="sm">
        {sortOrderOptions.map(({ icon, value, label }: sortOrderOption) => (
          <Menu.Item
            key={label}
            leftSection={<Icon name={icon} />}
            onClick={() => onSortChange(value)}
            color={sort === value ? 'blue' : 'default'}
            p="xs"
          >
            {label}
          </Menu.Item>
        ))}
        {selectedCategories &&
          onCategoryToggle &&
          checkboxOptions.map(({ type, label }) => (
            <Checkbox
              key={label}
              p="xs"
              label={label}
              onChange={() => onCategoryToggle(type)}
              checked={selectedCategories.includes(type)}
            />
          ))}
      </Menu.Dropdown>
    </Menu>
  )
}

export default SortToggleButton
