import type { SearchApiFilterValue } from '@/utils/types/filter-types.ts'

export const makeKeywordsFilter = (
  keywords: string[],
): SearchApiFilterValue => {
  return {
    type: 'KEYWORD',
    keywords: keywords,
  }
}
