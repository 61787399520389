import { useMemo } from 'react'
import { SEARCH_CATEGORIES } from '@/api/results/constants'
import { RESULT_CATEGORIES } from '@/utils/constants'
import type {
  AttomResult,
  FireResult,
  HazardPropertyResult,
  HazardRiskResult,
  ImageryResult,
  InsightResult,
  PropertyResult,
  PublicationResult,
  RegridResult,
  ResultWithId,
  ShipResult,
  SocialMediaResult,
  VexcelResult,
} from '@/utils/types/result-types'
import { useAllResults } from './use-all-results'
import { useCategorizedResults } from './use-categorized-results'
import { useResults } from './use-results'

export const useResultIds = () => {
  const results = useResults()
  return useMemo(
    () =>
      Object.values(results).reduce(
        (ids, documents) => [...ids, ...documents.map((result) => result.id)],
        [] as string[],
      ),
    [results],
  )
}

export const useFireResults = (): FireResult[] =>
  useResults()[RESULT_CATEGORIES.FIRE] || []

export const useShipResults = (): ShipResult[] =>
  useResults()[RESULT_CATEGORIES.SHIP] || []

export const useImageResults = (): ImageryResult[] =>
  useResults()[RESULT_CATEGORIES.IMAGE] || []

export const usePublicationResults = (): PublicationResult[] =>
  useResults()[RESULT_CATEGORIES.PUBLICATION] || []

export const useSocialMediaResults = (): SocialMediaResult[] =>
  useResults()[RESULT_CATEGORIES.SOCIAL_MEDIA] || []

export const useInsightResults = (): InsightResult[] =>
  useResults()[RESULT_CATEGORIES.INSIGHT] || []

export const useAttomResults = (): AttomResult[] =>
  useResults()[RESULT_CATEGORIES.ATTOM] || []
export const useHazardPropertyResults = (): HazardPropertyResult[] =>
  useResults()[RESULT_CATEGORIES.HAZARD_PROPERTY] || []
export const useHazardRiskResults = (): HazardRiskResult[] =>
  useResults()[RESULT_CATEGORIES.HAZARD_RISK] || []
export const useRegridResults = (): RegridResult[] =>
  useResults()[RESULT_CATEGORIES.REGRID] || []
export const useVexcelResults = (): VexcelResult[] =>
  useResults()[RESULT_CATEGORIES.VEXCEL] || []

export const usePropertyResults = (): PropertyResult[] => {
  const results = useResults()
  return [
    ...(results[SEARCH_CATEGORIES.ATTOM] || []),
    ...(results[SEARCH_CATEGORIES.HAZARD_PROPERTY] || []),
    ...(results[SEARCH_CATEGORIES.HAZARD_RISK] || []),
    ...(results[SEARCH_CATEGORIES.REGRID] || []),
    ...(results[RESULT_CATEGORIES.VEXCEL] || []),
  ]
}

export const useHasResults = () => useAllResults().length > 0
export const useDataResults = () => useCategorizedResults().dataResults
export const useResultsById = () => {
  const results = useAllResults()
  return useMemo(() => {
    return Object.fromEntries(results.map((result) => [result.id, result]))
  }, [results]) as Record<string, ResultWithId>
}
