export const MESSAGES = {
  addMoreFiles: 'Add More Files',
  cancel: 'Cancel',
  uploadFiles: 'Upload Files',
}

export const ACCEPTED_FILE_FORMATS = [
  'image/png',
  'image/jpeg',
  'image/tiff',
  'application/pdf',
  'application/geo+json',
  '.kml',
  '.kmz',
  '.json',
  '.ppt',
  '.pptx',
  '.doc',
  '.docx',
]

export const MAX_FILE_SIZE_5GB = 5 * 1024 * 1024 * 1024
