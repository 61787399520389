const MAX_TRIES = 10
export const actOnMap = (callback: () => void) => {
  let attemptNumber = 0
  const tryToAct = () => {
    try {
      callback()
    } catch (error) {
      if (attemptNumber < MAX_TRIES) {
        attemptNumber++
        setTimeout(tryToAct, 100)
      } else {
        throw error
      }
    }
  }
  tryToAct()
}
