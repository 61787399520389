import React from 'react'

interface ErrorBoundaryProps {
  children: React.ReactNode
  fallback?: React.ReactNode
}

interface ErrorBoundaryState {
  hasError: boolean
}

// Use this ErrorBoundary when testing locally so that we don't overwhelm Sentry
export class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(_error: Error): ErrorBoundaryState {
    return { hasError: true }
  }

  override componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    console.error(error, errorInfo)
  }

  override render(): React.ReactNode {
    if (this.state.hasError) {
      return (
        this.props.fallback ?? (
          <div
            style={{
              border: '1px solid red',
              backgroundColor: 'pink',
              color: 'red',
              fontSize: '2em',
              fontWeight: 'bold',
              padding: '1em',
              textAlign: 'center',
            }}
          >
            Oops! Something went wrong!
          </div>
        )
      )
    }

    return this.props.children
  }
}
