import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(relativeTime)
dayjs.extend(timezone)
dayjs.extend(advancedFormat)

export const formatDatetime = (datetime: string): string =>
  dayjs(datetime).format('MM/DD/YYYY [at] hh:mm A z')

export const formatDate = (datetime: string): string =>
  dayjs(datetime).format('YYYY-MM-DD')

export const formatTime = (datetime: string): string =>
  dayjs(datetime).format('HH:mm z')

export const fromNow = (datetime: string): string => dayjs(datetime).fromNow()

export const formatDatetimeWithWordMonths = (datetime: string): string =>
  dayjs(datetime).format('MMMM DD, HH:mm')

export const formatDateWithWordMonths = (datetime: string): string =>
  dayjs(datetime).format('MMMM DD, YYYY')

export const isValidDatetime = (datetime: string): boolean =>
  dayjs(datetime).isValid()

export const hourlyFrequency = (hour: number) =>
  `DAILY(${dayjs().hour(hour).minute(0).format('HH:mm')})`
