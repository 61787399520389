import type { SocialMediaResult } from '@/utils/types/result-types'

export const getSocialImages = (result: SocialMediaResult) => {
  const embedded = result.properties._dEmbedded as
    | { url: string }[]
    | { _dEmbedded: { url: string }[] }
  if (!embedded) {
    return []
  }
  if (Array.isArray(embedded)) {
    return embedded
  }
  return embedded._dEmbedded
}
