/* eslint-disable camelcase */
/// <reference types="vite-plugin-svgr/client" />
import BioRiskIcon from '@/assets/riskIcons/bio_risk_icon.svg?react'
import DroughtRiskIcon from '@/assets/riskIcons/drought_risk_icon.svg?react'
import EarthquakeRiskIcon from '@/assets/riskIcons/earthquake_risk_icon.svg?react'
import FireRiskIcon from '@/assets/riskIcons/fire_risk_icon.svg?react'
import FloodRiskIcon from '@/assets/riskIcons/flood_risk_icon.svg?react'
import HailRiskIcon from '@/assets/riskIcons/hail_risk_icon.svg?react'
import HurricaneRiskIcon from '@/assets/riskIcons/hurricane_risk_icon.svg?react'
import HydrantRiskIcon from '@/assets/riskIcons/hydrant_risk_icon.svg?react'
import TornadoRiskIcon from '@/assets/riskIcons/tornado_risk_icon.svg?react'
import WindRiskIcon from '@/assets/riskIcons/wind_risk_icon.svg?react'
import type * as React from 'react'

export const RISK_ICONS = {
  drought: <DroughtRiskIcon />,
  earthquake: <EarthquakeRiskIcon />,
  fire_suppression: <FireRiskIcon />,
  flood: <FloodRiskIcon />,
  hail: <HailRiskIcon />,
  hurricane: <HurricaneRiskIcon />,
  hydrant: <HydrantRiskIcon />,
  mold: <BioRiskIcon />,
  tornado: <TornadoRiskIcon />,
  wildfire: <FireRiskIcon />,
  wind: <WindRiskIcon />,
  wind_region: <WindRiskIcon />,
} as { [key: string]: React.ReactNode }
