import * as React from 'react'
import { VIEWS } from '@/features/TimelineView/constants'

export const useSelectedView = () => {
  const [selectedView, setSelectedView] = React.useState(VIEWS.timelapse)
  return {
    selectedView,
    setSelectedView,
  }
}
