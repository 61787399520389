import type { TimelineDoctypes } from '@/features/ResultsTimeline/types'
import { COLORS, RESULT_CATEGORY_COLORS } from '@/theme/colors'

export const BAR_FILL_COLOR: {
  [key in TimelineDoctypes]: { normal: string; selected: string }
} = {
  IMAGE: {
    normal: COLORS.blue[4],
    selected: COLORS.blue[8],
  },
  ORTHO: {
    normal: COLORS.blue[4],
    selected: COLORS.blue[8],
  },
  PUBLICATION: {
    normal: COLORS.orange[4],
    selected: COLORS.orange[4],
  },
  SOCIAL_MEDIA: {
    normal: '#4335B1',
    selected: '#4335B1',
  },
  FIRE: {
    normal: RESULT_CATEGORY_COLORS.fire,
    selected: RESULT_CATEGORY_COLORS.fire,
  },
  SHIP: {
    normal: RESULT_CATEGORY_COLORS.ship,
    selected: RESULT_CATEGORY_COLORS.ship,
  },
  PROPERTY: { normal: '#C2185B', selected: '#C2185B' },
}

export const FOCUS_HIST_HEIGHT = 60
export const INNER_MARGIN = 10
export const CONTEXT_HIST_HEIGHT = 25
export const SVG_TOP_PADDING = 20
export const TIMELINE_INSET = 32

export const BUCKET_MAX_THRESHOLD = 100

export const timeBucketUnitKeys = [
  'HOUR',
  'DAY',
  'WEEK',
  'MONTH',
  'YEAR',
] as const

export const TimeBucketUnit: {
  [key in (typeof timeBucketUnitKeys)[number]]: string
} = {
  HOUR: 'HOUR',
  DAY: 'DAY',
  WEEK: 'WEEK',
  MONTH: 'MONTH',
  YEAR: 'YEAR',
} as const
