import { Image } from '@mantine/core'
import { IMAGE_STYLE_PROPS } from '@/features/GeometryStaticDisplay/constants'
import { useGeometryStaticDisplay } from '@/features/GeometryStaticDisplay/hooks'
import { classes } from '@/utils/ui-helpers'
import type { MultiPolygon, Point, Polygon } from 'geojson'

import styles from './geometry-static-display.module.css'

export const GeometryStaticDisplay = ({
  geometry,
  onClick,
  className,
  color,
}: {
  geometry?: MultiPolygon | Polygon | Point
  onClick?: () => void
  className?: string
  color?: string
}) => {
  const { ref, staticMapUrl } = useGeometryStaticDisplay(geometry, color)
  return (
    geometry && (
      <div
        ref={ref}
        onClick={onClick}
        className={classes(styles['geometry'], className)}
      >
        {staticMapUrl && (
          <Image src={staticMapUrl} alt="Geometry" {...IMAGE_STYLE_PROPS} />
        )}
      </div>
    )
  )
}

export default GeometryStaticDisplay
