import React from 'react'
import { Divider, Flex, Popover, Stack, Text } from '@mantine/core'
import { Icon } from '@/components/lib/Icon'
import { Slider } from '@/components/lib/Slider'
import { useQualityFilter, useSetFilters } from '@/stores/filters-store'

const qualities = [
  { label: '5 cm', value: '0.05', blurRadius: 0 },
  { label: '10 cm', value: '0.1', blurRadius: 1 },
  { label: '15 cm', value: '.15', blurRadius: 2 },
  { label: '30 cm', value: '0.3', blurRadius: 3 },
  { label: '50 cm', value: '0.5', blurRadius: 4 },
  { label: '1 m', value: '1', blurRadius: 5 },
  { label: '3 m', value: '3', blurRadius: 6 },
  { label: '10 m', value: '10', blurRadius: 7 },
  { label: '10 m+', value: '5000', blurRadius: 8 },
]

function getQualityIndex(value: string) {
  const qualityIndex = qualities.findIndex((quality) => quality.value === value)

  return qualityIndex > -1 ? qualityIndex : qualities.length - 1
}

export function QualitySelect() {
  const quality = useQualityFilter()
  const setFilters = useSetFilters()
  const [qualityIndex, setQualityIndex] = React.useState(
    getQualityIndex(quality),
  )

  React.useEffect(() => {
    setQualityIndex(getQualityIndex(quality))
  }, [quality])

  const selectedQualityLabel =
    qualities[qualityIndex]?.label || 'Select quality'

  return (
    <Popover position="bottom" width="target">
      <Popover.Target>
        <Flex justify="space-between" style={{ cursor: 'pointer' }}>
          <Text size="sm">{selectedQualityLabel}</Text>
          <Icon name="keyboard_arrow_down" />
        </Flex>
      </Popover.Target>
      <Popover.Dropdown>
        <Stack gap="sm">
          <Text size="sm">{selectedQualityLabel}</Text>
          <Slider
            mt="sm"
            label={(value) => qualities[value].label}
            value={qualityIndex}
            onChange={(value) => setQualityIndex(value)}
            onChangeEnd={(value) =>
              setFilters({ quality: qualities[value].value })
            }
            marks={qualities.map(({ label }, index) => ({
              value: index,
              label,
            }))}
            min={0}
            max={qualities.length - 1}
          />
          <Divider />
          <Text size="xs">
            Satellite image quality is measured in centimeters (cm) on the
            ground, representing the size of the smallest discernible detail in
            the image.
            <br />
            <br />
            The smaller the cm, the better the quality, however high detail is
            not always necessary.
          </Text>
        </Stack>
      </Popover.Dropdown>
    </Popover>
  )
}
