import {
  CONTEXT_HIST_HEIGHT,
  FOCUS_HIST_HEIGHT,
  INNER_MARGIN,
  SVG_TOP_PADDING,
} from '@/features/ResultsTimeline/constants'
import type {
  ResultTimelineBarFillHandler,
  TimelineDoctypes,
  TimelineHistogramConfig,
  TooltipData,
  VisualizationDatum,
} from '@/features/ResultsTimeline/types'
import { AxisBottom } from '@visx/axis'
import type BaseBrush from '@visx/brush/lib/BaseBrush'
import type { Bounds, Point } from '@visx/brush/lib/types'
import { Group } from '@visx/group'
import { BarStack } from '@visx/shape'
import type { Accessor } from '@visx/shape/lib/types'
import type { UseTooltipParams } from '@visx/tooltip/lib/hooks/useTooltip'
import type React from 'react'

let tooltipTimeout: number

export function ResultsTimelineBarGraph(props: {
  width: number
  focusHistogramConfig: TimelineHistogramConfig
  onBrushStart: (start: Point) => void
  onBrushEnd: (bounds: Bounds | null) => void
  onChange: (domain: Bounds | null) => void
  onClick: React.MouseEventHandler<SVGRectElement>
  innerRef: React.RefObject<BaseBrush>
  clearSelection: () => void
  x: Accessor<VisualizationDatum, any>
  color: (key: TimelineDoctypes, index: number) => string
  makeOnBarClick: (index: number) => React.MouseEventHandler<SVGRectElement>
  makeBarFillColor: ResultTimelineBarFillHandler
  small: boolean
  pointerEvents: boolean
  barTooltipParams: UseTooltipParams<TooltipData>
  dateHoverTooltipParams: UseTooltipParams<{ xDate: Date }>
}) {
  return (
    <svg
      width={props.width}
      height={
        FOCUS_HIST_HEIGHT + CONTEXT_HIST_HEIGHT + INNER_MARGIN + SVG_TOP_PADDING
      }
    >
      <Group>
        <Group
          top={SVG_TOP_PADDING}
          onMouseMove={(event) => {
            props.dateHoverTooltipParams.showTooltip({
              tooltipLeft: event.nativeEvent.offsetX,
              tooltipTop: event.nativeEvent.offsetY,
              tooltipData: {
                xDate: props.focusHistogramConfig.dateContinuousScale.invert(
                  event.nativeEvent.offsetX - 3, // MAGIC - makes the continuous scale line up with band scale
                ),
              },
            })
          }}
        >
          <rect
            x={0}
            width={props.width}
            height={FOCUS_HIST_HEIGHT}
            fillOpacity={0}
          />
          <Group>
            {props.focusHistogramConfig.visualizationData.length > 1 && (
              <BarStack<VisualizationDatum, TimelineDoctypes>
                data={props.focusHistogramConfig.visualizationData}
                keys={[
                  'IMAGE',
                  'FIRE',
                  'SHIP',
                  'SOCIAL_MEDIA',
                  'PUBLICATION',
                  'PROPERTY',
                ]}
                height={FOCUS_HIST_HEIGHT}
                x={props.x}
                xScale={props.focusHistogramConfig.dateBandScale}
                yScale={props.focusHistogramConfig.countScale}
                color={props.color}
              >
                {(barStacks) => {
                  return barStacks.map((barStack) =>
                    barStack.bars.map((bar) => {
                      return (
                        <rect
                          key={`barstack-${barStack.index}-${bar.index}`}
                          x={bar.x}
                          y={bar.y}
                          width={bar.width}
                          height={bar.height}
                          fill={props.makeBarFillColor(bar)}
                          style={{
                            pointerEvents: props.pointerEvents
                              ? 'auto'
                              : 'none',
                          }}
                          onMouseLeave={() => {
                            tooltipTimeout = window.setTimeout(() => {
                              props.barTooltipParams.hideTooltip()
                            }, 300)
                          }}
                          onMouseMove={() => {
                            if (tooltipTimeout) {
                              clearTimeout(tooltipTimeout)
                            }

                            props.barTooltipParams.showTooltip({
                              tooltipData: bar,
                              tooltipTop: FOCUS_HIST_HEIGHT - bar.y,
                              tooltipLeft: bar.x,
                            })
                          }}
                        />
                      )
                    }),
                  )
                }}
              </BarStack>
            )}
          </Group>
          <AxisBottom
            scale={props.focusHistogramConfig.dateBandScale}
            top={FOCUS_HIST_HEIGHT - 8}
            hideAxisLine
            hideTicks
            tickFormat={(s) => {
              const d = new Date(s)
              return props.focusHistogramConfig.timeBucketConfig.tickDisplayFn(
                d,
              )
            }}
            numTicks={props.small ? 12 : 30}
          />
        </Group>
      </Group>
    </svg>
  )
}
