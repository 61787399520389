import { ImageReferenceCard } from '@/features/ResultCards'
import { useSubscribeScrollIntoView } from '@/hooks/use-subscribe-scroll-into-view.ts'
import {
  useActiveOrthoLayerGroupIds,
  useSetHoveredLayerId,
} from '@/stores/map-store'
import type { OrthoImageryLayerGroup } from '@/utils/types/ortho-imagery-types'
import { classes } from '@/utils/ui-helpers'
import type React from 'react'

import styles from '@/features/ResultCards/ReferenceCards/components/reference-card.module.css'

export interface ThumbnailProps extends React.HTMLAttributes<HTMLImageElement> {
  item: OrthoImageryLayerGroup
  className: string
}

export const OrthoThumbnail = ({ item, className }: ThumbnailProps) => {
  const { id, layers } = item
  const activeOrthoLayerIds = useActiveOrthoLayerGroupIds()
  const setHoveredLayerId = useSetHoveredLayerId()
  const { targetRef } = useSubscribeScrollIntoView(
    item.id,
    activeOrthoLayerIds.find((layerId) => layerId === id),
  )
  const result = layers[0].imageResult
  return (
    <div
      className={classes(styles.image, className)}
      onMouseEnter={() => setHoveredLayerId(id)}
      onMouseLeave={() => setHoveredLayerId(null)}
      ref={targetRef}
    >
      <ImageReferenceCard result={result} />
    </div>
  )
}
