import React from 'react'
import { Navigate } from 'react-router-dom'
import { REDIRECT_COOKIE_NAME } from '@/utils/constants.ts'

export function Redirect() {
  const redirectTo =
    window.sessionStorage.getItem(REDIRECT_COOKIE_NAME)?.replaceAll('"', '') ??
    '/'

  React.useEffect(() => {
    return () => {
      window.sessionStorage.removeItem(REDIRECT_COOKIE_NAME)
    }
  }, [])

  return <Navigate to={redirectTo} replace />
}

export default Redirect
