import { graphql } from '@/gql'
import { useFeatureFlag } from '@/hooks/use-feature-flag'
import { FEATURE_FLAG_VALUES } from '@/utils/constants'
import { useQuery } from '@apollo/client'

export const ProfileQuery = graphql(`
  query queries_ProfileQuery {
    me {
      id
      id
      firstName
      lastName
      address
      address2
      role
      city
      state
      zip
      country
      email
    }
  }
`)

const emptyProfile = {
  id: '',
  firstName: '',
  lastName: '',
  address: '',
  address2: '',
  role: '',
  city: '',
  state: '',
  zip: '',
  country: '',
  email: '',
}

export function useProfile() {
  const accountEnabled = useFeatureFlag(FEATURE_FLAG_VALUES.accountManagement)
  const { data } = useQuery(ProfileQuery, { skip: !accountEnabled })

  return data?.me ?? emptyProfile
}

export function useEmail() {
  const user = useProfile()
  return user?.email
}
