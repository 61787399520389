import {
  ActionIcon,
  Group,
  Menu,
  Stack,
  Table,
  Text,
  Title,
} from '@mantine/core'
import type { HistoryItem } from '@/api/history'
import { Icon } from '@/components/lib/Icon'
import { HistoryRow } from './HistoryRow'

// Given a date, return the start and end datetimes of the day
function getDayRange(date: Date): { startAt: Date; endAt: Date } {
  // Clone the date to avoid modifying the original date object
  const startAt = new Date(date)
  const endAt = new Date(date)

  startAt.setHours(0, 0, 0, 0)
  endAt.setHours(23, 59, 59, 999)

  return { startAt, endAt }
}

export interface HistorySectionData {
  date: Date
  items: HistoryItem[]
}

export interface HistorySectionProps {
  date: Date
  title: string
  items: HistoryItem[]
  selectedItemIds: string[]
  onToggle: (itemId: string) => void
  onClick: (item: HistoryItem) => void
  onRemove: (itemId: string) => void
  onRemoveDateRange: (startAt: Date, endAt: Date) => void
  onMonitorLocation: (item: HistoryItem) => void
}

export function HistoryDateSection({
  date,
  title,
  items,
  selectedItemIds,
  onToggle,
  onClick,
  onRemove,
  onRemoveDateRange,
  onMonitorLocation,
}: HistorySectionProps) {
  const handleRemoveDay = () => {
    const { startAt, endAt } = getDayRange(date)
    onRemoveDateRange(startAt, endAt)
  }

  return (
    <Stack gap={6}>
      <Group justify="space-between" mr="xs">
        <Title size={25}>{title}</Title>
        <Menu position="left">
          <Menu.Target>
            <ActionIcon variant="subtle" color="black">
              <Icon name="more_horiz" />
            </ActionIcon>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item onClick={handleRemoveDay} c="red">
              <Group gap={6}>
                <Icon size="xs" name="delete_outline" />
                <Text size="xs">{`Delete this day's queries`}</Text>
              </Group>
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Group>
      <Table>
        <Table.Tbody>
          {items.map((item) => (
            <HistoryRow
              item={item}
              isSelected={selectedItemIds.includes(item.queryId)}
              key={item.queryId}
              onToggle={onToggle}
              onClick={onClick}
              onRemove={onRemove}
              onMonitorLocation={onMonitorLocation}
            />
          ))}
        </Table.Tbody>
      </Table>
    </Stack>
  )
}
