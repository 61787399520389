import React from 'react'
import { useDebouncedValue } from '@mantine/hooks'
import { openFileUploadModal } from '@/components/lib/FileUpload/opener'
import {
  openCreateCollectionModal,
  openSaveSearchModal,
} from '@/features/AppLayout/Modals'
import { useProviders } from '@/features/Filters'
import { useCollectionsState } from '@/stores/collections'
import { captureEvent, POSTHOG_EVENTS } from '@/utils/posthog'

export function useCollectionsHeader() {
  const { search } = useCollectionsState()
  const { newProviders } = useProviders()

  const [searchTerm, setSearchTerm] = React.useState('')
  const [debouncedSearchTerm] = useDebouncedValue(searchTerm, 300)

  React.useEffect(() => {
    if (debouncedSearchTerm !== '') {
      captureEvent(POSTHOG_EVENTS.COLLECTIONS.SEARCH, {
        searchQuery: debouncedSearchTerm,
      })
    }

    search(debouncedSearchTerm)
  }, [search, debouncedSearchTerm])

  const handleNewCollectionClick = openCreateCollectionModal

  return {
    handleNewCollectionClick,
    handleNewFeedClick: () =>
      openSaveSearchModal({
        isNew: false,
        providers: newProviders,
        params: {
          mode: 'feed',
          name: '',
          query: '',
          locations: [],
        },
      }),
    handleUploadFileClick: () => openFileUploadModal(null),
    searchTerm,
    setSearchTerm,
  }
}
