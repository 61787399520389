import { getRequest } from '@/api/danti-requests'
import { normalizeDownloadParams } from '@/api/helper'
import { serviceApiUrl } from '@/utils/constants.ts'
import {
  isAnalyticResult,
  isImageryResult,
  type ResultWithId,
} from '@/utils/types/result-types.ts'
import { useQuery } from '@tanstack/react-query'

const makeDownloadCheckUrl = (
  source: string | undefined,
  provider: string,
  id: string,
) => {
  // Normalize inputs, eg Planet Labs -> Planet
  const _provider = normalizeDownloadParams(provider)

  if (source && source != provider) {
    const _source = normalizeDownloadParams(source)
    return `/download/check/${_provider}/${id}?source=${_source}`
  }

  return `/download/check/${_provider}/${id}`
}

async function checkDownloadAvailability(item: ResultWithId) {
  if (!isImageryResult(item) && !isAnalyticResult(item)) {
    return false
  }

  const { _dProvider, _dId, _dSource } = item.properties

  const downloadCheckUrl = makeDownloadCheckUrl(_dSource, _dProvider, _dId)

  try {
    await getRequest(downloadCheckUrl, {
      baseURL: serviceApiUrl,
    })
    return true
  } catch {
    return false
  }
}

const validSources = [
  ['', 'GEGD'],
  ['MAXAR', 'GEGD'],
  ['PLANET', 'CSPO'], // Future
  ['PLANET', 'GEGD'], // Future
  ['PLANET LABS', 'CSPO'],
  ['PLANET LABS', 'GEGD'],
  ['CAPELLA', 'CSPO'],
  ['CAPELLA', 'GEGD'],
]
const sourceId = (data: string[]) =>
  data
    .map((s) => s || '')
    .join(' - ')
    .toUpperCase()

export function useIsDownloadable(item: ResultWithId) {
  const source = [
    item.properties._dSource,
    item.properties._dProvider,
  ] as string[]
  const isEnabled = validSources.some(
    (valid) => sourceId(valid) === sourceId(source),
  )
  return useQuery({
    queryKey: ['isDownloadable', item.documentId],
    queryFn: async () => checkDownloadAvailability(item),
    retry: false,
    enabled: isEnabled,
  }).data
}
