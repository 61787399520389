import type { CollectionItem } from '@/api/collections/types'
import { ResultActions } from '@/features/ResultActions'
import { HIDE_ACTIONS_BY_CATEGORY } from '@/features/ResultActions/constants'
import type { ActionMenuOption } from '@/features/ResultActions/types.ts'
import { MENU_OPTION_KEYS } from '@/utils/constants.ts'
import type { ResultWithId } from '@/utils/types/raw-result-types'
import { getResultCategory } from '@/utils/types/result-type-checkers'

export interface CollectionDetailMenuProps {
  item: CollectionItem
}

export const CollectionDetailMenu = ({ item }: CollectionDetailMenuProps) => {
  const { doc } = item
  const result = JSON.parse(doc) as ResultWithId
  const category = getResultCategory(
    result,
  ) as keyof typeof HIDE_ACTIONS_BY_CATEGORY

  const hideActions: ActionMenuOption[] =
    HIDE_ACTIONS_BY_CATEGORY[category] || []
  hideActions.push(MENU_OPTION_KEYS.viewOnMap)

  return (
    <ResultActions
      item={item}
      result={result}
      hideActions={hideActions}
      iconClassName="more_horz"
      className=""
    />
  )
}
