import { modals } from '@mantine/modals'
import { FileUploadModalHeader } from './FileUploadModal'

export function openFileUploadModal(collectionId: string | null) {
  modals.openContextModal({
    size: 'lg',
    modal: 'uploadFile',
    title: <FileUploadModalHeader />,
    centered: true,
    innerProps: { collectionId },
  })
}
