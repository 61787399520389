import React from 'react'
import { Card, type CardProps, Group, Text } from '@mantine/core'
import type { CollectionItem } from '@/api/collections/types'
import { useMenuOptionActions } from '@/features/ResultActions/hooks'
import { useFeatureFlag } from '@/hooks/use-feature-flag'
import { useShowScores } from '@/stores/config-store'
import { FEATURE_FLAG_VALUES } from '@/utils/constants'
import { captureEvent, POSTHOG_EVENTS } from '@/utils/posthog'
import { isFileResult } from '@/utils/types/result-type-checkers'
import { isImageryResult, type ResultWithId } from '@/utils/types/result-types'
import { classes } from '@/utils/ui-helpers'

import styles from './result-base.module.css'

interface ResultBaseProps extends CardProps {
  item?: CollectionItem
  result: ResultWithId
  children?: React.ReactNode
  className?: string
  disableNavigate?: boolean
}

export const ResultBase = React.forwardRef(
  (
    {
      result,
      children,
      className,
      item,
      disableNavigate,
      ...props
    }: ResultBaseProps,
    forwardedRef: React.ForwardedRef<HTMLDivElement>,
  ) => {
    const isShowScoresFeatureEnabled = useFeatureFlag(
      FEATURE_FLAG_VALUES.showScores,
    )
    const showScores = useShowScores() && isShowScoresFeatureEnabled
    const isAnImageResult = isImageryResult(result) || isFileResult(result)

    const actions = useMenuOptionActions({ result, item })
    return (
      <Card
        ref={forwardedRef}
        onClick={(event) => {
          captureEvent(POSTHOG_EVENTS.RESULTS.CLICKED, {
            resultCategory: result.properties.resultCategory ?? result.category,
          })
          actions.viewDetails(event)
        }}
        className={classes(styles['hidden-panel'], className)}
        radius={0}
        pos="relative"
        p={0}
        pb={isAnImageResult && showScores ? 'sm' : 0}
        fz="sm"
        c="gray.5"
        {...props}
      >
        {children}
        {showScores && (
          <Group
            pos="absolute"
            bottom={0}
            left={isAnImageResult ? 0 : 'var(--mantine-spacing-sm)'}
          >
            <Text size="xs" fw={700}>
              score: {result.score}
            </Text>
          </Group>
        )}
      </Card>
    )
  },
)

ResultBase.displayName = 'ResultBase'
