import { Box, Button, Group } from '@mantine/core'
import type { ContextModalProps } from '@mantine/modals'
import { DataDisplay } from '@/components/lib/DataDisplay.tsx'
import type { ResultWithId } from '@/utils/types/result-types.ts'

export function ResultDetailsModal({
  context,
  id: modalId,
  innerProps: { result },
}: ContextModalProps<{ result: ResultWithId }>) {
  return result ? (
    <>
      <Box mah={400} style={{ overflowY: 'auto' }} px="md">
        <DataDisplay data={result.properties} />
      </Box>
      <Group justify="flex-end" pt="md">
        <Button
          size="xs"
          tt="uppercase"
          variant="primary"
          onClick={() => context.closeModal(modalId)}
        >
          Close
        </Button>
      </Group>
    </>
  ) : null
}
