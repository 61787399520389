import type { SearchApiFilterValue } from '@/utils/types/filter-types.ts'

export const makeSensorTypeFilter = (
  sensorTypes: string[],
): SearchApiFilterValue => {
  return {
    type: 'SENSOR',
    sensors: [...sensorTypes],
  }
}
