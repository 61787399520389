/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never }
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never
    }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  openfed__Scope: { input: any; output: any }
}

export type CreateUserInput = {
  email: Scalars['String']['input']
  firstName: Scalars['String']['input']
  id: Scalars['ID']['input']
  lastName: Scalars['String']['input']
}

export type Feedback = {
  __typename?: 'Feedback'
  createdAt: Scalars['String']['output']
  customFeedback?: Maybe<Scalars['String']['output']>
  feedback: Array<FeedbackReason>
  id: Scalars['String']['output']
  queryId: Scalars['String']['output']
}

export enum FeedbackReason {
  Incorrect = 'INCORRECT',
  Irrelevant = 'IRRELEVANT',
  Location = 'LOCATION',
  NoAnswer = 'NO_ANSWER',
  NoImages = 'NO_IMAGES',
  NoNews = 'NO_NEWS',
  NoSourcesLinked = 'NO_SOURCES_LINKED',
  TooVerbose = 'TOO_VERBOSE',
}

export type Mutation = {
  __typename?: 'Mutation'
  inviteUsers: Scalars['Boolean']['output']
  newOrg?: Maybe<Org>
  newSource: Source
  newUser?: Maybe<User>
  removeUserFromOrg?: Maybe<Scalars['Boolean']['output']>
  resetPassword?: Maybe<Scalars['Boolean']['output']>
  submitFeedback?: Maybe<Feedback>
  submitNoSearchResults?: Maybe<Feedback>
  updateUser?: Maybe<User>
  updateUserRole?: Maybe<User>
}

export type MutationInviteUsersArgs = {
  emails: Array<Scalars['String']['input']>
  role: OrgRole
}

export type MutationNewOrgArgs = {
  newOrg: NewOrg
}

export type MutationNewSourceArgs = {
  newSource: NewSource
  userId?: InputMaybe<Scalars['String']['input']>
}

export type MutationNewUserArgs = {
  newUser: CreateUserInput
  orgName?: InputMaybe<Scalars['String']['input']>
}

export type MutationRemoveUserFromOrgArgs = {
  userId: Scalars['ID']['input']
}

export type MutationResetPasswordArgs = {
  userId: Scalars['ID']['input']
}

export type MutationSubmitFeedbackArgs = {
  newFeedback: NewFeedback
}

export type MutationSubmitNoSearchResultsArgs = {
  query: Scalars['String']['input']
  queryId: Scalars['String']['input']
}

export type MutationUpdateUserArgs = {
  input: UpdateUserInput
}

export type MutationUpdateUserRoleArgs = {
  role: OrgRole
  userId: Scalars['ID']['input']
}

export type NewFeedback = {
  customFeedback?: InputMaybe<Scalars['String']['input']>
  feedback: Array<FeedbackReason>
  id?: InputMaybe<Scalars['String']['input']>
  queryId: Scalars['String']['input']
}

export type NewOrg = {
  name: Scalars['String']['input']
}

export type NewSource = {
  key: Scalars['String']['input']
  name: Scalars['String']['input']
  orgId: Scalars['String']['input']
  orgRole: Scalars['String']['input']
  provider: Scalars['String']['input']
}

export type Org = {
  __typename?: 'Org'
  createdAt: Scalars['String']['output']
  id: Scalars['String']['output']
  name: Scalars['String']['output']
  owner: User
  updatedAt: Scalars['String']['output']
  users: UserConnection
}

export type OrgUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  search?: InputMaybe<Scalars['String']['input']>
}

export enum OrgRole {
  Admin = 'ADMIN',
  Editor = 'EDITOR',
  Owner = 'OWNER',
  Viewer = 'VIEWER',
}

/** Information about pagination in a connection */
export type PageInfo = {
  __typename?: 'PageInfo'
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output']
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output']
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>
}

export type Query = {
  __typename?: 'Query'
  feedback?: Maybe<Feedback>
  me?: Maybe<User>
  sources: SourceConnection
  user?: Maybe<User>
  userByEmail?: Maybe<User>
}

export type QueryFeedbackArgs = {
  feedbackId: Scalars['ID']['input']
}

export type QuerySourcesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  role?: InputMaybe<Role>
  userId: Scalars['String']['input']
}

export type QueryUserArgs = {
  id: Scalars['ID']['input']
}

export type QueryUserByEmailArgs = {
  email: Scalars['String']['input']
}

export enum Role {
  Downloader = 'DOWNLOADER',
  Editor = 'EDITOR',
  Owner = 'OWNER',
  Viewer = 'VIEWER',
}

export type Source = {
  __typename?: 'Source'
  createdAt: Scalars['String']['output']
  id: Scalars['String']['output']
  name: Scalars['String']['output']
  provider: Scalars['String']['output']
  role: Role
  updatedAt: Scalars['String']['output']
}

export type SourceConnection = {
  __typename?: 'SourceConnection'
  /** A list of edges. */
  edges: Array<SourceEdge>
  /** A list of nodes. */
  nodes: Array<Source>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

/** An edge in a connection. */
export type SourceEdge = {
  __typename?: 'SourceEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge */
  node: Source
}

export type UpdateUserInput = {
  address?: InputMaybe<Scalars['String']['input']>
  address2?: InputMaybe<Scalars['String']['input']>
  city?: InputMaybe<Scalars['String']['input']>
  country?: InputMaybe<Scalars['String']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  firstName?: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
  lastName?: InputMaybe<Scalars['String']['input']>
  state?: InputMaybe<Scalars['String']['input']>
  zip?: InputMaybe<Scalars['String']['input']>
}

export type User = {
  __typename?: 'User'
  address: Scalars['String']['output']
  address2: Scalars['String']['output']
  city: Scalars['String']['output']
  country: Scalars['String']['output']
  createdAt: Scalars['String']['output']
  email: Scalars['String']['output']
  firstName: Scalars['String']['output']
  hasActiveSubscription: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  lastName: Scalars['String']['output']
  org: Org
  orgId: Scalars['String']['output']
  role: Scalars['String']['output']
  sources: SourceConnection
  state: Scalars['String']['output']
  subscriptionStatus?: Maybe<Scalars['String']['output']>
  updatedAt: Scalars['String']['output']
  zip: Scalars['String']['output']
}

export type UserSourcesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  role?: InputMaybe<Role>
}

export type UserConnection = {
  __typename?: 'UserConnection'
  /** A list of edges. */
  edges: Array<UserEdge>
  /** A list of nodes. */
  nodes: Array<User>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

/** An edge in a connection. */
export type UserEdge = {
  __typename?: 'UserEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge */
  node: User
}

export type OrgUsersQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
}>

export type OrgUsersQuery = {
  __typename?: 'Query'
  me?: {
    __typename?: 'User'
    id: string
    org: {
      __typename?: 'Org'
      id: string
      users: {
        __typename?: 'UserConnection'
        pageInfo: {
          __typename?: 'PageInfo'
          startCursor?: string | null
          endCursor?: string | null
          hasNextPage: boolean
          hasPreviousPage: boolean
        }
        nodes: Array<{
          __typename?: 'User'
          id: string
          firstName: string
          lastName: string
          email: string
          role: string
        }>
      }
    }
  } | null
}

export type SourcesByUserQueryVariables = Exact<{ [key: string]: never }>

export type SourcesByUserQuery = {
  __typename?: 'Query'
  me?: {
    __typename?: 'User'
    id: string
    sources: {
      __typename?: 'SourceConnection'
      nodes: Array<{ __typename?: 'Source'; provider: string }>
    }
  } | null
}

export type SubmitFeedbackMutationVariables = Exact<{
  newFeedback: NewFeedback
}>

export type SubmitFeedbackMutation = {
  __typename?: 'Mutation'
  submitFeedback?: {
    __typename?: 'Feedback'
    id: string
    queryId: string
    feedback: Array<FeedbackReason>
    customFeedback?: string | null
  } | null
}

export type HasActiveSubscriptionQueryVariables = Exact<{
  [key: string]: never
}>

export type HasActiveSubscriptionQuery = {
  __typename?: 'Query'
  me?: {
    __typename?: 'User'
    id: string
    org: {
      __typename?: 'Org'
      id: string
      owner: { __typename?: 'User'; id: string; hasActiveSubscription: boolean }
    }
  } | null
}

export type UserRoleQueryVariables = Exact<{ [key: string]: never }>

export type UserRoleQuery = {
  __typename?: 'Query'
  me?: { __typename?: 'User'; id: string; role: string } | null
}

export type UserIdQueryVariables = Exact<{ [key: string]: never }>

export type UserIdQuery = {
  __typename?: 'Query'
  me?: { __typename?: 'User'; id: string } | null
}

export type ResetPasswordMutationVariables = Exact<{
  userId: Scalars['ID']['input']
}>

export type ResetPasswordMutation = {
  __typename?: 'Mutation'
  resetPassword?: boolean | null
}

export type RemoveUserFromOrgMutationVariables = Exact<{
  userId: Scalars['ID']['input']
}>

export type RemoveUserFromOrgMutation = {
  __typename?: 'Mutation'
  removeUserFromOrg?: boolean | null
}

export type Queries_ProfileQueryQueryVariables = Exact<{ [key: string]: never }>

export type Queries_ProfileQueryQuery = {
  __typename?: 'Query'
  me?: {
    __typename?: 'User'
    id: string
    firstName: string
    lastName: string
    address: string
    address2: string
    role: string
    city: string
    state: string
    zip: string
    country: string
    email: string
  } | null
}

export type UpdateProfileMutationVariables = Exact<{
  input: UpdateUserInput
}>

export type UpdateProfileMutation = {
  __typename?: 'Mutation'
  updateUser?: {
    __typename?: 'User'
    id: string
    firstName: string
    lastName: string
    address: string
    address2: string
    city: string
    state: string
    zip: string
    country: string
    email: string
  } | null
}

export type UpdateUserRoleMutationVariables = Exact<{
  userId: Scalars['ID']['input']
  role: OrgRole
}>

export type UpdateUserRoleMutation = {
  __typename?: 'Mutation'
  updateUserRole?: { __typename?: 'User'; id: string } | null
}

export type InviteUsersMutationVariables = Exact<{
  emails: Array<Scalars['String']['input']> | Scalars['String']['input']
  role: OrgRole
}>

export type InviteUsersMutation = {
  __typename?: 'Mutation'
  inviteUsers: boolean
}

export const OrgUsersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'OrgUsers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'search' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'before' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'me' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'org' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'users' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'search' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'search' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'first' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'first' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'after' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'after' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'before' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'before' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pageInfo' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'startCursor',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'endCursor' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'hasNextPage',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'hasPreviousPage',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'nodes' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'firstName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'email' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'role' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OrgUsersQuery, OrgUsersQueryVariables>
export const SourcesByUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SourcesByUser' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'me' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sources' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nodes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'provider' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SourcesByUserQuery, SourcesByUserQueryVariables>
export const SubmitFeedbackDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SubmitFeedback' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'newFeedback' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'NewFeedback' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submitFeedback' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'newFeedback' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'newFeedback' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'queryId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'feedback' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customFeedback' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SubmitFeedbackMutation,
  SubmitFeedbackMutationVariables
>
export const HasActiveSubscriptionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'HasActiveSubscription' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'me' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'org' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'owner' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'hasActiveSubscription',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  HasActiveSubscriptionQuery,
  HasActiveSubscriptionQueryVariables
>
export const UserRoleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UserRole' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'me' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserRoleQuery, UserRoleQueryVariables>
export const UserIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UserID' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'me' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserIdQuery, UserIdQueryVariables>
export const ResetPasswordDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ResetPassword' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resetPassword' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'userId' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>
export const RemoveUserFromOrgDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RemoveUserFromOrg' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeUserFromOrg' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'userId' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RemoveUserFromOrgMutation,
  RemoveUserFromOrgMutationVariables
>
export const Queries_ProfileQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'queries_ProfileQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'me' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Queries_ProfileQueryQuery,
  Queries_ProfileQueryQueryVariables
>
export const UpdateProfileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateProfile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateUserInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateProfileMutation,
  UpdateProfileMutationVariables
>
export const UpdateUserRoleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateUserRole' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'role' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'OrgRole' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateUserRole' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'userId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'role' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'role' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateUserRoleMutation,
  UpdateUserRoleMutationVariables
>
export const InviteUsersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'InviteUsers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'emails' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'String' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'role' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'OrgRole' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'inviteUsers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'emails' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'emails' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'role' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'role' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InviteUsersMutation, InviteUsersMutationVariables>
