import { PAGE_SIZE } from '@/api/collections/collection-items/constants'
import type {
  AddItemParams,
  DeleteItemParams,
} from '@/api/collections/collection-items/types'
import type { CollectionItem } from '@/api/collections/types'
import { useCollectionItemsState } from '@/stores/collection-items'
import { useQueryClient } from '@tanstack/react-query'
import { useCollectionItemsMutations } from './use-collection-items-mutations'
import { useCollectionItemsQuery } from './use-collection-items-query'

export interface UseCollectionItemsHookResponse {
  items: CollectionItem[]
  totalCount: number
  isLoading: boolean
  pageSize: number
  filter?: string

  nextPage: () => void
  previousPage: () => void
  add: (params: AddItemParams) => Promise<void>
  remove: (params: DeleteItemParams) => Promise<void>
}

export function useCollectionItems(
  collectionId?: string,
): UseCollectionItemsHookResponse {
  const queryClient = useQueryClient()
  const state = useCollectionItemsState()

  const { next, prev, filter, items, isLoading, totalCount, maxPages } =
    useCollectionItemsQuery({ collectionId, ...state })

  const { addItem, removeItem } = useCollectionItemsMutations(queryClient)

  const nextPage = () => {
    if (next) {
      state.toNextPage(next, maxPages)
    }
  }

  const previousPage = () => {
    if (prev) {
      state.toPreviousPage(prev)
    }
  }

  return {
    items,
    totalCount,
    isLoading,
    pageSize: PAGE_SIZE,
    filter,
    nextPage,
    previousPage,
    add: addItem,
    remove: removeItem,
  }
}
