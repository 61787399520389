import * as React from 'react'
import { classes } from '@/utils/ui-helpers'

import styles from './bento-section.module.css'

export const CELL_HEIGHT = 142
export const CELL_MARGIN = 5

export interface BentoSectionProps {
  isPrimary?: boolean
  isOverflow?: boolean
  isLoading?: boolean
  children?: React.ReactNode
  className?: string
}

const EASE_TIME = 300
const FRAME_RATE = 300

export function BentoSection({
  isPrimary = false,
  isOverflow = false,
  isLoading = false,
  children,
  className = '',
}: BentoSectionProps) {
  let height = CELL_HEIGHT as number | undefined
  if (isPrimary) {
    height = 2 * (CELL_HEIGHT + CELL_MARGIN)
  }
  if (isOverflow) {
    height = undefined
  }
  const width = height

  const [showSkeleton, setShowSkeleton] = React.useState(false)

  const updateSkeleton = () => {
    if (Math.random() >= 0.7) {
      setShowSkeleton((show) => !show)
    }
  }

  React.useEffect(() => {
    if (isLoading) {
      updateSkeleton()
      const interval = setInterval(updateSkeleton, EASE_TIME + FRAME_RATE)
      return () => clearTimeout(interval)
    }
  }, [isLoading])

  if (isLoading) {
    return (
      <div
        style={{ width, height }}
        className={styles[showSkeleton ? 'show-skeleton' : 'hide-skeleton']}
      />
    )
  }

  const classNames = [styles['bento-section'], className]
  if (isOverflow) {
    classNames.push(styles['overflow'])
  }
  return (
    <div className={classes(...classNames)} style={{ height, width }}>
      {children}
    </div>
  )
}

export default BentoSection
