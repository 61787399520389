import { getRequest } from '@/api/danti-requests'

export const fetchSignedS3URL = async (thumbnailUrl: string) => {
  const url = new URL(thumbnailUrl)

  return getRequest<{ url: string }>(`/provider${url.pathname}`).then(
    (response) => {
      return response.data.url
    },
  )
}
