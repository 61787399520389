import { Image } from '@mantine/core'
import { getPropertyDataFields } from '@/api/results'
import { HIDE_ACTIONS_BY_CATEGORY } from '@/features/ResultActions/constants'
import { SingleObjectViewTemplate } from '@/features/SingleObjectView/components/SingleObjectViewTemplate'
import { useLocalNavigation } from '@/features/SingleObjectView/hooks'
import { RESULT_CATEGORIES } from '@/utils/constants'
import { formatDatetime } from '@/utils/format-datetime'
import type {
  HazardPropertyResult,
  ResultWithId,
} from '@/utils/types/result-types'

import styles from './single-object-view.module.css'

const category = RESULT_CATEGORIES.HAZARD_PROPERTY
const hideActions = HIDE_ACTIONS_BY_CATEGORY[category]

export function HazardPropertyObjectView({
  item: rawItem,
}: {
  item: ResultWithId
}) {
  const item = rawItem as HazardPropertyResult
  const images = item.properties.images ?? []

  const { localIndex, current, selectItem, toNext, toPrevious } =
    useLocalNavigation(images)

  return (
    <SingleObjectViewTemplate<HazardPropertyResult, string>
      {...{
        item,
        hideActions,
        hero: {
          staticImage: images.length > 0 ? current : undefined,
          imageAlt: item.title,
          toNext,
          toPrevious,
          metaDetails: [formatDatetime(item.authoredOn)],
          metaAction:
            images.length > 0 && localIndex !== undefined
              ? `${localIndex + 1} of ${images.length}`
              : undefined,
        },
        content: {
          title: item.properties.address,
          details: item.properties.publicRemarks,
          data: getPropertyDataFields(item),
        },
        carousel: {
          items: images,
          title: 'All Property Images',
          renderCard: (image) => (
            <Image
              src={image}
              alt="ALS image"
              className={styles['prop-category-card']}
            />
          ),
          selectItem,
          grid: true,
        },
      }}
    />
  )
}
