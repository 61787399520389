import { FeedbackReason } from '@/gql/graphql.ts'

export const DEFAULT_REASONS = {
  [FeedbackReason.NoAnswer]: 'No answer',
  [FeedbackReason.Irrelevant]: 'Irrelevant answer',
  [FeedbackReason.Location]: 'Wrong location',
  [FeedbackReason.Incorrect]: 'Not factually correct',
  [FeedbackReason.NoImages]: 'No images',
  [FeedbackReason.NoNews]: 'No news',
  [FeedbackReason.TooVerbose]: 'Too verbose',
  [FeedbackReason.NoSourcesLinked]: 'No sources linked',
} as const

export const PANEL_STATES = {
  BUTTON: 'button',
  PANEL: 'panel',
  DONE: 'done',
} as const

export const TEST_IDS = {
  customReasonClear: 'custom-reason-clear',
  insightFeedbackIcon: 'insight-feedback-icon',
  insightFeedbackClose: 'insight-feedback-close',
}
