const PAGE_NAME_REGEX = {
  Home: /^\/$/,
  All: /^\/(news|images|social|ships|fires|properties)$/,
  'Collection Item': /^\/collections\/\w+\/items\/\w+$/,
  'Collection Detail': /^\/collections\/\w+$/,
  'All Collections': /^\/collections$/,
  'Single Object View': /^\/results\/\w+$/,
  Explore: /^\/overview\?search=\w+$/,
  Map: /^\/map/,
  History: /^\/history$/,
  Account: /^\/account/,
  Login: /^\/login$/,
  Help: /^\/help$/,
} as const

export function parsePageNameFromPath(
  pathname: string = window.location.pathname,
): string {
  for (const [pageName, regex] of Object.entries(PAGE_NAME_REGEX)) {
    if (regex.test(pathname)) {
      if (pageName === 'All') {
        const category = pathname.match(regex)![1]
        return `All ${category[0].toUpperCase()}${category.slice(1)}`
      }
      return pageName
    }
  }
  return 'Unknown'
}
