import {
  useAttomWithPermits,
  useHazardPropertyResultsWithImages,
  useNewsWithImages,
  useNewsWithoutImages,
  usePropertyResults,
  useResults,
  useSocialWithImages,
  useSocialWithoutImages,
} from '@/api/results'
import {
  COMMERCIAL_BENTO_ORDER,
  GOV_BENTO_ORDER,
} from '@/features/BentoResults/constants'
import type { BentoCardData } from '@/features/BentoResults/types'
import { RESULT_CATEGORIES } from '@/utils/constants'
import {
  isAttomResult,
  isHazardPropertyResult,
  isPublicationResult,
  isVexcelResult,
  type ResultWithId,
} from '@/utils/types/result-types'
import { isHazardRiskResult } from '@/utils/types/single-address/hazard-risk-types'
import addResultToCardData from './add-result-to-card-data'

export function useBentoResults() {
  const cardData: BentoCardData[] = []
  const results = useResults() as Record<string, ResultWithId[]>
  const isProperty = usePropertyResults().length > 0
  const newsWithImages = useNewsWithImages()
  const newsWithoutImages = useNewsWithoutImages()
  const socialWithImages = useSocialWithImages()
  const socialWithoutImages = useSocialWithoutImages()
  const effectiveResults = {
    ...results,
    [RESULT_CATEGORIES.HAZARD_PROPERTY]: useHazardPropertyResultsWithImages(),
    [RESULT_CATEGORIES.PUBLICATION]: newsWithImages,
    [RESULT_CATEGORIES.SOCIAL_MEDIA]: socialWithImages,
    [RESULT_CATEGORIES.ATTOM]: useAttomWithPermits(),
  } as Record<string, ResultWithId[]>

  const resultOffset = {} as Record<string, number>

  const addResult = (
    category: string,
    index: number,
    subEntryIndex: number,
    includeTotal: boolean,
  ) =>
    addResultToCardData(
      cardData,
      category,
      index,
      subEntryIndex,
      effectiveResults[category],
      results[category],
      includeTotal,
    )

  if (isProperty) {
    // Each box will have a priority list of categories
    COMMERCIAL_BENTO_ORDER.forEach((categories) => {
      let isResolved = false

      categories.forEach((category) => {
        if (isResolved) {
          return
        }

        const results = effectiveResults[category]

        // No result for category
        if (!results?.length) {
          return
        }

        const [result] = results

        // There's only 1 regrid result and no subentries
        if (category == RESULT_CATEGORIES.REGRID) {
          if (resultOffset[category] === undefined) {
            resultOffset[category] = 1
            addResult(category, 0, 0, true)
            isResolved = true
          }
          return
        }

        const offset =
          resultOffset[category] === undefined ? 0 : resultOffset[category] + 1

        let entries = []
        let isSubEntryOffset = true

        if (isHazardPropertyResult(result)) {
          entries = result.properties.images
        } else if (isHazardRiskResult(result)) {
          entries = Object.keys(result.properties.risks)
        } else if (isAttomResult(result)) {
          entries = result.properties.buildingPermits || []
        } else if (isPublicationResult(result)) {
          entries = results
          isSubEntryOffset = false
        } else if (isVexcelResult(result)) {
          entries = result.properties.vexcel_images?.vexcel_images || []
        }

        if (offset >= entries.length) {
          return
        }

        resultOffset[category] = offset

        if (isSubEntryOffset) {
          addResult(category, 0, offset, true)
        } else {
          addResult(category, offset, 0, true)
        }
        isResolved = true
      })
    })
  } else {
    // Gov
    const order = GOV_BENTO_ORDER as string[]
    order.forEach((category: string) => {
      if (effectiveResults[category]?.length > 0) {
        addResult(category, 0, 0, true)
      }
    })
    let index = 1

    const bentoSize = 5
    while (cardData.length < bentoSize && index < bentoSize) {
      order.forEach((category: string) => {
        if (cardData.length >= bentoSize) {
          return
        }
        if (effectiveResults[category]?.length > index) {
          addResult(category, index, 0, false)
        }
      })
      index += 1
    }

    if (cardData.length < bentoSize) {
      index = 0
      const iconCategoryResults = {
        [RESULT_CATEGORIES.PUBLICATION]: newsWithoutImages,
        [RESULT_CATEGORIES.SOCIAL_MEDIA]: socialWithoutImages,
      } as Record<string, ResultWithId[]>

      const iconCategoryOrder = order.filter(
        (category: string) => iconCategoryResults[category]?.length > 0,
      )
      while (cardData.length < bentoSize && index < bentoSize) {
        iconCategoryOrder.forEach((category: string) => {
          if (iconCategoryResults[category]?.length > index) {
            addResultToCardData(
              cardData,
              category,
              index,
              0,
              iconCategoryResults[category],
              results[category],
              false,
            )
          }
        })
        index += 1
      }
    }
  }

  return { cardData, isProperty }
}
