import { Box, Button, Group, Menu } from '@mantine/core'
import { useCollectionItems } from '@/api/collections/collection-items'
import type { Collection } from '@/api/collections/types'
import { ConditionalTooltip } from '@/components/core/ConditionalTooltip'
import { Icon } from '@/components/lib/Icon'
import { Pager } from '@/components/pagination'
import {
  ActionsMenu,
  SearchInput,
  SortToggleButton,
  TypeControl,
} from '@/features/Collections/components'
import { useCollectionDetailHeader } from '@/features/Collections/hooks'
import type { ViewType } from '@/features/Collections/types'
import { useFeatureFlag } from '@/hooks/use-feature-flag'
import { useCollectionItemsState } from '@/stores/collection-items'
import { FEATURE_FLAG_VALUES } from '@/utils/constants'

export interface CollectionDetailHeaderProps {
  collection: Collection
  viewType: ViewType
  setViewType: (value: ViewType) => void
}

export function CollectionDetailActions({
  collection,
  viewType,
  setViewType,
}: CollectionDetailHeaderProps) {
  const state = useCollectionItemsState()
  const apiData = useCollectionItems(collection.id)
  const isCommercial = useFeatureFlag(FEATURE_FLAG_VALUES.commercial)

  const {
    disableShare,
    handleDownloadClick,
    handleShareClick,
    handleUploadClick,
    searchTerm,
    setSearchTerm,
    shareTooltip,
  } = useCollectionDetailHeader({ collection })

  const { totalCount } = apiData

  const icons = {
    download: <Icon name="download" />,
    upload: <Icon name="file_upload" />,
    people: <Icon name="people_alt" />,
  }

  return (
    <Group gap="sm">
      <SearchInput {...{ searchTerm, setSearchTerm }} />
      <TypeControl {...{ viewType, setViewType }} />
      <ActionsMenu>
        {!isCommercial && totalCount > 0 && (
          <Menu.Item leftSection={icons.download} onClick={handleDownloadClick}>
            Download Location Data
          </Menu.Item>
        )}
        <Menu.Item leftSection={icons.upload} onClick={handleUploadClick}>
          Upload a file
        </Menu.Item>
      </ActionsMenu>
      <ConditionalTooltip label={shareTooltip}>
        <Button
          color="#000000DE"
          size="xs"
          leftSection={icons.people}
          style={{ borderColor: '#000000DE' }}
          variant="outline"
          disabled={disableShare}
          onClick={handleShareClick}
        >
          SHARE
        </Button>
      </ConditionalTooltip>
      <SortToggleButton
        sort={state.sort}
        selectedCategories={state.selectedCategories}
        onSortChange={state.setSort}
        onCategoryToggle={state.toggleSelectedCategory}
      />
      <Box flex={1} />
      {state.selectedCategories.length > 0 && (
        <Pager
          currentPage={state.currentPage}
          pageSize={apiData.pageSize}
          totalCount={totalCount}
          nextPage={apiData.nextPage}
          previousPage={apiData.previousPage}
        />
      )}
    </Group>
  )
}
