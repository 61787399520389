import { getSocialImages, SEARCH_CATEGORIES } from '@/api/results'
import SocialIcon from '@/assets/social-icon.svg?react'
import type { ResultImageObject } from '@/features/ResultImage'
import type { SocialMediaResult } from '@/utils/types/result-types'
import { ReferenceCard } from './components/ReferenceCard'

export const SocialMediaReferenceCard = ({
  result,
  withActions,
}: {
  result: SocialMediaResult
  withActions?: boolean
}) => {
  const source = result.properties.source.toLowerCase()
  const images = getSocialImages(result)

  let image = null
  if (images.length > 0) {
    image = {
      thumbnail: images[0].url,
      title: source,
      source: images[0].url,
    }
  }
  return (
    <ReferenceCard
      heroImage={image as ResultImageObject}
      title={source}
      heroIcon={<SocialIcon />}
      authoredOn={result.authoredOn}
      description={result.title}
      withActions={withActions}
      result={result}
      category={SEARCH_CATEGORIES.SOCIAL_MEDIA}
    />
  )
}
