/* eslint-disable camelcase */
import { PostHogProvider } from 'posthog-js/react'
import type { PropsWithChildren } from 'react'

export function DantiPostHogProvider({ children }: PropsWithChildren) {
  return (
    <PostHogProvider
      apiKey={import.meta.env.VITE_POSTHOG_API_KEY}
      options={{
        api_host: 'https://dln3c850asnb9.cloudfront.net/',
        ui_host: 'https://us.posthog.com',
      }}
    >
      {children}
    </PostHogProvider>
  )
}
