import type { EmbeddedImage } from '@/api/results/types'
import type { PublicationResult } from '@/utils/types/result-types'

export const getNewsDetailCardProps = (post: PublicationResult) => ({
  iconName: 'newspaper' as const,
  highlightIcon: true,
  iconColor: '#ECEFF1',
  datetime: post.authoredOn,
  title: post.title,
  source: post.properties._dSource,
})

export const getNewsImages = (result: PublicationResult) => {
  const embedded = result.properties._dEmbedded as
    | EmbeddedImage[]
    | { _dEmbedded: EmbeddedImage[] }

  // GDELT images are not supported/relevant at the moment
  if (result.source === 'GDELT') {
    return []
  }
  if (Array.isArray(embedded)) {
    return embedded
  }
  return embedded._dEmbedded
}
