import {
  getNewsDetailCardProps,
  getNewsImages,
  SEARCH_CATEGORIES,
} from '@/api/results'
import { ICONS } from '@/utils/constants'
import type { PublicationResult } from '@/utils/types/result-types'
import { ReferenceCard } from './components/ReferenceCard'

export const NewsReferenceCard = ({
  result,
  withActions,
}: {
  result: PublicationResult
  withActions?: boolean
}) => {
  const images = getNewsImages(result)
  const image = images.length > 0 ? images[0].url : null
  const { datetime, title, source } = getNewsDetailCardProps(result)
  return (
    <ReferenceCard
      heroIconName={ICONS.newspaper}
      authoredOn={datetime}
      title={source}
      heroImageUrl={image}
      description={title}
      result={result}
      category={SEARCH_CATEGORIES.PUBLICATION}
      withActions={withActions}
    />
  )
}
