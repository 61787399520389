import React from 'react'
import { Box, Button, Divider, Group, Stack, TagsInput } from '@mantine/core'
import { isEmail } from '@mantine/form'
import type { ContextModalProps } from '@mantine/modals'
import { Icon } from '@/components/lib/Icon'
import type { OrgRole } from '@/gql/graphql.ts'
import { OrgRoleSelect } from './OrgRoleSelect'
import useInviteUserModalData from './use-invite-user-modal-data'

export function InviteUserModal({ context, id }: ContextModalProps) {
  const { form, isErrorState, onSubmit, closeModal } = useInviteUserModalData({
    context,
    id,
    innerProps: {},
  })

  const emailInputProps = form.getInputProps('emails')
  const emails = emailInputProps.value as string[]

  const roleInputProps = form.getInputProps('role')
  const role = roleInputProps.value as OrgRole
  const [searchValue, setSearchValue] = React.useState('')

  // If remaining search is a valid email, set it to emails
  const syncSearchValue = () => {
    const invalidEmail = isEmail('invalid email')(searchValue)
    if (!invalidEmail) {
      form.insertListItem('emails', searchValue)
      setSearchValue('')
    }
  }

  return (
    <Box p={12}>
      <Divider pb={24} />
      {isErrorState ? (
        <>Error on submit</>
      ) : (
        <form onSubmit={onSubmit}>
          <Stack p="12px">
            <TagsInput
              data={[]}
              label="By Email"
              placeholder={emails.length > 0 ? '' : 'Email Address'}
              searchValue={searchValue}
              onSearchChange={setSearchValue}
              onBlur={syncSearchValue}
              withAsterisk
              error={emailInputProps.error as string | undefined}
              value={emails}
              onChange={(x) => {
                form.setFieldValue('emails', x)
              }}
              rightSectionWidth={118}
              leftSection={<Icon name="search" />}
              rightSection={
                <OrgRoleSelect
                  role={role}
                  setRole={(role) => form.setFieldValue('role', role)}
                />
              }
            />
          </Stack>
          <Divider p={6} />
          <Group justify="space-between" mt="md">
            <Button
              leftSection={<Icon name="close" />}
              variant="transparent"
              color="black"
              style={{ fontWeight: 500 }}
              onClick={closeModal}
            >
              Cancel
            </Button>
            <Button type="submit">SHARE</Button>
          </Group>
        </form>
      )}
    </Box>
  )
}
