import { Button, Group, Text } from '@mantine/core'
import type { ContextModalProps } from '@mantine/modals'
import { useCollectionItems } from '@/api/collections/collection-items'

export interface RemoveFromCollectionModalProps {
  collectionId: string
  itemId: string
}

export function RemoveFromCollectionModal({
  context,
  id: modalId,
  innerProps: { collectionId, itemId },
}: ContextModalProps<RemoveFromCollectionModalProps>) {
  const { remove } = useCollectionItems(collectionId)

  async function handleDelete() {
    await remove({ collectionId, itemId })
    context.closeModal(modalId)
  }

  return (
    <>
      <Text>Are you sure you want to remove this from the saved search?</Text>
      <Group justify="space-between" mt="md">
        <Button variant="subtle" onClick={() => context.closeModal(modalId)}>
          Cancel
        </Button>
        <Button
          color="red"
          onClick={() => {
            handleDelete().catch((error) => console.error(error))
          }}
        >
          Remove
        </Button>
      </Group>
    </>
  )
}
