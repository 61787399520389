import * as React from 'react'
import { useUserData } from '@/api/user/use-user-data'
import { useUsersQuery } from '@/api/user/use-users-query'
import type { OrgUsersQuery as OrgUsersQueryType } from '@/gql/graphql.ts'

interface UseUsersHookResponse {
  users: NonNullable<OrgUsersQueryType['me']>['org']['users']['nodes']
  totalCount: number
  pageSize: number
  currentPage: number
  searchTerm: string
  setSearchTerm: (term: string) => void
  nextPage: () => void
  previousPage: () => void
}

export const useUsers = (): UseUsersHookResponse => {
  const {
    currentPage,
    setCurrentPage,
    nextToken,
    setNextToken,
    previousToken,
    setPreviousToken,
    searchTerm,
    setSearchTerm,
    pageSize,
  } = useUserData()

  const { users, pageInfo, totalCount } = useUsersQuery({
    nextToken,
    previousToken,
    pageSize,
    searchTerm,
  })

  const maxPages = React.useMemo(
    () => Math.ceil(totalCount / pageSize),
    [pageSize, totalCount],
  )

  const nextPage = () => {
    if (!pageInfo?.hasNextPage) {
      return
    }
    setPreviousToken('')
    setNextToken(pageInfo?.endCursor ?? '')
    setCurrentPage((page) => Math.min(maxPages, page + 1))
  }

  const previousPage = () => {
    if (!pageInfo?.hasPreviousPage) {
      return
    }
    setNextToken('')
    setPreviousToken(pageInfo?.startCursor ?? '')
    setCurrentPage((page) => Math.max(0, page - 1))
  }

  const search = React.useCallback(
    (term: string) => {
      setNextToken('')
      setPreviousToken('')
      setCurrentPage(0)
      setSearchTerm(term)
    },
    [setCurrentPage, setNextToken, setPreviousToken, setSearchTerm],
  )

  return {
    searchTerm,
    users,
    pageSize,
    currentPage,
    totalCount: totalCount ?? 0,
    nextPage,
    previousPage,
    setSearchTerm: search,
  }
}
