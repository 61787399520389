import {
  getNewsImages,
  getSocialImages,
  useAttomResults,
  useHazardPropertyResults,
  useImageResults,
  usePublicationResults,
  useSocialMediaResults,
} from '@/api/results'
import type { ImageryResult } from '@/utils/types/result-types'
import dayjs from 'dayjs'

export const useAttomWithPermits = () =>
  useAttomResults().filter(
    (result) => result.properties?.buildingPermits?.length > 0,
  )

export const useHazardPropertyResultsWithImages = () =>
  useHazardPropertyResults().filter((result) => {
    const images = result.properties?.images
    return Array.isArray(images) && images.length > 0
  })

export const useImagesByDate = () => {
  const results = useImageResults()
  return results.reduce(
    (object, image) => {
      const date = image.authoredOn
        ? dayjs(image.authoredOn).format('YYYY-MM-DD')
        : 'Unknown'
      if (!object[date]) {
        object[date] = []
      }
      object[date].push(image)
      return object
    },
    {} as Record<string, ImageryResult[]>,
  )
}
export const useImageById = (id: string | null) =>
  useImageResults().find((img) => img.id === id)

export const useNewsWithImages = () =>
  usePublicationResults().filter((result) => getNewsImages(result).length > 0)
export const useNewsWithoutImages = () =>
  usePublicationResults().filter((result) => getNewsImages(result).length === 0)

export const useSocialWithImages = () =>
  useSocialMediaResults().filter((result) => getSocialImages(result).length > 0)
export const useSocialWithoutImages = () =>
  useSocialMediaResults().filter(
    (result) => getSocialImages(result).length === 0,
  )
