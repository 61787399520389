import { postSearchRequest } from '../danti-requests'
import type { ResolveLocationsResponse } from './types'

export const resolveLocations = async (payload: string) => {
  return postSearchRequest<ResolveLocationsResponse>(
    'v2/multi/resolve',
    payload,
    { headers: { 'Content-Type': 'text/plain' } },
  )
}
