import { forwardRef } from 'react'
import { Button, type ButtonProps } from '@mantine/core'
import { Icon } from '@/components/lib/Icon'
import { openSearchFiltersModal } from '@/features/Filters/open-search-filters-modal'
import {
  useHasDefaultFilterValues,
  useMakeSearchFiltersFromCurrentPanelValues,
} from '@/stores/filters-store'

type FilterToggleButtonProps = ButtonProps & {
  activeColor?: null | string
  isFilterActive?: boolean
  fillIcon?: boolean
}
export const FilterToggleButton = forwardRef<
  HTMLButtonElement,
  FilterToggleButtonProps
>(
  (
    {
      children = null,
      size = 'sm',
      variant = 'subtle',
      isFilterActive = true,
      activeColor = 'dark',
      fillIcon = true,
      ...buttonProps
    },
    ref,
  ) => {
    const hasDefaultValues = useHasDefaultFilterValues()
    const currentFilters = useMakeSearchFiltersFromCurrentPanelValues()

    const defaultSize = children ? size : 'md'

    const icon = (
      <Icon
        size={defaultSize}
        name={hasDefaultValues() ? 'filter_alt_off' : 'filter_alt'}
        filled={isFilterActive && fillIcon && currentFilters.length > 0}
      />
    )

    return (
      <Button
        ref={ref}
        className="filterToggle"
        variant={variant}
        size={defaultSize}
        fw="normal"
        color={activeColor ?? buttonProps.color ?? 'blue'}
        onClick={openSearchFiltersModal}
        {...(children && { leftSection: icon })}
        {...buttonProps}
      >
        {children || icon}
      </Button>
    )
  },
)

FilterToggleButton.displayName = 'FilterToggleButton'
