import { Box, Checkbox, Divider, Stack, Text } from '@mantine/core'
import { classes } from '@/utils/ui-helpers'
import type { Categories } from '../constants'
import type { EnabledCheckboxes, SelectedCheckboxes } from '../types'

import styles from './image-providers-checkboxes.module.css'

type CategoryProps = {
  items: string[]
  selectedItems: SelectedCheckboxes[Categories]
  enabledItems: EnabledCheckboxes[Exclude<Categories, Categories.PROVIDERS>]
  category: Categories
  labelsList: Record<string, string>
  handleSelectAllClick: (checked: boolean, category: Categories) => void
  handleItemCheckboxChange: (
    checked: boolean,
    category: Categories,
    item: string,
  ) => void
  handleHover: (category: Categories, item?: string) => void
}

export const Category = ({
  items,
  selectedItems,
  enabledItems,
  category,
  labelsList,
  handleSelectAllClick,
  handleItemCheckboxChange,
  handleHover,
}: CategoryProps) => {
  const isSelectAllDisabled = enabledItems.size !== items.length
  return (
    <Stack className={styles.categoryContainer}>
      <Text tt="capitalize">{category}</Text>
      <Box>
        <Checkbox
          label={selectedItems.size > 0 ? 'Deselect All' : 'Select All'}
          size="xs"
          classNames={{
            root: styles.selectAllCheckbox,
            label: styles.checkboxLabel,
          }}
          className={classes(isSelectAllDisabled && styles.disabled)}
          disabled={isSelectAllDisabled}
          checked={selectedItems.size > 0}
          onChange={() => {}}
          wrapperProps={{
            onClick: () => {
              // mimic disabled state
              if (isSelectAllDisabled) {
                return
              }
              handleSelectAllClick(selectedItems.size === 0, category)
            },
          }}
        />
        <Divider />
        <Stack className={styles.checkboxGroup}>
          {items.sort().map((item) => {
            const isDisabled = !enabledItems.has(item)
            return (
              <Box
                key={item}
                onMouseEnter={() => handleHover(category, item)}
                onMouseLeave={() => handleHover(category)}
              >
                <Checkbox
                  size="xs"
                  label={labelsList[item]}
                  classNames={{
                    root: styles.checkbox,
                    label: styles.checkboxLabel,
                  }}
                  className={classes(isDisabled && styles.disabled)}
                  disabled={isDisabled}
                  checked={selectedItems.has(item)}
                  onChange={() => {}}
                  wrapperProps={{
                    onClick: () => {
                      // mimic disabled state
                      if (isDisabled) {
                        return
                      }
                      handleItemCheckboxChange(
                        !selectedItems.has(item),
                        category,
                        item,
                      )
                    },
                  }}
                />
              </Box>
            )
          })}
        </Stack>
      </Box>
    </Stack>
  )
}
