import { Stack, Text } from '@mantine/core'
import { Icon } from '@/components/lib/Icon'
import type { FileResult } from '@/utils/types/result-types'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

export function FileReferenceCard({ result }: { result: FileResult }) {
  const fileType = result.title.replace(/^.*\./, '')
  return (
    <>
      <Stack
        gap={0}
        align="center"
        justify="center"
        bg="gray.2"
        style={{
          aspectRatio: 2.4,
          borderRadius: 'var(--mantine-radius-md)',
          cursor: 'pointer',
        }}
      >
        <Icon name="upload_file" size="3rem" />
        <Text fz="md" fw="bold" data-no-hover>
          {fileType}
        </Text>
      </Stack>

      <Stack gap={0} mt="xs">
        {dayjs(result.authoredOn).utc().format('MM/DD/YY h:mm a').toUpperCase()}
        <Text fz="xs" fw="bold">
          {result.title}
        </Text>
      </Stack>
    </>
  )
}
