import type { LayerConfig, SourceCoordinates } from '@/features/DantiMap/types'

export const addSource = ({
  map,
  data,
  sourceId,
  type,
  sourceUrl,
  sourceCoordinates,
  sourceTiles,
  sourceBounds,
}: LayerConfig & {
  map: mapboxgl.Map | null
}) => {
  if (map === null) {
    return
  }
  // add source
  const dataSource = map.getSource(sourceId) as mapboxgl.GeoJSONSource | null
  if (dataSource) {
    if (data) {
      dataSource.setData(data as GeoJSON.FeatureCollection)
    }
  } else {
    if (type === 'raster') {
      if (sourceUrl) {
        map.addSource(sourceId, {
          type: 'image',
          url: sourceUrl,
          coordinates: sourceCoordinates as SourceCoordinates,
        })
      } else {
        map.addSource(sourceId, {
          type: 'raster',
          tiles: sourceTiles,
          bounds: sourceBounds,
        })
      }
    } else {
      map.addSource(sourceId, {
        type: 'geojson',
        data: data as GeoJSON.FeatureCollection,
      })
    }
  }
}
