import React from 'react'
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  Group,
  Stack,
  TagsInput,
  Text,
  Textarea,
} from '@mantine/core'
import { isEmail } from '@mantine/form'
import type { ContextModalProps } from '@mantine/modals'
import type { ShareRoleType } from '@/api/collections'
import type { Collection } from '@/api/collections/types'
import { useUsers } from '@/api/user'
import { Icon } from '@/components/lib/Icon'
import { useFeatureFlag } from '@/hooks/use-feature-flag'
import { FEATURE_FLAG_VALUES } from '@/utils/constants'
import { captureEvent, POSTHOG_EVENTS } from '@/utils/posthog'
import { ShareRoleSelect } from './ShareRoleSelect'
import { useShareModalData } from './use-share-modal-data'
import { UserList } from './UserList'

export function ShareModal({
  context,
  id,
  innerProps: { collection },
}: ContextModalProps<{ collection: Collection }>) {
  const shouldSupportAutocompletion = useFeatureFlag(
    FEATURE_FLAG_VALUES.accountManagement,
  )
  const { users, setSearchTerm, searchTerm } = useUsers()

  const {
    form,
    isErrorState,
    onSubmit,
    closeModal,
    handleRoleUpdate,
    handleUnshare,
  } = useShareModalData({
    context,
    id,
    innerProps: { collection },
  })

  const emailInputProps = form.getInputProps('emails')
  const emails = emailInputProps.value as string[]

  const roleInputProps = form.getInputProps('role')
  const role = roleInputProps.value as ShareRoleType
  const [searchValue, setSearchValue] = React.useState('')

  // If remaining search is a valid email, set it to emails
  const syncSearchValue = () => {
    const invalidEmail = isEmail('invalid email')(searchValue)
    if (!invalidEmail) {
      form.insertListItem('emails', searchValue)
      setSearchValue('')
    }
  }

  const syncSearchTerm = () => {
    const invalidEmail = isEmail('invalid email')(searchTerm)
    if (!invalidEmail) {
      form.insertListItem('emails', searchTerm)
      setSearchTerm('')
    }
  }

  const handleSubmit = (event?: React.FormEvent<HTMLFormElement>) => {
    captureEvent(POSTHOG_EVENTS.COLLECTIONS.SHARE, {
      collectionName: collection.name,
      collectionId: collection.id,
      recipient: emails.join(', '),
    })

    onSubmit(event)
  }

  const userEmails = React.useMemo(
    () => (searchTerm === '' ? [] : users.map((user) => user.email)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [users],
  )

  return (
    <Box p={12}>
      <Divider pb={24} />
      {isErrorState ? (
        <>Error on submit</>
      ) : (
        <form onSubmit={handleSubmit}>
          <Stack pl="12px">
            <Chip
              mt={-4}
              icon={<Icon name="folder" size="xs" />}
              color="gray"
              variant="outline"
              checked
            >
              <Text>{collection?.name}</Text>
            </Chip>
          </Stack>
          <Stack pt="md" p="12px">
            <TagsInput
              searchValue={
                shouldSupportAutocompletion ? searchTerm : searchValue
              }
              onSearchChange={
                shouldSupportAutocompletion ? setSearchTerm : setSearchValue
              }
              onBlur={
                shouldSupportAutocompletion ? syncSearchTerm : syncSearchValue
              }
              data={userEmails}
              placeholder={emails.length > 0 ? '' : 'Email Address'}
              withAsterisk
              splitChars={[',', ' ']}
              error={emailInputProps.error as string | undefined}
              value={emails}
              onChange={(x) => {
                form.setFieldValue('emails', x)
              }}
              rightSectionWidth={118}
              leftSection={<Icon name="search" />}
              rightSection={
                <ShareRoleSelect
                  role={role}
                  setRole={(role) => form.setFieldValue('role', role)}
                />
              }
            />
          </Stack>
          {emails.length === 0 ? (
            <Stack p="12px">
              <UserList
                collection={collection}
                onRoleUpdate={handleRoleUpdate}
                onUnshare={handleUnshare}
              />
            </Stack>
          ) : (
            <>
              <Stack p="12px">
                <Textarea
                  label="Message"
                  placeholder="Instructions for Recipient"
                  labelProps={{ fontSize: '16px', fontWeight: '700' }}
                  size="sm"
                  {...form.getInputProps('message')}
                  data-cy="share-collection-emails"
                />
              </Stack>
              <Stack p="12px" pb={22}>
                <Checkbox
                  label="I acknowledge that recipients of this email are authorized to view this imagery"
                  {...form.getInputProps('consent')}
                />
              </Stack>
            </>
          )}

          <Divider p={6} />
          <Group justify="space-between" mt="md">
            <Button
              leftSection={<Icon name="close" />}
              variant="transparent"
              color="black"
              style={{ fontWeight: 500 }}
              onClick={closeModal}
            >
              Cancel
            </Button>
            <Button type="submit">SHARE</Button>
          </Group>
        </form>
      )}
    </Box>
  )
}
