export function Error() {
  return (
    <div>
      <span role="img" aria-label="Sad">
        Sorry, there is an error in the page you are looking for &#x1F643;
      </span>
    </div>
  )
}

export default Error
