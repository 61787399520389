import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Text } from '@mantine/core'
import { useDantiAuth } from '@/hooks/use-danti-auth'
import { ROUTES } from '@/utils/constants.ts'

export function Subscribed() {
  const navigate = useNavigate()
  const { refreshUser } = useDantiAuth()
  const [timeLeft, setTimeLeft] = React.useState(5)

  React.useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft((previousTime) => previousTime - 1)
    }, 1000)
    return () => clearInterval(interval)
  }, [])

  React.useEffect(() => {
    if (timeLeft === 0) {
      const refreshAndNavigate = async () => {
        await refreshUser()
        navigate(ROUTES.root)
      }
      void refreshAndNavigate()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeLeft])

  return (
    <>
      <h1>Subscribed successfully!</h1>
      <Text>{`You'll be redirected to start searching in ${timeLeft} seconds.`}</Text>
    </>
  )
}
