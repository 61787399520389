import { Icon } from '@/components/lib/Icon'
import { DetailCard } from '@/features/ResultCards/ReferenceCards/components'
import { RESULT_CATEGORY_COLORS } from '@/theme/colors'
import { ICONS } from '@/utils/constants'
import type { AttomPermit } from '@/utils/types/result-types'

export const AttomPermitReferenceCard = ({
  result,
}: {
  result: AttomPermit
}) => (
  <DetailCard
    icon={<Icon name={ICONS.construction} />}
    title={result.permitNumber || 'No Permit Number'}
    iconColor={RESULT_CATEGORY_COLORS.attom}
    leftDetail={result.effectiveDate || 'No Date'}
  />
)
