import type { SearchApiFilterValue } from '@/utils/types/filter-types.ts'

export const makeOrientationsFilter = (
  orientations: { start: number; end: number }[],
): SearchApiFilterValue => {
  return {
    type: 'ORIENTATION',
    args: orientations,
  }
}
