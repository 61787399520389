import { HIDE_ACTIONS_BY_CATEGORY } from '@/features/ResultActions/constants'
import { SingleObjectViewTemplate } from '@/features/SingleObjectView/components/SingleObjectViewTemplate'
import { RESULT_CATEGORIES } from '@/utils/constants'
import { formatDatetime } from '@/utils/format-datetime'
import type { RegridResult, ResultWithId } from '@/utils/types/result-types'

const category = RESULT_CATEGORIES.REGRID
const hideActions = HIDE_ACTIONS_BY_CATEGORY[category]

interface ItemDetailProps {
  item: ResultWithId
}

export function RegridObjectView({ item: rawItem }: ItemDetailProps) {
  const item = rawItem as RegridResult

  return (
    <SingleObjectViewTemplate<RegridResult, null>
      {...{
        item,
        hideActions,
        hero: {
          geometry: item.geometry,
          metaDetails: [formatDatetime(item.authoredOn)],
        },
        content: { title: item.properties.address },
      }}
    />
  )
}
