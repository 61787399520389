import { Text } from '@mantine/core'

import styles from './reference-cards.module.css'

export interface DetailCardProps {
  iconColor: string
  icon: React.ReactNode
  title: string
  leftDetail?: string
  rightDetail?: string
}

export function DetailCard({
  iconColor,
  icon,
  title,
  leftDetail,
  rightDetail,
}: DetailCardProps) {
  return (
    <div className={styles['sov-detail-category-card-wrapper']}>
      <div className={styles['sov-detail-category-card']}>
        <div
          className={styles['icon-wrapper']}
          style={{ backgroundColor: iconColor }}
        >
          {icon}
        </div>
        <div className={styles['details-container']}>
          <Text className={styles['title']} lineClamp={2}>
            {title}
          </Text>
          {leftDetail && (
            <div className={styles['left-detail']}>
              <Text lineClamp={1}>{leftDetail}</Text>
            </div>
          )}
          {rightDetail && (
            <div className={styles['right-detail']}>
              <Text lineClamp={1}>{rightDetail}</Text>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
