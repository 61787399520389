import { Group, Text } from '@mantine/core'
import type { Collection } from '@/api/collections/types.ts'
import { Icon } from '@/components/lib/Icon'
import { TableHeaderSortCell } from '@/features/Collections/components'
import { SORT_TYPES } from '@/features/Collections/constants'
import { formatDatetime } from '@/utils/format-datetime'
import { createColumnHelper } from '@tanstack/react-table'
import { CollectionMenu } from './CollectionMenu'

const columnHelper = createColumnHelper<Collection>()

export const CollectionTableColumns = [
  columnHelper.accessor('name', {
    header: () => {
      return (
        <TableHeaderSortCell
          label="Saved Search Name"
          sortType={SORT_TYPES.name}
        />
      )
    },
    cell: (info) => {
      const isTerrapin = Boolean(info.row.original.refId)
      const icon = isTerrapin ? 'folder_special' : 'space_dashboard'
      return (
        <Group gap={12}>
          <Icon name={icon} filled />
          <Text>{info.getValue()}</Text>
        </Group>
      )
    },
  }),

  columnHelper.accessor('lastActivity', {
    header: () => (
      <TableHeaderSortCell label="Last updated" sortType={SORT_TYPES.date} />
    ),
    cell: (info) => (
      <Text p={6}>{formatDatetime(info.getValue().toString())}</Text>
    ),
  }),

  columnHelper.accessor('refId', {
    header: () => (
      <Text fw={500} fz={12}>
        Kind
      </Text>
    ),
    cell: (info) => {
      const isTerrapin = Boolean(info.row.original.refId)
      return (
        <Group gap={12}>
          <Text>
            Saved Search
            {isTerrapin && (
              <Icon
                name="loop"
                size={16}
                style={{ verticalAlign: 'middle', marginLeft: '4px' }}
              />
            )}
          </Text>
        </Group>
      )
    },
  }),

  columnHelper.accessor((row) => row, {
    id: 'actions',
    header: '',
    cell: (info) => (
      <CollectionMenu collection={info.getValue()} orientation="horizontal" />
    ),
  }),
]
