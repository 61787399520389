import { fetchSignedS3URL } from '@/api/thumbnail'
import { useQueries } from '@tanstack/react-query'

export function useTileUrls(tiles: { url: string; isImageSource: boolean }[]) {
  return useQueries({
    queries: tiles.map(({ url, isImageSource }) => ({
      queryKey: ['tileUrls', url],
      queryFn: isImageSource ? async () => fetchSignedS3URL(url) : () => url,
      retry: false,
    })),
  })
}
