import { GeometryStaticDisplay } from '@/features/GeometryStaticDisplay'
import type { MultiPolygon, Point, Polygon } from 'geojson'
import { BentoCardIcon } from './BentoCardIcon'
import { BentoCardImageContent } from './BentoCardImageContent'
import { BentoCardTextContent } from './BentoCardTextContent'

interface ImageCardContent {
  image?: {
    title: string
    source: string
    thumbnail?: string
  }
}
interface GeometryCardContent {
  geometry?: Polygon | Point | MultiPolygon
}
interface DataCardContent {
  content?: {
    heading?: React.ReactNode
    subHeading: React.ReactNode
    color?: string
    fontColor?: string
  }
}

export interface BentoCardContentParams
  extends ImageCardContent,
    GeometryCardContent,
    DataCardContent {
  highlightAction?: boolean
  highlightIcon?: boolean
  icon?: string
  svgIcon?: React.ReactNode
  socialIcon?: React.ReactNode
  size?: string
}
export const BentoCardContent = ({
  icon,
  highlightIcon,
  image,
  geometry,
  content,
  size = 'md',
  svgIcon,
  socialIcon,
}: BentoCardContentParams) => (
  <>
    <BentoCardImageContent {...{ image }} />
    <BentoCardIcon
      {...{
        icon,
        highlightIcon,
        svgIcon,
        socialIcon,
      }}
    />
    <BentoCardTextContent {...{ content, size }} />
    <GeometryStaticDisplay geometry={geometry} />
  </>
)

export default BentoCardContent
