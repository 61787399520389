import { useNavigateToMapItemCallback } from '@/features/AppRouting/hooks'
import { GeometryStaticDisplay } from '@/features/GeometryStaticDisplay'
import type { ResultWithId } from '@/utils/types/result-types'
import type { MultiPolygon, Point, Polygon } from 'geojson'

export function SOVHeroGeometry<Type>({
  geometry,
  item,
  color,
}: {
  item: Type
  geometry?: Polygon | Point | MultiPolygon
  color?: string
}) {
  const onClick = useNavigateToMapItemCallback()(item as ResultWithId)
  return <GeometryStaticDisplay {...{ geometry, color, onClick }} />
}

export default SOVHeroGeometry
