import {
  BUCKET_MAX_THRESHOLD,
  type TimeBucketUnit,
  timeBucketUnitKeys,
} from '@/features/ResultsTimeline/constants'
import type { TimeBucketConfig } from '@/features/ResultsTimeline/types'
import {
  timeDay,
  timeDays,
  timeMonth,
  timeMonths,
  timeSunday,
  timeSundays,
  timeYear,
  timeYears,
} from '@visx/vendor/d3-time'
import {
  format,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
} from 'date-fns'

const makeSpanDisplayFn =
  (spanFormatter: (d: Date) => string) => (d1: Date, d2: Date) => {
    return spanFormatter(d1) === spanFormatter(d2)
      ? spanFormatter(d1)
      : `${spanFormatter(d1)} - ${spanFormatter(d2)}`
  }

export const timeBucketConfigs: {
  [key in keyof typeof TimeBucketUnit]: TimeBucketConfig
} = {
  HOUR: {
    bucketUnit: 'DAY',
    rangeFn: timeDays,
    offsetFn: (d, s) => timeDay.offset(d, s),
    periodStartFn: startOfDay,
    displayStringFn: (d) => format(d, 'PP'),
    spanDisplayFn: makeSpanDisplayFn((d) => format(d, 'MMM yyyy')),
    tickDisplayFn: (d) => format(d, 'M/d'),
    maxBucketThreshold: 90,
  },
  DAY: {
    bucketUnit: 'DAY',
    rangeFn: timeDays,
    offsetFn: (d, s) => timeDay.offset(d, s),
    periodStartFn: startOfDay,
    displayStringFn: (d) => format(d, 'PP'),
    spanDisplayFn: makeSpanDisplayFn((d) => format(d, 'MMM yyyy')),
    tickDisplayFn: (d) => format(d, 'M/d'),
    maxBucketThreshold: 90,
  },
  WEEK: {
    bucketUnit: 'WEEK',
    rangeFn: timeSundays,
    offsetFn: (d, s) => timeSunday.offset(d, s),
    periodStartFn: startOfWeek,
    displayStringFn: (d) => format(startOfWeek(d), `'Week of 'P`),
    spanDisplayFn: makeSpanDisplayFn((d) => format(d, 'MMM yyyy')),
    tickDisplayFn: (d) => format(startOfWeek(d), 'M/d'),
    maxBucketThreshold: 50,
  },
  MONTH: {
    bucketUnit: 'MONTH',
    rangeFn: timeMonths,
    offsetFn: (d, s) => timeMonth.offset(d, s),
    periodStartFn: startOfMonth,
    displayStringFn: (d) => format(d, 'LLL yyyy'),
    spanDisplayFn: makeSpanDisplayFn((d) => format(d, 'yyyy')),
    tickDisplayFn: (d) => format(d, 'MMM'),
    maxBucketThreshold: 60,
  },
  YEAR: {
    bucketUnit: 'YEAR',
    rangeFn: timeYears,
    offsetFn: (d, s) => timeYear.offset(d, s),
    periodStartFn: startOfYear,
    displayStringFn: (d) => format(d, 'yyyy'),
    spanDisplayFn: makeSpanDisplayFn((d) => format(d, 'yyyy')),
    tickDisplayFn: (d) => format(d, 'yyyy'),
    maxBucketThreshold: 1e6,
  },
}

export const getConfigKeyForRange = (
  startDate: Date,
  endDate: Date,
  bucketMaxThreshold = BUCKET_MAX_THRESHOLD,
): keyof typeof TimeBucketUnit => {
  return (
    timeBucketUnitKeys.find(
      (v) =>
        timeBucketConfigs[v].rangeFn(startDate, endDate).length <
        bucketMaxThreshold,
    ) || 'YEAR'
  )
}

export const getConfigForRange = (
  startDate: Date,
  endDate: Date,
  bucketMaxThreshold = BUCKET_MAX_THRESHOLD,
): TimeBucketConfig => {
  const key = getConfigKeyForRange(startDate, endDate, bucketMaxThreshold)
  return timeBucketConfigs[key]
}
