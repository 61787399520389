import { useNavigate } from 'react-router-dom'
import { Button, Group, Text } from '@mantine/core'
import type { ContextModalProps } from '@mantine/modals'
import { useCollections } from '@/api/collections'

export interface DeleteCollectionModalParams {
  collectionId: string
}

export function DeleteCollectionModal({
  context,
  id: modalId,
  innerProps: { collectionId },
}: ContextModalProps<DeleteCollectionModalParams>) {
  const navigate = useNavigate()
  const { remove } = useCollections()

  function handleDelete() {
    remove(collectionId).catch((error) => console.error(error))
    context.closeModal(modalId)
    navigate('/collections')
  }

  return (
    <>
      <Text>Are you sure you want to delete this saved search?</Text>
      <Group justify="space-between" mt="md">
        <Button variant="subtle" onClick={() => context.closeModal(modalId)}>
          Cancel
        </Button>
        <Button color="red" onClick={handleDelete}>
          Delete
        </Button>
      </Group>
    </>
  )
}
