import ReactDOM from 'react-dom/client'
import { configureSentry } from '@/hooks/use-sentry-metadata-syncing'
import { App } from './App'

configureSentry()

const rootElement = document.querySelector('#root') as Element

if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement)
  root.render(<App />)
}
