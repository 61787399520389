import React from 'react'
import { Box, Button, Divider, Text } from '@mantine/core'
import { Icon } from '@/components/lib/Icon'
import type { FilterArgs } from '@/features/Filters/types'
import type { OrientationValue } from '@/stores/filters-store'
import { PieChart } from 'react-minimal-pie-chart'

import styles from './orientation.module.css'

const allSegments = ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW']
const segmentSize = 45
const segmentOffset = 22.5

const allSegmentRanges = allSegments.reduce(
  (result, key, index) => {
    const startValue = index * segmentSize - segmentOffset
    result[key] = {
      start: startValue < 0 ? 360 + startValue : startValue,
      end: index * segmentSize + segmentOffset,
    }
    return result
  },
  {} as Record<string, { start: number; end: number }>,
)

function segmentsToRanges(segments: string[]) {
  return segments.map((segment) => allSegmentRanges[segment])
}

function orientationStateToSegments(
  orientations: { start: number; end: number }[],
) {
  if (orientations.length === 0) {
    return allSegments
  }

  const selectedSegments: string[] = orientations.map((range) => {
    switch (range.start) {
      case 337.5:
      case 0:
        return 'N'
      case 22.5:
        return 'NE'
      case 67.5:
        return 'E'
      case 112.5:
        return 'SE'
      case 157.5:
        return 'S'
      case 202.5:
        return 'SW'
      case 247.5:
        return 'W'
      case 292.5:
        return 'NW'
      default:
        return 'N'
    }
  })

  return selectedSegments
}

export function Orientation({ value, setter }: FilterArgs<OrientationValue[]>) {
  const [selectedSegments, setSelectedSegments] = React.useState(
    orientationStateToSegments(value),
  )
  const [showError, setShowError] = React.useState(false)

  React.useEffect(() => {
    setSelectedSegments(orientationStateToSegments(value))
    setShowError(false)
  }, [value])

  function selectAll() {
    const allRanges = Object.values(allSegmentRanges)
    setter(allRanges)
  }

  function handleSegmentClick(_: React.MouseEvent, index: number) {
    const clickedSegment = allSegments[index]
    const isDeselect = selectedSegments.includes(clickedSegment)
    const isLastSegment = selectedSegments.length === 1 && isDeselect

    if (isLastSegment) {
      setShowError(true)
      return
    }

    const newSelection = selectedSegments.includes(clickedSegment)
      ? selectedSegments.filter((x) => x !== clickedSegment)
      : [...selectedSegments, clickedSegment]
    setSelectedSegments(newSelection)
    setter(segmentsToRanges(newSelection))
  }

  return (
    <Box w="100%">
      <Text size="md" mb="xs">
        Orientation
      </Text>
      <Button
        variant="subtle"
        size="xs"
        ml="-sm"
        c="black"
        fw="normal"
        onClick={selectAll}
      >
        Select All
      </Button>
      <Divider />
      <div className={styles.chartContainer}>
        <PieChart
          style={{ height: '160px', width: '160px' }}
          label={({ dataEntry }) => dataEntry.title}
          labelStyle={(dataIndex) => ({
            pointerEvents: 'none',
            userSelect: 'none',
            fontSize: '7px',
            fontFamily: 'Roboto, sans-serif',
            fontWeight: 'bold',
            fill: selectedSegments.includes(allSegments[dataIndex])
              ? 'var(--mantine-color-white)'
              : 'var(--mantine-color-black)',
          })}
          data={allSegments.map((direction, index) => ({
            title: index % 2 === 0 ? direction : '',
            value: 12.5,
            color: selectedSegments.includes(direction)
              ? 'var(--mantine-color-blue-6)'
              : 'var(--mantine-color-gray-4)',
          }))}
          segmentsStyle={{ transition: 'stroke 200ms', cursor: 'pointer' }}
          labelPosition={77}
          lineWidth={45}
          segmentsShift={2}
          radius={48}
          startAngle={-112.5}
          onClick={handleSegmentClick}
        />
        <div className={styles.orientationIcon}>
          <Icon
            size="1.5rem"
            name="satellite_alt"
            style={{ color: 'var(--mantine-color-white)' }}
          />
        </div>
      </div>
      <Text
        size="xs"
        c="red"
        mt="xs"
        mb="-sm"
        style={{ opacity: showError ? 1 : 0 }}
      >
        At least one must be selected
      </Text>
    </Box>
  )
}
