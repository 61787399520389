import { Table } from '@mantine/core'
import { useCollections } from '@/api/collections'
import { useNavigateToCollection } from '@/features/AppRouting/hooks'
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { CollectionTableColumns } from './CollectionTableColumns'

export function CollectionTable() {
  const { collections } = useCollections()
  const navigateToCollection = useNavigateToCollection()
  const handleRowClick = (row: { id: string }) => () =>
    navigateToCollection(row.id)

  const table = useReactTable({
    data: collections,
    columns: CollectionTableColumns,
    getCoreRowModel: getCoreRowModel(),
    getRowId: (collection) => collection.id,
  })

  return (
    <Table highlightOnHover visibleFrom="sm">
      <Table.Thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <Table.Tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <Table.Th key={header.id} pt={8} pb={8}>
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
              </Table.Th>
            ))}
          </Table.Tr>
        ))}
      </Table.Thead>
      <Table.Tbody>
        {table.getRowModel().rows.map((row) => (
          <Table.Tr
            style={{ cursor: 'pointer' }}
            key={row.id}
            onClick={handleRowClick(row)}
          >
            {row.getVisibleCells().map((cell) => (
              <Table.Td key={cell.id}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </Table.Td>
            ))}
          </Table.Tr>
        ))}
      </Table.Tbody>
    </Table>
  )
}
